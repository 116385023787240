"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.SoylentSeedlingSystems = void 0;
var CardType_1 = require("../CardType");
var CardName_1 = require("../../CardName");
var CardRenderer_1 = require("../render/CardRenderer");
var Card_1 = require("../Card");
var Tags_1 = require("../Tags");
var Size_1 = require("../render/Size");
var Options_1 = require("../Options");
var ResourceType_1 = require("../../common/ResourceType");
var TileType_1 = require("../../common/TileType");
var SoylentSeedlingSystems = (function (_super) {
    __extends(SoylentSeedlingSystems, _super);
    function SoylentSeedlingSystems() {
        var _this = _super.call(this, {
            cardType: CardType_1.CardType.CORPORATION,
            name: CardName_1.CardName.SOYLENT_SEEDLING_SYSTEMS,
            tags: [Tags_1.Tags.SCIENCE, Tags_1.Tags.PLANT],
            startingMegaCredits: 38,
            resourceType: ResourceType_1.ResourceType.SEED,
            metadata: {
                cardNumber: 'PfC8',
                renderData: CardRenderer_1.CardRenderer.builder(function (b) {
                    b.megacredits(38).seed().seed().br;
                    b.effect('When you place a greenery tile, add 1 seed resource to this card.', function (eb) {
                        eb.greenery().startEffect.seed();
                    }).br;
                    b.effect('When paying for a plant card, or the STANDARD GREENERY PROJECT, seeds here may be used as 5 M€ each.', function (eb) {
                        eb.plants(1, { played: Options_1.played }).slash().greenery(Size_1.Size.MEDIUM, true).startEffect.seed().equals().megacredits(5);
                    }).br;
                }),
                description: 'You starrt with 38M€ and 2 seeds on this card.',
            },
        }) || this;
        _this.resourceCount = 0;
        return _this;
    }
    SoylentSeedlingSystems.prototype.play = function () {
        this.resourceCount += 2;
        return undefined;
    };
    SoylentSeedlingSystems.prototype.onTilePlaced = function (cardOwner, activePlayer, space) {
        var _a;
        if (cardOwner.id !== activePlayer.id) {
            return;
        }
        if (((_a = space.tile) === null || _a === void 0 ? void 0 : _a.tileType) === TileType_1.TileType.GREENERY) {
            cardOwner.addResourceTo(this, { log: true });
        }
    };
    return SoylentSeedlingSystems;
}(Card_1.Card));
exports.SoylentSeedlingSystems = SoylentSeedlingSystems;
