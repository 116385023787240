"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.PersonalAgenda = void 0;
var PreludeCard_1 = require("../prelude/PreludeCard");
var CardName_1 = require("../../CardName");
var CardRenderer_1 = require("../render/CardRenderer");
var Tags_1 = require("../Tags");
var CardType_1 = require("../CardType");
var Units_1 = require("../../Units");
var PersonalAgenda = (function (_super) {
    __extends(PersonalAgenda, _super);
    function PersonalAgenda() {
        return _super.call(this, {
            name: CardName_1.CardName.PERSONAL_AGENDA,
            productionBox: Units_1.Units.of({ megacredits: 3 }),
            metadata: {
                cardNumber: 'P08',
                renderData: CardRenderer_1.CardRenderer.builder(function (b) {
                    b.production(function (pb) { return pb.megacredits(3); }).br;
                    b.cards(3, { secondaryTag: Tags_1.Tags.EVENT }).asterix();
                }),
                description: 'Increase your M€ production 3 steps. Draw 3 event cards that do not have a space tag.',
            },
        }) || this;
    }
    PersonalAgenda.prototype.play = function (player) {
        player.adjustProduction(this.productionBox, { log: true });
        player.drawCard(3, {
            include: function (card) {
                return card.cardType === CardType_1.CardType.EVENT &&
                    (card.tags.includes(Tags_1.Tags.SPACE) === false);
            }
        });
        return undefined;
    };
    return PersonalAgenda;
}(PreludeCard_1.PreludeCard));
exports.PersonalAgenda = PersonalAgenda;
