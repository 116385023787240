"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.Loan = void 0;
var PreludeCard_1 = require("./PreludeCard");
var Resources_1 = require("../../common/Resources");
var CardName_1 = require("../../CardName");
var CardRenderer_1 = require("../render/CardRenderer");
var Loan = (function (_super) {
    __extends(Loan, _super);
    function Loan() {
        return _super.call(this, {
            name: CardName_1.CardName.LOAN,
            startingMegacredits: 30,
            metadata: {
                cardNumber: 'P17',
                renderData: CardRenderer_1.CardRenderer.builder(function (b) {
                    b.production(function (pb) { return pb.minus().megacredits(2); }).br;
                    b.megacredits(30);
                }),
                description: 'Gain 30 M€. Decrease your M€ production 2 steps.',
            },
        }) || this;
    }
    Loan.prototype.canPlay = function (player) {
        return player.getProduction(Resources_1.Resources.MEGACREDITS) >= -3;
    };
    Loan.prototype.play = function (player) {
        player.addProduction(Resources_1.Resources.MEGACREDITS, -2);
        player.megaCredits += 30;
        return undefined;
    };
    return Loan;
}(PreludeCard_1.PreludeCard));
exports.Loan = Loan;
