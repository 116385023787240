"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.VictoryPointsBreakdown = void 0;
var VictoryPointsBreakdown = (function () {
    function VictoryPointsBreakdown() {
        this.terraformRating = 0;
        this.milestones = 0;
        this.awards = 0;
        this.greenery = 0;
        this.city = 0;
        this.escapeVelocity = 0;
        this.moonColonies = 0;
        this.moonMines = 0;
        this.moonRoads = 0;
        this.planetaryTracks = 0;
        this.victoryPoints = 0;
        this.total = 0;
        this.detailsCards = [];
        this.detailsMilestones = [];
        this.detailsAwards = [];
        this.detailsPlanetaryTracks = [];
    }
    VictoryPointsBreakdown.prototype.updateTotal = function () {
        this.total = 0;
        this.total += this.terraformRating;
        this.total += this.milestones;
        this.total += this.awards;
        this.total += this.greenery;
        this.total += this.city;
        this.total += this.escapeVelocity;
        this.total += this.moonColonies;
        this.total += this.moonMines;
        this.total += this.moonRoads;
        this.total += this.planetaryTracks;
        this.total += this.victoryPoints;
    };
    VictoryPointsBreakdown.prototype.setVictoryPoints = function (key, points, message) {
        switch (key) {
            case 'terraformRating':
                this.terraformRating += points;
                break;
            case 'milestones':
                this.milestones += points;
                if (message !== undefined)
                    this.detailsMilestones.push(message + ':' + points);
                break;
            case 'awards':
                this.awards += points;
                if (message !== undefined)
                    this.detailsAwards.push(message + ':' + points);
                break;
            case 'greenery':
                this.greenery += points;
                break;
            case 'city':
                this.city += points;
                break;
            case 'escapeVelocity':
                this.escapeVelocity += points;
                break;
            case 'victoryPoints':
                this.victoryPoints += points;
                if (message !== undefined)
                    this.detailsCards.push({ cardName: message, victoryPoint: points });
                break;
            case 'moon colony':
                this.moonColonies += points;
                break;
            case 'moon mine':
                this.moonMines += points;
                break;
            case 'moon road':
                this.moonRoads += points;
                break;
            case 'planetary tracks':
                this.planetaryTracks += points;
                if (message !== undefined)
                    this.detailsPlanetaryTracks.push({ tag: message, points: points });
                break;
            default:
                console.warn('Unknown victory point constraint ' + key);
                break;
        }
    };
    return VictoryPointsBreakdown;
}());
exports.VictoryPointsBreakdown = VictoryPointsBreakdown;
