"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.FloatingHabs = void 0;
var Tags_1 = require("../Tags");
var CardType_1 = require("../CardType");
var ResourceType_1 = require("../../common/ResourceType");
var SelectCard_1 = require("../../inputs/SelectCard");
var CardName_1 = require("../../CardName");
var SelectHowToPayDeferred_1 = require("../../deferredActions/SelectHowToPayDeferred");
var CardRequirements_1 = require("../CardRequirements");
var CardRenderer_1 = require("../render/CardRenderer");
var Card_1 = require("../Card");
var ICard_1 = require("../ICard");
var FloatingHabs = (function (_super) {
    __extends(FloatingHabs, _super);
    function FloatingHabs() {
        var _this = _super.call(this, {
            name: CardName_1.CardName.FLOATING_HABS,
            cardType: CardType_1.CardType.ACTIVE,
            tags: [Tags_1.Tags.VENUS],
            cost: 5,
            resourceType: ResourceType_1.ResourceType.FLOATER,
            victoryPoints: ICard_1.VictoryPoints.resource(1, 2),
            requirements: CardRequirements_1.CardRequirements.builder(function (b) { return b.tag(Tags_1.Tags.SCIENCE, 2); }),
            metadata: {
                cardNumber: '225',
                renderData: CardRenderer_1.CardRenderer.builder(function (b) {
                    b.action('Spend 2 M€ to add 1 Floater to ANY card', function (eb) {
                        eb.megacredits(2).startAction.floaters(1).asterix();
                    }).br;
                    b.vpText('1 VP for every 2nd Floater on this card.');
                }),
                description: 'Requires 2 Science tags.',
            },
        }) || this;
        _this.resourceCount = 0;
        return _this;
    }
    ;
    FloatingHabs.prototype.play = function () {
        return undefined;
    };
    FloatingHabs.prototype.canAct = function (player) {
        return player.canAfford(2);
    };
    FloatingHabs.prototype.action = function (player) {
        var floaterCards = player.getResourceCards(ResourceType_1.ResourceType.FLOATER);
        if (floaterCards.length === 1) {
            player.game.defer(new SelectHowToPayDeferred_1.SelectHowToPayDeferred(player, 2, { title: 'Select how to pay for Floating Habs action' }));
            player.addResourceTo(floaterCards[0], { log: true });
            return undefined;
        }
        return new SelectCard_1.SelectCard('Spend 2 M€ and select card to add 1 floater', 'Add floater', floaterCards, function (foundCards) {
            player.game.defer(new SelectHowToPayDeferred_1.SelectHowToPayDeferred(player, 2, { title: 'Select how to pay for Floating Habs action' }));
            player.addResourceTo(foundCards[0], { log: true });
            return undefined;
        });
    };
    return FloatingHabs;
}(Card_1.Card));
exports.FloatingHabs = FloatingHabs;
