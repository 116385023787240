"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.RedCity = void 0;
var Tags_1 = require("../Tags");
var CardType_1 = require("../CardType");
var Card_1 = require("../Card");
var CardName_1 = require("../../CardName");
var CardRenderer_1 = require("../render/CardRenderer");
var Units_1 = require("../../Units");
var CardRequirements_1 = require("../CardRequirements");
var PartyName_1 = require("../../turmoil/parties/PartyName");
var CardRenderDynamicVictoryPoints_1 = require("../render/CardRenderDynamicVictoryPoints");
var TileType_1 = require("../../common/TileType");
var SelectSpace_1 = require("../../inputs/SelectSpace");
var AresHandler_1 = require("../../ares/AresHandler");
var RedCity = (function (_super) {
    __extends(RedCity, _super);
    function RedCity() {
        return _super.call(this, {
            cardType: CardType_1.CardType.AUTOMATED,
            name: CardName_1.CardName.RED_CITY,
            tags: [Tags_1.Tags.CITY, Tags_1.Tags.BUILDING],
            cost: 21,
            productionBox: Units_1.Units.of({ energy: -1, megacredits: 2 }),
            requirements: CardRequirements_1.CardRequirements.builder(function (b) { return b.party(PartyName_1.PartyName.REDS); }),
            victoryPoints: 'special',
            metadata: {
                cardNumber: 'PFT2',
                renderData: CardRenderer_1.CardRenderer.builder(function (b) {
                    b.production(function (pb) { return pb.minus().energy(1).megacredits(2); });
                }),
                description: 'Requires that Reds are ruling or that you have 2 delegates there. ' +
                    '-1 energy prod, +2 M€ prod. ' +
                    'Place the special tile on Mars ADJACENT TO NO GREENERY. ' +
                    'NO GREENERY MAY BE PLACED NEXT TO THIS TILE. 1 VP for every empty space next to this tile (Ares hazards don\'t count.)',
                victoryPoints: CardRenderDynamicVictoryPoints_1.CardRenderDynamicVictoryPoints.questionmark(),
            },
        }) || this;
    }
    RedCity.prototype.availableRedCitySpaces = function (player) {
        var board = player.game.board;
        var citySpaces = board.getAvailableSpacesForCity(player);
        return citySpaces.filter(function (space) {
            return !board.getAdjacentSpaces(space).some(function (neighbor) { var _a; return ((_a = neighbor.tile) === null || _a === void 0 ? void 0 : _a.tileType) === TileType_1.TileType.GREENERY; });
        });
    };
    RedCity.prototype.canPlay = function (player) {
        return player.canAdjustProduction(this.productionBox) && this.availableRedCitySpaces(player).length > 0;
    };
    RedCity.prototype.play = function (player) {
        var _this = this;
        player.adjustProduction(this.productionBox);
        return new SelectSpace_1.SelectSpace('Select space for Red City', this.availableRedCitySpaces(player), function (space) {
            player.game.addTile(player, space.spaceType, space, { tileType: TileType_1.TileType.RED_CITY, card: _this.name });
            return undefined;
        });
    };
    RedCity.prototype.getVictoryPoints = function (player) {
        var space = player.game.board.getSpaceByTileCard(this.name);
        if (space === undefined) {
            return 0;
        }
        var neighbors = player.game.board.getAdjacentSpaces(space);
        return neighbors.filter(function (neighbor) { return neighbor.tile === undefined || AresHandler_1.AresHandler.hasHazardTile(neighbor); }).length;
    };
    return RedCity;
}(Card_1.Card));
exports.RedCity = RedCity;
