"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SendDelegateToArea = void 0;
var SelectPartyToSendDelegate_1 = require("../inputs/SelectPartyToSendDelegate");
var DeferredAction_1 = require("./DeferredAction");
var SelectHowToPayDeferred_1 = require("./SelectHowToPayDeferred");
var Turmoil_1 = require("../turmoil/Turmoil");
var SendDelegateToArea = (function () {
    function SendDelegateToArea(player, title, options) {
        if (title === void 0) { title = 'Select where to send a delegate'; }
        if (options === void 0) { options = {}; }
        this.player = player;
        this.title = title;
        this.options = options;
        this.priority = DeferredAction_1.Priority.DEFAULT;
        this.turmoil = Turmoil_1.Turmoil.getTurmoil(this.player.game);
    }
    SendDelegateToArea.prototype.getAvailableParties = function () {
        var _this = this;
        var parties = this.turmoil.parties;
        if (this.options.replace) {
            parties = this.turmoil.parties.filter(function (party) {
                if (party.delegates.length < 2)
                    return false;
                for (var _i = 0, _a = party.delegates; _i < _a.length; _i++) {
                    var delegate = _a[_i];
                    if (delegate !== _this.options.replace)
                        continue;
                    if (delegate !== party.partyLeader)
                        return true;
                    return party.delegates.filter(function (delegate) { return delegate === _this.options.replace; }).length > 1;
                }
                return false;
            });
        }
        else {
            parties = this.turmoil.parties;
        }
        return parties.map(function (party) { return party.name; });
    };
    SendDelegateToArea.prototype.execute = function () {
        var _this = this;
        var _a;
        var availableParties = this.getAvailableParties();
        if (availableParties.length === 0) {
            return undefined;
        }
        var numDelegateToSend = (_a = this.options.count) !== null && _a !== void 0 ? _a : 1;
        var sendDelegate = new SelectPartyToSendDelegate_1.SelectPartyToSendDelegate(this.title, 'Send delegate', availableParties, function (partyName) {
            if (_this.options.cost) {
                _this.player.game.defer(new SelectHowToPayDeferred_1.SelectHowToPayDeferred(_this.player, _this.options.cost, { title: 'Select how to pay for send delegate action' }));
            }
            var source = _this.options.source || 'lobby';
            if (numDelegateToSend > 1 && source === 'lobby') {
                _this.turmoil.sendDelegateToParty(_this.player.id, partyName, _this.player.game, 'lobby');
                for (var i = 0; i < numDelegateToSend - 1; i++) {
                    _this.turmoil.sendDelegateToParty(_this.player.id, partyName, _this.player.game, 'reserve');
                }
            }
            else {
                for (var i = 0; i < numDelegateToSend; i++) {
                    if (_this.options.replace) {
                        _this.turmoil.replaceDelegateFromParty(_this.options.replace, _this.player.id, source, partyName, _this.player.game);
                    }
                    else {
                        _this.turmoil.sendDelegateToParty(_this.player.id, partyName, _this.player.game, source);
                    }
                }
            }
            _this.player.game.log('${0} sent ${1} delegate(s) in ${2} area', function (b) { return b.player(_this.player).number(numDelegateToSend).partyName(partyName); });
            return undefined;
        });
        return sendDelegate;
    };
    return SendDelegateToArea;
}());
exports.SendDelegateToArea = SendDelegateToArea;
