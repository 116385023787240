"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.AirRaid = void 0;
var CardType_1 = require("../CardType");
var CardName_1 = require("../../CardName");
var Resources_1 = require("../../common/Resources");
var ResourceType_1 = require("../../common/ResourceType");
var RemoveResourcesFromCard_1 = require("../../deferredActions/RemoveResourcesFromCard");
var StealResources_1 = require("../../deferredActions/StealResources");
var Card_1 = require("../Card");
var Size_1 = require("../render/Size");
var CardRenderer_1 = require("../render/CardRenderer");
var Options_1 = require("../Options");
var AirRaid = (function (_super) {
    __extends(AirRaid, _super);
    function AirRaid() {
        return _super.call(this, {
            cost: 0,
            name: CardName_1.CardName.AIR_RAID,
            cardType: CardType_1.CardType.EVENT,
            metadata: {
                cardNumber: 'C02',
                description: 'Requires that you lose 1 floater. Steal 5 M€ from any player.',
                renderData: CardRenderer_1.CardRenderer.builder(function (b) {
                    b.minus().floaters(1);
                    b.text('steal', Size_1.Size.MEDIUM, true).megacredits(5, { all: Options_1.all });
                }),
            },
        }) || this;
    }
    AirRaid.prototype.canPlay = function (player) {
        return player.getResourceCount(ResourceType_1.ResourceType.FLOATER) > 0;
    };
    AirRaid.prototype.play = function (player) {
        player.game.defer(new StealResources_1.StealResources(player, Resources_1.Resources.MEGACREDITS, 5));
        player.game.defer(new RemoveResourcesFromCard_1.RemoveResourcesFromCard(player, ResourceType_1.ResourceType.FLOATER, 1, true));
        return undefined;
    };
    return AirRaid;
}(Card_1.Card));
exports.AirRaid = AirRaid;
