"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.PublicSponsoredGrant = void 0;
var Card_1 = require("../Card");
var CardType_1 = require("../CardType");
var CardName_1 = require("../../CardName");
var CardRenderer_1 = require("../render/CardRenderer");
var Resources_1 = require("../../common/Resources");
var Tags_1 = require("../Tags");
var CardRequirements_1 = require("../CardRequirements");
var PartyName_1 = require("../../turmoil/parties/PartyName");
var OrOptions_1 = require("../../inputs/OrOptions");
var SelectOption_1 = require("../../inputs/SelectOption");
var Options_1 = require("../Options");
var PublicSponsoredGrant = (function (_super) {
    __extends(PublicSponsoredGrant, _super);
    function PublicSponsoredGrant() {
        return _super.call(this, {
            cardType: CardType_1.CardType.EVENT,
            name: CardName_1.CardName.PUBLIC_SPONSORED_GRANT,
            cost: 6,
            requirements: CardRequirements_1.CardRequirements.builder(function (b) { return b.party(PartyName_1.PartyName.SCIENTISTS); }),
            metadata: {
                cardNumber: 'PfTVD',
                renderData: CardRenderer_1.CardRenderer.builder(function (b) {
                    b.minus().megacredits(2, { all: Options_1.all }).br;
                    b.cards(1, { secondaryTag: Tags_1.Tags.WILDCARD }).cards(1, { secondaryTag: Tags_1.Tags.WILDCARD }).asterix();
                }),
                description: 'Requires Scientists are ruling or that you have 2 delegates there. All players lose 2M€. Choose a tag (NOT CITY, ? OR PLANETARY TRACK) and draw 2 cards with that tag.',
            },
        }) || this;
    }
    PublicSponsoredGrant.prototype.draw2Cards = function (player, tag) {
        player.drawCard(2, { tag: tag });
    };
    PublicSponsoredGrant.prototype.play = function (player) {
        var _this = this;
        player.game.getPlayers().forEach(function (p) { return p.deductResource(Resources_1.Resources.MEGACREDITS, Math.min(p.megaCredits, 2), { log: true }); });
        var tags = [
            Tags_1.Tags.BUILDING,
            Tags_1.Tags.SPACE,
            Tags_1.Tags.SCIENCE,
            Tags_1.Tags.ENERGY,
            Tags_1.Tags.PLANT,
            Tags_1.Tags.MICROBE,
            Tags_1.Tags.ANIMAL,
            Tags_1.Tags.EVENT
        ];
        var options = tags.map(function (tag) {
            return new SelectOption_1.SelectOption(tag, undefined, function () {
                _this.draw2Cards(player, tag);
                return undefined;
            });
        });
        return new (OrOptions_1.OrOptions.bind.apply(OrOptions_1.OrOptions, __spreadArray([void 0], options, false)))();
    };
    return PublicSponsoredGrant;
}(Card_1.Card));
exports.PublicSponsoredGrant = PublicSponsoredGrant;
