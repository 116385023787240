"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.MoholeAreaAres = void 0;
var CardName_1 = require("../../CardName");
var SpaceBonus_1 = require("../../SpaceBonus");
var MoholeArea_1 = require("../base/MoholeArea");
var TileType_1 = require("../../common/TileType");
var CardRenderer_1 = require("../render/CardRenderer");
var Options_1 = require("../Options");
var MoholeAreaAres = (function (_super) {
    __extends(MoholeAreaAres, _super);
    function MoholeAreaAres() {
        return _super.call(this, CardName_1.CardName.MOHOLE_AREA_ARES, { bonus: [SpaceBonus_1.SpaceBonus.HEAT, SpaceBonus_1.SpaceBonus.HEAT] }, {
            cardNumber: 'A16',
            renderData: CardRenderer_1.CardRenderer.builder(function (b) {
                b.production(function (pb) { return pb.heat(4, { digit: Options_1.digit }); }).br;
                b.tile(TileType_1.TileType.MOHOLE_AREA, false, true);
            }),
            description: 'Increase your heat production 4 steps. Place this tile ON AN AREA RESERVED FOR OCEAN. The tile grants an ADJACENCY BONUS of 2 heat.',
        }) || this;
    }
    return MoholeAreaAres;
}(MoholeArea_1.MoholeArea));
exports.MoholeAreaAres = MoholeAreaAres;
