"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.UrbanizedArea = void 0;
var Tags_1 = require("../Tags");
var Card_1 = require("../Card");
var CardType_1 = require("../CardType");
var SelectSpace_1 = require("../../inputs/SelectSpace");
var Resources_1 = require("../../common/Resources");
var CardName_1 = require("../../CardName");
var Board_1 = require("../../boards/Board");
var CardRenderer_1 = require("../render/CardRenderer");
var Units_1 = require("../../Units");
var UrbanizedArea = (function (_super) {
    __extends(UrbanizedArea, _super);
    function UrbanizedArea() {
        return _super.call(this, {
            cardType: CardType_1.CardType.AUTOMATED,
            name: CardName_1.CardName.URBANIZED_AREA,
            tags: [Tags_1.Tags.CITY, Tags_1.Tags.BUILDING],
            cost: 10,
            productionBox: Units_1.Units.of({ energy: -1, megacredits: 2 }),
            metadata: {
                cardNumber: '120',
                renderData: CardRenderer_1.CardRenderer.builder(function (b) {
                    b.production(function (pb) {
                        pb.minus().energy(1).br;
                        pb.plus().megacredits(2);
                    }).city().asterix();
                }),
                description: 'Decrease your Energy production 1 step and increase your M€ production 2 steps. Place a city tile ADJACENT TO AT LEAST 2 OTHER CITY TILES.',
            },
        }) || this;
    }
    UrbanizedArea.prototype.getAvailableSpaces = function (player) {
        return player.game.board.getAvailableSpacesOnLand(player)
            .filter(function (space) { return player.game.board.getAdjacentSpaces(space).filter(function (adjacentSpace) { return Board_1.Board.isCitySpace(adjacentSpace); }).length >= 2; });
    };
    UrbanizedArea.prototype.canPlay = function (player) {
        return player.getProduction(Resources_1.Resources.ENERGY) >= 1 && this.getAvailableSpaces(player).length > 0;
    };
    UrbanizedArea.prototype.play = function (player) {
        return new SelectSpace_1.SelectSpace('Select space next to at least 2 other city tiles', this.getAvailableSpaces(player), function (foundSpace) {
            player.game.addCityTile(player, foundSpace.id);
            player.addProduction(Resources_1.Resources.ENERGY, -1);
            player.addProduction(Resources_1.Resources.MEGACREDITS, 2);
            return undefined;
        });
    };
    return UrbanizedArea;
}(Card_1.Card));
exports.UrbanizedArea = UrbanizedArea;
