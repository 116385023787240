"use strict";
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Player = void 0;
var constants = require("./constants");
var constants_1 = require("./constants");
var AndOptions_1 = require("./inputs/AndOptions");
var Aridor_1 = require("./cards/colonies/Aridor");
var Board_1 = require("./boards/Board");
var CardFinder_1 = require("./CardFinder");
var CardName_1 = require("./CardName");
var CardType_1 = require("./cards/CardType");
var ColonyName_1 = require("./colonies/ColonyName");
var ICard_1 = require("./cards/ICard");
var LogMessageDataType_1 = require("./common/logs/LogMessageDataType");
var OrOptions_1 = require("./inputs/OrOptions");
var PartyHooks_1 = require("./turmoil/parties/PartyHooks");
var PartyName_1 = require("./turmoil/parties/PartyName");
var PharmacyUnion_1 = require("./cards/promo/PharmacyUnion");
var Phase_1 = require("./Phase");
var PlayerInput_1 = require("./PlayerInput");
var Resources_1 = require("./common/Resources");
var ResourceType_1 = require("./common/ResourceType");
var SelectAmount_1 = require("./inputs/SelectAmount");
var SelectCard_1 = require("./inputs/SelectCard");
var SellPatentsStandardProject_1 = require("./cards/base/standardProjects/SellPatentsStandardProject");
var SendDelegateToArea_1 = require("./deferredActions/SendDelegateToArea");
var DeferredAction_1 = require("./deferredActions/DeferredAction");
var SelectHowToPayDeferred_1 = require("./deferredActions/SelectHowToPayDeferred");
var SelectColony_1 = require("./inputs/SelectColony");
var SelectPartyToSendDelegate_1 = require("./inputs/SelectPartyToSendDelegate");
var SelectDelegate_1 = require("./inputs/SelectDelegate");
var SelectHowToPay_1 = require("./inputs/SelectHowToPay");
var SelectHowToPayForProjectCard_1 = require("./inputs/SelectHowToPayForProjectCard");
var SelectOption_1 = require("./inputs/SelectOption");
var SelectPlayer_1 = require("./inputs/SelectPlayer");
var SelectSpace_1 = require("./inputs/SelectSpace");
var SelfReplicatingRobots_1 = require("./cards/promo/SelfReplicatingRobots");
var SpaceType_1 = require("./SpaceType");
var Tags_1 = require("./cards/Tags");
var TileType_1 = require("./common/TileType");
var VictoryPointsBreakdown_1 = require("./VictoryPointsBreakdown");
var SelectProductionToLose_1 = require("./inputs/SelectProductionToLose");
var ShiftAresGlobalParameters_1 = require("./inputs/ShiftAresGlobalParameters");
var Timer_1 = require("./Timer");
var TurmoilHandler_1 = require("./turmoil/TurmoilHandler");
var CardLoader_1 = require("./CardLoader");
var DrawCards_1 = require("./deferredActions/DrawCards");
var Units_1 = require("./Units");
var MoonExpansion_1 = require("./moon/MoonExpansion");
var ConvertPlants_1 = require("./cards/base/standardActions/ConvertPlants");
var ConvertHeat_1 = require("./cards/base/standardActions/ConvertHeat");
var Manutech_1 = require("./cards/venusNext/Manutech");
var LunaProjectOffice_1 = require("./cards/moon/LunaProjectOffice");
var LogHelper_1 = require("./LogHelper");
var UndoActionOption_1 = require("./inputs/UndoActionOption");
var LawSuit_1 = require("./cards/promo/LawSuit");
var CrashSiteCleanup_1 = require("./cards/promo/CrashSiteCleanup");
var Turmoil_1 = require("./turmoil/Turmoil");
var PathfindersExpansion_1 = require("./pathfinders/PathfindersExpansion");
var CardSerialization_1 = require("./cards/CardSerialization");
var ColoniesHandler_1 = require("./colonies/ColoniesHandler");
var Player = (function () {
    function Player(name, color, beginner, handicap, id) {
        if (handicap === void 0) { handicap = 0; }
        this.name = name;
        this.color = color;
        this.beginner = beginner;
        this.handicap = handicap;
        this._game = undefined;
        this.corporationCard = undefined;
        this.pickedCorporationCard = undefined;
        this.terraformRating = 20;
        this.hasIncreasedTerraformRatingThisGeneration = false;
        this.terraformRatingAtGenerationStart = 20;
        this.megaCredits = 0;
        this.megaCreditProduction = 0;
        this.steel = 0;
        this.steelProduction = 0;
        this.titanium = 0;
        this.titaniumProduction = 0;
        this.plants = 0;
        this.plantProduction = 0;
        this.energy = 0;
        this.energyProduction = 0;
        this.heat = 0;
        this.heatProduction = 0;
        this.titaniumValue = 3;
        this.steelValue = 2;
        this.canUseHeatAsMegaCredits = false;
        this.actionsTakenThisRound = 0;
        this.actionsThisGeneration = new Set();
        this.corporationInitialActionDone = false;
        this.dealtCorporationCards = [];
        this.dealtProjectCards = [];
        this.dealtPreludeCards = [];
        this.cardsInHand = [];
        this.preludeCardsInHand = [];
        this.playedCards = [];
        this.draftedCards = [];
        this.cardCost = constants.CARD_COST;
        this.needsToDraft = undefined;
        this.cardDiscount = 0;
        this.timer = Timer_1.Timer.newInstance();
        this.fleetSize = 1;
        this.tradesThisGeneration = 0;
        this.colonyTradeOffset = 0;
        this.colonyTradeDiscount = 0;
        this.colonyVictoryPoints = 0;
        this.turmoilPolicyActionUsed = false;
        this.politicalAgendasActionUsedCount = 0;
        this.oceanBonus = constants.OCEAN_BONUS;
        this.scienceTagCount = 0;
        this.hasTurmoilScienceTagBonus = false;
        this.plantsNeededForGreenery = 8;
        this.removingPlayers = [];
        this.removedFromPlayCards = [];
        this.actionsTakenThisGame = 0;
        this.id = id;
    }
    Player.initialize = function (name, color, beginner, handicap, id) {
        if (handicap === void 0) { handicap = 0; }
        var player = new Player(name, color, beginner, handicap, id);
        return player;
    };
    Object.defineProperty(Player.prototype, "game", {
        get: function () {
            if (this._game === undefined) {
                throw new Error("Fetching game for player " + this.color + " too soon.");
            }
            return this._game;
        },
        set: function (game) {
            if (this._game !== undefined) {
                console.warn("Reinitializing game " + game.id + " for player " + this.color);
            }
            this._game = game;
        },
        enumerable: false,
        configurable: true
    });
    Player.prototype.isCorporation = function (corporationName) {
        var _a;
        return ((_a = this.corporationCard) === null || _a === void 0 ? void 0 : _a.name) === corporationName;
    };
    Player.prototype.getTitaniumValue = function () {
        if (PartyHooks_1.PartyHooks.shouldApplyPolicy(this, PartyName_1.PartyName.UNITY))
            return this.titaniumValue + 1;
        return this.titaniumValue;
    };
    Player.prototype.increaseTitaniumValue = function () {
        this.titaniumValue++;
    };
    Player.prototype.decreaseTitaniumValue = function () {
        if (this.titaniumValue > constants.DEFAULT_TITANIUM_VALUE) {
            this.titaniumValue--;
        }
    };
    Player.prototype.getSelfReplicatingRobotsTargetCards = function () {
        var _a, _b;
        return (_b = (_a = this.playedCards.find(function (card) { return card instanceof SelfReplicatingRobots_1.SelfReplicatingRobots; })) === null || _a === void 0 ? void 0 : _a.targetCards) !== null && _b !== void 0 ? _b : [];
    };
    Player.prototype.getSteelValue = function () {
        if (PartyHooks_1.PartyHooks.shouldApplyPolicy(this, PartyName_1.PartyName.MARS, 'mfp03'))
            return this.steelValue + 1;
        return this.steelValue;
    };
    Player.prototype.increaseSteelValue = function () {
        this.steelValue++;
    };
    Player.prototype.decreaseSteelValue = function () {
        if (this.steelValue > constants.DEFAULT_STEEL_VALUE) {
            this.steelValue--;
        }
    };
    Player.prototype.getTerraformRating = function () {
        return this.terraformRating;
    };
    Player.prototype.decreaseTerraformRating = function (opts) {
        if (opts === void 0) { opts = {}; }
        this.decreaseTerraformRatingSteps(1, opts);
    };
    Player.prototype.increaseTerraformRating = function (opts) {
        var _this = this;
        if (opts === void 0) { opts = {}; }
        if (!this.game.gameOptions.turmoilExtension) {
            this.terraformRating++;
            this.hasIncreasedTerraformRatingThisGeneration = true;
            return;
        }
        if (PartyHooks_1.PartyHooks.shouldApplyPolicy(this, PartyName_1.PartyName.REDS)) {
            if (this.canAfford(constants_1.REDS_RULING_POLICY_COST)) {
                this.game.defer(new SelectHowToPayDeferred_1.SelectHowToPayDeferred(this, constants_1.REDS_RULING_POLICY_COST, { title: 'Select how to pay for TR increase' }));
            }
            else {
                return;
            }
        }
        this.terraformRating++;
        this.hasIncreasedTerraformRatingThisGeneration = true;
        if (opts.log === true) {
            this.game.log('${0} gained 1 TR', function (b) { return b.player(_this); });
        }
    };
    Player.prototype.increaseTerraformRatingSteps = function (steps, opts) {
        var _this = this;
        if (opts === void 0) { opts = {}; }
        for (var i = 0; i < steps; i++) {
            this.increaseTerraformRating();
        }
        if (opts.log === true) {
            this.game.log('${0} gained ${1} TR', function (b) { return b.player(_this).number(steps); });
        }
    };
    Player.prototype.decreaseTerraformRatingSteps = function (steps, opts) {
        var _this = this;
        if (opts === void 0) { opts = {}; }
        this.terraformRating -= steps;
        if (opts.log === true) {
            this.game.log('${0} lost ${1} TR', function (b) { return b.player(_this).number(steps); });
        }
    };
    Player.prototype.setTerraformRating = function (value) {
        return this.terraformRating = value;
    };
    Player.prototype.getProduction = function (resource) {
        if (resource === Resources_1.Resources.MEGACREDITS)
            return this.megaCreditProduction;
        if (resource === Resources_1.Resources.STEEL)
            return this.steelProduction;
        if (resource === Resources_1.Resources.TITANIUM)
            return this.titaniumProduction;
        if (resource === Resources_1.Resources.PLANTS)
            return this.plantProduction;
        if (resource === Resources_1.Resources.ENERGY)
            return this.energyProduction;
        if (resource === Resources_1.Resources.HEAT)
            return this.heatProduction;
        throw new Error('Resource ' + resource + ' not found');
    };
    Player.prototype.getResource = function (resource) {
        if (resource === Resources_1.Resources.MEGACREDITS)
            return this.megaCredits;
        if (resource === Resources_1.Resources.STEEL)
            return this.steel;
        if (resource === Resources_1.Resources.TITANIUM)
            return this.titanium;
        if (resource === Resources_1.Resources.PLANTS)
            return this.plants;
        if (resource === Resources_1.Resources.ENERGY)
            return this.energy;
        if (resource === Resources_1.Resources.HEAT)
            return this.heat;
        throw new Error('Resource ' + resource + ' not found');
    };
    Player.prototype.resolveMonsInsurance = function () {
        var _this = this;
        if (this.game.monsInsuranceOwner !== undefined && this.game.monsInsuranceOwner !== this.id) {
            var monsInsuranceOwner_1 = this.game.getPlayerById(this.game.monsInsuranceOwner);
            var retribution_1 = Math.min(monsInsuranceOwner_1.megaCredits, 3);
            this.megaCredits += retribution_1;
            monsInsuranceOwner_1.deductResource(Resources_1.Resources.MEGACREDITS, 3);
            if (retribution_1 > 0) {
                this.game.log('${0} received ${1} M€ from ${2} owner (${3})', function (b) {
                    return b.player(_this)
                        .number(retribution_1)
                        .cardName(CardName_1.CardName.MONS_INSURANCE)
                        .player(monsInsuranceOwner_1);
                });
            }
        }
    };
    Player.prototype.logUnitDelta = function (resource, amount, unitType, from, stealing) {
        var _this = this;
        if (stealing === void 0) { stealing = false; }
        if (amount === 0) {
            return;
        }
        var modifier = amount > 0 ? 'increased' : 'decreased';
        var absAmount = Math.abs(amount);
        var message = '${0}\'s ${1} ' + unitType + ' ${2} by ${3}';
        if (from !== undefined) {
            if (stealing === true) {
                message = message + ' stolen';
            }
            message = message + ' by ' + ((from instanceof Player) ? '${4}' : 'Global Event');
        }
        this.game.log(message, function (b) {
            b.player(_this)
                .string(resource)
                .string(modifier)
                .number(absAmount);
            if (from instanceof Player) {
                b.player(from);
            }
        });
    };
    Player.prototype.deductResource = function (resource, amount, options) {
        this.addResource(resource, -amount, options);
    };
    Player.prototype.addResource = function (resource, amount, options) {
        var playerAmount = this.getResource(resource);
        var delta = (amount >= 0) ? amount : Math.max(amount, -playerAmount);
        if (delta !== amount && (options === null || options === void 0 ? void 0 : options.from) === undefined) {
            this.game.logIllegalState("Adjusting " + amount + " " + resource + " when player has " + playerAmount, { player: { color: this.color, id: this.id, name: this.name }, resource: resource, amount: amount });
        }
        if (resource === Resources_1.Resources.MEGACREDITS)
            this.megaCredits += delta;
        else if (resource === Resources_1.Resources.STEEL)
            this.steel += delta;
        else if (resource === Resources_1.Resources.TITANIUM)
            this.titanium += delta;
        else if (resource === Resources_1.Resources.PLANTS)
            this.plants += delta;
        else if (resource === Resources_1.Resources.ENERGY)
            this.energy += delta;
        else if (resource === Resources_1.Resources.HEAT)
            this.heat += delta;
        else {
            throw new Error("tried to add unsupported resource " + resource);
        }
        if ((options === null || options === void 0 ? void 0 : options.log) === true) {
            this.logUnitDelta(resource, delta, 'amount', options.from, options.stealing);
        }
        if ((options === null || options === void 0 ? void 0 : options.from) instanceof Player) {
            LawSuit_1.LawSuit.resourceHook(this, resource, delta, options.from);
            CrashSiteCleanup_1.CrashSiteCleanup.resourceHook(this, resource, delta, options.from);
        }
        if ((options === null || options === void 0 ? void 0 : options.from) !== undefined && delta < 0 && (options.from instanceof Player && options.from.id !== this.id)) {
            this.resolveMonsInsurance();
        }
    };
    Player.prototype.addProduction = function (resource, amount, options) {
        var adj = resource === Resources_1.Resources.MEGACREDITS ? -5 : 0;
        var delta = (amount >= 0) ? amount : Math.max(amount, -(this.getProduction(resource) - adj));
        if (resource === Resources_1.Resources.MEGACREDITS)
            this.megaCreditProduction += delta;
        else if (resource === Resources_1.Resources.STEEL)
            this.steelProduction += delta;
        else if (resource === Resources_1.Resources.TITANIUM)
            this.titaniumProduction += delta;
        else if (resource === Resources_1.Resources.PLANTS)
            this.plantProduction += delta;
        else if (resource === Resources_1.Resources.ENERGY)
            this.energyProduction += delta;
        else if (resource === Resources_1.Resources.HEAT)
            this.heatProduction += delta;
        else {
            throw new Error("tried to add unsupported production " + resource);
        }
        if ((options === null || options === void 0 ? void 0 : options.log) === true) {
            this.logUnitDelta(resource, amount, 'production', options.from, options.stealing);
        }
        if ((options === null || options === void 0 ? void 0 : options.from) instanceof Player) {
            LawSuit_1.LawSuit.resourceHook(this, resource, delta, options.from);
        }
        if ((options === null || options === void 0 ? void 0 : options.from) !== undefined && delta < 0 && (options.from instanceof Player && options.from.id !== this.id)) {
            this.resolveMonsInsurance();
        }
        if (this.isCorporation(CardName_1.CardName.MANUTECH)) {
            Manutech_1.Manutech.onProductionGain(this, resource, amount);
        }
    };
    ;
    Player.prototype.hasUnits = function (units) {
        return this.megaCredits - units.megacredits >= 0 &&
            this.steel - units.steel >= 0 &&
            this.titanium - units.titanium >= 0 &&
            this.plants - units.plants >= 0 &&
            this.energy - units.energy >= 0 &&
            this.availableHeat - units.heat >= 0;
    };
    Player.prototype.addUnits = function (units, options) {
        this.addResource(Resources_1.Resources.MEGACREDITS, units.megacredits || 0, options);
        this.addResource(Resources_1.Resources.STEEL, units.steel || 0, options);
        this.addResource(Resources_1.Resources.TITANIUM, units.titanium || 0, options);
        this.addResource(Resources_1.Resources.PLANTS, units.plants || 0, options);
        this.addResource(Resources_1.Resources.ENERGY, units.energy || 0, options);
        this.addResource(Resources_1.Resources.HEAT, units.heat || 0, options);
    };
    Player.prototype.deductUnits = function (units) {
        this.deductResource(Resources_1.Resources.MEGACREDITS, units.megacredits);
        this.deductResource(Resources_1.Resources.STEEL, units.steel);
        this.deductResource(Resources_1.Resources.TITANIUM, units.titanium);
        this.deductResource(Resources_1.Resources.PLANTS, units.plants);
        this.deductResource(Resources_1.Resources.ENERGY, units.energy);
        this.deductResource(Resources_1.Resources.HEAT, units.heat);
    };
    Player.prototype.canAdjustProduction = function (units) {
        return this.getProduction(Resources_1.Resources.MEGACREDITS) + units.megacredits >= -5 &&
            this.getProduction(Resources_1.Resources.STEEL) + units.steel >= 0 &&
            this.getProduction(Resources_1.Resources.TITANIUM) + units.titanium >= 0 &&
            this.getProduction(Resources_1.Resources.PLANTS) + units.plants >= 0 &&
            this.getProduction(Resources_1.Resources.ENERGY) + units.energy >= 0 &&
            this.getProduction(Resources_1.Resources.HEAT) + units.heat >= 0;
    };
    Player.prototype.adjustProduction = function (units, options) {
        if (units.megacredits !== undefined) {
            this.addProduction(Resources_1.Resources.MEGACREDITS, units.megacredits, options);
        }
        if (units.steel !== undefined) {
            this.addProduction(Resources_1.Resources.STEEL, units.steel, options);
        }
        if (units.titanium !== undefined) {
            this.addProduction(Resources_1.Resources.TITANIUM, units.titanium, options);
        }
        if (units.plants !== undefined) {
            this.addProduction(Resources_1.Resources.PLANTS, units.plants, options);
        }
        if (units.energy !== undefined) {
            this.addProduction(Resources_1.Resources.ENERGY, units.energy, options);
        }
        if (units.heat !== undefined) {
            this.addProduction(Resources_1.Resources.HEAT, units.heat, options);
        }
    };
    Player.prototype.getActionsThisGeneration = function () {
        return this.actionsThisGeneration;
    };
    Player.prototype.addActionThisGeneration = function (cardName) {
        this.actionsThisGeneration.add(cardName);
        return;
    };
    Player.prototype.getVictoryPoints = function () {
        var _this = this;
        var _a;
        var victoryPointsBreakdown = new VictoryPointsBreakdown_1.VictoryPointsBreakdown();
        if (this.corporationCard !== undefined && this.corporationCard.victoryPoints !== undefined) {
            victoryPointsBreakdown.setVictoryPoints('victoryPoints', this.corporationCard.getVictoryPoints(this), this.corporationCard.name);
        }
        for (var _i = 0, _b = this.playedCards; _i < _b.length; _i++) {
            var playedCard = _b[_i];
            if (playedCard.victoryPoints !== undefined) {
                victoryPointsBreakdown.setVictoryPoints('victoryPoints', playedCard.getVictoryPoints(this), playedCard.name);
            }
        }
        victoryPointsBreakdown.setVictoryPoints('terraformRating', this.terraformRating);
        this.giveAwards(victoryPointsBreakdown);
        for (var _c = 0, _d = this.game.claimedMilestones; _c < _d.length; _c++) {
            var milestone = _d[_c];
            if (milestone.player !== undefined && milestone.player.id === this.id) {
                victoryPointsBreakdown.setVictoryPoints('milestones', 5, 'Claimed ' + milestone.milestone.name + ' milestone');
            }
        }
        this.game.board.spaces.forEach(function (space) {
            if (space.tile && space.tile.tileType === TileType_1.TileType.GREENERY && space.player !== undefined && space.player.id === _this.id) {
                victoryPointsBreakdown.setVictoryPoints('greenery', 1);
            }
            if (Board_1.Board.isCitySpace(space) && space.player !== undefined && space.player.id === _this.id) {
                var adjacent = _this.game.board.getAdjacentSpaces(space);
                for (var _i = 0, adjacent_1 = adjacent; _i < adjacent_1.length; _i++) {
                    var adj = adjacent_1[_i];
                    if (adj.tile && adj.tile.tileType === TileType_1.TileType.GREENERY) {
                        victoryPointsBreakdown.setVictoryPoints('city', 1);
                    }
                }
            }
        });
        var includeTurmoilVP = this.game.gameIsOver() || this.game.phase === Phase_1.Phase.END;
        Turmoil_1.Turmoil.ifTurmoil(this.game, function (turmoil) {
            if (includeTurmoilVP) {
                victoryPointsBreakdown.setVictoryPoints('victoryPoints', turmoil.getPlayerVictoryPoints(_this), 'Turmoil Points');
            }
        });
        if (this.colonyVictoryPoints > 0) {
            victoryPointsBreakdown.setVictoryPoints('victoryPoints', this.colonyVictoryPoints, 'Colony VP');
        }
        MoonExpansion_1.MoonExpansion.calculateVictoryPoints(this, victoryPointsBreakdown);
        PathfindersExpansion_1.PathfindersExpansion.calculateVictoryPoints(this, victoryPointsBreakdown);
        if (this.game.gameOptions.escapeVelocityMode) {
            var threshold = this.game.gameOptions.escapeVelocityThreshold;
            var period = this.game.gameOptions.escapeVelocityPeriod;
            var penaltyPerMin = (_a = this.game.gameOptions.escapeVelocityPenalty) !== null && _a !== void 0 ? _a : 1;
            var elapsedTimeInMinutes = this.timer.getElapsedTimeInMinutes();
            if (threshold !== undefined && period !== undefined && elapsedTimeInMinutes > threshold) {
                var overTimeInMinutes = Math.max(elapsedTimeInMinutes - threshold - (this.actionsTakenThisGame * (constants.BONUS_SECONDS_PER_ACTION / 60)), 0);
                victoryPointsBreakdown.updateTotal();
                var totalBeforeEscapeVelocity = victoryPointsBreakdown.total;
                var penaltyTotal = Math.min(penaltyPerMin * Math.floor(overTimeInMinutes / period), totalBeforeEscapeVelocity);
                victoryPointsBreakdown.setVictoryPoints('escapeVelocity', -penaltyTotal, 'Escape Velocity Penalty');
            }
        }
        victoryPointsBreakdown.updateTotal();
        return victoryPointsBreakdown;
    };
    Player.prototype.cardIsInEffect = function (cardName) {
        return this.playedCards.some(function (playedCard) { return playedCard.name === cardName; });
    };
    Player.prototype.hasProtectedHabitats = function () {
        return this.cardIsInEffect(CardName_1.CardName.PROTECTED_HABITATS);
    };
    Player.prototype.plantsAreProtected = function () {
        return this.hasProtectedHabitats() || this.cardIsInEffect(CardName_1.CardName.ASTEROID_DEFLECTION_SYSTEM);
    };
    Player.prototype.alloysAreProtected = function () {
        return this.cardIsInEffect(CardName_1.CardName.LUNAR_SECURITY_STATIONS);
    };
    Player.prototype.productionIsProtected = function () {
        return this.cardIsInEffect(CardName_1.CardName.PRIVATE_SECURITY);
    };
    Player.prototype.getCitiesCount = function () {
        var _this = this;
        return Array.from(TileType_1.CITY_TILES).map(function (tileType) { return _this.game.getSpaceCount(tileType, _this); })
            .reduce(function (previous, current) { return previous + current; }, 0);
    };
    Player.prototype.getNoTagsCount = function () {
        var noTagsCount = 0;
        if (this.corporationCard !== undefined && this.corporationCard.tags.every(function (tag) { return tag === Tags_1.Tags.WILDCARD; })) {
            noTagsCount++;
        }
        noTagsCount += this.playedCards.filter(function (card) { return card.cardType !== CardType_1.CardType.EVENT && card.tags.every(function (tag) { return tag === Tags_1.Tags.WILDCARD; }); }).length;
        return noTagsCount;
    };
    Player.prototype.getColoniesCount = function () {
        var _this = this;
        if (!this.game.gameOptions.coloniesExtension)
            return 0;
        var coloniesCount = 0;
        this.game.colonies.forEach(function (colony) {
            coloniesCount += colony.colonies.filter(function (owner) { return owner === _this.id; }).length;
        });
        return coloniesCount;
    };
    Player.prototype.getPlayedEventsCount = function () {
        var _a;
        var count = this.playedCards.filter(function (card) { return card.cardType === CardType_1.CardType.EVENT; }).length;
        if (this.isCorporation(CardName_1.CardName.PHARMACY_UNION) && ((_a = this.corporationCard) === null || _a === void 0 ? void 0 : _a.isDisabled))
            count++;
        return count;
    };
    Player.prototype.getResourcesOnCard = function (card) {
        return card.resourceCount;
    };
    Player.prototype.getResourcesOnCorporation = function () {
        if (this.corporationCard !== undefined &&
            this.corporationCard.resourceCount !== undefined) {
            return this.corporationCard.resourceCount;
        }
        else
            return 0;
    };
    Player.prototype.getRequirementsBonus = function (parameter) {
        var requirementsBonus = 0;
        if (this.corporationCard !== undefined &&
            this.corporationCard.getRequirementBonus !== undefined) {
            requirementsBonus += this.corporationCard.getRequirementBonus(this, parameter);
        }
        for (var _i = 0, _a = this.playedCards; _i < _a.length; _i++) {
            var playedCard = _a[_i];
            if (playedCard.getRequirementBonus !== undefined &&
                playedCard.getRequirementBonus(this, parameter)) {
                requirementsBonus += playedCard.getRequirementBonus(this, parameter);
            }
        }
        if (PartyHooks_1.PartyHooks.shouldApplyPolicy(this, PartyName_1.PartyName.SCIENTISTS, 'sp02')) {
            requirementsBonus += 2;
        }
        return requirementsBonus;
    };
    Player.prototype.removeResourceFrom = function (card, count, game, removingPlayer, shouldLogAction) {
        var _this = this;
        if (count === void 0) { count = 1; }
        if (shouldLogAction === void 0) { shouldLogAction = true; }
        if (card.resourceCount) {
            card.resourceCount = Math.max(card.resourceCount - count, 0);
            if (game !== undefined && removingPlayer !== undefined) {
                if (removingPlayer !== this)
                    this.resolveMonsInsurance();
                if (shouldLogAction) {
                    game.log('${0} removed ${1} resource(s) from ${2}\'s ${3}', function (b) {
                        return b.player(removingPlayer)
                            .number(count)
                            .player(_this)
                            .card(card);
                    });
                }
            }
            if (removingPlayer !== undefined && removingPlayer !== this && this.removingPlayers.includes(removingPlayer.id) === false) {
                this.removingPlayers.push(removingPlayer.id);
            }
        }
    };
    Player.prototype.addResourceTo = function (card, options) {
        var _a;
        if (options === void 0) { options = 1; }
        var count = typeof (options) === 'number' ? options : ((_a = options.qty) !== null && _a !== void 0 ? _a : 1);
        if (card.resourceCount !== undefined) {
            card.resourceCount += count;
        }
        if (card.resourceType === ResourceType_1.ResourceType.MICROBE && this.playedCards.map(function (card) { return card.name; }).includes(CardName_1.CardName.TOPSOIL_CONTRACT)) {
            this.megaCredits += count;
        }
        if (card.resourceType === ResourceType_1.ResourceType.ANIMAL && this.playedCards.map(function (card) { return card.name; }).includes(CardName_1.CardName.MEAT_INDUSTRY)) {
            this.megaCredits += count * 2;
        }
        if (typeof (options) !== 'number' && options.log === true) {
            LogHelper_1.LogHelper.logAddResource(this, card, count);
        }
    };
    Player.prototype.getCardsWithResources = function (resource) {
        var result = this.playedCards.filter(function (card) { return card.resourceType !== undefined && card.resourceCount && card.resourceCount > 0; });
        if (this.corporationCard !== undefined &&
            this.corporationCard.resourceType !== undefined &&
            this.corporationCard.resourceCount !== undefined &&
            this.corporationCard.resourceCount > 0) {
            result.push(this.corporationCard);
        }
        if (resource !== undefined) {
            result = result.filter(function (card) { return card.resourceType === resource; });
        }
        return result;
    };
    Player.prototype.getResourceCards = function (resource) {
        var result = this.playedCards.filter(function (card) { return card.resourceType !== undefined; });
        if (this.corporationCard !== undefined && this.corporationCard.resourceType !== undefined) {
            result.push(this.corporationCard);
        }
        if (resource !== undefined) {
            result = result.filter(function (card) { return card.resourceType === resource; });
        }
        return result;
    };
    Player.prototype.getResourceCount = function (resource) {
        var _this = this;
        var count = 0;
        this.getCardsWithResources(resource).forEach(function (card) {
            var _a;
            count += ((_a = _this.getResourcesOnCard(card)) !== null && _a !== void 0 ? _a : 0);
        });
        return count;
    };
    Player.prototype.getCardsByCardType = function (cardType) {
        return this.playedCards.filter(function (card) { return card.cardType === cardType; });
    };
    Player.prototype.getAllTags = function () {
        return [
            { tag: Tags_1.Tags.BUILDING, count: this.getTagCount(Tags_1.Tags.BUILDING, 'raw') },
            { tag: Tags_1.Tags.CITY, count: this.getTagCount(Tags_1.Tags.CITY, 'raw') },
            { tag: Tags_1.Tags.EARTH, count: this.getTagCount(Tags_1.Tags.EARTH, 'raw') },
            { tag: Tags_1.Tags.ENERGY, count: this.getTagCount(Tags_1.Tags.ENERGY, 'raw') },
            { tag: Tags_1.Tags.JOVIAN, count: this.getTagCount(Tags_1.Tags.JOVIAN, 'raw') },
            { tag: Tags_1.Tags.MARS, count: this.getTagCount(Tags_1.Tags.MARS, 'raw') },
            { tag: Tags_1.Tags.MICROBE, count: this.getTagCount(Tags_1.Tags.MICROBE, 'raw') },
            { tag: Tags_1.Tags.MOON, count: this.getTagCount(Tags_1.Tags.MOON, 'raw') },
            { tag: Tags_1.Tags.PLANT, count: this.getTagCount(Tags_1.Tags.PLANT, 'raw') },
            { tag: Tags_1.Tags.SCIENCE, count: this.getTagCount(Tags_1.Tags.SCIENCE, 'raw') },
            { tag: Tags_1.Tags.SPACE, count: this.getTagCount(Tags_1.Tags.SPACE, 'raw') },
            { tag: Tags_1.Tags.VENUS, count: this.getTagCount(Tags_1.Tags.VENUS, 'raw') },
            { tag: Tags_1.Tags.WILDCARD, count: this.getTagCount(Tags_1.Tags.WILDCARD, 'raw') },
            { tag: Tags_1.Tags.ANIMAL, count: this.getTagCount(Tags_1.Tags.ANIMAL, 'raw') },
            { tag: Tags_1.Tags.EVENT, count: this.getPlayedEventsCount() },
        ].filter(function (tag) { return tag.count > 0; });
    };
    Player.prototype.getTagCount = function (tag, mode) {
        var _a;
        if (mode === void 0) { mode = 'default'; }
        var includeEvents = mode === 'vps';
        var includeTagSubstitutions = (mode === 'default' || mode === 'milestone');
        var tagCount = this.getRawTagCount(tag, includeEvents);
        if (tag === Tags_1.Tags.SCIENCE && this.scienceTagCount > 0) {
            tagCount += this.scienceTagCount;
        }
        if (includeTagSubstitutions) {
            if (tag === Tags_1.Tags.EARTH && this.playedCards.some(function (c) { return c.name === CardName_1.CardName.EARTH_EMBASSY; })) {
                tagCount += this.getRawTagCount(Tags_1.Tags.MOON, includeEvents);
            }
            if (tag !== Tags_1.Tags.WILDCARD) {
                tagCount += this.getRawTagCount(Tags_1.Tags.WILDCARD, includeEvents);
            }
        }
        if (((_a = this.corporationCard) === null || _a === void 0 ? void 0 : _a.name) === CardName_1.CardName.CHIMERA) {
            if (mode === 'award') {
                tagCount++;
            }
            ;
            if (mode === 'milestone') {
                tagCount--;
            }
        }
        return tagCount;
    };
    Player.prototype.getRawTagCount = function (tag, includeEventsTags) {
        var tagCount = 0;
        this.playedCards.forEach(function (card) {
            if (!includeEventsTags && card.cardType === CardType_1.CardType.EVENT)
                return;
            tagCount += card.tags.filter(function (cardTag) { return cardTag === tag; }).length;
        });
        if (this.corporationCard !== undefined && !this.corporationCard.isDisabled) {
            tagCount += this.corporationCard.tags.filter(function (cardTag) { return cardTag === tag; }).length;
        }
        return tagCount;
    };
    Player.prototype.getMultipleTagCount = function (tags) {
        var _this = this;
        var tagCount = 0;
        tags.forEach(function (tag) {
            tagCount += _this.getRawTagCount(tag, false);
        });
        if (tags.includes(Tags_1.Tags.EARTH) && !tags.includes(Tags_1.Tags.MOON) && this.playedCards.some(function (c) { return c.name === CardName_1.CardName.EARTH_EMBASSY; })) {
            tagCount += this.getRawTagCount(Tags_1.Tags.MOON, false);
        }
        return tagCount + this.getRawTagCount(Tags_1.Tags.WILDCARD, false);
    };
    Player.prototype.getDistinctTagCount = function (countWild, extraTag) {
        var allTags = [];
        var wildcardCount = 0;
        if (extraTag !== undefined) {
            allTags.push(extraTag);
        }
        var uniqueTags = new Set();
        if (this.corporationCard !== undefined && this.corporationCard.tags.length > 0 && !this.corporationCard.isDisabled) {
            this.corporationCard.tags.forEach(function (tag) { return allTags.push(tag); });
        }
        this.playedCards.forEach(function (card) {
            if (card.cardType === CardType_1.CardType.EVENT) {
                return;
            }
            card.tags.forEach(function (tag) {
                allTags.push(tag);
            });
        });
        for (var _i = 0, allTags_1 = allTags; _i < allTags_1.length; _i++) {
            var tags = allTags_1[_i];
            if (tags === Tags_1.Tags.WILDCARD) {
                wildcardCount++;
            }
            else {
                uniqueTags.add(tags);
            }
        }
        if (countWild) {
            var maxTagCount = 10;
            if (this.game.gameOptions.venusNextExtension)
                maxTagCount++;
            if (this.game.gameOptions.moonExpansion)
                maxTagCount++;
            if (this.game.gameOptions.pathfindersExpansion)
                maxTagCount++;
            return Math.min(uniqueTags.size + wildcardCount, maxTagCount);
        }
        else {
            return uniqueTags.size;
        }
    };
    Player.prototype.checkMultipleTagPresence = function (tags) {
        var _this = this;
        var distinctCount = 0;
        tags.forEach(function (tag) {
            if (_this.getTagCount(tag, 'raw') > 0) {
                distinctCount++;
            }
            else if (tag === Tags_1.Tags.SCIENCE && _this.hasTurmoilScienceTagBonus) {
                distinctCount++;
            }
        });
        if (distinctCount + this.getTagCount(Tags_1.Tags.WILDCARD) >= tags.length) {
            return true;
        }
        return false;
    };
    Player.prototype.runInputCb = function (result) {
        if (result !== undefined) {
            this.game.defer(new DeferredAction_1.DeferredAction(this, function () { return result; }));
        }
    };
    Player.prototype.checkInputLength = function (input, length, firstOptionLength) {
        if (input.length !== length) {
            throw new Error('Incorrect options provided');
        }
        if (firstOptionLength !== undefined && input[0].length !== firstOptionLength) {
            throw new Error('Incorrect options provided (nested)');
        }
    };
    Player.prototype.parseHowToPayJSON = function (json) {
        var defaults = {
            steel: 0,
            heat: 0,
            titanium: 0,
            megaCredits: 0,
            microbes: 0,
            floaters: 0,
            science: 0,
            seeds: 0,
        };
        try {
            var howToPay = JSON.parse(json);
            if (Object.keys(howToPay).every(function (key) { return key in defaults; }) === false) {
                throw new Error('Input contains unauthorized keys');
            }
            return howToPay;
        }
        catch (err) {
            throw new Error('Unable to parse HowToPay input ' + err);
        }
    };
    Player.prototype.runInput = function (input, pi) {
        var _a;
        if (pi instanceof AndOptions_1.AndOptions) {
            this.checkInputLength(input, pi.options.length);
            for (var i = 0; i < input.length; i++) {
                this.runInput([input[i]], pi.options[i]);
            }
            this.runInputCb(pi.cb());
        }
        else if (pi instanceof SelectAmount_1.SelectAmount) {
            this.checkInputLength(input, 1, 1);
            var amount = parseInt(input[0][0]);
            if (isNaN(amount)) {
                throw new Error('Number not provided for amount');
            }
            if (amount > pi.max) {
                throw new Error('Amount provided too high (max ' + String(pi.max) + ')');
            }
            if (amount < pi.min) {
                throw new Error('Amount provided too low (min ' + String(pi.min) + ')');
            }
            this.runInputCb(pi.cb(amount));
        }
        else if (pi instanceof SelectOption_1.SelectOption) {
            this.runInputCb(pi.cb());
        }
        else if (pi instanceof SelectColony_1.SelectColony) {
            this.checkInputLength(input, 1, 1);
            var colonyName = (input[0][0]);
            if (colonyName === undefined) {
                throw new Error('No colony selected');
            }
            var colony = ColoniesHandler_1.ColoniesHandler.getColony(this.game, colonyName, true);
            this.runInputCb(pi.cb(colony));
        }
        else if (pi instanceof OrOptions_1.OrOptions) {
            if (input.length === 0 || input[0].length !== 1) {
                throw new Error('Incorrect options provided');
            }
            var optionIndex = parseInt(input[0][0]);
            var selectedOptionInput = input.slice(1);
            this.runInput(selectedOptionInput, pi.options[optionIndex]);
            this.runInputCb(pi.cb());
        }
        else if (pi instanceof SelectHowToPayForProjectCard_1.SelectHowToPayForProjectCard) {
            this.checkInputLength(input, 1, 2);
            var cardName = input[0][0];
            var _data = PlayerInput_1.PlayerInput.getCard(pi.cards, cardName);
            var foundCard = _data.card;
            var howToPay = this.parseHowToPayJSON(input[0][1]);
            var reserveUnits = pi.reserveUnits[_data.idx];
            if (reserveUnits.steel + howToPay.steel > this.steel) {
                throw new Error(reserveUnits.steel + " units of steel must be reserved for " + cardName);
            }
            if (reserveUnits.titanium + howToPay.titanium > this.titanium) {
                throw new Error(reserveUnits.titanium + " units of titanium must be reserved for " + cardName);
            }
            this.runInputCb(pi.cb(foundCard, howToPay));
        }
        else if (pi instanceof SelectCard_1.SelectCard) {
            this.checkInputLength(input, 1);
            if (input[0].length < pi.minCardsToSelect) {
                throw new Error('Not enough cards selected');
            }
            if (input[0].length > pi.maxCardsToSelect) {
                throw new Error('Too many cards selected');
            }
            var mappedCards = [];
            for (var _i = 0, _b = input[0]; _i < _b.length; _i++) {
                var cardName = _b[_i];
                var cardIndex = PlayerInput_1.PlayerInput.getCard(pi.cards, cardName);
                mappedCards.push(cardIndex.card);
                if (((_a = pi.enabled) === null || _a === void 0 ? void 0 : _a[cardIndex.idx]) === false) {
                    throw new Error('Selected unavailable card');
                }
            }
            this.runInputCb(pi.cb(mappedCards));
        }
        else if (pi instanceof SelectAmount_1.SelectAmount) {
            this.checkInputLength(input, 1, 1);
            var amount = parseInt(input[0][0]);
            if (isNaN(amount)) {
                throw new Error('Amount is not a number');
            }
            this.runInputCb(pi.cb(amount));
        }
        else if (pi instanceof SelectSpace_1.SelectSpace) {
            this.checkInputLength(input, 1, 1);
            var foundSpace = pi.availableSpaces.find(function (space) { return space.id === input[0][0]; });
            if (foundSpace === undefined) {
                throw new Error('Space not available');
            }
            this.runInputCb(pi.cb(foundSpace));
        }
        else if (pi instanceof SelectPlayer_1.SelectPlayer) {
            this.checkInputLength(input, 1, 1);
            var foundPlayer = pi.players.find(function (player) { return player.color === input[0][0] || player.id === input[0][0]; });
            if (foundPlayer === undefined) {
                throw new Error('Player not available');
            }
            this.runInputCb(pi.cb(foundPlayer));
        }
        else if (pi instanceof SelectDelegate_1.SelectDelegate) {
            this.checkInputLength(input, 1, 1);
            var foundPlayer = pi.players.find(function (player) {
                return player === input[0][0] ||
                    (player instanceof Player && (player.id === input[0][0] || player.color === input[0][0]));
            });
            if (foundPlayer === undefined) {
                throw new Error('Player not available');
            }
            this.runInputCb(pi.cb(foundPlayer));
        }
        else if (pi instanceof SelectHowToPay_1.SelectHowToPay) {
            this.checkInputLength(input, 1, 1);
            var howToPay = this.parseHowToPayJSON(input[0][0]);
            this.runInputCb(pi.cb(howToPay));
        }
        else if (pi instanceof SelectProductionToLose_1.SelectProductionToLose) {
            var units = JSON.parse(input[0][0]);
            pi.cb(units);
        }
        else if (pi instanceof ShiftAresGlobalParameters_1.ShiftAresGlobalParameters) {
            var response = JSON.parse(input[0][0]);
            pi.cb(response);
        }
        else if (pi instanceof SelectPartyToSendDelegate_1.SelectPartyToSendDelegate) {
            this.checkInputLength(input, 1, 1);
            var party = (input[0][0]);
            if (party === undefined) {
                throw new Error('No party selected');
            }
            this.runInputCb(pi.cb(party));
        }
        else {
            throw new Error('Unsupported waitingFor');
        }
    };
    Player.prototype.getAvailableBlueActionCount = function () {
        return this.getPlayableActionCards().length;
    };
    Player.prototype.getPlayableActionCards = function () {
        var result = [];
        if ((0, ICard_1.isIActionCard)(this.corporationCard) &&
            !this.actionsThisGeneration.has(this.corporationCard.name) &&
            (0, ICard_1.isIActionCard)(this.corporationCard) &&
            this.corporationCard.canAct(this)) {
            result.push(this.corporationCard);
        }
        for (var _i = 0, _a = this.playedCards; _i < _a.length; _i++) {
            var playedCard = _a[_i];
            if ((0, ICard_1.isIActionCard)(playedCard) &&
                !this.actionsThisGeneration.has(playedCard.name) &&
                playedCard.canAct(this)) {
                result.push(playedCard);
            }
        }
        return result;
    };
    Player.prototype.runProductionPhase = function () {
        this.actionsThisGeneration.clear();
        this.removingPlayers = [];
        if (this.game.syndicatePirateRaider === undefined) {
            this.tradesThisGeneration = 0;
        }
        else if (this.game.syndicatePirateRaider === this.id) {
            this.tradesThisGeneration = 0;
        }
        this.turmoilPolicyActionUsed = false;
        this.politicalAgendasActionUsedCount = 0;
        this.megaCredits += this.megaCreditProduction + this.terraformRating;
        this.heat += this.energy;
        this.heat += this.heatProduction;
        this.energy = this.energyProduction;
        this.titanium += this.titaniumProduction;
        this.steel += this.steelProduction;
        this.plants += this.plantProduction;
        if (this.corporationCard !== undefined && this.corporationCard.onProductionPhase !== undefined) {
            this.corporationCard.onProductionPhase(this);
        }
    };
    Player.prototype.doneWorldGovernmentTerraforming = function () {
        var _this = this;
        this.game.deferredActions.runAll(function () { return _this.game.doneWorldGovernmentTerraforming(); });
    };
    Player.prototype.worldGovernmentTerraforming = function () {
        var _this = this;
        var action = new OrOptions_1.OrOptions();
        action.title = 'Select action for World Government Terraforming';
        action.buttonLabel = 'Confirm';
        var game = this.game;
        if (game.getTemperature() < constants.MAX_TEMPERATURE) {
            action.options.push(new SelectOption_1.SelectOption('Increase temperature', 'Increase', function () {
                game.increaseTemperature(_this, 1);
                game.log('${0} acted as World Government and increased temperature', function (b) { return b.player(_this); });
                return undefined;
            }));
        }
        if (game.getOxygenLevel() < constants.MAX_OXYGEN_LEVEL) {
            action.options.push(new SelectOption_1.SelectOption('Increase oxygen', 'Increase', function () {
                game.increaseOxygenLevel(_this, 1);
                game.log('${0} acted as World Government and increased oxygen level', function (b) { return b.player(_this); });
                return undefined;
            }));
        }
        if (game.canAddOcean()) {
            action.options.push(new SelectSpace_1.SelectSpace('Add an ocean', game.board.getAvailableSpacesForOcean(this), function (space) {
                game.addOceanTile(_this, space.id, SpaceType_1.SpaceType.OCEAN);
                game.log('${0} acted as World Government and placed an ocean', function (b) { return b.player(_this); });
                return undefined;
            }));
        }
        if (game.getVenusScaleLevel() < constants.MAX_VENUS_SCALE && game.gameOptions.venusNextExtension) {
            action.options.push(new SelectOption_1.SelectOption('Increase Venus scale', 'Increase', function () {
                game.increaseVenusScaleLevel(_this, 1);
                game.log('${0} acted as World Government and increased Venus scale', function (b) { return b.player(_this); });
                return undefined;
            }));
        }
        MoonExpansion_1.MoonExpansion.ifMoon(game, function (moonData) {
            if (moonData.colonyRate < constants.MAXIMUM_COLONY_RATE) {
                action.options.push(new SelectOption_1.SelectOption('Increase the Moon colony rate', 'Increase', function () {
                    MoonExpansion_1.MoonExpansion.raiseColonyRate(_this, 1);
                    return undefined;
                }));
            }
            if (moonData.miningRate < constants.MAXIMUM_MINING_RATE) {
                action.options.push(new SelectOption_1.SelectOption('Increase the Moon mining rate', 'Increase', function () {
                    MoonExpansion_1.MoonExpansion.raiseMiningRate(_this, 1);
                    return undefined;
                }));
            }
            if (moonData.logisticRate < constants.MAXIMUM_LOGISTICS_RATE) {
                action.options.push(new SelectOption_1.SelectOption('Increase the Moon logistics rate', 'Increase', function () {
                    MoonExpansion_1.MoonExpansion.raiseLogisticRate(_this, 1);
                    return undefined;
                }));
            }
        });
        this.setWaitingFor(action, function () {
            _this.doneWorldGovernmentTerraforming();
        });
    };
    Player.prototype.dealCards = function (quantity, cards) {
        for (var i = 0; i < quantity; i++) {
            cards.push(this.game.dealer.dealCard(this.game, true));
        }
    };
    Player.prototype.runDraftPhase = function (initialDraft, playerName, passedCards) {
        var _this = this;
        var cardsToKeep = 1;
        var cards = [];
        if (passedCards === undefined) {
            if (!initialDraft) {
                var cardsToDraw = 4;
                if (LunaProjectOffice_1.LunaProjectOffice.isActive(this)) {
                    cardsToDraw = 5;
                    cardsToKeep = 2;
                }
                this.dealCards(cardsToDraw, cards);
            }
            else {
                this.dealCards(5, cards);
            }
        }
        else {
            cards = passedCards;
        }
        var message = cardsToKeep === 1 ?
            'Select a card to keep and pass the rest to ${0}' :
            'Select two cards to keep and pass the rest to ${0}';
        this.setWaitingFor(new SelectCard_1.SelectCard({
            message: message,
            data: [{
                    type: LogMessageDataType_1.LogMessageDataType.RAW_STRING,
                    value: playerName,
                }],
        }, 'Keep', cards, function (foundCards) {
            foundCards.forEach(function (card) {
                _this.draftedCards.push(card);
                cards = cards.filter(function (c) { return c !== card; });
            });
            _this.game.playerIsFinishedWithDraftingPhase(initialDraft, _this, cards);
            return undefined;
        }, cardsToKeep, cardsToKeep, false, undefined, false));
    };
    Player.prototype.spendableMegacredits = function () {
        return (this.canUseHeatAsMegaCredits) ? (this.heat + this.megaCredits) : this.megaCredits;
    };
    Player.prototype.runResearchPhase = function (draftVariant) {
        var _this = this;
        var dealtCards = [];
        if (!draftVariant) {
            this.dealCards(LunaProjectOffice_1.LunaProjectOffice.isActive(this) ? 5 : 4, dealtCards);
        }
        else {
            dealtCards = this.draftedCards;
            this.draftedCards = [];
        }
        var action = DrawCards_1.DrawCards.choose(this, dealtCards, { paying: true });
        this.setWaitingFor(action, function () { return _this.game.playerIsFinishedWithResearchPhase(_this); });
    };
    Player.prototype.getCardCost = function (card) {
        var _this = this;
        var cost = card.cost;
        cost -= this.cardDiscount;
        this.playedCards.forEach(function (playedCard) {
            if (playedCard.getCardDiscount !== undefined) {
                cost -= playedCard.getCardDiscount(_this, card);
            }
        });
        if (this.corporationCard !== undefined && this.corporationCard.getCardDiscount !== undefined) {
            cost -= this.corporationCard.getCardDiscount(this, card);
        }
        this.removedFromPlayCards.forEach(function (removedFromPlayCard) {
            if (removedFromPlayCard.getCardDiscount !== undefined) {
                cost -= removedFromPlayCard.getCardDiscount(_this, card);
            }
        });
        if (card.tags.includes(Tags_1.Tags.SPACE) && PartyHooks_1.PartyHooks.shouldApplyPolicy(this, PartyName_1.PartyName.UNITY, 'up04')) {
            cost -= 2;
        }
        return Math.max(cost, 0);
    };
    Player.prototype.canUseSteel = function (card) {
        return this.lastCardPlayed === CardName_1.CardName.LAST_RESORT_INGENUITY || card.tags.includes(Tags_1.Tags.BUILDING);
    };
    Player.prototype.canUseTitanium = function (card) {
        return this.lastCardPlayed === CardName_1.CardName.LAST_RESORT_INGENUITY || card.tags.includes(Tags_1.Tags.SPACE);
    };
    Player.prototype.canUseMicrobes = function (card) {
        return card.tags.includes(Tags_1.Tags.PLANT);
    };
    Player.prototype.canUseFloaters = function (card) {
        return card.tags.includes(Tags_1.Tags.VENUS);
    };
    Player.prototype.canUseScience = function (card) {
        return card.tags.includes(Tags_1.Tags.MOON);
    };
    Player.prototype.canUseSeeds = function (card) {
        return card.tags.includes(Tags_1.Tags.PLANT) || card.name === CardName_1.CardName.GREENERY_STANDARD_PROJECT;
    };
    Player.prototype.playPreludeCard = function () {
        var _this = this;
        return new SelectCard_1.SelectCard('Select prelude card to play', 'Play', this.getPlayablePreludeCards(), function (foundCards) {
            return _this.playCard(foundCards[0]);
        });
    };
    Player.prototype.checkHowToPayAndPlayCard = function (selectedCard, howToPay) {
        var _a, _b;
        var cardCost = this.getCardCost(selectedCard);
        var totalToPay = 0;
        var canUseSteel = this.canUseSteel(selectedCard);
        var canUseTitanium = this.canUseTitanium(selectedCard);
        if (canUseSteel && howToPay.steel > 0) {
            if (howToPay.steel > this.steel) {
                throw new Error('Do not have enough steel');
            }
            totalToPay += howToPay.steel * this.getSteelValue();
        }
        if (canUseTitanium && howToPay.titanium > 0) {
            if (howToPay.titanium > this.titanium) {
                throw new Error('Do not have enough titanium');
            }
            totalToPay += howToPay.titanium * this.getTitaniumValue();
        }
        if (this.canUseHeatAsMegaCredits && howToPay.heat !== undefined) {
            totalToPay += howToPay.heat;
        }
        if (howToPay.microbes !== undefined) {
            totalToPay += howToPay.microbes * constants_1.DEFAULT_MICROBES_VALUE;
        }
        if (howToPay.floaters !== undefined && howToPay.floaters > 0) {
            if (selectedCard.name === CardName_1.CardName.STRATOSPHERIC_BIRDS && howToPay.floaters === this.getFloatersCanSpend()) {
                var cardsWithFloater = this.getCardsWithResources(ResourceType_1.ResourceType.FLOATER);
                if (cardsWithFloater.length === 1) {
                    throw new Error('Cannot spend all floaters to play Stratospheric Birds');
                }
            }
            totalToPay += howToPay.floaters * constants_1.DEFAULT_FLOATERS_VALUE;
        }
        if ((_a = howToPay.science) !== null && _a !== void 0 ? _a : 0 > 0) {
            totalToPay += howToPay.science;
        }
        if ((_b = howToPay.seeds) !== null && _b !== void 0 ? _b : 0 > 0) {
            totalToPay += howToPay.seeds * constants.SEED_VALUE;
        }
        if (howToPay.megaCredits > this.megaCredits) {
            throw new Error('Do not have enough M€');
        }
        if (howToPay.science !== undefined) {
            totalToPay += howToPay.science;
        }
        totalToPay += howToPay.megaCredits;
        if (totalToPay < cardCost) {
            throw new Error('Did not spend enough to pay for card');
        }
        return this.playCard(selectedCard, howToPay);
    };
    Player.prototype.playProjectCard = function () {
        var _this = this;
        return new SelectHowToPayForProjectCard_1.SelectHowToPayForProjectCard(this, this.getPlayableCards(), function (selectedCard, howToPay) { return _this.checkHowToPayAndPlayCard(selectedCard, howToPay); });
    };
    Player.prototype.getMicrobesCanSpend = function () {
        var _a;
        var psychrophiles = this.playedCards.find(function (card) { return card.name === CardName_1.CardName.PSYCHROPHILES; });
        return psychrophiles !== undefined ?
            (_a = this.getResourcesOnCard(psychrophiles)) !== null && _a !== void 0 ? _a : 0 :
            0;
    };
    Player.prototype.getFloatersCanSpend = function () {
        var _a;
        var dirigibles = this.playedCards.find(function (card) { return card.name === CardName_1.CardName.DIRIGIBLES; });
        return dirigibles !== undefined ?
            (_a = this.getResourcesOnCard(dirigibles)) !== null && _a !== void 0 ? _a : 0 :
            0;
    };
    Player.prototype.getSpendableScienceResources = function () {
        var _a;
        var lunaArchives = this.playedCards.find(function (card) { return card.name === CardName_1.CardName.LUNA_ARCHIVES; });
        return lunaArchives !== undefined ?
            (_a = this.getResourcesOnCard(lunaArchives)) !== null && _a !== void 0 ? _a : 0 :
            0;
    };
    Player.prototype.getSpendableSeedResources = function () {
        var _a, _b;
        if (this.isCorporation(CardName_1.CardName.SOYLENT_SEEDLING_SYSTEMS)) {
            return (_b = (_a = this.corporationCard) === null || _a === void 0 ? void 0 : _a.resourceCount) !== null && _b !== void 0 ? _b : 0;
        }
        return 0;
    };
    Player.prototype.playCard = function (selectedCard, howToPay, addToPlayedCards) {
        var _this = this;
        var _a;
        if (addToPlayedCards === void 0) { addToPlayedCards = true; }
        if (howToPay !== undefined) {
            this.deductResource(Resources_1.Resources.STEEL, howToPay.steel);
            this.deductResource(Resources_1.Resources.TITANIUM, howToPay.titanium);
            this.deductResource(Resources_1.Resources.MEGACREDITS, howToPay.megaCredits);
            this.deductResource(Resources_1.Resources.HEAT, howToPay.heat);
            for (var _i = 0, _b = this.playedCards; _i < _b.length; _i++) {
                var playedCard = _b[_i];
                if (playedCard.name === CardName_1.CardName.PSYCHROPHILES) {
                    this.removeResourceFrom(playedCard, howToPay.microbes);
                }
                if (playedCard.name === CardName_1.CardName.DIRIGIBLES) {
                    this.removeResourceFrom(playedCard, howToPay.floaters);
                }
                if (playedCard.name === CardName_1.CardName.LUNA_ARCHIVES) {
                    this.removeResourceFrom(playedCard, howToPay.science);
                }
                if (((_a = this.corporationCard) === null || _a === void 0 ? void 0 : _a.name) === CardName_1.CardName.SOYLENT_SEEDLING_SYSTEMS) {
                    this.removeResourceFrom(this.corporationCard, howToPay.seeds);
                }
            }
        }
        if (this.game.gameOptions.coloniesExtension && selectedCard.resourceType !== undefined) {
            this.game.colonies.forEach(function (colony) {
                if (colony.resourceType !== undefined && colony.resourceType === selectedCard.resourceType) {
                    colony.isActive = true;
                }
            });
            if (selectedCard.tags.includes(Tags_1.Tags.VENUS)) {
                var venusColony = this.game.colonies.find(function (colony) { return colony.name === ColonyName_1.ColonyName.VENUS; });
                if (venusColony)
                    venusColony.isActive = true;
            }
        }
        if (selectedCard.cardType !== CardType_1.CardType.PROXY) {
            this.lastCardPlayed = selectedCard.name;
            this.game.log('${0} played ${1}', function (b) { return b.player(_this).card(selectedCard); });
        }
        var action = selectedCard.play(this);
        if (action !== undefined) {
            this.game.defer(new DeferredAction_1.DeferredAction(this, function () { return action; }));
        }
        var projectCardIndex = this.cardsInHand.findIndex(function (card) { return card.name === selectedCard.name; });
        var preludeCardIndex = this.preludeCardsInHand.findIndex(function (card) { return card.name === selectedCard.name; });
        if (projectCardIndex !== -1) {
            this.cardsInHand.splice(projectCardIndex, 1);
        }
        else if (preludeCardIndex !== -1) {
            this.preludeCardsInHand.splice(preludeCardIndex, 1);
        }
        var card = this.playedCards.find(function (card) { return card.name === CardName_1.CardName.SELF_REPLICATING_ROBOTS; });
        if (card instanceof SelfReplicatingRobots_1.SelfReplicatingRobots) {
            for (var _c = 0, _d = card.targetCards; _c < _d.length; _c++) {
                var targetCard = _d[_c];
                if (targetCard.card.name === selectedCard.name) {
                    var index = card.targetCards.indexOf(targetCard);
                    card.targetCards.splice(index, 1);
                }
            }
        }
        if (addToPlayedCards && selectedCard.name !== CardName_1.CardName.LAW_SUIT) {
            this.playedCards.push(selectedCard);
        }
        if (!selectedCard.tags.includes(Tags_1.Tags.CLONE)) {
            this.onCardPlayed(selectedCard);
        }
        return undefined;
    };
    Player.prototype.onCardPlayed = function (card) {
        var _loop_1 = function (playedCard) {
            if (playedCard.onCardPlayed !== undefined) {
                var actionFromPlayedCard_1 = playedCard.onCardPlayed(this_1, card);
                if (actionFromPlayedCard_1 !== undefined) {
                    this_1.game.defer(new DeferredAction_1.DeferredAction(this_1, function () { return actionFromPlayedCard_1; }));
                }
            }
        };
        var this_1 = this;
        for (var _i = 0, _a = this.playedCards; _i < _a.length; _i++) {
            var playedCard = _a[_i];
            _loop_1(playedCard);
        }
        TurmoilHandler_1.TurmoilHandler.applyOnCardPlayedEffect(this, card);
        var _loop_2 = function (somePlayer) {
            if (somePlayer.corporationCard !== undefined && somePlayer.corporationCard.onCardPlayed !== undefined) {
                var actionFromPlayedCard_2 = somePlayer.corporationCard.onCardPlayed(this_2, card);
                if (actionFromPlayedCard_2 !== undefined) {
                    this_2.game.defer(new DeferredAction_1.DeferredAction(this_2, function () { return actionFromPlayedCard_2; }));
                }
            }
        };
        var this_2 = this;
        for (var _b = 0, _c = this.game.getPlayers(); _b < _c.length; _b++) {
            var somePlayer = _c[_b];
            _loop_2(somePlayer);
        }
        PathfindersExpansion_1.PathfindersExpansion.onCardPlayed(this, card);
    };
    Player.prototype.playActionCard = function () {
        var _this = this;
        return new SelectCard_1.SelectCard('Perform an action from a played card', 'Take action', this.getPlayableActionCards(), function (foundCards) {
            var foundCard = foundCards[0];
            _this.game.log('${0} used ${1} action', function (b) { return b.player(_this).card(foundCard); });
            var action = foundCard.action(_this);
            if (action !== undefined) {
                _this.game.defer(new DeferredAction_1.DeferredAction(_this, function () { return action; }));
            }
            _this.actionsThisGeneration.add(foundCard.name);
            return undefined;
        }, 1, 1, true);
    };
    Player.prototype.drawCard = function (count, options) {
        return DrawCards_1.DrawCards.keepAll(this, count, options).execute();
    };
    Player.prototype.drawCardKeepSome = function (count, options) {
        return DrawCards_1.DrawCards.keepSome(this, count, options).execute();
    };
    Object.defineProperty(Player.prototype, "availableHeat", {
        get: function () {
            return this.heat + (this.isCorporation(CardName_1.CardName.STORMCRAFT_INCORPORATED) ? this.getResourcesOnCorporation() * 2 : 0);
        },
        enumerable: false,
        configurable: true
    });
    Player.prototype.spendHeat = function (amount, cb) {
        if (cb === void 0) { cb = function () { return undefined; }; }
        if (this.isCorporation(CardName_1.CardName.STORMCRAFT_INCORPORATED) && this.getResourcesOnCorporation() > 0) {
            return this.corporationCard.spendHeat(this, amount, cb);
        }
        this.deductResource(Resources_1.Resources.HEAT, amount);
        return cb();
    };
    Player.prototype.claimMilestone = function (milestone) {
        var _this = this;
        return new SelectOption_1.SelectOption(milestone.name, 'Claim - ' + '(' + milestone.name + ')', function () {
            _this.game.claimedMilestones.push({
                player: _this,
                milestone: milestone,
            });
            _this.game.defer(new SelectHowToPayDeferred_1.SelectHowToPayDeferred(_this, constants_1.MILESTONE_COST, { title: 'Select how to pay for milestone' }));
            _this.game.log('${0} claimed ${1} milestone', function (b) { return b.player(_this).milestone(milestone); });
            return undefined;
        });
    };
    Player.prototype.fundAward = function (award) {
        var _this = this;
        return new SelectOption_1.SelectOption(award.name, 'Fund - ' + '(' + award.name + ')', function () {
            _this.game.defer(new SelectHowToPayDeferred_1.SelectHowToPayDeferred(_this, _this.game.getAwardFundingCost(), { title: 'Select how to pay for award' }));
            _this.game.fundAward(_this, award);
            return undefined;
        });
    };
    Player.prototype.giveAwards = function (vpb) {
        var _this = this;
        this.game.fundedAwards.forEach(function (fundedAward) {
            if (_this.game.isSoloMode())
                return;
            var players = _this.game.getPlayers().slice();
            players.sort(function (p1, p2) { return fundedAward.award.getScore(p2) - fundedAward.award.getScore(p1); });
            if (fundedAward.award.getScore(players[0]) > fundedAward.award.getScore(players[1])) {
                if (players[0].id === _this.id)
                    vpb.setVictoryPoints('awards', 5, '1st place for ' + fundedAward.award.name + ' award (funded by ' + fundedAward.player.name + ')');
                players.shift();
                if (players.length > 1) {
                    if (fundedAward.award.getScore(players[0]) > fundedAward.award.getScore(players[1])) {
                        if (players[0].id === _this.id)
                            vpb.setVictoryPoints('awards', 2, '2nd place for ' + fundedAward.award.name + ' award (funded by ' + fundedAward.player.name + ')');
                    }
                    else {
                        var score = fundedAward.award.getScore(players[0]);
                        while (players.length > 0 && fundedAward.award.getScore(players[0]) === score) {
                            if (players[0].id === _this.id)
                                vpb.setVictoryPoints('awards', 2, '2nd place for ' + fundedAward.award.name + ' award (funded by ' + fundedAward.player.name + ')');
                            players.shift();
                        }
                    }
                }
            }
            else {
                var score = fundedAward.award.getScore(players[0]);
                while (players.length > 0 && fundedAward.award.getScore(players[0]) === score) {
                    if (players[0].id === _this.id)
                        vpb.setVictoryPoints('awards', 5, '1st place for ' + fundedAward.award.name + ' award (funded by ' + fundedAward.player.name + ')');
                    players.shift();
                }
            }
        });
    };
    Player.prototype.endTurnOption = function () {
        var _this = this;
        return new SelectOption_1.SelectOption('End Turn', 'End', function () {
            _this.actionsTakenThisRound = 1;
            _this.game.log('${0} ended turn', function (b) { return b.player(_this); });
            return undefined;
        });
    };
    Player.prototype.pass = function () {
        this.game.playerHasPassed(this);
        this.lastCardPlayed = undefined;
    };
    Player.prototype.passOption = function () {
        var _this = this;
        return new SelectOption_1.SelectOption('Pass for this generation', 'Pass', function () {
            _this.pass();
            _this.game.log('${0} passed', function (b) { return b.player(_this); });
            return undefined;
        });
    };
    Player.prototype.takeActionForFinalGreenery = function () {
        var _this = this;
        if (this.game.deferredActions.length > 0) {
            this.resolveFinalGreeneryDeferredActions();
            return;
        }
        if (this.game.canPlaceGreenery(this)) {
            var action = new OrOptions_1.OrOptions();
            action.title = 'Place any final greenery from plants';
            action.buttonLabel = 'Confirm';
            action.options.push(new SelectSpace_1.SelectSpace('Select space for greenery', this.game.board.getAvailableSpacesForGreenery(this), function (space) {
                _this.game.addGreenery(_this, space.id, SpaceType_1.SpaceType.LAND, false);
                _this.deductResource(Resources_1.Resources.PLANTS, _this.plantsNeededForGreenery);
                _this.takeActionForFinalGreenery();
                if (_this.game.deferredActions.length > 0)
                    _this.resolveFinalGreeneryDeferredActions();
                return undefined;
            }));
            action.options.push(new SelectOption_1.SelectOption('Don\'t place a greenery', 'Confirm', function () {
                _this.game.playerIsDoneWithGame(_this);
                return undefined;
            }));
            this.setWaitingFor(action);
            return;
        }
        if (this.game.deferredActions.length > 0) {
            this.resolveFinalGreeneryDeferredActions();
        }
        else {
            this.game.playerIsDoneWithGame(this);
        }
    };
    Player.prototype.resolveFinalGreeneryDeferredActions = function () {
        var _this = this;
        this.game.deferredActions.runAll(function () { return _this.takeActionForFinalGreenery(); });
    };
    Player.prototype.getPlayablePreludeCards = function () {
        var _this = this;
        return this.preludeCardsInHand.filter(function (card) { return card.canPlay === undefined || card.canPlay(_this); });
    };
    Player.prototype.getPlayableCards = function () {
        var _this = this;
        var candidateCards = __spreadArray([], this.cardsInHand, true);
        var card = this.playedCards.find(function (card) { return card.name === CardName_1.CardName.SELF_REPLICATING_ROBOTS; });
        if (card instanceof SelfReplicatingRobots_1.SelfReplicatingRobots) {
            for (var _i = 0, _a = card.targetCards; _i < _a.length; _i++) {
                var targetCard = _a[_i];
                candidateCards.push(targetCard.card);
            }
        }
        return candidateCards.filter(function (card) { return _this.canPlay(card); });
    };
    Player.prototype.canPlay = function (card) {
        var baseCost = this.getCardCost(card);
        var canAfford = this.canAfford(baseCost, {
            steel: this.canUseSteel(card),
            titanium: this.canUseTitanium(card),
            floaters: this.canUseFloaters(card),
            microbes: this.canUseMicrobes(card),
            science: this.canUseScience(card),
            seeds: this.canUseSeeds(card),
            reserveUnits: MoonExpansion_1.MoonExpansion.adjustedReserveCosts(this, card),
            tr: card.tr,
        });
        if (!canAfford) {
            return false;
        }
        return this.canPlayIgnoringCost(card);
    };
    Player.prototype.canPlayIgnoringCost = function (card) {
        if (card.requirements !== undefined && !card.requirements.satisfies(this)) {
            return false;
        }
        return card.canPlay(this);
    };
    Player.prototype.canAfford = function (cost, options) {
        var _a, _b, _c, _d, _e, _f, _g;
        var reserveUnits = (_a = options === null || options === void 0 ? void 0 : options.reserveUnits) !== null && _a !== void 0 ? _a : Units_1.Units.EMPTY;
        if (!this.hasUnits(reserveUnits)) {
            return false;
        }
        var canUseSteel = (_b = options === null || options === void 0 ? void 0 : options.steel) !== null && _b !== void 0 ? _b : false;
        var canUseTitanium = (_c = options === null || options === void 0 ? void 0 : options.titanium) !== null && _c !== void 0 ? _c : false;
        var canUseFloaters = (_d = options === null || options === void 0 ? void 0 : options.floaters) !== null && _d !== void 0 ? _d : false;
        var canUseMicrobes = (_e = options === null || options === void 0 ? void 0 : options.microbes) !== null && _e !== void 0 ? _e : false;
        var canUseScience = (_f = options === null || options === void 0 ? void 0 : options.science) !== null && _f !== void 0 ? _f : false;
        var canUseSeeds = (_g = options === null || options === void 0 ? void 0 : options.seeds) !== null && _g !== void 0 ? _g : false;
        var redsCost = TurmoilHandler_1.TurmoilHandler.computeTerraformRatingBump(this, options === null || options === void 0 ? void 0 : options.tr) * constants_1.REDS_RULING_POLICY_COST;
        var availableMegacredits = this.megaCredits;
        if (this.canUseHeatAsMegaCredits) {
            availableMegacredits += this.heat;
            availableMegacredits -= reserveUnits.heat;
        }
        availableMegacredits -= reserveUnits.megacredits;
        availableMegacredits -= redsCost;
        if (availableMegacredits < 0) {
            return false;
        }
        return cost <= availableMegacredits +
            (canUseSteel ? (this.steel - reserveUnits.steel) * this.getSteelValue() : 0) +
            (canUseTitanium ? (this.titanium - reserveUnits.titanium) * this.getTitaniumValue() : 0) +
            (canUseFloaters ? this.getFloatersCanSpend() * 3 : 0) +
            (canUseMicrobes ? this.getMicrobesCanSpend() * 2 : 0) +
            (canUseScience ? this.getSpendableScienceResources() : 0) +
            (canUseSeeds ? this.getSpendableSeedResources() * constants.SEED_VALUE : 0);
    };
    Player.prototype.getStandardProjects = function () {
        var _this = this;
        return new CardLoader_1.CardLoader(this.game.gameOptions)
            .getStandardProjects()
            .filter(function (card) {
            switch (card.name) {
                case CardName_1.CardName.SELL_PATENTS_STANDARD_PROJECT:
                    return false;
                case CardName_1.CardName.BUFFER_GAS_STANDARD_PROJECT:
                    return _this.game.isSoloMode() && _this.game.gameOptions.soloTR;
                case CardName_1.CardName.AIR_SCRAPPING_STANDARD_PROJECT:
                    return _this.game.gameOptions.altVenusBoard === false;
                case CardName_1.CardName.AIR_SCRAPPING_STANDARD_PROJECT_VARIANT:
                    return _this.game.gameOptions.altVenusBoard === true;
                default:
                    return true;
            }
            ;
        })
            .sort(function (a, b) { return a.cost - b.cost; });
    };
    Player.prototype.getStandardProjectOption = function () {
        var _this = this;
        var standardProjects = this.getStandardProjects();
        return new SelectCard_1.SelectCard('Standard projects', 'Confirm', standardProjects, function (card) { return card[0].action(_this); }, 1, 1, false, standardProjects.map(function (card) { return card.canAct(_this); }));
    };
    Player.prototype.takeAction = function () {
        var _this = this;
        var game = this.game;
        if (game.deferredActions.length > 0) {
            game.deferredActions.runAll(function () { return _this.takeAction(); });
            return;
        }
        var allOtherPlayersHavePassed = this.allOtherPlayersHavePassed();
        if (this.actionsTakenThisRound === 0 || game.gameOptions.undoOption) {
            game.save();
        }
        if (this.preludeCardsInHand.length > 0) {
            game.phase = Phase_1.Phase.PRELUDES;
            if (this.getPlayablePreludeCards().length === 0) {
                this.preludeCardsInHand = [];
                game.playerIsFinishedTakingActions();
                return;
            }
            this.setWaitingFor(this.playPreludeCard(), function () {
                if (_this.preludeCardsInHand.length === 1) {
                    _this.takeAction();
                }
                else {
                    game.playerIsFinishedTakingActions();
                }
            });
            return;
        }
        else {
            game.phase = Phase_1.Phase.ACTION;
        }
        if (game.hasPassedThisActionPhase(this) || (allOtherPlayersHavePassed === false && this.actionsTakenThisRound >= 2)) {
            this.actionsTakenThisRound = 0;
            game.playerIsFinishedTakingActions();
            return;
        }
        var corporationCard = this.corporationCard;
        if (this.isCorporation(CardName_1.CardName.VITOR) && this.game.allAwardsFunded()) {
            this.corporationInitialActionDone = true;
        }
        if (corporationCard !== undefined &&
            corporationCard.initialAction !== undefined &&
            corporationCard.initialActionText !== undefined &&
            this.corporationInitialActionDone === false) {
            var initialActionOption = new SelectOption_1.SelectOption({
                message: 'Take first action of ${0} corporation',
                data: [{
                        type: LogMessageDataType_1.LogMessageDataType.RAW_STRING,
                        value: corporationCard.name,
                    }],
            }, corporationCard.initialActionText, function () {
                game.defer(new DeferredAction_1.DeferredAction(_this, function () {
                    if (corporationCard.initialAction) {
                        return corporationCard.initialAction(_this);
                    }
                    else {
                        return undefined;
                    }
                }));
                _this.corporationInitialActionDone = true;
                return undefined;
            });
            var initialActionOrPass = new OrOptions_1.OrOptions(initialActionOption, this.passOption());
            this.setWaitingFor(initialActionOrPass, function () {
                _this.incrementActionsTaken();
                _this.takeAction();
            });
            return;
        }
        this.setWaitingFor(this.getActions(), function () {
            _this.incrementActionsTaken();
            _this.takeAction();
        });
    };
    Player.prototype.incrementActionsTaken = function () {
        this.actionsTakenThisRound++;
        this.actionsTakenThisGame++;
    };
    Player.prototype.getActions = function () {
        var _this = this;
        var action = new OrOptions_1.OrOptions();
        action.title = this.actionsTakenThisRound === 0 ?
            'Take your first action' : 'Take your next action';
        action.buttonLabel = 'Take action';
        if (this.canAfford(constants_1.MILESTONE_COST) && !this.game.allMilestonesClaimed()) {
            var remainingMilestones = new OrOptions_1.OrOptions();
            remainingMilestones.title = 'Claim a milestone';
            remainingMilestones.options = this.game.milestones
                .filter(function (milestone) {
                return !_this.game.milestoneClaimed(milestone) &&
                    milestone.canClaim(_this);
            })
                .map(function (milestone) {
                return _this.claimMilestone(milestone);
            });
            if (remainingMilestones.options.length >= 1)
                action.options.push(remainingMilestones);
        }
        var convertPlants = new ConvertPlants_1.ConvertPlants();
        if (convertPlants.canAct(this)) {
            action.options.push(convertPlants.action(this));
        }
        var convertHeat = new ConvertHeat_1.ConvertHeat();
        if (convertHeat.canAct(this)) {
            action.options.push(new SelectOption_1.SelectOption("Convert " + constants.HEAT_FOR_TEMPERATURE + " heat into temperature", 'Convert heat', function () {
                return convertHeat.action(_this);
            }));
        }
        TurmoilHandler_1.TurmoilHandler.addPlayerAction(this, action.options);
        if (this.getPlayableActionCards().length > 0) {
            action.options.push(this.playActionCard());
        }
        if (this.getPlayableCards().length > 0) {
            action.options.push(this.playProjectCard());
        }
        var coloniesTradeAction = ColoniesHandler_1.ColoniesHandler.coloniesTradeAction(this);
        if (coloniesTradeAction !== undefined) {
            action.options.push(coloniesTradeAction);
        }
        Turmoil_1.Turmoil.ifTurmoil(this.game, function (turmoil) {
            var sendDelegate;
            if (turmoil.lobby.has(_this.id)) {
                sendDelegate = new SendDelegateToArea_1.SendDelegateToArea(_this, 'Send a delegate in an area (from lobby)');
            }
            else if (_this.isCorporation(CardName_1.CardName.INCITE) && _this.canAfford(3) && turmoil.hasDelegatesInReserve(_this.id)) {
                sendDelegate = new SendDelegateToArea_1.SendDelegateToArea(_this, 'Send a delegate in an area (3 M€)', { cost: 3 });
            }
            else if (_this.canAfford(5) && turmoil.hasDelegatesInReserve(_this.id)) {
                sendDelegate = new SendDelegateToArea_1.SendDelegateToArea(_this, 'Send a delegate in an area (5 M€)', { cost: 5 });
            }
            if (sendDelegate) {
                var input = sendDelegate.execute();
                if (input !== undefined) {
                    action.options.push(input);
                }
            }
        });
        if (this.game.getPlayers().length > 1 &&
            this.actionsTakenThisRound > 0 &&
            !this.game.gameOptions.fastModeOption &&
            this.allOtherPlayersHavePassed() === false) {
            action.options.push(this.endTurnOption());
        }
        var fundingCost = this.game.getAwardFundingCost();
        if (this.canAfford(fundingCost) && !this.game.allAwardsFunded()) {
            var remainingAwards = new OrOptions_1.OrOptions();
            remainingAwards.title = {
                data: [{
                        type: LogMessageDataType_1.LogMessageDataType.RAW_STRING,
                        value: String(fundingCost),
                    }],
                message: 'Fund an award (${0} M€)',
            };
            remainingAwards.buttonLabel = 'Confirm';
            remainingAwards.options = this.game.awards
                .filter(function (award) { return _this.game.hasBeenFunded(award) === false; })
                .map(function (award) { return _this.fundAward(award); });
            action.options.push(remainingAwards);
        }
        action.options.push(this.getStandardProjectOption());
        action.options.push(this.passOption());
        var sellPatents = new SellPatentsStandardProject_1.SellPatentsStandardProject();
        if (sellPatents.canAct(this)) {
            action.options.push(sellPatents.action(this));
        }
        if (this.actionsTakenThisRound > 0 && this.game.gameOptions.undoOption) {
            action.options.push(new UndoActionOption_1.UndoActionOption());
        }
        return action;
    };
    Player.prototype.allOtherPlayersHavePassed = function () {
        var game = this.game;
        if (game.isSoloMode())
            return true;
        var players = game.getPlayers();
        var passedPlayers = game.getPassedPlayers();
        return passedPlayers.length === players.length - 1 && passedPlayers.includes(this.color) === false;
    };
    Player.prototype.process = function (input) {
        if (this.waitingFor === undefined || this.waitingForCb === undefined) {
            throw new Error('Not waiting for anything');
        }
        var waitingFor = this.waitingFor;
        var waitingForCb = this.waitingForCb;
        this.waitingFor = undefined;
        this.waitingForCb = undefined;
        try {
            this.timer.stop();
            this.runInput(input, waitingFor);
            waitingForCb();
        }
        catch (err) {
            this.setWaitingFor(waitingFor, waitingForCb);
            throw err;
        }
    };
    Player.prototype.getWaitingFor = function () {
        return this.waitingFor;
    };
    Player.prototype.setWaitingFor = function (input, cb) {
        if (cb === void 0) { cb = function () { }; }
        this.timer.start();
        this.waitingFor = input;
        this.waitingForCb = cb;
    };
    Player.prototype.serialize = function () {
        var _a;
        var result = {
            id: this.id,
            corporationCard: this.corporationCard === undefined ? undefined : {
                name: this.corporationCard.name,
                resourceCount: this.corporationCard.resourceCount,
                allTags: this.corporationCard instanceof Aridor_1.Aridor ? Array.from(this.corporationCard.allTags) : [],
                isDisabled: this.corporationCard instanceof PharmacyUnion_1.PharmacyUnion && this.corporationCard.isDisabled,
            },
            pickedCorporationCard: (_a = this.pickedCorporationCard) === null || _a === void 0 ? void 0 : _a.name,
            terraformRating: this.terraformRating,
            hasIncreasedTerraformRatingThisGeneration: this.hasIncreasedTerraformRatingThisGeneration,
            terraformRatingAtGenerationStart: this.terraformRatingAtGenerationStart,
            megaCredits: this.megaCredits,
            megaCreditProduction: this.megaCreditProduction,
            steel: this.steel,
            steelProduction: this.steelProduction,
            titanium: this.titanium,
            titaniumProduction: this.titaniumProduction,
            plants: this.plants,
            plantProduction: this.plantProduction,
            energy: this.energy,
            energyProduction: this.energyProduction,
            heat: this.heat,
            heatProduction: this.heatProduction,
            titaniumValue: this.titaniumValue,
            steelValue: this.steelValue,
            canUseHeatAsMegaCredits: this.canUseHeatAsMegaCredits,
            actionsTakenThisRound: this.actionsTakenThisRound,
            actionsThisGeneration: Array.from(this.actionsThisGeneration),
            corporationInitialActionDone: this.corporationInitialActionDone,
            dealtCorporationCards: this.dealtCorporationCards.map(function (c) { return c.name; }),
            dealtProjectCards: this.dealtProjectCards.map(function (c) { return c.name; }),
            dealtPreludeCards: this.dealtPreludeCards.map(function (c) { return c.name; }),
            cardsInHand: this.cardsInHand.map(function (c) { return c.name; }),
            preludeCardsInHand: this.preludeCardsInHand.map(function (c) { return c.name; }),
            playedCards: this.playedCards.map(CardSerialization_1.serializeProjectCard),
            draftedCards: this.draftedCards.map(function (c) { return c.name; }),
            cardCost: this.cardCost,
            needsToDraft: this.needsToDraft,
            cardDiscount: this.cardDiscount,
            fleetSize: this.fleetSize,
            tradesThisTurn: this.tradesThisGeneration,
            colonyTradeOffset: this.colonyTradeOffset,
            colonyTradeDiscount: this.colonyTradeDiscount,
            colonyVictoryPoints: this.colonyVictoryPoints,
            turmoilPolicyActionUsed: this.turmoilPolicyActionUsed,
            politicalAgendasActionUsedCount: this.politicalAgendasActionUsedCount,
            hasTurmoilScienceTagBonus: this.hasTurmoilScienceTagBonus,
            oceanBonus: this.oceanBonus,
            scienceTagCount: this.scienceTagCount,
            plantsNeededForGreenery: this.plantsNeededForGreenery,
            removingPlayers: this.removingPlayers,
            removedFromPlayCards: this.removedFromPlayCards.map(function (c) { return c.name; }),
            name: this.name,
            color: this.color,
            beginner: this.beginner,
            handicap: this.handicap,
            timer: this.timer.serialize(),
            actionsTakenThisGame: this.actionsTakenThisGame,
        };
        if (this.lastCardPlayed !== undefined) {
            result.lastCardPlayed = this.lastCardPlayed;
        }
        return result;
    };
    Player.deserialize = function (d) {
        var player = new Player(d.name, d.color, d.beginner, Number(d.handicap), d.id);
        var cardFinder = new CardFinder_1.CardFinder();
        player.actionsTakenThisGame = d.actionsTakenThisGame;
        player.actionsTakenThisRound = d.actionsTakenThisRound;
        player.canUseHeatAsMegaCredits = d.canUseHeatAsMegaCredits;
        player.cardCost = d.cardCost;
        player.cardDiscount = d.cardDiscount;
        player.colonyTradeDiscount = d.colonyTradeDiscount;
        player.colonyTradeOffset = d.colonyTradeOffset;
        player.colonyVictoryPoints = d.colonyVictoryPoints;
        player.corporationInitialActionDone = d.corporationInitialActionDone;
        player.energy = d.energy;
        player.energyProduction = d.energyProduction;
        player.fleetSize = d.fleetSize;
        player.hasIncreasedTerraformRatingThisGeneration = d.hasIncreasedTerraformRatingThisGeneration;
        player.hasTurmoilScienceTagBonus = d.hasTurmoilScienceTagBonus;
        player.heat = d.heat;
        player.heatProduction = d.heatProduction;
        player.megaCreditProduction = d.megaCreditProduction;
        player.megaCredits = d.megaCredits;
        player.needsToDraft = d.needsToDraft;
        player.oceanBonus = d.oceanBonus;
        player.plantProduction = d.plantProduction;
        player.plants = d.plants;
        player.plantsNeededForGreenery = d.plantsNeededForGreenery;
        player.removingPlayers = d.removingPlayers;
        player.scienceTagCount = d.scienceTagCount;
        player.steel = d.steel;
        player.steelProduction = d.steelProduction;
        player.steelValue = d.steelValue;
        player.terraformRating = d.terraformRating;
        player.terraformRatingAtGenerationStart = d.terraformRatingAtGenerationStart;
        player.titanium = d.titanium;
        player.titaniumProduction = d.titaniumProduction;
        player.titaniumValue = d.titaniumValue;
        player.tradesThisGeneration = d.tradesThisTurn;
        player.turmoilPolicyActionUsed = d.turmoilPolicyActionUsed;
        player.politicalAgendasActionUsedCount = d.politicalAgendasActionUsedCount;
        player.lastCardPlayed = d.lastCardPlayed;
        player.removedFromPlayCards = cardFinder.cardsFromJSON(d.removedFromPlayCards);
        player.actionsThisGeneration = new Set(d.actionsThisGeneration);
        if (d.pickedCorporationCard !== undefined) {
            player.pickedCorporationCard = cardFinder.getCorporationCardByName(d.pickedCorporationCard);
        }
        if (d.corporationCard !== undefined) {
            player.corporationCard = cardFinder.getCorporationCardByName(d.corporationCard.name);
            if (player.corporationCard !== undefined) {
                if (d.corporationCard.resourceCount !== undefined) {
                    player.corporationCard.resourceCount = d.corporationCard.resourceCount;
                }
            }
            if (player.corporationCard instanceof Aridor_1.Aridor) {
                if (d.corporationCard.allTags !== undefined) {
                    player.corporationCard.allTags = new Set(d.corporationCard.allTags);
                }
                else {
                    console.warn('did not find allTags for ARIDOR');
                }
            }
            if (player.corporationCard instanceof PharmacyUnion_1.PharmacyUnion) {
                player.corporationCard.isDisabled = Boolean(d.corporationCard.isDisabled);
            }
        }
        else {
            player.corporationCard = undefined;
        }
        player.dealtCorporationCards = cardFinder.corporationCardsFromJSON(d.dealtCorporationCards);
        player.dealtPreludeCards = cardFinder.cardsFromJSON(d.dealtPreludeCards);
        player.dealtProjectCards = cardFinder.cardsFromJSON(d.dealtProjectCards);
        player.cardsInHand = cardFinder.cardsFromJSON(d.cardsInHand);
        player.preludeCardsInHand = cardFinder.cardsFromJSON(d.preludeCardsInHand);
        player.playedCards = d.playedCards.map(function (element) { return (0, CardSerialization_1.deserializeProjectCard)(element, cardFinder); });
        player.draftedCards = cardFinder.cardsFromJSON(d.draftedCards);
        player.timer = Timer_1.Timer.deserialize(d.timer);
        return player;
    };
    Player.prototype.getFleetSize = function () {
        return this.fleetSize;
    };
    Player.prototype.increaseFleetSize = function () {
        if (this.fleetSize < constants_1.MAX_FLEET_SIZE)
            this.fleetSize++;
    };
    Player.prototype.decreaseFleetSize = function () {
        if (this.fleetSize > 0)
            this.fleetSize--;
    };
    Player.prototype.hasAvailableColonyTileToBuildOn = function (allowDuplicate) {
        var _this = this;
        if (allowDuplicate === void 0) { allowDuplicate = false; }
        if (this.game.gameOptions.coloniesExtension === false)
            return false;
        var availableColonyTiles = this.game.colonies.filter(function (colony) { return colony.isActive; });
        var unavailableColonies = 0;
        availableColonyTiles.forEach(function (colony) {
            if (colony.colonies.length === constants.MAX_COLONIES_PER_TILE) {
                unavailableColonies++;
            }
            else if (!allowDuplicate && colony.colonies.includes(_this.id)) {
                unavailableColonies++;
            }
        });
        return unavailableColonies < availableColonyTiles.length;
    };
    return Player;
}());
exports.Player = Player;
