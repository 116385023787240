"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TradeWithMegacredits = exports.TradeWithTitanium = exports.TradeWithEnergy = exports.ColoniesHandler = void 0;
var constants_1 = require("../constants");
var SelectHowToPayDeferred_1 = require("../deferredActions/SelectHowToPayDeferred");
var Resources_1 = require("../common/Resources");
var TitanFloatingLaunchPad_1 = require("../cards/colonies/TitanFloatingLaunchPad");
var OrOptions_1 = require("../inputs/OrOptions");
var SelectOption_1 = require("../inputs/SelectOption");
var SelectColony_1 = require("../inputs/SelectColony");
var AndOptions_1 = require("../inputs/AndOptions");
var CollegiumCopernicus_1 = require("../cards/pathfinders/CollegiumCopernicus");
var ColoniesHandler = (function () {
    function ColoniesHandler() {
    }
    ColoniesHandler.getColony = function (game, colonyName, includeDiscardedColonies) {
        if (includeDiscardedColonies === void 0) { includeDiscardedColonies = false; }
        var colony = game.colonies.find(function (c) { return c.name === colonyName; });
        if (colony !== undefined)
            return colony;
        if (includeDiscardedColonies === true && game.colonyDealer !== undefined) {
            colony = game.colonyDealer.discardedColonies.find(function (c) { return c.name === colonyName; });
            if (colony !== undefined)
                return colony;
        }
        throw new Error("Unknown colony '" + colonyName + "'");
    };
    ColoniesHandler.tradeableColonies = function (game) {
        return game.colonies.filter(function (colony) { return colony.isActive && colony.visitor === undefined; });
    };
    ColoniesHandler.canTrade = function (player) {
        return ColoniesHandler.tradeableColonies(player.game).length > 0 &&
            player.getFleetSize() > player.tradesThisGeneration;
    };
    ColoniesHandler.coloniesTradeAction = function (player) {
        if (player.game.gameOptions.coloniesExtension) {
            var openColonies = this.tradeableColonies(player.game);
            if (openColonies.length > 0 &&
                player.getFleetSize() > player.tradesThisGeneration) {
                return ColoniesHandler.tradeWithColony(player, openColonies);
            }
        }
        return undefined;
    };
    ColoniesHandler.tradeWithColony = function (player, openColonies) {
        var handlers = [
            new TitanFloatingLaunchPad_1.TradeWithTitanFloatingLaunchPad(player),
            new CollegiumCopernicus_1.TradeWithCollegiumCopernicus(player),
            new TradeWithEnergy(player),
            new TradeWithTitanium(player),
            new TradeWithMegacredits(player),
        ];
        var selected = undefined;
        var howToPayForTrade = new OrOptions_1.OrOptions();
        howToPayForTrade.title = 'Pay trade fee';
        howToPayForTrade.buttonLabel = 'Pay';
        handlers.forEach(function (handler) {
            if (handler.canUse()) {
                howToPayForTrade.options.push(new SelectOption_1.SelectOption(handler.optionText(), '', function () {
                    selected = handler;
                    return undefined;
                }));
            }
        });
        if (howToPayForTrade.options.length === 0) {
            return undefined;
        }
        var selectColony = new SelectColony_1.SelectColony('Select colony tile for trade', 'trade', openColonies, function (colony) {
            if (selected === undefined) {
                throw new Error("Unexpected condition: no trade funding source selected when trading with " + colony.name + ".");
            }
            selected.trade(colony);
            return undefined;
        });
        var trade = new AndOptions_1.AndOptions(function () {
            return undefined;
        }, howToPayForTrade, selectColony);
        trade.title = 'Trade with a colony tile';
        trade.buttonLabel = 'Trade';
        return trade;
    };
    return ColoniesHandler;
}());
exports.ColoniesHandler = ColoniesHandler;
var TradeWithEnergy = (function () {
    function TradeWithEnergy(player) {
        this.player = player;
        this.tradeCost = constants_1.ENERGY_TRADE_COST - player.colonyTradeDiscount;
    }
    TradeWithEnergy.prototype.canUse = function () {
        return this.player.energy >= this.tradeCost;
    };
    TradeWithEnergy.prototype.optionText = function () {
        return 'Pay ' + this.tradeCost + ' Energy';
    };
    TradeWithEnergy.prototype.trade = function (colony) {
        var _this = this;
        this.player.deductResource(Resources_1.Resources.ENERGY, this.tradeCost);
        this.player.game.log('${0} spent ${1} energy to trade with ${2}', function (b) { return b.player(_this.player).number(_this.tradeCost).colony(colony); });
        colony.trade(this.player);
    };
    return TradeWithEnergy;
}());
exports.TradeWithEnergy = TradeWithEnergy;
var TradeWithTitanium = (function () {
    function TradeWithTitanium(player) {
        this.player = player;
        this.tradeCost = constants_1.TITANIUM_TRADE_COST - player.colonyTradeDiscount;
    }
    TradeWithTitanium.prototype.canUse = function () {
        return this.player.titanium >= this.tradeCost;
    };
    TradeWithTitanium.prototype.optionText = function () {
        return 'Pay ' + this.tradeCost + ' Titanium';
    };
    TradeWithTitanium.prototype.trade = function (colony) {
        var _this = this;
        this.player.deductResource(Resources_1.Resources.TITANIUM, this.tradeCost);
        this.player.game.log('${0} spent ${1} titanium to trade with ${2}', function (b) { return b.player(_this.player).number(_this.tradeCost).colony(colony); });
        colony.trade(this.player);
    };
    return TradeWithTitanium;
}());
exports.TradeWithTitanium = TradeWithTitanium;
var TradeWithMegacredits = (function () {
    function TradeWithMegacredits(player) {
        this.player = player;
        this.tradeCost = constants_1.MC_TRADE_COST - player.colonyTradeDiscount;
    }
    TradeWithMegacredits.prototype.canUse = function () {
        return this.player.megaCredits >= this.tradeCost;
    };
    TradeWithMegacredits.prototype.optionText = function () {
        return 'Pay ' + this.tradeCost + ' M€';
    };
    TradeWithMegacredits.prototype.trade = function (colony) {
        var _this = this;
        this.player.game.defer(new SelectHowToPayDeferred_1.SelectHowToPayDeferred(this.player, this.tradeCost, {
            title: 'Select how to pay ' + this.tradeCost + ' for colony trade',
            afterPay: function () {
                _this.player.game.log('${0} spent ${1} M€ to trade with ${2}', function (b) { return b.player(_this.player).number(_this.tradeCost).colony(colony); });
                colony.trade(_this.player);
            },
        }));
    };
    return TradeWithMegacredits;
}());
exports.TradeWithMegacredits = TradeWithMegacredits;
