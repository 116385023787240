"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var vue_1 = require("vue");
var PreferencesManager_1 = require("@/client/utils/PreferencesManager");
var constants_1 = require("@/constants");
exports.default = vue_1.default.extend({
    name: 'PreferencesDialog',
    data: function () {
        return {
            'hide_hand': false,
            'hide_awards_and_milestones': false,
            'hide_top_bar': false,
            'small_cards': false,
            'remove_background': false,
            'magnify_cards': true,
            'show_alerts': true,
            'lang': 'en',
            'langs': constants_1.LANGUAGES,
            'enable_sounds': false,
            'hide_tile_confirmation': false,
            'show_card_number': false,
            'hide_discount_on_cards': false,
            'learner_mode': true,
            'hide_animated_sidebar': false,
            'experimental_ui': false,
        };
    },
    methods: {
        setPreferencesCSS: function (val, cssClassSuffix) {
            var target = document.getElementById('ts-preferences-target');
            if (!target)
                return;
            if (val) {
                target.classList.add('preferences_' + cssClassSuffix);
            }
            else {
                target.classList.remove('preferences_' + cssClassSuffix);
            }
            if (!target.classList.contains('language-' + this.lang)) {
                target.classList.add('language-' + this.lang);
            }
        },
        updatePreferencesFromStorage: function () {
            for (var _i = 0, preferences_1 = PreferencesManager_1.preferences; _i < preferences_1.length; _i++) {
                var k = preferences_1[_i];
                var val = PreferencesManager_1.PreferencesManager.load(k);
                if (k === 'lang') {
                    PreferencesManager_1.PreferencesManager.preferencesValues.set(k, this.$data[k]);
                    this[k] = val || 'en';
                    PreferencesManager_1.PreferencesManager.preferencesValues.set(k, val || 'en');
                }
                else {
                    var boolVal = val !== '' ? val === '1' : this.$data[k];
                    PreferencesManager_1.PreferencesManager.preferencesValues.set(k, val === '1');
                    this.$data[k] = boolVal;
                }
            }
            return PreferencesManager_1.PreferencesManager.preferencesValues;
        },
        updatePreferences: function () {
            var strVal = '';
            for (var _i = 0, preferences_2 = PreferencesManager_1.preferences; _i < preferences_2.length; _i++) {
                var k = preferences_2[_i];
                var val = PreferencesManager_1.PreferencesManager.preferencesValues.get(k);
                if (val !== this.$data[k]) {
                    if (k === 'lang') {
                        strVal = this.$data[k];
                    }
                    else {
                        strVal = this.$data[k] ? '1' : '0';
                    }
                    PreferencesManager_1.PreferencesManager.save(k, strVal);
                    PreferencesManager_1.PreferencesManager.preferencesValues.set(k, this.$data[k]);
                    this.setPreferencesCSS(this.$data[k], k);
                }
            }
        },
        syncPreferences: function () {
            for (var _i = 0, preferences_3 = PreferencesManager_1.preferences; _i < preferences_3.length; _i++) {
                var k = preferences_3[_i];
                this.$data[k] = PreferencesManager_1.PreferencesManager.preferencesValues.get(k);
                this.setPreferencesCSS(this.$data[k], k);
            }
        },
        okClicked: function () {
            this.$emit('okButtonClicked');
        },
    },
    mounted: function () {
        this.updatePreferencesFromStorage();
    },
});
