"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.SCIENTISTS_POLICY_4 = exports.SCIENTISTS_POLICY_3 = exports.SCIENTISTS_POLICY_2 = exports.SCIENTISTS_POLICY_1 = exports.SCIENTISTS_BONUS_2 = exports.SCIENTISTS_BONUS_1 = exports.Scientists = void 0;
var Party_1 = require("./Party");
var PartyName_1 = require("./PartyName");
var Tags_1 = require("../../cards/Tags");
var Resources_1 = require("../../common/Resources");
var SelectHowToPayDeferred_1 = require("../../deferredActions/SelectHowToPayDeferred");
var Scientists = (function (_super) {
    __extends(Scientists, _super);
    function Scientists() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.name = PartyName_1.PartyName.SCIENTISTS;
        _this.description = 'Tech is the door to the future, and Scientists will do anything to open it.';
        _this.bonuses = [exports.SCIENTISTS_BONUS_1, exports.SCIENTISTS_BONUS_2];
        _this.policies = [exports.SCIENTISTS_POLICY_1, exports.SCIENTISTS_POLICY_2, exports.SCIENTISTS_POLICY_3, exports.SCIENTISTS_POLICY_4];
        return _this;
    }
    return Scientists;
}(Party_1.Party));
exports.Scientists = Scientists;
var ScientistsBonus01 = (function () {
    function ScientistsBonus01() {
        this.id = 'sb01';
        this.isDefault = true;
        this.description = 'Gain 1 M€ for each Science tag you have';
    }
    ScientistsBonus01.prototype.getScore = function (player) {
        return player.getTagCount(Tags_1.Tags.SCIENCE, 'raw');
    };
    ScientistsBonus01.prototype.grant = function (game) {
        var _this = this;
        game.getPlayers().forEach(function (player) {
            player.addResource(Resources_1.Resources.MEGACREDITS, _this.getScore(player));
        });
    };
    return ScientistsBonus01;
}());
var ScientistsBonus02 = (function () {
    function ScientistsBonus02() {
        this.id = 'sb02';
        this.description = 'Gain 1 M€ for every 3 cards in hand';
        this.isDefault = false;
    }
    ScientistsBonus02.prototype.getScore = function (player) {
        return Math.floor(player.cardsInHand.length / 3);
    };
    ScientistsBonus02.prototype.grant = function (game) {
        var _this = this;
        game.getPlayers().forEach(function (player) {
            player.addResource(Resources_1.Resources.MEGACREDITS, _this.getScore(player));
        });
    };
    return ScientistsBonus02;
}());
var ScientistsPolicy01 = (function () {
    function ScientistsPolicy01() {
        this.isDefault = true;
        this.id = 'sp01';
        this.description = 'Pay 10 M€ to draw 3 cards (Turmoil Scientists)';
    }
    ScientistsPolicy01.prototype.canAct = function (player) {
        return player.canAfford(10) && player.turmoilPolicyActionUsed === false;
    };
    ScientistsPolicy01.prototype.action = function (player) {
        var game = player.game;
        game.log('${0} used Turmoil Scientists action', function (b) { return b.player(player); });
        game.defer(new SelectHowToPayDeferred_1.SelectHowToPayDeferred(player, 10, {
            title: 'Select how to pay for Turmoil Scientists action',
            afterPay: function () {
                player.drawCard(3);
                player.turmoilPolicyActionUsed = true;
            },
        }));
        return undefined;
    };
    return ScientistsPolicy01;
}());
var ScientistsPolicy02 = (function () {
    function ScientistsPolicy02() {
        this.id = 'sp02';
        this.description = 'Your global requirements are +/- 2 steps';
        this.isDefault = false;
    }
    return ScientistsPolicy02;
}());
var ScientistsPolicy03 = (function () {
    function ScientistsPolicy03() {
        this.id = 'sp03';
        this.description = 'When you raise a global parameter, draw a card per step raised';
        this.isDefault = false;
    }
    return ScientistsPolicy03;
}());
var ScientistsPolicy04 = (function () {
    function ScientistsPolicy04() {
        this.id = 'sp04';
        this.description = 'Cards with Science tag requirements may be played with 1 less Science tag';
        this.isDefault = false;
    }
    ScientistsPolicy04.prototype.apply = function (game) {
        game.getPlayers().forEach(function (player) {
            player.hasTurmoilScienceTagBonus = true;
        });
    };
    return ScientistsPolicy04;
}());
exports.SCIENTISTS_BONUS_1 = new ScientistsBonus01();
exports.SCIENTISTS_BONUS_2 = new ScientistsBonus02();
exports.SCIENTISTS_POLICY_1 = new ScientistsPolicy01();
exports.SCIENTISTS_POLICY_2 = new ScientistsPolicy02();
exports.SCIENTISTS_POLICY_3 = new ScientistsPolicy03();
exports.SCIENTISTS_POLICY_4 = new ScientistsPolicy04();
