"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.Viron = void 0;
var Tags_1 = require("../Tags");
var ICard_1 = require("../ICard");
var SelectCard_1 = require("../../inputs/SelectCard");
var Card_1 = require("../Card");
var CardName_1 = require("../../CardName");
var CardType_1 = require("../CardType");
var CardRenderer_1 = require("../render/CardRenderer");
var Viron = (function (_super) {
    __extends(Viron, _super);
    function Viron() {
        return _super.call(this, {
            name: CardName_1.CardName.VIRON,
            tags: [Tags_1.Tags.MICROBE],
            startingMegaCredits: 48,
            cardType: CardType_1.CardType.CORPORATION,
            metadata: {
                cardNumber: 'R12',
                description: 'You start with 48 M€.',
                renderData: CardRenderer_1.CardRenderer.builder(function (b) {
                    b.br.br.br;
                    b.megacredits(48);
                    b.corpBox('action', function (ce) {
                        ce.action('Use a blue card action that has already been used this generation.', function (eb) {
                            eb.empty().startAction.empty();
                        });
                    });
                }),
            },
        }) || this;
    }
    Viron.prototype.getActionCards = function (player) {
        var result = [];
        for (var _i = 0, _a = player.playedCards; _i < _a.length; _i++) {
            var playedCard = _a[_i];
            if ((0, ICard_1.isIActionCard)(playedCard) &&
                player.getActionsThisGeneration().has(playedCard.name) &&
                playedCard.canAct(player)) {
                result.push(playedCard);
            }
        }
        return result;
    };
    Viron.prototype.canAct = function (player) {
        return this.getActionCards(player).length > 0 && !player.getActionsThisGeneration().has(this.name);
    };
    Viron.prototype.action = function (player) {
        var _this = this;
        if (this.getActionCards(player).length === 0) {
            return undefined;
        }
        return new SelectCard_1.SelectCard('Perform again an action from a played card', 'Take action', this.getActionCards(player), function (foundCards) {
            var foundCard = foundCards[0];
            player.game.log('${0} used ${1} action with ${2}', function (b) { return b.player(player).card(foundCard).card(_this); });
            return foundCard.action(player);
        });
    };
    Viron.prototype.play = function () {
        return undefined;
    };
    return Viron;
}(Card_1.Card));
exports.Viron = Viron;
