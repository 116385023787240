"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.CloudSeeding = void 0;
var Card_1 = require("../Card");
var CardType_1 = require("../CardType");
var Resources_1 = require("../../common/Resources");
var CardName_1 = require("../../CardName");
var DecreaseAnyProduction_1 = require("../../deferredActions/DecreaseAnyProduction");
var CardRequirements_1 = require("../CardRequirements");
var CardRenderer_1 = require("../render/CardRenderer");
var Options_1 = require("../Options");
var CloudSeeding = (function (_super) {
    __extends(CloudSeeding, _super);
    function CloudSeeding() {
        return _super.call(this, {
            cardType: CardType_1.CardType.AUTOMATED,
            name: CardName_1.CardName.CLOUD_SEEDING,
            cost: 11,
            requirements: CardRequirements_1.CardRequirements.builder(function (b) { return b.oceans(3); }),
            metadata: {
                cardNumber: '004',
                description: 'Requires 3 ocean tiles. Decrease your M€ production 1 step and any heat production 1 step. Increase your Plant production 2 steps.',
                renderData: CardRenderer_1.CardRenderer.builder(function (b) { return b.production(function (pb) {
                    pb.minus().megacredits(1).heat(1, { all: Options_1.all }).br;
                    pb.plus().plants(2);
                }); }),
            },
        }) || this;
    }
    CloudSeeding.prototype.canPlay = function (player) {
        return player.getProduction(Resources_1.Resources.MEGACREDITS) > -5 &&
            player.game.someoneHasResourceProduction(Resources_1.Resources.HEAT, 1);
    };
    CloudSeeding.prototype.play = function (player) {
        player.game.defer(new DecreaseAnyProduction_1.DecreaseAnyProduction(player, Resources_1.Resources.HEAT, { count: 1 }));
        player.addProduction(Resources_1.Resources.MEGACREDITS, -1);
        player.addProduction(Resources_1.Resources.PLANTS, 2);
        return undefined;
    };
    return CloudSeeding;
}(Card_1.Card));
exports.CloudSeeding = CloudSeeding;
