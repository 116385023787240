"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.VenusianAnimals = void 0;
var Tags_1 = require("../Tags");
var CardType_1 = require("../CardType");
var ResourceType_1 = require("../../common/ResourceType");
var CardName_1 = require("../../CardName");
var CardRequirements_1 = require("../CardRequirements");
var CardRenderer_1 = require("../render/CardRenderer");
var Card_1 = require("../Card");
var ICard_1 = require("../ICard");
var Options_1 = require("../Options");
var VenusianAnimals = (function (_super) {
    __extends(VenusianAnimals, _super);
    function VenusianAnimals() {
        var _this = _super.call(this, {
            name: CardName_1.CardName.VENUSIAN_ANIMALS,
            cardType: CardType_1.CardType.ACTIVE,
            tags: [Tags_1.Tags.VENUS, Tags_1.Tags.ANIMAL, Tags_1.Tags.SCIENCE],
            cost: 15,
            resourceType: ResourceType_1.ResourceType.ANIMAL,
            victoryPoints: ICard_1.VictoryPoints.resource(1, 1),
            requirements: CardRequirements_1.CardRequirements.builder(function (b) { return b.venus(18); }),
            metadata: {
                cardNumber: '259',
                renderData: CardRenderer_1.CardRenderer.builder(function (b) {
                    b.effect('When you play a Science tag, including this, add 1 Animal to this card.', function (eb) {
                        eb.science(1, { played: Options_1.played }).startEffect.animals(1);
                    }).br;
                    b.vpText('1 VP per Animal on this card.');
                }),
                description: 'Requires Venus 18%',
            },
        }) || this;
        _this.resourceCount = 0;
        return _this;
    }
    ;
    VenusianAnimals.prototype.play = function () {
        return undefined;
    };
    VenusianAnimals.prototype.onCardPlayed = function (player, card) {
        player.addResourceTo(this, card.tags.filter(function (tag) { return tag === Tags_1.Tags.SCIENCE; }).length);
    };
    return VenusianAnimals;
}(Card_1.Card));
exports.VenusianAnimals = VenusianAnimals;
