"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.Ants = void 0;
var Tags_1 = require("../Tags");
var Card_1 = require("../Card");
var ICard_1 = require("../ICard");
var CardType_1 = require("../CardType");
var ResourceType_1 = require("../../common/ResourceType");
var CardName_1 = require("../../CardName");
var AddResourcesToCard_1 = require("../../deferredActions/AddResourcesToCard");
var RemoveResourcesFromCard_1 = require("../../deferredActions/RemoveResourcesFromCard");
var CardRequirements_1 = require("../CardRequirements");
var CardRenderer_1 = require("../render/CardRenderer");
var Options_1 = require("../Options");
var Ants = (function (_super) {
    __extends(Ants, _super);
    function Ants() {
        var _this = _super.call(this, {
            cardType: CardType_1.CardType.ACTIVE,
            name: CardName_1.CardName.ANTS,
            tags: [Tags_1.Tags.MICROBE],
            cost: 9,
            resourceType: ResourceType_1.ResourceType.MICROBE,
            victoryPoints: ICard_1.VictoryPoints.resource(1, 2),
            requirements: CardRequirements_1.CardRequirements.builder(function (b) { return b.oxygen(4); }),
            metadata: {
                cardNumber: '035',
                description: 'Requires 4% oxygen.',
                renderData: CardRenderer_1.CardRenderer.builder(function (b) {
                    b.action('Remove 1 Microbe from any card to add 1 to this card.', function (eb) {
                        eb.microbes(1, { all: Options_1.all }).startAction.microbes(1);
                    }).br;
                    b.vpText('1 VP per 2 Microbes on this card.');
                }),
            },
        }) || this;
        _this.resourceCount = 0;
        return _this;
    }
    Ants.prototype.play = function () {
        return undefined;
    };
    Ants.prototype.canAct = function (player) {
        if (player.game.isSoloMode())
            return true;
        return RemoveResourcesFromCard_1.RemoveResourcesFromCard.getAvailableTargetCards(player, this.resourceType).length > 0;
    };
    Ants.prototype.action = function (player) {
        var _this = this;
        player.game.defer(new RemoveResourcesFromCard_1.RemoveResourcesFromCard(player, ResourceType_1.ResourceType.MICROBE));
        player.game.defer(new AddResourcesToCard_1.AddResourcesToCard(player, ResourceType_1.ResourceType.MICROBE, { filter: function (c) { return c.name === _this.name; } }));
        return undefined;
    };
    return Ants;
}(Card_1.Card));
exports.Ants = Ants;
