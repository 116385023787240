"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
var vue_1 = require("vue");
var Button_vue_1 = require("@/client/components/common/Button.vue");
var Card_vue_1 = require("@/client/components/card/Card.vue");
var ClientCardManifest_1 = require("@/client/cards/ClientCardManifest");
var CardOrderStorage_1 = require("@/client/utils/CardOrderStorage");
var PaymentWidgetMixin_1 = require("@/client/mixins/PaymentWidgetMixin");
var PreferencesManager_1 = require("@/client/utils/PreferencesManager");
var Tags_1 = require("@/cards/Tags");
var Units_1 = require("@/Units");
var CardName_1 = require("@/CardName");
exports.default = vue_1.default.extend({
    name: 'SelectHowToPayForProjectCard',
    props: {
        playerView: {
            type: Object,
        },
        playerinput: {
            type: Object,
        },
        onsave: {
            type: Function,
        },
        showsave: {
            type: Boolean,
        },
        showtitle: {
            type: Boolean,
        },
    },
    computed: {
        thisPlayer: function () {
            return this.playerView.thisPlayer;
        },
    },
    data: function () {
        var card;
        var cards = [];
        if (this.playerinput !== undefined &&
            this.playerinput.cards !== undefined &&
            this.playerinput.cards.length > 0) {
            cards = CardOrderStorage_1.CardOrderStorage.getOrdered(CardOrderStorage_1.CardOrderStorage.getCardOrder(this.playerView.id), this.playerinput.cards);
            card = cards[0];
        }
        if (card === undefined) {
            throw new Error('no card provided in player input');
        }
        return {
            cardName: card.name,
            card: card,
            cards: cards,
            cost: 0,
            tags: [],
            heat: 0,
            megaCredits: 0,
            steel: 0,
            titanium: 0,
            microbes: 0,
            science: 0,
            seeds: 0,
            floaters: 0,
            warning: undefined,
            available: Units_1.Units.of({}),
        };
    },
    components: {
        Card: Card_vue_1.default,
        Button: Button_vue_1.default,
    },
    mounted: function () {
        var _this = this;
        vue_1.default.nextTick(function () {
            _this.$data.card = _this.getCard();
            _this.$data.cost = _this.$data.card.calculatedCost;
            _this.$data.tags = _this.getCardTags(),
                _this.$data.megaCredits = _this.getMegaCreditsMax();
            _this.setDefaultValues();
        });
    },
    methods: __assign(__assign({}, PaymentWidgetMixin_1.PaymentWidgetMixin.methods), { getCard: function () {
            var _this = this;
            var card = this.cards.find(function (c) { return c.name === _this.cardName; });
            if (card === undefined) {
                throw new Error("card not found " + this.cardName);
            }
            return card;
        }, getCardTags: function () {
            var cam = (0, ClientCardManifest_1.getCard)(this.cardName);
            if (cam === undefined) {
                throw new Error("card not found " + this.cardName);
            }
            return cam.card.tags;
        }, setDefaultValues: function () {
            this.microbes = 0;
            this.floaters = 0;
            this.science = 0;
            this.seeds = 0;
            this.steel = 0;
            this.titanium = 0;
            this.heat = 0;
            var megacreditBalance = Math.max(this.cost - this.thisPlayer.megaCredits, 0);
            var deductUnits = function (availableUnits, unitValue, overpay) {
                if (overpay === void 0) { overpay = true; }
                if (availableUnits === undefined || availableUnits === 0) {
                    return 0;
                }
                var _tmp = megacreditBalance / unitValue;
                var balanceAsUnits = overpay ? Math.ceil(_tmp) : Math.floor(_tmp);
                var contributingUnits = Math.min(availableUnits, balanceAsUnits);
                megacreditBalance -= contributingUnits * unitValue;
                return contributingUnits;
            };
            var saveOverSpendingUnits = function (spendingUnits, unitValue) {
                if (spendingUnits === undefined || spendingUnits === 0 || megacreditBalance === 0) {
                    return 0;
                }
                var overSpendingAsUnits = Math.floor(Math.abs(megacreditBalance) / unitValue);
                var toSaveUnits = Math.min(spendingUnits, overSpendingAsUnits);
                megacreditBalance += toSaveUnits * unitValue;
                return toSaveUnits;
            };
            if (megacreditBalance > 0 && this.canUseSeeds()) {
                this.seeds = deductUnits(this.playerinput.seeds, 5);
            }
            if (megacreditBalance > 0 && this.canUseMicrobes()) {
                this.microbes = deductUnits(this.playerinput.microbes, 2);
            }
            if (megacreditBalance > 0 && this.canUseFloaters()) {
                this.floaters = deductUnits(this.playerinput.floaters, 3);
            }
            if (megacreditBalance > 0 && this.canUseScience()) {
                this.science = deductUnits(this.playerinput.science, 1);
            }
            this.available.steel = Math.max(this.thisPlayer.steel - this.card.reserveUnits.steel, 0);
            if (megacreditBalance > 0 && this.canUseSteel()) {
                this.steel = deductUnits(this.available.steel, this.thisPlayer.steelValue, true);
            }
            this.available.titanium = Math.max(this.thisPlayer.titanium - this.card.reserveUnits.titanium, 0);
            if (megacreditBalance > 0 && this.canUseTitanium()) {
                this.titanium = deductUnits(this.available.titanium, this.thisPlayer.titaniumValue, true);
            }
            this.available.heat = Math.max(this.thisPlayer.heat - this.card.reserveUnits.heat, 0);
            if (megacreditBalance > 0 && this.canUseHeat()) {
                this.heat = deductUnits(this.available.heat, 1);
            }
            if (megacreditBalance < 0) {
                this.steel -= saveOverSpendingUnits(this.steel, this.thisPlayer.steelValue);
                this.floaters -= saveOverSpendingUnits(this.floaters, 3);
                this.microbes -= saveOverSpendingUnits(this.microbes, 2);
                this.science -= saveOverSpendingUnits(this.science, 1);
                this.seeds -= saveOverSpendingUnits(this.seeds, 5);
                this.megaCredits -= saveOverSpendingUnits(this.megaCredits, 1);
            }
        }, canUseHeat: function () {
            return this.playerinput.canUseHeat === true && this.thisPlayer.heat > 0;
        }, canUseSteel: function () {
            if (this.card !== undefined && this.available.steel > 0) {
                if (this.tags.includes(Tags_1.Tags.BUILDING) || this.thisPlayer.lastCardPlayed === CardName_1.CardName.LAST_RESORT_INGENUITY) {
                    return true;
                }
            }
            return false;
        }, canUseTitanium: function () {
            if (this.card !== undefined && this.available.titanium > 0) {
                if (this.tags.includes(Tags_1.Tags.SPACE) || this.thisPlayer.lastCardPlayed === CardName_1.CardName.LAST_RESORT_INGENUITY) {
                    return true;
                }
            }
            return false;
        }, canUseMicrobes: function () {
            if (this.card !== undefined && this.playerinput.microbes !== undefined && this.playerinput.microbes > 0) {
                if (this.tags.includes(Tags_1.Tags.PLANT)) {
                    return true;
                }
            }
            return false;
        }, canUseFloaters: function () {
            if (this.card !== undefined && this.playerinput.floaters !== undefined && this.playerinput.floaters > 0) {
                if (this.tags.includes(Tags_1.Tags.VENUS)) {
                    return true;
                }
            }
            return false;
        }, canUseScience: function () {
            var _a;
            if (this.card !== undefined && ((_a = this.playerinput.science) !== null && _a !== void 0 ? _a : 0) > 0) {
                if (this.tags.includes(Tags_1.Tags.MOON)) {
                    return true;
                }
            }
            return false;
        }, canUseSeeds: function () {
            var _a;
            if (this.card !== undefined && ((_a = this.playerinput.seeds) !== null && _a !== void 0 ? _a : 0) > 0) {
                if (this.tags.includes(Tags_1.Tags.PLANT)) {
                    return true;
                }
                if (this.card.name === CardName_1.CardName.GREENERY_STANDARD_PROJECT) {
                    return true;
                }
            }
            return false;
        }, cardChanged: function () {
            this.card = this.getCard();
            this.cost = this.card.calculatedCost || 0;
            this.tags = this.getCardTags();
            this.megaCredits = this.getMegaCreditsMax();
            this.setDefaultValues();
        }, hasWarning: function () {
            return this.warning !== undefined;
        }, hasCardWarning: function () {
            return this.card !== undefined && this.card.warning !== undefined;
        }, showReserveSteelWarning: function () {
            var _a, _b;
            return ((_b = (_a = this.card) === null || _a === void 0 ? void 0 : _a.reserveUnits) === null || _b === void 0 ? void 0 : _b.steel) > 0 && this.canUseSteel();
        }, showReserveTitaniumWarning: function () {
            var _a, _b;
            return ((_b = (_a = this.card) === null || _a === void 0 ? void 0 : _a.reserveUnits) === null || _b === void 0 ? void 0 : _b.titanium) > 0 && this.canUseTitanium();
        }, showReserveHeatWarning: function () {
            var _a, _b;
            return ((_b = (_a = this.card) === null || _a === void 0 ? void 0 : _a.reserveUnits) === null || _b === void 0 ? void 0 : _b.heat) > 0 && this.canUseHeat();
        }, saveData: function () {
            var htp = {
                heat: this.heat,
                megaCredits: this.megaCredits,
                steel: this.steel,
                titanium: this.titanium,
                microbes: this.microbes,
                floaters: this.floaters,
                science: this.science,
                seeds: this.seeds,
            };
            var totalSpent = 0;
            for (var _i = 0, unit_1 = PaymentWidgetMixin_1.unit; _i < unit_1.length; _i++) {
                var target = unit_1[_i];
                if (htp[target] > this.getAmount(target)) {
                    this.$data.warning = "You do not have enough " + target;
                    return;
                }
                totalSpent += htp[target] * this.getResourceRate(target);
            }
            if (totalSpent < this.cost) {
                this.warning = 'Haven\'t spent enough';
                return;
            }
            if (totalSpent > this.cost) {
                var diff = totalSpent - this.cost;
                for (var _a = 0, unit_2 = PaymentWidgetMixin_1.unit; _a < unit_2.length; _a++) {
                    var target = unit_2[_a];
                    if (htp[target] && diff >= this.getResourceRate(target)) {
                        this.$data.warning = "You cannot overspend " + target;
                        return;
                    }
                }
            }
            var showAlert = PreferencesManager_1.PreferencesManager.load('show_alerts') === '1';
            if (totalSpent > this.cost && showAlert) {
                var diff = totalSpent - this.cost;
                if (confirm('Warning: You are overpaying by ' + diff + ' M€')) {
                    this.onsave([[
                            this.card.name,
                            JSON.stringify(htp),
                        ]]);
                }
                else {
                    this.warning = 'Please adjust payment amount';
                    return;
                }
            }
            else {
                this.onsave([[
                        this.card.name,
                        JSON.stringify(htp),
                    ]]);
            }
        } }),
});
