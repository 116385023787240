"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.RedTourismWave = void 0;
var Tags_1 = require("../Tags");
var CardName_1 = require("../../CardName");
var CardType_1 = require("../CardType");
var PartyName_1 = require("../../turmoil/parties/PartyName");
var Resources_1 = require("../../common/Resources");
var CardRequirements_1 = require("../CardRequirements");
var CardRenderer_1 = require("../render/CardRenderer");
var Size_1 = require("../render/Size");
var Card_1 = require("../Card");
var RedTourismWave = (function (_super) {
    __extends(RedTourismWave, _super);
    function RedTourismWave() {
        return _super.call(this, {
            cost: 3,
            tags: [Tags_1.Tags.EARTH],
            name: CardName_1.CardName.RED_TOURISM_WAVE,
            cardType: CardType_1.CardType.EVENT,
            requirements: CardRequirements_1.CardRequirements.builder(function (b) { return b.party(PartyName_1.PartyName.REDS); }),
            metadata: {
                cardNumber: 'T12',
                renderData: CardRenderer_1.CardRenderer.builder(function (b) {
                    b.megacredits(1).slash().emptyTile('normal', { size: Size_1.Size.SMALL }).asterix();
                }),
                description: 'Requires that Reds are ruling or that you have 2 delegates there. Gain 1 M€ from each EMPTY AREA ADJACENT TO YOUR TILES',
            },
        }) || this;
    }
    RedTourismWave.prototype.play = function (player) {
        var amount = player.game.board.getEmptySpaces().filter(function (space) {
            return player.game.board.getAdjacentSpaces(space).some(function (adj) {
                return adj.tile !== undefined && adj.player === player;
            });
        }).length;
        player.addResource(Resources_1.Resources.MEGACREDITS, amount);
        return undefined;
    };
    return RedTourismWave;
}(Card_1.Card));
exports.RedTourismWave = RedTourismWave;
