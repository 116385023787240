"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var vue_1 = require("vue");
var Tag_vue_1 = require("@/client/components/Tag.vue");
exports.default = vue_1.default.extend({
    name: 'tag-count',
    props: {
        tag: {
            type: String,
        },
        count: {
            type: Number,
        },
        size: {
            type: String,
        },
        type: {
            type: String,
        },
        hideCount: {
            required: false,
            type: Boolean,
        },
    },
    components: {
        Tag: Tag_vue_1.default,
    },
    methods: {
        getClasses: function () {
            var classes = ['tag-display'];
            if (this.count === 0 && this.tag !== 'escape') {
                classes.push('tag-no-show');
            }
            return classes.join(' ');
        },
        getCountClasses: function () {
            var classes = ['tag-count-display'];
            if (this.count === 0 && this.tag !== 'escape') {
                classes.push('tag-count-no-show');
            }
            return classes.join(' ');
        },
        getCount: function () {
            return this.hideCount === true ? '?' : this.count;
        },
    },
});
