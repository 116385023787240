"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.OceanSanctuary = void 0;
var Card_1 = require("../Card");
var ICard_1 = require("../ICard");
var CardName_1 = require("../../CardName");
var SelectSpace_1 = require("../../inputs/SelectSpace");
var ResourceType_1 = require("../../common/ResourceType");
var SpaceBonus_1 = require("../../SpaceBonus");
var TileType_1 = require("../../common/TileType");
var CardType_1 = require("../CardType");
var Tags_1 = require("../Tags");
var CardRequirements_1 = require("../CardRequirements");
var CardRenderer_1 = require("../render/CardRenderer");
var OceanSanctuary = (function (_super) {
    __extends(OceanSanctuary, _super);
    function OceanSanctuary() {
        var _this = _super.call(this, {
            cardType: CardType_1.CardType.ACTIVE,
            name: CardName_1.CardName.OCEAN_SANCTUARY,
            tags: [Tags_1.Tags.ANIMAL],
            cost: 9,
            resourceType: ResourceType_1.ResourceType.ANIMAL,
            victoryPoints: ICard_1.VictoryPoints.resource(1, 1),
            requirements: CardRequirements_1.CardRequirements.builder(function (b) { return b.oceans(5); }),
            metadata: {
                cardNumber: 'A22',
                renderData: CardRenderer_1.CardRenderer.builder(function (b) {
                    b.tile(TileType_1.TileType.OCEAN_SANCTUARY, false, true).nbsp.animals(1).br;
                    b.vpText('1 VP per animal on this card.');
                }),
                description: 'Requires 5 ocean tiles. Place this tile on top of an existing ocean tile. The tile grants an ADJACENCY BONUS of 1 animal. Add 1 animal to this card.',
            },
        }) || this;
        _this.resourceCount = 0;
        return _this;
    }
    OceanSanctuary.prototype.play = function (player) {
        var _this = this;
        player.addResourceTo(this, 1);
        return new SelectSpace_1.SelectSpace('Select space for Ocean Sanctuary', player.game.board.getOceansTiles(false), function (space) {
            var tile = {
                tileType: TileType_1.TileType.OCEAN_SANCTUARY,
                card: _this.name,
                covers: space.tile,
            };
            player.game.addTile(player, space.spaceType, space, tile);
            space.adjacency = { bonus: [SpaceBonus_1.SpaceBonus.ANIMAL] };
            return undefined;
        });
    };
    return OceanSanctuary;
}(Card_1.Card));
exports.OceanSanctuary = OceanSanctuary;
