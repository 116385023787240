"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.ProtectedValley = void 0;
var Card_1 = require("../Card");
var CardType_1 = require("../CardType");
var SpaceType_1 = require("../../SpaceType");
var Tags_1 = require("../Tags");
var SelectSpace_1 = require("../../inputs/SelectSpace");
var Resources_1 = require("../../common/Resources");
var CardName_1 = require("../../CardName");
var CardRenderer_1 = require("../render/CardRenderer");
var Units_1 = require("../../Units");
var ProtectedValley = (function (_super) {
    __extends(ProtectedValley, _super);
    function ProtectedValley() {
        return _super.call(this, {
            cardType: CardType_1.CardType.AUTOMATED,
            name: CardName_1.CardName.PROTECTED_VALLEY,
            tags: [Tags_1.Tags.PLANT, Tags_1.Tags.BUILDING],
            cost: 23,
            productionBox: Units_1.Units.of({ megacredits: 2 }),
            tr: { oxygen: 1 },
            metadata: {
                cardNumber: '174',
                renderData: CardRenderer_1.CardRenderer.builder(function (b) {
                    b.production(function (pb) { return pb.megacredits(2); }).nbsp;
                    b.greenery().asterix();
                }),
                description: 'Increase your M€ production 2 steps. Place on a greenery tile ON AN AREA RESERVED FOR OCEAN, disregarding normal placement restrictions, and increase oxygen 1 step.',
            },
        }) || this;
    }
    ProtectedValley.prototype.play = function (player) {
        return new SelectSpace_1.SelectSpace('Select space reserved for ocean to place greenery tile', player.game.board.getAvailableSpacesForOcean(player), function (space) {
            player.addProduction(Resources_1.Resources.MEGACREDITS, 2);
            return player.game.addGreenery(player, space.id, SpaceType_1.SpaceType.OCEAN);
        });
    };
    return ProtectedValley;
}(Card_1.Card));
exports.ProtectedValley = ProtectedValley;
