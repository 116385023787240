"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.MartianEmbassy = void 0;
var CardName_1 = require("../../CardName");
var CardType_1 = require("../CardType");
var Tags_1 = require("../Tags");
var CardRenderer_1 = require("../render/CardRenderer");
var Card_1 = require("../Card");
var PathfindersExpansion_1 = require("../../pathfinders/PathfindersExpansion");
var Options_1 = require("../Options");
var MartianEmbassy = (function (_super) {
    __extends(MartianEmbassy, _super);
    function MartianEmbassy() {
        return _super.call(this, {
            name: CardName_1.CardName.MARTIAN_EMBASSY,
            cardType: CardType_1.CardType.AUTOMATED,
            tags: [Tags_1.Tags.MOON, Tags_1.Tags.MARS],
            cost: 11,
            metadata: {
                cardNumber: 'M76',
                description: 'Raise the Mars Planetary Track 1 step for every 3 Moon tags you have, including this.',
                renderData: CardRenderer_1.CardRenderer.builder(function (b) {
                    b.mars(1, { played: Options_1.played }).planetaryTrack().text('+1').slash().moon(3);
                }),
            },
        }) || this;
    }
    ;
    MartianEmbassy.prototype.play = function (player) {
        var tags = player.getTagCount(Tags_1.Tags.MOON) + 1;
        var rate = Math.floor(tags / 3);
        PathfindersExpansion_1.PathfindersExpansion.raiseTrack(Tags_1.Tags.MARS, player, rate);
        return undefined;
    };
    return MartianEmbassy;
}(Card_1.Card));
exports.MartianEmbassy = MartianEmbassy;
