"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.HE3FusionPlant = void 0;
var CardName_1 = require("../../CardName");
var CardType_1 = require("../CardType");
var Tags_1 = require("../Tags");
var MoonExpansion_1 = require("../../moon/MoonExpansion");
var Resources_1 = require("../../common/Resources");
var TileType_1 = require("../../common/TileType");
var CardRenderer_1 = require("../render/CardRenderer");
var CardRequirements_1 = require("../CardRequirements");
var Card_1 = require("../Card");
var Options_1 = require("../Options");
var HE3FusionPlant = (function (_super) {
    __extends(HE3FusionPlant, _super);
    function HE3FusionPlant() {
        return _super.call(this, {
            name: CardName_1.CardName.HE3_FUSION_PLANT,
            cardType: CardType_1.CardType.AUTOMATED,
            tags: [Tags_1.Tags.ENERGY, Tags_1.Tags.ENERGY, Tags_1.Tags.MOON],
            cost: 12,
            requirements: CardRequirements_1.CardRequirements.builder(function (b) { return b.miningRate(2); }),
            metadata: {
                description: 'Requires Mining Rate of 2 or higher. ' +
                    'Increase your energy production 1 step for each mining tile on the Moon.',
                cardNumber: 'M48',
                renderData: CardRenderer_1.CardRenderer.builder(function (b) {
                    b.production(function (pb) { return pb.energy(1); }).slash().moonMine({ all: Options_1.all });
                }),
            },
        }) || this;
    }
    ;
    HE3FusionPlant.prototype.play = function (player) {
        var count = MoonExpansion_1.MoonExpansion.tiles(player.game, TileType_1.TileType.MOON_MINE, { surfaceOnly: true }).length;
        player.addProduction(Resources_1.Resources.ENERGY, count, { log: true });
        return undefined;
    };
    return HE3FusionPlant;
}(Card_1.Card));
exports.HE3FusionPlant = HE3FusionPlant;
