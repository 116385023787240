"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.RustEatingBacteria = void 0;
var CardName_1 = require("../../CardName");
var CardType_1 = require("../CardType");
var Tags_1 = require("../Tags");
var ResourceType_1 = require("../../common/ResourceType");
var CardRenderer_1 = require("../render/CardRenderer");
var Card_1 = require("../Card");
var ICard_1 = require("../ICard");
var Resources_1 = require("../../common/Resources");
var RustEatingBacteria = (function (_super) {
    __extends(RustEatingBacteria, _super);
    function RustEatingBacteria() {
        var _this = _super.call(this, {
            name: CardName_1.CardName.RUST_EATING_BACTERIA,
            cardType: CardType_1.CardType.ACTIVE,
            tags: [Tags_1.Tags.MICROBE],
            cost: 7,
            resourceType: ResourceType_1.ResourceType.MICROBE,
            victoryPoints: ICard_1.VictoryPoints.resource(1, 3),
            metadata: {
                cardNumber: 'M88',
                renderData: CardRenderer_1.CardRenderer.builder(function (b) {
                    b.action('Spend 1 steel to add 2 Microbes here.', function (eb) {
                        eb.startAction.steel(1).arrow().microbes(2);
                    }).br;
                    b.vpText('1 VP per 3 Microbes here.');
                }),
            },
        }) || this;
        _this.resourceCount = 0;
        return _this;
    }
    ;
    RustEatingBacteria.prototype.play = function () {
        return undefined;
    };
    RustEatingBacteria.prototype.canAct = function (player) {
        return player.steel >= 1;
    };
    RustEatingBacteria.prototype.action = function (player) {
        player.deductResource(Resources_1.Resources.STEEL, 1);
        player.addResourceTo(this, 2);
        return undefined;
    };
    return RustEatingBacteria;
}(Card_1.Card));
exports.RustEatingBacteria = RustEatingBacteria;
