"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.ImportedNitrogen = void 0;
var Tags_1 = require("../Tags");
var Card_1 = require("../Card");
var CardType_1 = require("../CardType");
var ResourceType_1 = require("../../common/ResourceType");
var CardName_1 = require("../../CardName");
var AddResourcesToCard_1 = require("../../deferredActions/AddResourcesToCard");
var CardRenderer_1 = require("../render/CardRenderer");
var Options_1 = require("../Options");
var ImportedNitrogen = (function (_super) {
    __extends(ImportedNitrogen, _super);
    function ImportedNitrogen() {
        return _super.call(this, {
            cardType: CardType_1.CardType.EVENT,
            name: CardName_1.CardName.IMPORTED_NITROGEN,
            tags: [Tags_1.Tags.EARTH, Tags_1.Tags.SPACE],
            cost: 23,
            tr: { tr: 1 },
            metadata: {
                cardNumber: '163',
                renderData: CardRenderer_1.CardRenderer.builder(function (b) {
                    b.tr(1).br;
                    b.plants(4, { digit: Options_1.digit });
                    b.microbes(3, { digit: Options_1.digit }).asterix().nbsp;
                    b.animals(2, { digit: Options_1.digit }).asterix();
                }),
                description: 'Raise your TR 1 step and gain 4 Plants. Add 3 Microbes to ANOTHER card and 2 Animals to ANOTHER card.',
            },
        }) || this;
    }
    ImportedNitrogen.prototype.play = function (player) {
        player.plants += 4;
        player.increaseTerraformRating();
        player.game.defer(new AddResourcesToCard_1.AddResourcesToCard(player, ResourceType_1.ResourceType.MICROBE, { count: 3 }));
        player.game.defer(new AddResourcesToCard_1.AddResourcesToCard(player, ResourceType_1.ResourceType.ANIMAL, { count: 2 }));
        return undefined;
    };
    return ImportedNitrogen;
}(Card_1.Card));
exports.ImportedNitrogen = ImportedNitrogen;
