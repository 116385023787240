"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BuildColony = void 0;
var SelectColony_1 = require("../inputs/SelectColony");
var DeferredAction_1 = require("./DeferredAction");
var BuildColony = (function () {
    function BuildColony(player, allowDuplicate, title, openColonies, options) {
        if (allowDuplicate === void 0) { allowDuplicate = false; }
        if (title === void 0) { title = 'Select where to build a colony'; }
        this.player = player;
        this.allowDuplicate = allowDuplicate;
        this.title = title;
        this.openColonies = openColonies;
        this.options = options;
        this.priority = DeferredAction_1.Priority.BUILD_COLONY;
    }
    BuildColony.prototype.execute = function () {
        var _this = this;
        if (this.openColonies === undefined) {
            this.openColonies = this.player.game.colonies.filter(function (colony) {
                return colony.colonies.length < 3 &&
                    (colony.colonies.includes(_this.player.id) === false || _this.allowDuplicate) &&
                    colony.isActive;
            });
        }
        if (this.openColonies.length === 0) {
            return undefined;
        }
        var openColonies = this.openColonies;
        return new SelectColony_1.SelectColony(this.title, 'Build', openColonies, function (colony) {
            var _a, _b, _c;
            colony.addColony(_this.player, { giveBonusTwice: (_b = (_a = _this.options) === null || _a === void 0 ? void 0 : _a.giveBonusTwice) !== null && _b !== void 0 ? _b : false });
            if ((_c = _this.options) === null || _c === void 0 ? void 0 : _c.cb)
                _this.options.cb(colony);
            return undefined;
        });
    };
    return BuildColony;
}());
exports.BuildColony = BuildColony;
