"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.RoverConstruction = void 0;
var Tags_1 = require("../Tags");
var Card_1 = require("../Card");
var CardType_1 = require("../CardType");
var CardName_1 = require("../../CardName");
var Resources_1 = require("../../common/Resources");
var DeferredAction_1 = require("../../deferredActions/DeferredAction");
var GainResources_1 = require("../../deferredActions/GainResources");
var Board_1 = require("../../boards/Board");
var CardRenderer_1 = require("../render/CardRenderer");
var Size_1 = require("../render/Size");
var Options_1 = require("../Options");
var RoverConstruction = (function (_super) {
    __extends(RoverConstruction, _super);
    function RoverConstruction() {
        return _super.call(this, {
            cardType: CardType_1.CardType.ACTIVE,
            name: CardName_1.CardName.ROVER_CONSTRUCTION,
            tags: [Tags_1.Tags.BUILDING],
            cost: 8,
            victoryPoints: 1,
            metadata: {
                cardNumber: '038',
                renderData: CardRenderer_1.CardRenderer.builder(function (b) {
                    b.effect('When any City tile is placed, gain 2 M€.', function (eb) {
                        eb.city({ size: Size_1.Size.SMALL, all: Options_1.all }).startEffect.megacredits(2);
                    });
                }),
            },
        }) || this;
    }
    RoverConstruction.prototype.onTilePlaced = function (cardOwner, activePlayer, space) {
        if (Board_1.Board.isCitySpace(space)) {
            cardOwner.game.defer(new GainResources_1.GainResources(cardOwner, Resources_1.Resources.MEGACREDITS, { count: 2, log: true }), cardOwner.id !== activePlayer.id ? DeferredAction_1.Priority.OPPONENT_TRIGGER : undefined);
        }
    };
    RoverConstruction.prototype.play = function () {
        return undefined;
    };
    return RoverConstruction;
}(Card_1.Card));
exports.RoverConstruction = RoverConstruction;
