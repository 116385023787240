"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Thermophiles = void 0;
var Tags_1 = require("../Tags");
var CardType_1 = require("../CardType");
var ResourceType_1 = require("../../common/ResourceType");
var OrOptions_1 = require("../../inputs/OrOptions");
var SelectOption_1 = require("../../inputs/SelectOption");
var constants_1 = require("../../constants");
var SelectCard_1 = require("../../inputs/SelectCard");
var CardName_1 = require("../../CardName");
var CardRequirements_1 = require("../CardRequirements");
var CardRenderer_1 = require("../render/CardRenderer");
var Card_1 = require("../Card");
var Thermophiles = (function (_super) {
    __extends(Thermophiles, _super);
    function Thermophiles() {
        var _this = _super.call(this, {
            name: CardName_1.CardName.THERMOPHILES,
            cardType: CardType_1.CardType.ACTIVE,
            tags: [Tags_1.Tags.VENUS, Tags_1.Tags.MICROBE],
            cost: 9,
            resourceType: ResourceType_1.ResourceType.MICROBE,
            requirements: CardRequirements_1.CardRequirements.builder(function (b) { return b.venus(6); }),
            metadata: {
                cardNumber: '253',
                renderData: CardRenderer_1.CardRenderer.builder(function (b) {
                    b.action('Add 1 Microbe to ANY Venus CARD.', function (eb) {
                        eb.empty().startAction.microbes(1, { secondaryTag: Tags_1.Tags.VENUS });
                    }).br;
                    b.or().br;
                    b.action('Spend 2 Microbes here to raise Venus 1 step.', function (eb) {
                        eb.microbes(2).startAction.venus(1);
                    });
                }),
                description: 'Requires Venus 6%',
            },
        }) || this;
        _this.resourceCount = 0;
        return _this;
    }
    ;
    Thermophiles.prototype.play = function () {
        return undefined;
    };
    Thermophiles.prototype.canAct = function () {
        return true;
    };
    Thermophiles.prototype.action = function (player) {
        var _this = this;
        var venusMicrobeCards = player.getResourceCards(ResourceType_1.ResourceType.MICROBE).filter(function (card) { return card.tags.includes(Tags_1.Tags.VENUS); });
        var canRaiseVenus = this.resourceCount > 1 && player.game.getVenusScaleLevel() < constants_1.MAX_VENUS_SCALE;
        if (venusMicrobeCards.length === 1 && !canRaiseVenus) {
            player.addResourceTo(this, { log: true });
            return undefined;
        }
        var opts = [];
        var spendResource = new SelectOption_1.SelectOption('Remove 2 microbes to raise Venus 1 step', 'Remove microbes', function () {
            player.removeResourceFrom(_this, 2);
            player.game.increaseVenusScaleLevel(player, 1);
            return undefined;
        });
        var addResource = new SelectCard_1.SelectCard('Select a Venus card to add 1 microbe', 'Add microbe', venusMicrobeCards, function (foundCards) {
            player.addResourceTo(foundCards[0], { log: true });
            return undefined;
        });
        var addResourceToSelf = new SelectOption_1.SelectOption('Add a microbe to this card', 'Add microbe', function () {
            player.addResourceTo(venusMicrobeCards[0], { log: true });
            return undefined;
        });
        if (canRaiseVenus) {
            if (player.canAfford(0, { tr: { venus: 1 } })) {
                opts.push(spendResource);
            }
        }
        else {
            if (venusMicrobeCards.length === 1)
                return addResourceToSelf;
            return addResource;
        }
        venusMicrobeCards.length === 1 ? opts.push(addResourceToSelf) : opts.push(addResource);
        return new (OrOptions_1.OrOptions.bind.apply(OrOptions_1.OrOptions, __spreadArray([void 0], opts, false)))();
    };
    return Thermophiles;
}(Card_1.Card));
exports.Thermophiles = Thermophiles;
