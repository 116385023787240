"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.MartianRails = void 0;
var Tags_1 = require("../Tags");
var Card_1 = require("../Card");
var CardType_1 = require("../CardType");
var CardName_1 = require("../../CardName");
var Resources_1 = require("../../common/Resources");
var CardRenderer_1 = require("../render/CardRenderer");
var Size_1 = require("../render/Size");
var Options_1 = require("../Options");
var MartianRails = (function (_super) {
    __extends(MartianRails, _super);
    function MartianRails() {
        return _super.call(this, {
            cardType: CardType_1.CardType.ACTIVE,
            name: CardName_1.CardName.MARTIAN_RAILS,
            tags: [Tags_1.Tags.BUILDING],
            cost: 13,
            metadata: {
                cardNumber: '007',
                renderData: CardRenderer_1.CardRenderer.builder(function (b) {
                    b.action('Spend 1 Energy to gain 1 M€ for each City tile ON MARS.', function (eb) {
                        eb.energy(1).startAction.megacredits(1).slash();
                        eb.city({ all: Options_1.all, size: Size_1.Size.SMALL }).asterix();
                    }).br;
                }),
            },
        }) || this;
    }
    MartianRails.prototype.play = function (_player) {
        return undefined;
    };
    MartianRails.prototype.canAct = function (player) {
        return player.energy >= 1;
    };
    MartianRails.prototype.action = function (player) {
        player.deductResource(Resources_1.Resources.ENERGY, 1);
        player.addResource(Resources_1.Resources.MEGACREDITS, player.game.getCitiesInPlayOnMars(), { log: true });
        return undefined;
    };
    return MartianRails;
}(Card_1.Card));
exports.MartianRails = MartianRails;
