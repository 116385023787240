"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.GenerousFunding = void 0;
var IGlobalEvent_1 = require("./IGlobalEvent");
var GlobalEventName_1 = require("./GlobalEventName");
var PartyName_1 = require("../parties/PartyName");
var Resources_1 = require("../../common/Resources");
var CardRenderer_1 = require("../../cards/render/CardRenderer");
var Options_1 = require("../../cards/Options");
var RENDER_DATA = CardRenderer_1.CardRenderer.builder(function (b) {
    b.megacredits(2).slash().influence().plus().tr(5, { digit: Options_1.digit, over: 15 }).br.br;
});
var GenerousFunding = (function (_super) {
    __extends(GenerousFunding, _super);
    function GenerousFunding() {
        return _super.call(this, {
            name: GlobalEventName_1.GlobalEventName.GENEROUS_FUNDING,
            description: 'Gain 2 M€ for each influence and set of 5 TR over 15 (max 5 sets).',
            revealedDelegate: PartyName_1.PartyName.KELVINISTS,
            currentDelegate: PartyName_1.PartyName.UNITY,
            renderData: RENDER_DATA,
        }) || this;
    }
    GenerousFunding.prototype.resolve = function (game, turmoil) {
        var _this = this;
        game.getPlayers().forEach(function (player) {
            var trSets = Math.max(0, Math.floor((player.getTerraformRating() - 15) / 5));
            var maxTRSets = 5;
            var totalSets = Math.min(maxTRSets, trSets) + turmoil.getPlayerInfluence(player);
            player.addResource(Resources_1.Resources.MEGACREDITS, 2 * totalSets, { log: true, from: _this.name });
        });
    };
    return GenerousFunding;
}(IGlobalEvent_1.GlobalEvent));
exports.GenerousFunding = GenerousFunding;
