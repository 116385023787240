"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.MiningRights = void 0;
var CardName_1 = require("../../CardName");
var MiningCard_1 = require("./MiningCard");
var TileType_1 = require("../../common/TileType");
var CardRenderer_1 = require("../render/CardRenderer");
var MiningRights = (function (_super) {
    __extends(MiningRights, _super);
    function MiningRights(name, metadata) {
        if (name === void 0) { name = CardName_1.CardName.MINING_RIGHTS; }
        if (metadata === void 0) { metadata = {
            cardNumber: '067',
            renderData: CardRenderer_1.CardRenderer.builder(function (b) {
                b.tile(TileType_1.TileType.MINING_RIGHTS, true).asterix().br;
                b.production(function (pb) {
                    pb.steel(1).or().titanium(1);
                }).asterix();
            }),
            description: 'Place this tile on an area with a steel or titanium placement bonus. Increase that production 1 step.',
        }; }
        return _super.call(this, name, 9, metadata) || this;
    }
    return MiningRights;
}(MiningCard_1.MiningCard));
exports.MiningRights = MiningRights;
