"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PlaceCityTile = void 0;
var SelectSpace_1 = require("../inputs/SelectSpace");
var DeferredAction_1 = require("./DeferredAction");
var PlaceCityTile = (function () {
    function PlaceCityTile(player, title, spaces) {
        if (title === void 0) { title = 'Select space for city tile'; }
        if (spaces === void 0) { spaces = undefined; }
        this.player = player;
        this.title = title;
        this.spaces = spaces;
        this.priority = DeferredAction_1.Priority.DEFAULT;
    }
    PlaceCityTile.prototype.execute = function () {
        var _this = this;
        var spaces = this.spaces !== undefined ? this.spaces : this.player.game.board.getAvailableSpacesForCity(this.player);
        if (spaces.length === 0) {
            return undefined;
        }
        return new SelectSpace_1.SelectSpace(this.title, spaces, function (space) {
            _this.player.game.addCityTile(_this.player, space.id);
            return undefined;
        });
    };
    return PlaceCityTile;
}());
exports.PlaceCityTile = PlaceCityTile;
