"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.Pollinators = void 0;
var ICard_1 = require("../ICard");
var Card_1 = require("../Card");
var CardType_1 = require("../CardType");
var CardName_1 = require("../../CardName");
var CardRenderer_1 = require("../render/CardRenderer");
var ResourceType_1 = require("../../common/ResourceType");
var Tags_1 = require("../Tags");
var CardRequirements_1 = require("../CardRequirements");
var Resources_1 = require("../../common/Resources");
var Pollinators = (function (_super) {
    __extends(Pollinators, _super);
    function Pollinators() {
        var _this = _super.call(this, {
            cardType: CardType_1.CardType.ACTIVE,
            name: CardName_1.CardName.POLLINATORS,
            cost: 19,
            tags: [Tags_1.Tags.PLANT, Tags_1.Tags.ANIMAL],
            resourceType: ResourceType_1.ResourceType.ANIMAL,
            requirements: CardRequirements_1.CardRequirements.builder(function (b) { return b.tag(Tags_1.Tags.PLANT, 3); }),
            victoryPoints: ICard_1.VictoryPoints.resource(1, 1),
            metadata: {
                cardNumber: '...',
                renderData: CardRenderer_1.CardRenderer.builder(function (b) {
                    b.action('Add 1 animal on this card', function (ab) { return ab.empty().startAction.animals(1); }).br;
                    b.production(function (pb) { return pb.plants(1).megacredits(2); });
                    b.vpText('1 VP per animal on this card.');
                }),
                description: 'Requires 3 plant tags. Raise your plant production 1 step and your M€ production 2 steps.',
            },
        }) || this;
        _this.resourceCount = 0;
        return _this;
    }
    Pollinators.prototype.canAct = function () {
        return true;
    };
    Pollinators.prototype.action = function (player) {
        player.addResourceTo(this, 1);
        return undefined;
    };
    Pollinators.prototype.play = function (player) {
        player.addProduction(Resources_1.Resources.PLANTS, 1);
        player.addProduction(Resources_1.Resources.MEGACREDITS, 2);
        return undefined;
    };
    return Pollinators;
}(Card_1.Card));
exports.Pollinators = Pollinators;
