"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var vue_1 = require("vue");
var utils_1 = require("@/utils/utils");
exports.default = vue_1.default.extend({
    name: 'DynamicTitle',
    props: {
        title: {
            type: String,
            required: true,
        },
        color: {
            type: String,
            required: true,
        },
        withAdditional: {
            type: Boolean,
            default: false,
        },
        additional: {
            type: [String, Number],
            default: '',
        },
    },
    methods: {
        getClasses: function () {
            return [
                (0, utils_1.playerColorClass)(this.color, 'shadow'),
                'dynamic-title',
            ].join(' ');
        },
    },
});
