"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.ConvertHeat = void 0;
var StandardActionCard_1 = require("../../StandardActionCard");
var CardName_1 = require("../../../CardName");
var CardRenderer_1 = require("../../render/CardRenderer");
var constants_1 = require("../../../constants");
var Units_1 = require("../../../Units");
var ConvertHeat = (function (_super) {
    __extends(ConvertHeat, _super);
    function ConvertHeat() {
        return _super.call(this, {
            name: CardName_1.CardName.CONVERT_HEAT,
            metadata: {
                cardNumber: 'SA2',
                renderData: CardRenderer_1.CardRenderer.builder(function (b) {
                    return b.standardProject('Spend 8 Heat to raise temperature 1 step.', function (eb) {
                        eb.heat(8).startAction.temperature(1);
                    });
                }),
            },
        }) || this;
    }
    ConvertHeat.prototype.canAct = function (player) {
        if (player.game.getTemperature() === constants_1.MAX_TEMPERATURE) {
            return false;
        }
        if (player.availableHeat < constants_1.HEAT_FOR_TEMPERATURE) {
            return false;
        }
        return player.canAfford(0, {
            tr: { temperature: 1 },
            reserveUnits: Units_1.Units.of({ heat: 8 }),
        });
    };
    ConvertHeat.prototype.action = function (player) {
        var _this = this;
        return player.spendHeat(constants_1.HEAT_FOR_TEMPERATURE, function () {
            _this.actionUsed(player);
            player.game.increaseTemperature(player, 1);
            return undefined;
        });
    };
    return ConvertHeat;
}(StandardActionCard_1.StandardActionCard));
exports.ConvertHeat = ConvertHeat;
