"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.CommercialDistrictAres = void 0;
var SpaceBonus_1 = require("../../SpaceBonus");
var CardName_1 = require("../../CardName");
var CommercialDistrict_1 = require("../base/CommercialDistrict");
var CardRenderer_1 = require("../render/CardRenderer");
var TileType_1 = require("../../common/TileType");
var CardRenderDynamicVictoryPoints_1 = require("../render/CardRenderDynamicVictoryPoints");
var CommercialDistrictAres = (function (_super) {
    __extends(CommercialDistrictAres, _super);
    function CommercialDistrictAres() {
        return _super.call(this, CardName_1.CardName.COMMERCIAL_DISTRICT_ARES, { bonus: [SpaceBonus_1.SpaceBonus.MEGACREDITS, SpaceBonus_1.SpaceBonus.MEGACREDITS] }, {
            cardNumber: 'A06',
            description: 'Place this tile which grants an ADJACENCY BONUS of 2 M€. Decrease your energy production 1 step and increase your M€ production 4 steps.',
            renderData: CardRenderer_1.CardRenderer.builder(function (b) {
                b.production(function (pb) {
                    pb.minus().energy(1).br;
                    pb.plus().megacredits(4).br;
                }).nbsp.nbsp.tile(TileType_1.TileType.COMMERCIAL_DISTRICT, false, true).br;
                b.vpText('1 VP per adjacent city tile.');
            }),
            victoryPoints: CardRenderDynamicVictoryPoints_1.CardRenderDynamicVictoryPoints.cities(1, 1),
        }) || this;
    }
    return CommercialDistrictAres;
}(CommercialDistrict_1.CommercialDistrict));
exports.CommercialDistrictAres = CommercialDistrictAres;
