"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.LunarMineUrbanization = void 0;
var CardName_1 = require("../../CardName");
var CardType_1 = require("../CardType");
var Tags_1 = require("../Tags");
var CardRenderer_1 = require("../render/CardRenderer");
var MoonExpansion_1 = require("../../moon/MoonExpansion");
var Resources_1 = require("../../common/Resources");
var TileType_1 = require("../../common/TileType");
var SelectSpace_1 = require("../../inputs/SelectSpace");
var Card_1 = require("../Card");
var CardRequirements_1 = require("../CardRequirements");
var Units_1 = require("../../Units");
var LunarMineUrbanization = (function (_super) {
    __extends(LunarMineUrbanization, _super);
    function LunarMineUrbanization() {
        return _super.call(this, {
            name: CardName_1.CardName.LUNAR_MINE_URBANIZATION,
            cardType: CardType_1.CardType.EVENT,
            tags: [Tags_1.Tags.MOON, Tags_1.Tags.BUILDING],
            cost: 8,
            productionBox: Units_1.Units.of({ megacredits: 1 }),
            requirements: CardRequirements_1.CardRequirements.builder(function (b) { return b.miningTiles(1); }),
            tr: { moonColony: 1 },
            metadata: {
                description: 'Requires you have 1 mine tile. Increase your M€ production 1 step. Replace one of your mine tiles ' +
                    'with this special tile. Raise the Colony Rate 1 step. This tile counts both as a colony and a mine tile.',
                cardNumber: 'M55',
                renderData: CardRenderer_1.CardRenderer.builder(function (b) {
                    b.production(function (pb) { return pb.megacredits(1); }).br;
                    b.moonColonyRate();
                    b.tile(TileType_1.TileType.LUNAR_MINE_URBANIZATION, true).asterix();
                }),
            },
        }) || this;
    }
    ;
    LunarMineUrbanization.prototype.play = function (player) {
        player.addProduction(Resources_1.Resources.MEGACREDITS, 1);
        var tiles = MoonExpansion_1.MoonExpansion.tiles(player.game, TileType_1.TileType.MOON_MINE, { ownedBy: player });
        return new SelectSpace_1.SelectSpace('Select one of your mines to upgrade', tiles, function (space) {
            if (space.tile === undefined) {
                throw new Error("Space " + space.id + " should have a tile, how doesn't it?");
            }
            space.tile.tileType = TileType_1.TileType.LUNAR_MINE_URBANIZATION;
            MoonExpansion_1.MoonExpansion.raiseColonyRate(player);
            return undefined;
        });
    };
    return LunarMineUrbanization;
}(Card_1.Card));
exports.LunarMineUrbanization = LunarMineUrbanization;
