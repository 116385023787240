"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AddResourcesToCard = void 0;
var SelectCard_1 = require("../inputs/SelectCard");
var DeferredAction_1 = require("./DeferredAction");
var AddResourcesToCard = (function () {
    function AddResourcesToCard(player, resourceType, options) {
        if (options === void 0) { options = {}; }
        this.player = player;
        this.resourceType = resourceType;
        this.options = options;
        this.priority = DeferredAction_1.Priority.GAIN_RESOURCE_OR_PRODUCTION;
    }
    AddResourcesToCard.prototype.execute = function () {
        var _this = this;
        var _a, _b;
        var count = (_a = this.options.count) !== null && _a !== void 0 ? _a : 1;
        var title = (_b = this.options.title) !== null && _b !== void 0 ? _b : 'Select card to add ' + count + ' ' + (this.resourceType || 'resources') + '(s)';
        var cards = this.player.getResourceCards(this.resourceType);
        if (this.options.restrictedTag !== undefined) {
            cards = cards.filter(function (card) { return card.tags.includes(_this.options.restrictedTag); });
        }
        if (this.options.filter !== undefined) {
            cards = cards.filter(this.options.filter);
        }
        if (cards.length === 0) {
            return undefined;
        }
        if (cards.length === 1) {
            this.player.addResourceTo(cards[0], { qty: count, log: true });
            return undefined;
        }
        return new SelectCard_1.SelectCard(title, count === 1 ? 'Add resource' : 'Add resources', cards, function (selected) {
            _this.player.addResourceTo(selected[0], { qty: count, log: true });
            return undefined;
        });
    };
    return AddResourcesToCard;
}());
exports.AddResourcesToCard = AddResourcesToCard;
