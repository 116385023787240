"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.ExtractorBalloons = void 0;
var Tags_1 = require("../Tags");
var CardType_1 = require("../CardType");
var ResourceType_1 = require("../../common/ResourceType");
var OrOptions_1 = require("../../inputs/OrOptions");
var SelectOption_1 = require("../../inputs/SelectOption");
var CardName_1 = require("../../CardName");
var constants_1 = require("../../constants");
var LogHelper_1 = require("../../LogHelper");
var CardRenderer_1 = require("../render/CardRenderer");
var Size_1 = require("../render/Size");
var Card_1 = require("../Card");
var ExtractorBalloons = (function (_super) {
    __extends(ExtractorBalloons, _super);
    function ExtractorBalloons() {
        var _this = _super.call(this, {
            name: CardName_1.CardName.EXTRACTOR_BALLOONS,
            cardType: CardType_1.CardType.ACTIVE,
            tags: [Tags_1.Tags.VENUS],
            cost: 21,
            resourceType: ResourceType_1.ResourceType.FLOATER,
            metadata: {
                cardNumber: '223',
                description: 'Add 3 Floaters to this card',
                renderData: CardRenderer_1.CardRenderer.builder(function (b) {
                    b.action('Add 1 Floater to this card.', function (eb) {
                        eb.empty().startAction.floaters(1);
                    }).br;
                    b.action('Remove 2 Floaters here to raise Venus 1 step.', function (eb) {
                        eb.or(Size_1.Size.SMALL).floaters(2).startAction.venus(1);
                    }).br.floaters(3);
                }),
            },
        }) || this;
        _this.resourceCount = 0;
        return _this;
    }
    ;
    ExtractorBalloons.prototype.play = function (player) {
        player.addResourceTo(this, 3);
        return undefined;
    };
    ExtractorBalloons.prototype.canAct = function () {
        return true;
    };
    ExtractorBalloons.prototype.action = function (player) {
        var _this = this;
        var venusMaxed = player.game.getVenusScaleLevel() === constants_1.MAX_VENUS_SCALE;
        var canAffordReds = player.canAfford(0, { tr: { venus: 1 } });
        if (this.resourceCount < 2 || venusMaxed || !canAffordReds) {
            player.addResourceTo(this, { log: true });
            return undefined;
        }
        return new OrOptions_1.OrOptions(new SelectOption_1.SelectOption('Remove 2 floaters to raise Venus scale 1 step', 'Remove floaters', function () {
            player.removeResourceFrom(_this, 2);
            player.game.increaseVenusScaleLevel(player, 1);
            LogHelper_1.LogHelper.logVenusIncrease(player, 1);
            return undefined;
        }), new SelectOption_1.SelectOption('Add 1 floater to this card', 'Add floater', function () {
            player.addResourceTo(_this, { log: true });
            return undefined;
        }));
    };
    return ExtractorBalloons;
}(Card_1.Card));
exports.ExtractorBalloons = ExtractorBalloons;
