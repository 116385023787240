"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.Ambient = void 0;
var Card_1 = require("../Card");
var Tags_1 = require("../Tags");
var Resources_1 = require("../../common/Resources");
var CardName_1 = require("../../CardName");
var CardType_1 = require("../CardType");
var CardRenderer_1 = require("../render/CardRenderer");
var Options_1 = require("../Options");
var DeferredAction_1 = require("../../deferredActions/DeferredAction");
var constants_1 = require("../../constants");
var Size_1 = require("../render/Size");
var Ambient = (function (_super) {
    __extends(Ambient, _super);
    function Ambient() {
        return _super.call(this, {
            cardType: CardType_1.CardType.CORPORATION,
            name: CardName_1.CardName.AMBIENT,
            tags: [Tags_1.Tags.VENUS],
            startingMegaCredits: 38,
            initialActionText: 'Raise the Venus scale 2 steps.',
            metadata: {
                cardNumber: 'PfC3',
                description: 'You start with 38 M€. As your first action, raise the Venus scale 2 steps.',
                renderData: CardRenderer_1.CardRenderer.builder(function (b) {
                    b.megacredits(38).venus(2, { size: Size_1.Size.SMALL }).br;
                    b.effect('When you play a card with a Venus tag (including this) increase your heat production 1 step.', function (eb) {
                        eb.venus(1, { played: Options_1.played }).startEffect.production(function (pb) { return pb.heat(1); });
                    }).br;
                    b.action('When temperature is maxed, spend 8 heat gain 1 TR. ' +
                        'You may repeat this action like a standard project.', function (ab) {
                        ab.heat(8, { digit: Options_1.digit, size: Size_1.Size.SMALL }).startAction.tr(1, { size: Size_1.Size.SMALL }).text('∞');
                    });
                }),
            },
        }) || this;
    }
    Ambient.prototype.play = function (player) {
        this.onCorpCardPlayed(player, this);
        return undefined;
    };
    Ambient.prototype.initialAction = function (player) {
        player.game.increaseVenusScaleLevel(player, 2);
        return undefined;
    };
    Ambient.prototype.onCorpCardPlayed = function (player, card) {
        return this.onCardPlayed(player, card);
    };
    Ambient.prototype.onCardPlayed = function (player, card) {
        if (player.isCorporation(this.name) && card.tags.includes(Tags_1.Tags.VENUS)) {
            player.addProduction(Resources_1.Resources.HEAT, 1, { log: true });
        }
    };
    Ambient.prototype.canAct = function (player) {
        return player.heat >= 8 && player.game.getTemperature() === constants_1.MAX_TEMPERATURE && player.canAfford(0, { tr: { tr: 1 } });
    };
    Ambient.prototype.action = function (player) {
        var _this = this;
        player.heat -= 8;
        player.increaseTerraformRating();
        player.game.defer(new DeferredAction_1.DeferredAction(player, function () {
            player.getActionsThisGeneration().delete(_this.name);
            return undefined;
        }));
        return undefined;
    };
    return Ambient;
}(Card_1.Card));
exports.Ambient = Ambient;
