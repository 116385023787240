"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.SocietySupport = void 0;
var PreludeCard_1 = require("./PreludeCard");
var Resources_1 = require("../../common/Resources");
var CardName_1 = require("../../CardName");
var CardRenderer_1 = require("../render/CardRenderer");
var SocietySupport = (function (_super) {
    __extends(SocietySupport, _super);
    function SocietySupport() {
        return _super.call(this, {
            name: CardName_1.CardName.SOCIETY_SUPPORT,
            metadata: {
                cardNumber: 'P31',
                renderData: CardRenderer_1.CardRenderer.builder(function (b) {
                    b.production(function (pb) {
                        pb.megacredits(-1).plants(1).br;
                        pb.energy(1).heat(1);
                    });
                }),
                description: 'Increase your plant, energy and heat production 1 step. Decrease money production 1 step.',
            },
        }) || this;
    }
    SocietySupport.prototype.play = function (player) {
        player.addProduction(Resources_1.Resources.MEGACREDITS, -1);
        player.addProduction(Resources_1.Resources.PLANTS, 1);
        player.addProduction(Resources_1.Resources.ENERGY, 1);
        player.addProduction(Resources_1.Resources.HEAT, 1);
        return undefined;
    };
    return SocietySupport;
}(PreludeCard_1.PreludeCard));
exports.SocietySupport = SocietySupport;
