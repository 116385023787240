"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.NitrogenDelivery = void 0;
var PreludeCard_1 = require("./PreludeCard");
var Resources_1 = require("../../common/Resources");
var CardName_1 = require("../../CardName");
var CardRenderer_1 = require("../render/CardRenderer");
var NitrogenDelivery = (function (_super) {
    __extends(NitrogenDelivery, _super);
    function NitrogenDelivery() {
        return _super.call(this, {
            name: CardName_1.CardName.NITROGEN_SHIPMENT,
            startingMegacredits: 5,
            metadata: {
                cardNumber: 'P24',
                renderData: CardRenderer_1.CardRenderer.builder(function (b) {
                    b.production(function (pb) { return pb.plants(1); }).tr(1).br;
                    b.megacredits(5);
                }),
                description: 'Increase your plant production 1 step. Increase your TR 1 step. Gain 5 M€.',
            },
        }) || this;
    }
    NitrogenDelivery.prototype.play = function (player) {
        player.megaCredits += 5;
        player.increaseTerraformRating();
        player.addProduction(Resources_1.Resources.PLANTS, 1);
        return undefined;
    };
    return NitrogenDelivery;
}(PreludeCard_1.PreludeCard));
exports.NitrogenDelivery = NitrogenDelivery;
