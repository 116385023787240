"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.CommunicationBoom = void 0;
var IGlobalEvent_1 = require("./IGlobalEvent");
var GlobalEventName_1 = require("./GlobalEventName");
var PartyName_1 = require("../parties/PartyName");
var Resources_1 = require("../../common/Resources");
var ResourceType_1 = require("../../common/ResourceType");
var AddResourcesToCards_1 = require("../../deferredActions/AddResourcesToCards");
var CardRenderer_1 = require("../../cards/render/CardRenderer");
var RENDER_DATA = CardRenderer_1.CardRenderer.builder(function (b) {
    b.megacredits(-10).nbsp.data({ amount: 2 }).asterix().br;
    b.data().slash().influence();
});
var CommunicationBoom = (function (_super) {
    __extends(CommunicationBoom, _super);
    function CommunicationBoom() {
        return _super.call(this, {
            name: GlobalEventName_1.GlobalEventName.COMMUNICATION_BOOM,
            description: 'Pay 10MC. Add 2 data to EVERY data card. Add 1 data to any data card for each influence you have.',
            revealedDelegate: PartyName_1.PartyName.UNITY,
            currentDelegate: PartyName_1.PartyName.SCIENTISTS,
            renderData: RENDER_DATA,
        }) || this;
    }
    CommunicationBoom.prototype.resolve = function (game, turmoil) {
        var _this = this;
        game.getPlayers().forEach(function (player) {
            player.deductResource(Resources_1.Resources.MEGACREDITS, 10, { log: true, from: _this.name });
            player.getResourceCards(ResourceType_1.ResourceType.DATA).forEach(function (card) {
                player.addResourceTo(card, { qty: 2, log: true });
            });
            var count = turmoil.getPlayerInfluence(player);
            game.defer(new AddResourcesToCards_1.AddResourcesToCards(player, ResourceType_1.ResourceType.DATA, count));
        });
    };
    return CommunicationBoom;
}(IGlobalEvent_1.GlobalEvent));
exports.CommunicationBoom = CommunicationBoom;
