"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var vue_1 = require("vue");
var ResourceType_1 = require("@/common/ResourceType");
exports.default = vue_1.default.extend({
    name: 'CardResourceCounter',
    props: {
        amount: {
            type: Number,
            required: true,
        },
        type: {
            type: String,
            required: true,
        },
    },
    computed: {
        getClass: function () {
            switch (this.type) {
                case ResourceType_1.ResourceType.ANIMAL:
                    return 'card-resource-animal';
                case ResourceType_1.ResourceType.MICROBE:
                    return 'card-resource-microbe';
                case ResourceType_1.ResourceType.FIGHTER:
                    return 'card-resource-fighter';
                case ResourceType_1.ResourceType.SCIENCE:
                    return 'card-resource-science';
                case ResourceType_1.ResourceType.FLOATER:
                    return 'card-resource-floater';
                case ResourceType_1.ResourceType.ASTEROID:
                    return 'card-resource-asteroid';
                case ResourceType_1.ResourceType.PRESERVATION:
                    return 'card-resource-preservation';
                case ResourceType_1.ResourceType.CAMP:
                    return 'card-resource-camp';
                case ResourceType_1.ResourceType.DISEASE:
                    return 'card-resource-disease';
                case ResourceType_1.ResourceType.RESOURCE_CUBE:
                    return 'card-resource-cube';
                case ResourceType_1.ResourceType.DATA:
                    return 'card-resource-data';
                case ResourceType_1.ResourceType.SYNDICATE_FLEET:
                    return 'card-resource-syndicate-fleet';
                case ResourceType_1.ResourceType.VENUSIAN_HABITAT:
                    return 'card-resource-venusian-habitat';
                case ResourceType_1.ResourceType.SPECIALIZED_ROBOT:
                    return 'card-resource-specialized-robot';
                case ResourceType_1.ResourceType.SEED:
                    return 'card-resource-seed';
                default:
                    return '';
            }
        },
    },
});
