"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var vue_1 = require("vue");
var Button_vue_1 = require("@/client/components/common/Button.vue");
var constants = require("@/constants");
exports.default = vue_1.default.extend({
    name: 'LoadGameForm',
    components: {
        Button: Button_vue_1.default,
    },
    data: function () {
        return {
            constants: constants,
            gameId: '',
            rollbackCount: '0',
        };
    },
    methods: {
        loadGame: function () {
            var _this = this;
            var gameId = this.$data.gameId;
            var rollbackCount = this.$data.rollbackCount;
            var xhr = new XMLHttpRequest();
            xhr.open('PUT', '/load_game');
            xhr.onerror = function () {
                alert('Error loading game');
            };
            xhr.onload = function () {
                if (xhr.status === 200) {
                    var response = xhr.response;
                    if (response.players.length === 1) {
                        window.location.href = '/player?id=' + response.players[0].id;
                        return;
                    }
                    else {
                        window.history.replaceState(response, constants.APP_NAME + " - Game", '/game?id=' + response.id);
                        _this.$root.$data.game = response;
                        _this.$root.$data.screen = 'game-home';
                    }
                }
                else {
                    alert('Unexpected server response');
                }
            };
            var loadGameFormModel = {
                game_id: gameId,
                rollbackCount: rollbackCount,
            };
            xhr.responseType = 'json';
            xhr.send(JSON.stringify(loadGameFormModel));
        },
    },
});
