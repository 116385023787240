"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.AsteroidResources = void 0;
var Card_1 = require("../Card");
var CardType_1 = require("../CardType");
var CardName_1 = require("../../CardName");
var CardRenderer_1 = require("../render/CardRenderer");
var Tags_1 = require("../Tags");
var Resources_1 = require("../../common/Resources");
var Units_1 = require("../../Units");
var OrOptions_1 = require("../../inputs/OrOptions");
var SelectOption_1 = require("../../inputs/SelectOption");
var PlaceOceanTile_1 = require("../../deferredActions/PlaceOceanTile");
var Size_1 = require("../render/Size");
var Options_1 = require("../Options");
var AsteroidResources = (function (_super) {
    __extends(AsteroidResources, _super);
    function AsteroidResources() {
        return _super.call(this, {
            cardType: CardType_1.CardType.AUTOMATED,
            name: CardName_1.CardName.ASTEROID_RESOURCES,
            cost: 17,
            tags: [Tags_1.Tags.JOVIAN, Tags_1.Tags.SPACE],
            reserveUnits: Units_1.Units.of({ energy: 3 }),
            victoryPoints: 1,
            metadata: {
                cardNumber: 'Pf40',
                renderData: CardRenderer_1.CardRenderer.builder(function (b) {
                    b.minus().energy(3, { digit: Options_1.digit }).production(function (pb) { return pb.steel(1).titanium(1); }).br
                        .or(Size_1.Size.SMALL).br;
                    b.minus().energy(3, { digit: Options_1.digit }).oceans(1, { size: Size_1.Size.SMALL }).steel(2, { digit: Options_1.digit }).titanium(1);
                }),
                description: 'Spend 3 energy. Either increase your steel and titanium production one step, OR ' +
                    'place an ocean, and gain 2 steel and one titanium.',
            },
        }) || this;
    }
    AsteroidResources.prototype.canPlay = function (player) {
        return player.hasUnits(this.reserveUnits);
    };
    AsteroidResources.prototype.play = function (player) {
        var _this = this;
        var options = new OrOptions_1.OrOptions(new SelectOption_1.SelectOption('Increase your steel and titanium production 1 step.', 'Select', function () {
            player.deductUnits(_this.reserveUnits);
            player.addProduction(Resources_1.Resources.STEEL, 1, { log: true });
            player.addProduction(Resources_1.Resources.TITANIUM, 1, { log: true });
            return undefined;
        }), new SelectOption_1.SelectOption('Place an ocean, and gain 2 steel and one titanium.', 'Select', function () {
            player.deductUnits(_this.reserveUnits);
            player.addResource(Resources_1.Resources.STEEL, 2, { log: true });
            player.addResource(Resources_1.Resources.TITANIUM, 1, { log: true });
            player.game.defer(new PlaceOceanTile_1.PlaceOceanTile(player));
            return undefined;
        }));
        if (!player.canAfford(0, { tr: { oceans: 1 } })) {
            options.options[0].cb();
            return undefined;
        }
        return options;
    };
    return AsteroidResources;
}(Card_1.Card));
exports.AsteroidResources = AsteroidResources;
