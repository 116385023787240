"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GlobalEvent = exports.staticGlobalEventProperties = void 0;
exports.staticGlobalEventProperties = new Map();
var GlobalEvent = (function () {
    function GlobalEvent(properties) {
        var staticInstance = exports.staticGlobalEventProperties.get(properties.name);
        if (staticInstance === undefined) {
            exports.staticGlobalEventProperties.set(properties.name, properties);
            staticInstance = properties;
        }
        this.properties = staticInstance;
    }
    Object.defineProperty(GlobalEvent.prototype, "name", {
        get: function () {
            return this.properties.name;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(GlobalEvent.prototype, "description", {
        get: function () {
            return this.properties.description;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(GlobalEvent.prototype, "revealedDelegate", {
        get: function () {
            return this.properties.revealedDelegate;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(GlobalEvent.prototype, "currentDelegate", {
        get: function () {
            return this.properties.currentDelegate;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(GlobalEvent.prototype, "renderData", {
        get: function () {
            return this.properties.renderData;
        },
        enumerable: false,
        configurable: true
    });
    return GlobalEvent;
}());
exports.GlobalEvent = GlobalEvent;
