"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.Polaris = void 0;
var Card_1 = require("../Card");
var Tags_1 = require("../Tags");
var Resources_1 = require("../../common/Resources");
var CardName_1 = require("../../CardName");
var CardType_1 = require("../CardType");
var CardRenderer_1 = require("../render/CardRenderer");
var Options_1 = require("../Options");
var PlaceOceanTile_1 = require("../../deferredActions/PlaceOceanTile");
var TileType_1 = require("../../common/TileType");
var GainResources_1 = require("../../deferredActions/GainResources");
var DeferredAction_1 = require("../../deferredActions/DeferredAction");
var Size_1 = require("../render/Size");
var Polaris = (function (_super) {
    __extends(Polaris, _super);
    function Polaris() {
        return _super.call(this, {
            cardType: CardType_1.CardType.CORPORATION,
            name: CardName_1.CardName.POLARIS,
            tags: [Tags_1.Tags.SPACE],
            startingMegaCredits: 32,
            initialActionText: 'Place your initial ocean.',
            metadata: {
                cardNumber: 'PfC1',
                description: 'You start with 32 M€. As your first action, place an ocean tile.',
                renderData: CardRenderer_1.CardRenderer.builder(function (b) {
                    b.br;
                    b.megacredits(32).oceans(1);
                    b.corpBox('effect', function (ce) {
                        ce.effect('When any ocean tile is placed ON MARS, increase your M€ production 1 step. When you place an ocean tile, gain 4M€.', function (eb) {
                            eb.oceans(1, { size: Size_1.Size.SMALL, all: Options_1.all }).colon().production(function (pb) { return pb.megacredits(1); });
                            eb.nbsp;
                            eb.oceans(1, { size: Size_1.Size.SMALL }).startEffect.megacredits(4, { digit: Options_1.digit });
                        });
                    });
                }),
            },
        }) || this;
    }
    Polaris.prototype.play = function () {
        return undefined;
    };
    Polaris.prototype.initialAction = function (player) {
        player.game.defer(new PlaceOceanTile_1.PlaceOceanTile(player));
        return undefined;
    };
    Polaris.prototype.onTilePlaced = function (cardOwner, activePlayer, space) {
        var _this = this;
        var _a;
        if (((_a = space.tile) === null || _a === void 0 ? void 0 : _a.tileType) === TileType_1.TileType.OCEAN) {
            cardOwner.addProduction(Resources_1.Resources.MEGACREDITS, 1);
            activePlayer.game.log('${0} gained 1 ${1} production from ${2}', function (b) { return b.player(cardOwner).string(Resources_1.Resources.MEGACREDITS).cardName(_this.name); });
            if (activePlayer.id === cardOwner.id) {
                cardOwner.game.defer(new GainResources_1.GainResources(cardOwner, Resources_1.Resources.MEGACREDITS, {
                    count: 4,
                    cb: function () { return activePlayer.game.log('${0} gained ${1} from ${2}', function (b) { return b.player(cardOwner).string(Resources_1.Resources.MEGACREDITS).cardName(_this.name); }); },
                }), cardOwner.id !== activePlayer.id ? DeferredAction_1.Priority.OPPONENT_TRIGGER : undefined);
            }
        }
    };
    return Polaris;
}(Card_1.Card));
exports.Polaris = Polaris;
