"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.LeadershipSummit = void 0;
var IGlobalEvent_1 = require("./IGlobalEvent");
var GlobalEventName_1 = require("./GlobalEventName");
var PartyName_1 = require("../parties/PartyName");
var CardRenderer_1 = require("../../cards/render/CardRenderer");
var RENDER_DATA = CardRenderer_1.CardRenderer.builder(function (b) {
    b.cards(1).slash().partyLeaders(1).plus().influence();
});
var LeadershipSummit = (function (_super) {
    __extends(LeadershipSummit, _super);
    function LeadershipSummit() {
        return _super.call(this, {
            name: GlobalEventName_1.GlobalEventName.LEADERSHIP_SUMMIT,
            description: 'Draw 1 card for each party leader (max 5) and influence.',
            revealedDelegate: PartyName_1.PartyName.GREENS,
            currentDelegate: PartyName_1.PartyName.REDS,
            renderData: RENDER_DATA,
        }) || this;
    }
    LeadershipSummit.prototype.resolve = function (game, turmoil) {
        game.getPlayers().forEach(function (player) {
            var partyLeaderCount = turmoil.parties.filter(function (party) { return party.partyLeader === player.id; }).length;
            player.drawCard(Math.min(5, partyLeaderCount) + turmoil.getPlayerInfluence(player));
        });
    };
    return LeadershipSummit;
}(IGlobalEvent_1.GlobalEvent));
exports.LeadershipSummit = LeadershipSummit;
