"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.MetalsCompany = void 0;
var PreludeCard_1 = require("./PreludeCard");
var CardName_1 = require("../../CardName");
var CardRenderer_1 = require("../render/CardRenderer");
var Units_1 = require("../../Units");
var MetalsCompany = (function (_super) {
    __extends(MetalsCompany, _super);
    function MetalsCompany() {
        return _super.call(this, {
            name: CardName_1.CardName.METALS_COMPANY,
            productionBox: Units_1.Units.of({ megacredits: 1, steel: 1, titanium: 1 }),
            metadata: {
                cardNumber: 'P20',
                renderData: CardRenderer_1.CardRenderer.builder(function (b) {
                    b.production(function (pb) { return pb.megacredits(1).steel(1).titanium(1); });
                }),
                description: 'Increase your MC, steel and titanium production 1 step.',
            },
        }) || this;
    }
    MetalsCompany.prototype.play = function (player) {
        player.adjustProduction(this.productionBox);
        return undefined;
    };
    return MetalsCompany;
}(PreludeCard_1.PreludeCard));
exports.MetalsCompany = MetalsCompany;
