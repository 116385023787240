"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.CorroderSuits = void 0;
var Tags_1 = require("../Tags");
var CardType_1 = require("../CardType");
var Resources_1 = require("../../common/Resources");
var SelectCard_1 = require("../../inputs/SelectCard");
var CardName_1 = require("../../CardName");
var CardRenderer_1 = require("../render/CardRenderer");
var Card_1 = require("../Card");
var CorroderSuits = (function (_super) {
    __extends(CorroderSuits, _super);
    function CorroderSuits() {
        return _super.call(this, {
            name: CardName_1.CardName.CORRODER_SUITS,
            cardType: CardType_1.CardType.AUTOMATED,
            tags: [Tags_1.Tags.VENUS],
            cost: 8,
            metadata: {
                cardNumber: '219',
                description: 'Increase your M€ production 2 steps. Add 1 resource to ANY Venus CARD.',
                renderData: CardRenderer_1.CardRenderer.builder(function (b) {
                    b.production(function (pb) {
                        pb.megacredits(2);
                    }).wild(1, { secondaryTag: Tags_1.Tags.VENUS });
                }),
            },
        }) || this;
    }
    ;
    CorroderSuits.prototype.play = function (player) {
        player.addProduction(Resources_1.Resources.MEGACREDITS, 2);
        var cards = CorroderSuits.getVenusResCards(player);
        if (cards.length === 0)
            return undefined;
        if (cards.length === 1) {
            player.addResourceTo(cards[0], { log: true });
            return undefined;
        }
        return new SelectCard_1.SelectCard('Select card to add 1 resource', 'Add resource', CorroderSuits.getVenusResCards(player), function (foundCards) {
            player.addResourceTo(foundCards[0], { log: true });
            return undefined;
        });
    };
    CorroderSuits.getVenusResCards = function (player) {
        return player.getResourceCards().filter(function (card) { return card.tags.includes(Tags_1.Tags.VENUS); });
    };
    return CorroderSuits;
}(Card_1.Card));
exports.CorroderSuits = CorroderSuits;
