"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.AgricolaInc = void 0;
var Tags_1 = require("../Tags");
var Resources_1 = require("../../common/Resources");
var Card_1 = require("../Card");
var CardName_1 = require("../../CardName");
var CardType_1 = require("../CardType");
var CardRenderer_1 = require("../render/CardRenderer");
var Size_1 = require("../render/Size");
var CardRenderDynamicVictoryPoints_1 = require("../render/CardRenderDynamicVictoryPoints");
var AgricolaInc = (function (_super) {
    __extends(AgricolaInc, _super);
    function AgricolaInc() {
        return _super.call(this, {
            name: CardName_1.CardName.AGRICOLA_INC,
            tags: [Tags_1.Tags.PLANT],
            startingMegaCredits: 40,
            cardType: CardType_1.CardType.CORPORATION,
            victoryPoints: 'special',
            metadata: {
                cardNumber: 'R36',
                description: 'You start with 1 plant production, 1 M€ production and 40 M€.',
                renderData: CardRenderer_1.CardRenderer.builder(function (b) {
                    b.br.br;
                    b.production(function (pb) { return pb.megacredits(1).plants(1); }).nbsp.megacredits(40);
                    b.corpBox('effect', function (ce) {
                        ce.text('Effect: At game end, score -2 / 0 / 1 / 2 VP PER TAG TYPE for 0 / 1-2 / 3-4 / 5+ tags.', Size_1.Size.SMALL, true);
                    });
                }),
                victoryPoints: CardRenderDynamicVictoryPoints_1.CardRenderDynamicVictoryPoints.questionmark(),
            },
        }) || this;
    }
    AgricolaInc.prototype.play = function (player) {
        player.addProduction(Resources_1.Resources.MEGACREDITS, 1);
        player.addProduction(Resources_1.Resources.PLANTS, 1);
        return undefined;
    };
    AgricolaInc.prototype.getVictoryPoints = function (player) {
        var scorableTags = [Tags_1.Tags.CITY, Tags_1.Tags.EARTH, Tags_1.Tags.ENERGY, Tags_1.Tags.JOVIAN, Tags_1.Tags.MICROBE, Tags_1.Tags.PLANT, Tags_1.Tags.SCIENCE, Tags_1.Tags.SPACE, Tags_1.Tags.BUILDING, Tags_1.Tags.ANIMAL];
        if (player.game.gameOptions.venusNextExtension)
            scorableTags.push(Tags_1.Tags.VENUS);
        var playerTags = player.getAllTags();
        var points = 0;
        scorableTags.forEach(function (tag) {
            var tagData = playerTags.find(function (data) { return data.tag === tag; });
            if (tagData === undefined) {
                points -= 2;
            }
            else if (tagData.count === 3 || tagData.count === 4) {
                points += 1;
            }
            else if (tagData.count > 4) {
                points += 2;
            }
        });
        return points;
    };
    return AgricolaInc;
}(Card_1.Card));
exports.AgricolaInc = AgricolaInc;
