"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.AerialLenses = void 0;
var Card_1 = require("../Card");
var CardName_1 = require("../../CardName");
var CardType_1 = require("../CardType");
var Resources_1 = require("../../common/Resources");
var PartyName_1 = require("../../turmoil/parties/PartyName");
var RemoveAnyPlants_1 = require("../../deferredActions/RemoveAnyPlants");
var CardRequirements_1 = require("../CardRequirements");
var CardRenderer_1 = require("../render/CardRenderer");
var Options_1 = require("../Options");
var AerialLenses = (function (_super) {
    __extends(AerialLenses, _super);
    function AerialLenses() {
        return _super.call(this, {
            cardType: CardType_1.CardType.AUTOMATED,
            name: CardName_1.CardName.AERIAL_LENSES,
            cost: 2,
            victoryPoints: -1,
            requirements: CardRequirements_1.CardRequirements.builder(function (b) { return b.party(PartyName_1.PartyName.KELVINISTS); }),
            metadata: {
                description: 'Requires that Kelvinists are ruling or that you have 2 delegates there. Remove up to 2 plants from any player. Increase your heat production 2 steps.',
                cardNumber: 'T01',
                renderData: CardRenderer_1.CardRenderer.builder(function (b) { return b.minus().plants(-2, { all: Options_1.all }).production(function (pb) { return pb.heat(2); }); }),
            },
        }) || this;
    }
    AerialLenses.prototype.play = function (player) {
        player.addProduction(Resources_1.Resources.HEAT, 2);
        player.game.defer(new RemoveAnyPlants_1.RemoveAnyPlants(player, 2));
        return undefined;
    };
    return AerialLenses;
}(Card_1.Card));
exports.AerialLenses = AerialLenses;
