"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.UnitedNationsMarsInitiative = exports.ACTION_COST = void 0;
var Card_1 = require("../Card");
var Tags_1 = require("../Tags");
var CardName_1 = require("../../CardName");
var CardType_1 = require("../CardType");
var CardRenderer_1 = require("../render/CardRenderer");
var Resources_1 = require("../../common/Resources");
exports.ACTION_COST = 3;
var UnitedNationsMarsInitiative = (function (_super) {
    __extends(UnitedNationsMarsInitiative, _super);
    function UnitedNationsMarsInitiative() {
        return _super.call(this, {
            cardType: CardType_1.CardType.CORPORATION,
            name: CardName_1.CardName.UNITED_NATIONS_MARS_INITIATIVE,
            tags: [Tags_1.Tags.EARTH],
            startingMegaCredits: 40,
            metadata: {
                cardNumber: 'R32',
                description: 'You start with 40 M€.',
                renderData: CardRenderer_1.CardRenderer.builder(function (b) {
                    b.br.br.br;
                    b.empty().nbsp.nbsp.nbsp.nbsp.megacredits(40);
                    b.corpBox('action', function (ce) {
                        ce.action('If your Terraform Rating was raised this generation, you may pay 3 M€ to raise it 1 step more.', function (eb) {
                            eb.megacredits(3).startAction.tr(1).asterix();
                        });
                    });
                }),
            },
        }) || this;
    }
    UnitedNationsMarsInitiative.prototype.play = function () {
        return undefined;
    };
    UnitedNationsMarsInitiative.prototype.canAct = function (player) {
        return player.hasIncreasedTerraformRatingThisGeneration && player.canAfford(exports.ACTION_COST, { tr: { tr: 1 } });
    };
    UnitedNationsMarsInitiative.prototype.action = function (player) {
        player.deductResource(Resources_1.Resources.MEGACREDITS, 3);
        player.increaseTerraformRating();
        return undefined;
    };
    return UnitedNationsMarsInitiative;
}(Card_1.Card));
exports.UnitedNationsMarsInitiative = UnitedNationsMarsInitiative;
