"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.HuygensObservatory = void 0;
var Tags_1 = require("../Tags");
var CardType_1 = require("../CardType");
var CardName_1 = require("../../CardName");
var BuildColony_1 = require("../../deferredActions/BuildColony");
var OrOptions_1 = require("../../inputs/OrOptions");
var Card_1 = require("../Card");
var CardRenderer_1 = require("../render/CardRenderer");
var SelectOption_1 = require("../../inputs/SelectOption");
var SelectColony_1 = require("../../inputs/SelectColony");
var DeferredAction_1 = require("../../deferredActions/DeferredAction");
var HuygensObservatory = (function (_super) {
    __extends(HuygensObservatory, _super);
    function HuygensObservatory() {
        return _super.call(this, {
            cost: 27,
            tags: [Tags_1.Tags.SCIENCE, Tags_1.Tags.SPACE],
            name: CardName_1.CardName.HUYGENS_OBSERVATORY,
            cardType: CardType_1.CardType.AUTOMATED,
            victoryPoints: 1,
            tr: { tr: 1 },
            metadata: {
                cardNumber: 'Pf61',
                renderData: CardRenderer_1.CardRenderer.builder(function (b) { return b.colonies(1).asterix().trade().asterix().tr(1); }),
                description: 'Place a colony. MAY BE PLACED ON A COLONY TILE WHERE YOU ALREADY HAVE A COLONY. ' +
                    'Trade for free. You may use a Trade Fleet that you used this generation already, but you may not ' +
                    'trade with the tile that fleet came from. Gain 1 TR.',
            },
        }) || this;
    }
    HuygensObservatory.prototype.trade = function (player, colonies) {
        return new SelectColony_1.SelectColony('Select colony tile to trade with for free', 'Select', colonies, function (colony) {
            colony.trade(player);
            return undefined;
        });
    };
    HuygensObservatory.prototype.tradeableColonies = function (player) {
        return player.game.colonies.filter(function (colony) { return colony.isActive && colony.visitor === undefined; });
    };
    HuygensObservatory.prototype.tryToTrade = function (player) {
        var _this = this;
        var game = player.game;
        var tradeableColonies = this.tradeableColonies(player);
        if (tradeableColonies.length === 0) {
            game.log('${0} cannot trade with ${1} because there is no colony they may visit.', function (b) { return b.player(player).card(_this); });
            return;
        }
        var orOptions = new OrOptions_1.OrOptions();
        orOptions.title = 'Select a trade fleet';
        var visitedColonies = game.colonies.filter(function (colony) { return colony.visitor === player.id; });
        var hasFreeTradeFleet = visitedColonies.length < player.getFleetSize();
        var tradeInput = this.trade(player, tradeableColonies);
        if (visitedColonies.length > 0) {
            orOptions.options.push(new SelectColony_1.SelectColony('Select a colony tile to recall a trade fleet from', 'OK', visitedColonies, function (colony) {
                game.log('${0} is reusing a trade fleet from ${1}', function (b) { return b.player(player).colony(colony); });
                colony.visitor = undefined;
                player.tradesThisGeneration--;
                game.defer(new DeferredAction_1.DeferredAction(player, function () { return tradeInput; }));
                return undefined;
            }));
        }
        if (hasFreeTradeFleet) {
            if (orOptions.options.length === 1) {
                orOptions.options.push(new SelectOption_1.SelectOption('Use an available trade fleet', 'OK', function () {
                    game.defer(new DeferredAction_1.DeferredAction(player, function () { return tradeInput; }));
                    return undefined;
                }));
            }
            else {
                game.defer(new DeferredAction_1.DeferredAction(player, function () { return tradeInput; }));
            }
        }
        if (orOptions.options.length === 1) {
            game.defer(new DeferredAction_1.DeferredAction(player, function () { return orOptions.options[0]; }));
        }
        if (orOptions.options.length > 1) {
            game.defer(new DeferredAction_1.DeferredAction(player, function () { return orOptions; }));
        }
    };
    HuygensObservatory.prototype.canPlay = function (player) {
        return player.hasAvailableColonyTileToBuildOn(true) || this.tradeableColonies(player).length > 0;
    };
    HuygensObservatory.prototype.play = function (player) {
        var _this = this;
        player.increaseTerraformRating();
        var game = player.game;
        if (player.hasAvailableColonyTileToBuildOn(true)) {
            game.defer(new BuildColony_1.BuildColony(player, true, 'Select colony for Huygens Observatory', undefined, {
                cb: function () { return _this.tryToTrade(player); },
            }));
        }
        else {
            game.defer(new DeferredAction_1.DeferredAction(player, function () {
                _this.tryToTrade(player);
                return undefined;
            }));
        }
        return undefined;
    };
    return HuygensObservatory;
}(Card_1.Card));
exports.HuygensObservatory = HuygensObservatory;
