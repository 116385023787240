"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.MoonColonyStandardProject = void 0;
var CardName_1 = require("../../CardName");
var CardRenderer_1 = require("../render/CardRenderer");
var StandardProjectCard_1 = require("../StandardProjectCard");
var MoonExpansion_1 = require("../../moon/MoonExpansion");
var PlaceMoonColonyTile_1 = require("../../moon/PlaceMoonColonyTile");
var Units_1 = require("../../Units");
var Resources_1 = require("../../common/Resources");
var TileType_1 = require("../../common/TileType");
var CardRenderItem_1 = require("../render/CardRenderItem");
var MoonColonyStandardProject = (function (_super) {
    __extends(MoonColonyStandardProject, _super);
    function MoonColonyStandardProject(properties) {
        if (properties === void 0) { properties = {
            name: CardName_1.CardName.MOON_COLONY_STANDARD_PROJECT,
            cost: 22,
            reserveUnits: Units_1.Units.of({ titanium: 1 }),
            tr: { moonColony: 1 },
            metadata: {
                cardNumber: '',
                renderData: CardRenderer_1.CardRenderer.builder(function (b) {
                    return b.standardProject('Spend 22 M€ and 1 titanium to place a colony on the moon and raise your M€ production 1 step.', function (eb) {
                        eb.megacredits(22).titanium(1).startAction.moonColony({ secondaryTag: CardRenderItem_1.AltSecondaryTag.MOON_COLONY_RATE }).production(function (pb) { return pb.megacredits(1); });
                    });
                }),
            },
        }; }
        var _this = _super.call(this, properties) || this;
        _this.tilesBuilt = [TileType_1.TileType.MOON_COLONY];
        return _this;
    }
    MoonColonyStandardProject.prototype.discount = function (player) {
        if (player.playedCards.find(function (card) { return card.name === CardName_1.CardName.MOONCRATE_BLOCK_FACTORY; })) {
            return 4;
        }
        return _super.prototype.discount.call(this, player);
    };
    MoonColonyStandardProject.prototype.canAct = function (player) {
        var moonData = MoonExpansion_1.MoonExpansion.moonData(player.game);
        var spaces = moonData.moon.getAvailableSpacesOnLand(player);
        if (spaces.length === 0) {
            return false;
        }
        return _super.prototype.canAct.call(this, player);
    };
    MoonColonyStandardProject.prototype.actionEssence = function (player) {
        var adjustedReserveUnits = MoonExpansion_1.MoonExpansion.adjustedReserveCosts(player, this);
        player.deductUnits(adjustedReserveUnits);
        player.game.defer(new PlaceMoonColonyTile_1.PlaceMoonColonyTile(player));
        player.addProduction(Resources_1.Resources.MEGACREDITS, 1, { log: true });
    };
    return MoonColonyStandardProject;
}(StandardProjectCard_1.StandardProjectCard));
exports.MoonColonyStandardProject = MoonColonyStandardProject;
