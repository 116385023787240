"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.NuclearZoneAres = void 0;
var TileType_1 = require("../../common/TileType");
var CardName_1 = require("../../CardName");
var NuclearZone_1 = require("../base/NuclearZone");
var CardRenderer_1 = require("../render/CardRenderer");
var NuclearZoneAres = (function (_super) {
    __extends(NuclearZoneAres, _super);
    function NuclearZoneAres() {
        return _super.call(this, CardName_1.CardName.NUCLEAR_ZONE_ARES, 11, { bonus: [], cost: 2 }, {
            cardNumber: 'A19',
            renderData: CardRenderer_1.CardRenderer.builder(function (b) {
                b.tile(TileType_1.TileType.NUCLEAR_ZONE, false, true).br;
                b.temperature(2);
            }),
            description: 'Raise the temperature two steps. Place this tile. Players must pay an additional 2M€ when they place a tile with their player marker on it ADJACENT to the Nuclear Zone.',
        }) || this;
    }
    return NuclearZoneAres;
}(NuclearZone_1.NuclearZone));
exports.NuclearZoneAres = NuclearZoneAres;
