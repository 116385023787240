"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.DeimosDownPromo = void 0;
var Tags_1 = require("../Tags");
var Card_1 = require("../Card");
var CardType_1 = require("../CardType");
var CardName_1 = require("../../CardName");
var SelectSpace_1 = require("../../inputs/SelectSpace");
var TileType_1 = require("../../common/TileType");
var RemoveAnyPlants_1 = require("../../deferredActions/RemoveAnyPlants");
var CardRenderer_1 = require("../render/CardRenderer");
var Options_1 = require("../Options");
var DeimosDownPromo = (function (_super) {
    __extends(DeimosDownPromo, _super);
    function DeimosDownPromo() {
        return _super.call(this, {
            cardType: CardType_1.CardType.EVENT,
            name: CardName_1.CardName.DEIMOS_DOWN_PROMO,
            tags: [Tags_1.Tags.SPACE],
            cost: 31,
            tr: { temperature: 3 },
            metadata: {
                cardNumber: 'X31',
                description: 'Raise temperature 3 steps and gain 4 steel. Place this tile ADJACENT TO no other city tile. Remove up to 6 Plants from any player.',
                renderData: CardRenderer_1.CardRenderer.builder(function (b) {
                    b.temperature(3).br;
                    b.tile(TileType_1.TileType.DEIMOS_DOWN, true).asterix().br;
                    b.steel(4, { digit: Options_1.digit }).nbsp.minus().plants(-6, { all: Options_1.all });
                }),
            },
        }) || this;
    }
    DeimosDownPromo.prototype.canPlay = function (player) {
        return player.game.board.getAvailableSpacesForCity(player).length > 0;
    };
    DeimosDownPromo.prototype.play = function (player) {
        player.game.increaseTemperature(player, 3);
        player.game.defer(new RemoveAnyPlants_1.RemoveAnyPlants(player, 6));
        player.steel += 4;
        var availableSpaces = player.game.board.getAvailableSpacesForCity(player);
        return new SelectSpace_1.SelectSpace('Select space for tile', availableSpaces, function (foundSpace) {
            player.game.addTile(player, foundSpace.spaceType, foundSpace, { tileType: TileType_1.TileType.DEIMOS_DOWN });
            return undefined;
        });
    };
    return DeimosDownPromo;
}(Card_1.Card));
exports.DeimosDownPromo = DeimosDownPromo;
