"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var vue_1 = require("vue");
var CardName_1 = require("@/CardName");
var Resources_1 = require("@/common/Resources");
exports.default = vue_1.default.extend({
    name: 'CardExtraContent',
    props: {
        card: {
            type: Object,
            required: true,
        },
    },
    methods: {
        isMiningTileOnSteel: function () {
            var _a;
            return (_a = this.card.bonusResource) === null || _a === void 0 ? void 0 : _a.includes(Resources_1.Resources.STEEL);
        },
        isMiningTileOnTitanium: function () {
            var _a;
            return (_a = this.card.bonusResource) === null || _a === void 0 ? void 0 : _a.includes(Resources_1.Resources.TITANIUM);
        },
        lifeFound: function () {
            return this.card.name === CardName_1.CardName.SEARCH_FOR_LIFE && this.card.resources !== undefined && this.card.resources > 0;
        },
    },
});
