"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var vue_1 = require("vue");
var Button_vue_1 = require("@/client/components/common/Button.vue");
var ClientCardManifest_1 = require("@/client/cards/ClientCardManifest");
var CardName_1 = require("@/CardName");
var constants = require("@/constants");
var SelectCard_vue_1 = require("@/client/components/SelectCard.vue");
var ConfirmDialog_vue_1 = require("@/client/components/common/ConfirmDialog.vue");
var PreferencesManager_1 = require("@/client/utils/PreferencesManager");
var Tags_1 = require("@/cards/Tags");
exports.default = vue_1.default.extend({
    name: 'SelectInitialCards',
    props: {
        playerView: {
            type: Object,
        },
        playerinput: {
            type: Object,
        },
        onsave: {
            type: Function,
        },
        showsave: {
            type: Boolean,
        },
        showtitle: {
            type: Boolean,
        },
    },
    components: {
        Button: Button_vue_1.default,
        SelectCard: SelectCard_vue_1.default,
        'confirm-dialog': ConfirmDialog_vue_1.default,
    },
    data: function () {
        return {
            selectedCards: [],
            selectedCorporation: undefined,
            selectedPreludes: [],
        };
    },
    methods: {
        noop: function () {
            throw new Error('should not be called');
        },
        getAfterPreludes: function () {
            var _a, _b;
            var result = 0;
            for (var _i = 0, _c = this.selectedPreludes; _i < _c.length; _i++) {
                var prelude = _c[_i];
                var cam = (0, ClientCardManifest_1.getCard)(prelude);
                if (cam === undefined) {
                    throw new Error("Prelude " + prelude + " not found");
                }
                var card = cam.card;
                result += (_a = card.startingMegaCredits) !== null && _a !== void 0 ? _a : 0;
                switch ((_b = this.selectedCorporation) === null || _b === void 0 ? void 0 : _b.name) {
                    case CardName_1.CardName.MANUTECH:
                        var productionBox = card === null || card === void 0 ? void 0 : card.productionBox;
                        result += productionBox.megacredits;
                        break;
                    case CardName_1.CardName.THARSIS_REPUBLIC:
                        switch (prelude) {
                            case CardName_1.CardName.SELF_SUFFICIENT_SETTLEMENT:
                            case CardName_1.CardName.EARLY_SETTLEMENT:
                            case CardName_1.CardName.STRATEGIC_BASE_PLANNING:
                                result += 3;
                                break;
                        }
                        break;
                    case CardName_1.CardName.PHARMACY_UNION:
                        var tags = card.tags.filter(function (tag) { return tag === Tags_1.Tags.MICROBE; }).length;
                        result -= (4 * tags);
                        break;
                    case CardName_1.CardName.SPLICE:
                        var microbeTags = card.tags.filter(function (tag) { return tag === Tags_1.Tags.MICROBE; }).length;
                        result += (2 * microbeTags);
                        break;
                    case CardName_1.CardName.APHRODITE:
                        switch (prelude) {
                            case CardName_1.CardName.VENUS_FIRST:
                            case CardName_1.CardName.VENUS_FIRST_PATHFINDERS:
                                result += 4;
                                break;
                            case CardName_1.CardName.HYDROGEN_BOMBARDMENT:
                                result += 2;
                                break;
                        }
                    case CardName_1.CardName.LUNA_FIRST_INCORPORATED:
                        switch (prelude) {
                            case CardName_1.CardName.FIRST_LUNAR_SETTLEMENT:
                            case CardName_1.CardName.CORE_MINE:
                            case CardName_1.CardName.BASIC_INFRASTRUCTURE:
                                result += 1;
                                break;
                            case CardName_1.CardName.MINING_COMPLEX:
                                result += 2;
                                break;
                        }
                        break;
                }
            }
            return result;
        },
        getOption: function (idx) {
            if (this.playerinput.options === undefined || this.playerinput.options[idx] === undefined) {
                throw new Error('invalid input, missing option');
            }
            return this.playerinput.options[idx];
        },
        getStartingMegacredits: function () {
            if (this.selectedCorporation === undefined) {
                return NaN;
            }
            var starting = this.selectedCorporation.startingMegaCredits;
            var cardCost = this.selectedCorporation.cardCost === undefined ? constants.CARD_COST : this.selectedCorporation.cardCost;
            starting -= this.selectedCards.length * cardCost;
            return starting;
        },
        saveIfConfirmed: function () {
            if (PreferencesManager_1.PreferencesManager.load('show_alerts') === '1' && this.selectedCards.length === 0) {
                this.$refs['confirmation'].show();
            }
            else {
                this.saveData();
            }
        },
        saveData: function () {
            var result = [];
            result.push([]);
            if (this.selectedCorporation !== undefined) {
                result[0].push(this.selectedCorporation.name);
            }
            if (this.hasPrelude()) {
                result.push(this.selectedPreludes);
            }
            result.push(this.selectedCards);
            this.onsave(result);
        },
        hasPrelude: function () {
            return this.playerinput.options !== undefined && this.playerinput.options.length === 3;
        },
        cardsChanged: function (cards) {
            this.selectedCards = cards;
        },
        corporationChanged: function (cards) {
            var _a;
            this.selectedCorporation = (_a = (0, ClientCardManifest_1.getCard)(cards[0])) === null || _a === void 0 ? void 0 : _a.card;
        },
        preludesChanged: function (cards) {
            this.selectedPreludes = cards;
        },
        confirmSelection: function () {
            this.saveData();
        },
    },
});
