"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PlayerInput = void 0;
var PlayerInput;
(function (PlayerInput) {
    function getCard(cards, cardName) {
        var idx = cards.findIndex(function (card) { return card.name === cardName; });
        if (idx === -1) {
            throw new Error("Card " + cardName + " not found");
        }
        var card = cards[idx];
        return { card: card, idx: idx };
    }
    PlayerInput.getCard = getCard;
})(PlayerInput = exports.PlayerInput || (exports.PlayerInput = {}));
