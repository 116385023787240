"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var vue_1 = require("vue");
var Button_vue_1 = require("@/client/components/common/Button.vue");
var utils_1 = require("@/utils/utils");
var GameSetupDetail_vue_1 = require("@/client/components/GameSetupDetail.vue");
function copyToClipboard(text) {
    var input = document.createElement('input');
    input.setAttribute('value', text);
    document.body.appendChild(input);
    input.select();
    document.execCommand('copy');
    document.body.removeChild(input);
}
var DEFAULT_COPIED_PLAYER_ID = '-1';
exports.default = vue_1.default.extend({
    name: 'game-home',
    props: {
        game: {
            type: Object,
        },
    },
    components: {
        Button: Button_vue_1.default,
        'game-setup-detail': GameSetupDetail_vue_1.default,
    },
    data: function () {
        return {
            urlCopiedPlayerId: DEFAULT_COPIED_PLAYER_ID,
        };
    },
    methods: {
        getGameId: function () {
            return this.game !== undefined ? this.game.id.toString() : 'n/a';
        },
        getTurnOrder: function (index) {
            if (index === 0) {
                return '1st';
            }
            else if (index === 1) {
                return '2nd';
            }
            else if (index === 2) {
                return '3rd';
            }
            else if (index > 2) {
                return index + 1 + "th";
            }
            else {
                return 'n/a';
            }
        },
        setCopiedIdToDefault: function () {
            this.urlCopiedPlayerId = DEFAULT_COPIED_PLAYER_ID;
        },
        getPlayerCubeColorClass: function (color) {
            return (0, utils_1.playerColorClass)(color.toLowerCase(), 'bg');
        },
        getHref: function (playerId) {
            if (playerId === this.game.spectatorId) {
                return "/spectator?id=" + playerId;
            }
            return "/player?id=" + playerId;
        },
        copyUrl: function (playerId) {
            copyToClipboard(window.location.origin + this.getHref(playerId));
            this.urlCopiedPlayerId = playerId;
        },
        isPlayerUrlCopied: function (playerId) {
            return playerId === this.urlCopiedPlayerId;
        },
    },
});
