"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PathfindersExpansion = void 0;
var AddResourcesToCard_1 = require("../deferredActions/AddResourcesToCard");
var GrantResourceDeferred_1 = require("./GrantResourceDeferred");
var IPathfindersData_1 = require("./IPathfindersData");
var PlaceCityTile_1 = require("../deferredActions/PlaceCityTile");
var PlaceGreeneryTile_1 = require("../deferredActions/PlaceGreeneryTile");
var PlaceMoonMineTile_1 = require("../moon/PlaceMoonMineTile");
var PlaceMoonRoadTile_1 = require("../moon/PlaceMoonRoadTile");
var PlaceOceanTile_1 = require("../deferredActions/PlaceOceanTile");
var PlanetaryTracks_1 = require("./PlanetaryTracks");
var Player_1 = require("../Player");
var Resources_1 = require("../common/Resources");
var ResourceType_1 = require("../common/ResourceType");
var SelectResourcesDeferred_1 = require("../deferredActions/SelectResourcesDeferred");
var SendDelegateToArea_1 = require("../deferredActions/SendDelegateToArea");
var Tags_1 = require("../cards/Tags");
var Turmoil_1 = require("../turmoil/Turmoil");
var VALID_TAGS = [Tags_1.Tags.VENUS, Tags_1.Tags.EARTH, Tags_1.Tags.MARS, Tags_1.Tags.JOVIAN, Tags_1.Tags.MOON];
var TRACKS = PlanetaryTracks_1.PlanetaryTracks.initialize();
var PathfindersExpansion = (function () {
    function PathfindersExpansion() {
    }
    PathfindersExpansion.initialize = function (gameOptions) {
        return {
            venus: gameOptions.venusNextExtension ? 0 : -1,
            earth: 0,
            mars: 0,
            jovian: 0,
            moon: gameOptions.moonExpansion ? 0 : -1,
            vps: [],
        };
    };
    PathfindersExpansion.onCardPlayed = function (player, card) {
        if (player.game.gameOptions.pathfindersExpansion === false) {
            return;
        }
        var tags = card.tags;
        tags.forEach(function (tag) {
            if (VALID_TAGS.includes(tag)) {
                PathfindersExpansion.raiseTrack(tag, player);
            }
        });
    };
    PathfindersExpansion.raiseTrack = function (tag, player, steps) {
        if (steps === void 0) { steps = 1; }
        PathfindersExpansion.raiseTrackEssense(tag, player, player.game, steps, true);
    };
    PathfindersExpansion.raiseTrackForGlobalEvent = function (tag, name, game, steps, gainRewards) {
        if (steps === void 0) { steps = 1; }
        if (gainRewards === void 0) { gainRewards = true; }
        PathfindersExpansion.raiseTrackEssense(tag, name, game, steps, gainRewards);
    };
    PathfindersExpansion.raiseTrackEssense = function (tag, from, game, steps, gainRewards) {
        if (steps === void 0) { steps = 1; }
        if (gainRewards === void 0) { gainRewards = true; }
        var data = game.pathfindersData;
        if (data === undefined) {
            return;
        }
        var track = PathfindersExpansion.trackMap.get(tag);
        if (track === undefined) {
            return;
        }
        var space = IPathfindersData_1.IPathfindersData.getValue(data, tag);
        if (space === -1) {
            return;
        }
        var lastSpace = Math.min(track.spaces.length - 1, space + steps);
        var distance = lastSpace - space;
        if (distance === 0)
            return;
        if (from instanceof Player_1.Player) {
            game.log('${0} raised the ${1} planetary track ${2} ${3}', function (b) {
                b.player(from).string(tag).number(distance).string(distance > 1 ? 'steps' : 'step');
            });
        }
        else {
            game.log('Global Event ${0} raised the ${1} planetary track ${2} ${3}', function (b) {
                b.globalEventName(from).string(tag).number(distance).string(distance > 1 ? 'steps' : 'step');
            });
        }
        var _loop_1 = function () {
            space++;
            IPathfindersData_1.IPathfindersData.setValue(data, tag, space);
            var rewards = track.spaces[space];
            if (gainRewards) {
                if (from instanceof Player_1.Player) {
                    rewards.risingPlayer.forEach(function (reward) {
                        PathfindersExpansion.grant(reward, from, tag);
                    });
                }
                rewards.everyone.forEach(function (reward) {
                    game.getPlayers().forEach(function (p) {
                        PathfindersExpansion.grant(reward, p, tag);
                    });
                });
                if (rewards.mostTags.length > 0) {
                    var players_1 = PathfindersExpansion.playersWithMostTags(tag, game.getPlayers(), (from instanceof Player_1.Player) ? from : undefined);
                    rewards.mostTags.forEach(function (reward) {
                        players_1.forEach(function (p) {
                            PathfindersExpansion.grant(reward, p, tag);
                        });
                    });
                }
            }
        };
        while (space < lastSpace) {
            _loop_1();
        }
    };
    PathfindersExpansion.grant = function (reward, player, tag) {
        var _a, _b;
        var game = player.game;
        switch (reward) {
            case '1vp':
                (_a = game.pathfindersData) === null || _a === void 0 ? void 0 : _a.vps.push({ id: player.id, tag: tag, points: 1 });
                game.log('${0} has the most ${1} tags and earns 1VP', function (b) { return b.player(player).string(tag); });
                break;
            case '2vp':
                (_b = game.pathfindersData) === null || _b === void 0 ? void 0 : _b.vps.push({ id: player.id, tag: tag, points: 2 });
                game.log('${0} has the most ${1} tags and earns 2VP', function (b) { return b.player(player).string(tag); });
                break;
            case '3mc':
                player.addResource(Resources_1.Resources.MEGACREDITS, 3, { log: true });
                break;
            case '6mc':
                player.addResource(Resources_1.Resources.MEGACREDITS, 6, { log: true });
                break;
            case 'any_resource':
                game.defer(new GrantResourceDeferred_1.GrantResourceDeferred(player, false));
                break;
            case 'card':
                player.drawCard();
                break;
            case 'city':
                game.defer(new PlaceCityTile_1.PlaceCityTile(player));
                break;
            case 'delegate':
                Turmoil_1.Turmoil.ifTurmoilElse(game, function () {
                    game.defer(new SendDelegateToArea_1.SendDelegateToArea(player, 'Select where to send a delegate', { source: 'reserve' }));
                }, function () {
                    player.game.log('TODO: come up with some reward in place of Add Delegate.');
                });
                break;
            case 'energy':
                player.addResource(Resources_1.Resources.ENERGY, 1, { log: true });
                break;
            case 'energy_production':
                player.addProduction(Resources_1.Resources.ENERGY, 1, { log: true });
                break;
            case 'floater':
                game.defer(new AddResourcesToCard_1.AddResourcesToCard(player, ResourceType_1.ResourceType.FLOATER));
                break;
            case 'greenery':
                game.defer(new PlaceGreeneryTile_1.PlaceGreeneryTile(player));
                break;
            case 'heat':
                player.addResource(Resources_1.Resources.HEAT, 1, { log: true });
                break;
            case 'heat_production':
                player.addProduction(Resources_1.Resources.HEAT, 1, { log: true });
                break;
            case 'moon_mine':
                game.defer(new PlaceMoonMineTile_1.PlaceMoonMineTile(player));
                break;
            case 'moon_road':
                game.defer(new PlaceMoonRoadTile_1.PlaceMoonRoadTile(player));
                break;
            case 'ocean':
                game.defer(new PlaceOceanTile_1.PlaceOceanTile(player));
                break;
            case 'plant':
                player.addResource(Resources_1.Resources.PLANTS, 1, { log: true });
                break;
            case 'plant_production':
                player.addProduction(Resources_1.Resources.PLANTS, 1, { log: true });
                break;
            case 'resource':
                game.defer(new SelectResourcesDeferred_1.SelectResourcesDeferred(player, 1, 'Gain 1 resource for your Planetary track bonus.'));
                break;
            case 'steel':
                player.addResource(Resources_1.Resources.STEEL, 1, { log: true });
                break;
            case 'steel_production':
                player.addProduction(Resources_1.Resources.STEEL, 1, { log: true });
                break;
            case 'titanium':
                player.addResource(Resources_1.Resources.TITANIUM, 1, { log: true });
                break;
            case 'titanium_production':
                player.addProduction(Resources_1.Resources.TITANIUM, 1, { log: true });
                break;
            case 'tr':
                player.increaseTerraformRating();
                break;
            case 'venus_scale':
                if (game.gameOptions.venusNextExtension) {
                    game.increaseVenusScaleLevel(player, 1);
                }
                else {
                    player.game.log('TODO: come up with some reward in place of Increase Venus Scale.');
                }
                break;
            default:
                throw new Error('Unknown reward: ' + reward);
        }
    };
    PathfindersExpansion.playersWithMostTags = function (tag, players, activePlayer) {
        var counts = players.map(function (player) {
            var includeWildTags = player.id === (activePlayer === null || activePlayer === void 0 ? void 0 : activePlayer.id);
            var count = player.getTagCount(tag, includeWildTags ? 'default' : 'raw');
            return { player: player, count: count };
        });
        var max = Math.max.apply(Math, counts.map(function (c) { return c.count; }));
        var filtered = counts.filter(function (c) { return c.count === max; });
        var result = filtered.map(function (c) { return c.player; });
        return result;
    };
    PathfindersExpansion.calculateVictoryPoints = function (player, victoryPointsBreakdown) {
        var data = player.game.pathfindersData;
        if (data === undefined) {
            return;
        }
        data.vps
            .filter(function (vp) { return vp.id === player.id; })
            .forEach(function (vp) { return victoryPointsBreakdown.setVictoryPoints('planetary tracks', vp.points, vp.tag); });
    };
    PathfindersExpansion.trackMap = new Map([
        [Tags_1.Tags.VENUS, TRACKS.venus],
        [Tags_1.Tags.EARTH, TRACKS.earth],
        [Tags_1.Tags.MARS, TRACKS.mars],
        [Tags_1.Tags.JOVIAN, TRACKS.jovian],
        [Tags_1.Tags.MOON, TRACKS.moon],
    ]);
    return PathfindersExpansion;
}());
exports.PathfindersExpansion = PathfindersExpansion;
