"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.SpacePortColony = void 0;
var Tags_1 = require("../Tags");
var CardType_1 = require("../CardType");
var CardName_1 = require("../../CardName");
var BuildColony_1 = require("../../deferredActions/BuildColony");
var CardRenderer_1 = require("../render/CardRenderer");
var CardRequirements_1 = require("../CardRequirements");
var Card_1 = require("../Card");
var CardRenderDynamicVictoryPoints_1 = require("../render/CardRenderDynamicVictoryPoints");
var SpacePortColony = (function (_super) {
    __extends(SpacePortColony, _super);
    function SpacePortColony() {
        return _super.call(this, {
            cost: 27,
            tags: [Tags_1.Tags.SPACE],
            name: CardName_1.CardName.SPACE_PORT_COLONY,
            cardType: CardType_1.CardType.AUTOMATED,
            requirements: CardRequirements_1.CardRequirements.builder(function (b) { return b.colonies(); }),
            victoryPoints: 'special',
            metadata: {
                cardNumber: 'C39',
                renderData: CardRenderer_1.CardRenderer.builder(function (b) {
                    b.colonies(1).asterix().nbsp.tradeFleet().br;
                    b.vpText('1VP per 2 colonies in play.');
                }),
                description: 'Requires a colony. Place a colony. MAY BE PLACED ON A COLONY TILE WHERE YOU ALREADY HAVE A COLONY. Gain 1 Trade Fleet.',
                victoryPoints: CardRenderDynamicVictoryPoints_1.CardRenderDynamicVictoryPoints.colonies(1, 2, true),
            },
        }) || this;
    }
    SpacePortColony.prototype.play = function (player) {
        player.game.defer(new BuildColony_1.BuildColony(player, true, 'Select colony for Space Port Colony'));
        player.increaseFleetSize();
        return undefined;
    };
    SpacePortColony.prototype.getVictoryPoints = function (player) {
        var coloniesCount = 0;
        player.game.colonies.forEach(function (colony) {
            coloniesCount += colony.colonies.length;
        });
        return Math.floor(coloniesCount / 2);
    };
    return SpacePortColony;
}(Card_1.Card));
exports.SpacePortColony = SpacePortColony;
