"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.TitanShuttles = void 0;
var Tags_1 = require("../Tags");
var CardType_1 = require("../CardType");
var CardName_1 = require("../../CardName");
var ResourceType_1 = require("../../common/ResourceType");
var SelectOption_1 = require("../../inputs/SelectOption");
var OrOptions_1 = require("../../inputs/OrOptions");
var SelectAmount_1 = require("../../inputs/SelectAmount");
var AddResourcesToCard_1 = require("../../deferredActions/AddResourcesToCard");
var CardRenderer_1 = require("../render/CardRenderer");
var Card_1 = require("../Card");
var TitanShuttles = (function (_super) {
    __extends(TitanShuttles, _super);
    function TitanShuttles() {
        var _this = _super.call(this, {
            cost: 23,
            tags: [Tags_1.Tags.JOVIAN, Tags_1.Tags.SPACE],
            name: CardName_1.CardName.TITAN_SHUTTLES,
            cardType: CardType_1.CardType.ACTIVE,
            resourceType: ResourceType_1.ResourceType.FLOATER,
            victoryPoints: 1,
            metadata: {
                cardNumber: 'C45',
                renderData: CardRenderer_1.CardRenderer.builder(function (b) {
                    b.action('Add 2 floaters to ANY JOVIAN CARD.', function (eb) {
                        eb.empty().startAction.floaters(2, { secondaryTag: Tags_1.Tags.JOVIAN });
                    }).br;
                    b.or().br;
                    b.action('Spend any number of floaters here to gain the same number of titanium.', function (eb) {
                        eb.text('x').floaters(1).startAction.text('x').titanium(1);
                    }).br;
                }),
            },
        }) || this;
        _this.resourceCount = 0;
        return _this;
    }
    TitanShuttles.prototype.canAct = function () {
        return true;
    };
    TitanShuttles.prototype.action = function (player) {
        var _this = this;
        if (this.resourceCount === 0) {
            player.game.defer(new AddResourcesToCard_1.AddResourcesToCard(player, ResourceType_1.ResourceType.FLOATER, { count: 2, restrictedTag: Tags_1.Tags.JOVIAN, title: 'Add 2 floaters to a Jovian card' }));
            return undefined;
        }
        return new OrOptions_1.OrOptions(new SelectOption_1.SelectOption('Add 2 floaters to a Jovian card', 'Add floaters', function () {
            player.game.defer(new AddResourcesToCard_1.AddResourcesToCard(player, ResourceType_1.ResourceType.FLOATER, { count: 2, restrictedTag: Tags_1.Tags.JOVIAN }));
            return undefined;
        }), new SelectAmount_1.SelectAmount('Remove X floaters on this card to gain X titanium', 'Remove floaters', function (amount) {
            player.removeResourceFrom(_this, amount);
            player.titanium += amount;
            player.game.log('${0} removed ${1} floaters to gain ${2} titanium', function (b) { return b.player(player).number(amount).number(amount); });
            return undefined;
        }, 1, this.resourceCount, true));
    };
    TitanShuttles.prototype.play = function () {
        return undefined;
    };
    return TitanShuttles;
}(Card_1.Card));
exports.TitanShuttles = TitanShuttles;
