"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DeferredAction = exports.Priority = void 0;
var Priority;
(function (Priority) {
    Priority[Priority["DECLARE_CLONE_TAG"] = 0] = "DECLARE_CLONE_TAG";
    Priority[Priority["COST"] = 1] = "COST";
    Priority[Priority["OPPONENT_TRIGGER"] = 2] = "OPPONENT_TRIGGER";
    Priority[Priority["DISCARD_BEFORE_DRAW"] = 3] = "DISCARD_BEFORE_DRAW";
    Priority[Priority["DRAW_CARDS"] = 4] = "DRAW_CARDS";
    Priority[Priority["BUILD_COLONY"] = 5] = "BUILD_COLONY";
    Priority[Priority["INCREASE_COLONY_TRACK"] = 6] = "INCREASE_COLONY_TRACK";
    Priority[Priority["PLACE_OCEAN_TILE"] = 7] = "PLACE_OCEAN_TILE";
    Priority[Priority["DEFAULT"] = 8] = "DEFAULT";
    Priority[Priority["ATTACK_OPPONENT"] = 9] = "ATTACK_OPPONENT";
    Priority[Priority["LOSE_AS_MUCH_AS_POSSIBLE"] = 10] = "LOSE_AS_MUCH_AS_POSSIBLE";
    Priority[Priority["GAIN_RESOURCE_OR_PRODUCTION"] = 11] = "GAIN_RESOURCE_OR_PRODUCTION";
    Priority[Priority["LOSE_RESOURCE_OR_PRODUCTION"] = 12] = "LOSE_RESOURCE_OR_PRODUCTION";
    Priority[Priority["DECREASE_COLONY_TRACK_AFTER_TRADE"] = 13] = "DECREASE_COLONY_TRACK_AFTER_TRADE";
    Priority[Priority["DISCARD_CARDS"] = 14] = "DISCARD_CARDS";
})(Priority = exports.Priority || (exports.Priority = {}));
var DeferredAction = (function () {
    function DeferredAction(player, execute) {
        this.player = player;
        this.execute = execute;
        this.priority = Priority.DEFAULT;
    }
    return DeferredAction;
}());
exports.DeferredAction = DeferredAction;
