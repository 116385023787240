"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.MicrobiologyPatents = void 0;
var Card_1 = require("../Card");
var CardType_1 = require("../CardType");
var CardName_1 = require("../../CardName");
var CardRenderer_1 = require("../render/CardRenderer");
var Tags_1 = require("../Tags");
var Resources_1 = require("../../common/Resources");
var Options_1 = require("../Options");
var MicrobiologyPatents = (function (_super) {
    __extends(MicrobiologyPatents, _super);
    function MicrobiologyPatents() {
        return _super.call(this, {
            cardType: CardType_1.CardType.ACTIVE,
            name: CardName_1.CardName.MICROBIOLOGY_PATENTS,
            cost: 6,
            tags: [Tags_1.Tags.MARS, Tags_1.Tags.MICROBE],
            metadata: {
                cardNumber: 'Pf63',
                renderData: CardRenderer_1.CardRenderer.builder(function (b) {
                    b.effect('After you play a card with a microbe tag, increase your M€ production 1 step.', function (eb) { return eb.microbes(1, { played: Options_1.played }).startEffect.production(function (pb) { return pb.megacredits(1); }); });
                }),
            },
        }) || this;
    }
    MicrobiologyPatents.prototype.onCardPlayed = function (player, card) {
        if (card.tags.includes(Tags_1.Tags.MICROBE)) {
            player.addProduction(Resources_1.Resources.MEGACREDITS, 1, { log: true });
        }
    };
    MicrobiologyPatents.prototype.play = function () {
        return undefined;
    };
    return MicrobiologyPatents;
}(Card_1.Card));
exports.MicrobiologyPatents = MicrobiologyPatents;
