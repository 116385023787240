"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.GeologicalSurvey = void 0;
var CardName_1 = require("../../CardName");
var CardType_1 = require("../CardType");
var SpaceBonus_1 = require("../../SpaceBonus");
var Resources_1 = require("../../common/Resources");
var Tags_1 = require("../Tags");
var CardRequirements_1 = require("../CardRequirements");
var CardRenderer_1 = require("../render/CardRenderer");
var SurveyCard_1 = require("./SurveyCard");
var Options_1 = require("../Options");
var GeologicalSurvey = (function (_super) {
    __extends(GeologicalSurvey, _super);
    function GeologicalSurvey() {
        return _super.call(this, {
            cardType: CardType_1.CardType.ACTIVE,
            name: CardName_1.CardName.GEOLOGICAL_SURVEY,
            tags: [Tags_1.Tags.SCIENCE],
            cost: 8,
            requirements: CardRequirements_1.CardRequirements.builder(function (b) { return b.greeneries(5, { all: Options_1.all, max: Options_1.max }); }),
            metadata: {
                cardNumber: 'A09',
                renderData: CardRenderer_1.CardRenderer.builder(function (b) {
                    b.effect('When placing a tile ON MARS grants you any steel, titanium, or heat, you gain one additional of each of those resources that you gain.', function (eb) {
                        eb.emptyTile().startEffect;
                        eb.plus().steel(1).titanium(1).heat(1);
                    });
                }),
                description: 'Requires 5 or fewer greeneries on Mars.',
            },
        }) || this;
    }
    GeologicalSurvey.prototype.checkForBonuses = function (cardOwner, space) {
        _super.prototype.testForStandardResource.call(this, cardOwner, space, Resources_1.Resources.STEEL, SpaceBonus_1.SpaceBonus.STEEL);
        _super.prototype.testForStandardResource.call(this, cardOwner, space, Resources_1.Resources.TITANIUM, SpaceBonus_1.SpaceBonus.TITANIUM);
        _super.prototype.testForStandardResource.call(this, cardOwner, space, Resources_1.Resources.HEAT, SpaceBonus_1.SpaceBonus.HEAT);
    };
    return GeologicalSurvey;
}(SurveyCard_1.SurveyCard));
exports.GeologicalSurvey = GeologicalSurvey;
