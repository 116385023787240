"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.Titan = void 0;
var Colony_1 = require("./Colony");
var ColonyName_1 = require("./ColonyName");
var ColonyBenefit_1 = require("./ColonyBenefit");
var ResourceType_1 = require("../common/ResourceType");
var Titan = (function (_super) {
    __extends(Titan, _super);
    function Titan() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.name = ColonyName_1.ColonyName.TITAN;
        _this.description = 'Floaters';
        _this.isActive = false;
        _this.resourceType = ResourceType_1.ResourceType.FLOATER;
        _this.buildType = ColonyBenefit_1.ColonyBenefit.ADD_RESOURCES_TO_CARD;
        _this.buildQuantity = [3, 3, 3];
        _this.tradeType = ColonyBenefit_1.ColonyBenefit.ADD_RESOURCES_TO_CARD;
        _this.tradeQuantity = [0, 1, 1, 2, 3, 3, 4];
        _this.colonyBonusType = ColonyBenefit_1.ColonyBenefit.ADD_RESOURCES_TO_CARD;
        return _this;
    }
    return Titan;
}(Colony_1.Colony));
exports.Titan = Titan;
