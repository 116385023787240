"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.TurmoilHandler = void 0;
var GlobalParameter_1 = require("../GlobalParameter");
var SelectOption_1 = require("../inputs/SelectOption");
var Resources_1 = require("../common/Resources");
var Greens_1 = require("./parties/Greens");
var Kelvinists_1 = require("./parties/Kelvinists");
var MarsFirst_1 = require("./parties/MarsFirst");
var PartyHooks_1 = require("./parties/PartyHooks");
var PartyName_1 = require("./parties/PartyName");
var Reds_1 = require("./parties/Reds");
var Scientists_1 = require("./parties/Scientists");
var Unity_1 = require("./parties/Unity");
var constants = require("../constants");
var MoonExpansion_1 = require("../moon/MoonExpansion");
var TurmoilHandler = (function () {
    function TurmoilHandler() {
    }
    TurmoilHandler.addPlayerAction = function (player, options) {
        if (PartyHooks_1.PartyHooks.shouldApplyPolicy(player, PartyName_1.PartyName.SCIENTISTS)) {
            var scientistsPolicy_1 = Scientists_1.SCIENTISTS_POLICY_1;
            if (scientistsPolicy_1.canAct(player)) {
                options.push(new SelectOption_1.SelectOption(scientistsPolicy_1.description, 'Pay', function () { return scientistsPolicy_1.action(player); }));
            }
        }
        if (PartyHooks_1.PartyHooks.shouldApplyPolicy(player, PartyName_1.PartyName.KELVINISTS)) {
            var kelvinistsPolicy_1 = Kelvinists_1.KELVINISTS_POLICY_1;
            if (kelvinistsPolicy_1.canAct(player)) {
                options.push(new SelectOption_1.SelectOption(kelvinistsPolicy_1.description, 'Pay', function () { return kelvinistsPolicy_1.action(player); }));
            }
        }
        if (PartyHooks_1.PartyHooks.shouldApplyPolicy(player, PartyName_1.PartyName.KELVINISTS, 'kp03')) {
            var kelvinistsPolicy_2 = Kelvinists_1.KELVINISTS_POLICY_3;
            if (kelvinistsPolicy_2.canAct(player)) {
                options.push(new SelectOption_1.SelectOption(kelvinistsPolicy_2.description, 'Pay', function () { return kelvinistsPolicy_2.action(player); }));
            }
        }
        if (PartyHooks_1.PartyHooks.shouldApplyPolicy(player, PartyName_1.PartyName.GREENS, 'gp04')) {
            var greensPolicy_1 = Greens_1.GREENS_POLICY_4;
            if (greensPolicy_1.canAct(player)) {
                options.push(new SelectOption_1.SelectOption(greensPolicy_1.description, 'Pay', function () { return greensPolicy_1.action(player); }));
            }
        }
        if (PartyHooks_1.PartyHooks.shouldApplyPolicy(player, PartyName_1.PartyName.MARS, 'mfp04')) {
            var marsFirstPolicy_1 = MarsFirst_1.MARS_FIRST_POLICY_4;
            if (marsFirstPolicy_1.canAct(player)) {
                options.push(new SelectOption_1.SelectOption(marsFirstPolicy_1.description, 'Pay', function () { return marsFirstPolicy_1.action(player); }));
            }
        }
        if (PartyHooks_1.PartyHooks.shouldApplyPolicy(player, PartyName_1.PartyName.UNITY, 'up02')) {
            var unityPolicy_1 = Unity_1.UNITY_POLICY_2;
            if (unityPolicy_1.canAct(player)) {
                options.push(new SelectOption_1.SelectOption(unityPolicy_1.description, 'Pay', function () { return unityPolicy_1.action(player); }));
            }
        }
        if (PartyHooks_1.PartyHooks.shouldApplyPolicy(player, PartyName_1.PartyName.UNITY, 'up03')) {
            var unityPolicy_2 = Unity_1.UNITY_POLICY_3;
            if (unityPolicy_2.canAct(player)) {
                options.push(new SelectOption_1.SelectOption(unityPolicy_2.description, 'Pay', function () { return unityPolicy_2.action(player); }));
            }
        }
        if (PartyHooks_1.PartyHooks.shouldApplyPolicy(player, PartyName_1.PartyName.REDS, 'rp03')) {
            var redsPolicy_1 = Reds_1.REDS_POLICY_3;
            if (redsPolicy_1.canAct(player)) {
                options.push(new SelectOption_1.SelectOption(redsPolicy_1.description, 'Pay', function () { return redsPolicy_1.action(player); }));
            }
        }
    };
    TurmoilHandler.applyOnCardPlayedEffect = function (player, selectedCard) {
        if (PartyHooks_1.PartyHooks.shouldApplyPolicy(player, PartyName_1.PartyName.GREENS, 'gp03')) {
            var policy = Greens_1.GREENS_POLICY_3;
            policy.onCardPlayed(player, selectedCard);
        }
        if (PartyHooks_1.PartyHooks.shouldApplyPolicy(player, PartyName_1.PartyName.MARS, 'mfp02')) {
            var policy = MarsFirst_1.MARS_FIRST_POLICY_2;
            policy.onCardPlayed(player, selectedCard);
        }
    };
    TurmoilHandler.resolveTilePlacementCosts = function (player) {
        if (PartyHooks_1.PartyHooks.shouldApplyPolicy(player, PartyName_1.PartyName.REDS, 'rp02')) {
            var redsPolicy = Reds_1.REDS_POLICY_2;
            redsPolicy.onTilePlaced(player);
        }
    };
    TurmoilHandler.resolveTilePlacementBonuses = function (player, spaceType) {
        PartyHooks_1.PartyHooks.applyMarsFirstRulingPolicy(player, spaceType);
        if (PartyHooks_1.PartyHooks.shouldApplyPolicy(player, PartyName_1.PartyName.GREENS, 'gp02')) {
            var greensPolicy = Greens_1.GREENS_POLICY_2;
            greensPolicy.onTilePlaced(player);
        }
        if (PartyHooks_1.PartyHooks.shouldApplyPolicy(player, PartyName_1.PartyName.KELVINISTS, 'kp04')) {
            var kelvinistsPolicy = Kelvinists_1.KELVINISTS_POLICY_4;
            kelvinistsPolicy.onTilePlaced(player);
        }
    };
    TurmoilHandler.onGlobalParameterIncrease = function (player, parameter, steps) {
        if (steps === void 0) { steps = 1; }
        if (parameter === GlobalParameter_1.GlobalParameter.TEMPERATURE) {
            if (PartyHooks_1.PartyHooks.shouldApplyPolicy(player, PartyName_1.PartyName.KELVINISTS, 'kp02')) {
                player.addResource(Resources_1.Resources.MEGACREDITS, steps * 3);
            }
        }
        if (PartyHooks_1.PartyHooks.shouldApplyPolicy(player, PartyName_1.PartyName.REDS, 'rp04')) {
            player.addProduction(Resources_1.Resources.MEGACREDITS, -1 * steps, { log: true });
        }
        if (PartyHooks_1.PartyHooks.shouldApplyPolicy(player, PartyName_1.PartyName.SCIENTISTS, 'sp03')) {
            player.drawCard(steps);
        }
    };
    TurmoilHandler.computeTerraformRatingBump = function (player, tr) {
        var _a, _b, _c, _d;
        if (tr === void 0) { tr = {}; }
        if (!PartyHooks_1.PartyHooks.shouldApplyPolicy(player, PartyName_1.PartyName.REDS))
            return 0;
        tr = __assign({}, tr);
        var total = 0;
        if (tr.oxygen !== undefined) {
            var availableSteps = constants.MAX_OXYGEN_LEVEL - player.game.getOxygenLevel();
            var steps = Math.min(availableSteps, tr.oxygen);
            total = total + steps;
            if (player.game.getOxygenLevel() < 8 && player.game.getOxygenLevel() + steps >= 8) {
                tr.temperature = ((_a = tr.temperature) !== null && _a !== void 0 ? _a : 0) + 1;
            }
        }
        if (tr.temperature !== undefined) {
            var availableSteps = Math.floor((constants.MAX_TEMPERATURE - player.game.getTemperature()) / 2);
            var steps = Math.min(availableSteps, tr.temperature);
            total = total + steps;
            if (player.game.getTemperature() < 0 && player.game.getTemperature() + (steps * 2) >= 0) {
                tr.oceans = ((_b = tr.oceans) !== null && _b !== void 0 ? _b : 0) + 1;
            }
        }
        if (tr.oceans !== undefined) {
            var availableSteps = constants.MAX_OCEAN_TILES - player.game.board.getOceansOnBoard();
            var steps = Math.min(availableSteps, tr.oceans);
            total = total + steps;
        }
        if (tr.venus !== undefined) {
            var availableSteps = Math.floor((constants.MAX_VENUS_SCALE - player.game.getVenusScaleLevel()) / 2);
            var steps = Math.min(availableSteps, tr.venus);
            total = total + steps;
            if (player.game.getVenusScaleLevel() < 16 && player.game.getVenusScaleLevel() + (steps * 2) >= 16) {
                tr.tr = ((_c = tr.tr) !== null && _c !== void 0 ? _c : 0) + 1;
            }
        }
        MoonExpansion_1.MoonExpansion.ifMoon(player.game, function (moonData) {
            if (tr.moonColony !== undefined) {
                var availableSteps = constants.MAXIMUM_COLONY_RATE - moonData.colonyRate;
                total = total + Math.min(availableSteps, tr.moonColony);
            }
            if (tr.moonMining !== undefined) {
                var availableSteps = constants.MAXIMUM_MINING_RATE - moonData.miningRate;
                total = total + Math.min(availableSteps, tr.moonMining);
            }
            if (tr.moonLogistics !== undefined) {
                var availableSteps = constants.MAXIMUM_LOGISTICS_RATE - moonData.logisticRate;
                total = total + Math.min(availableSteps, tr.moonLogistics);
            }
        });
        total += (_d = tr.tr) !== null && _d !== void 0 ? _d : 0;
        return total;
    };
    return TurmoilHandler;
}());
exports.TurmoilHandler = TurmoilHandler;
