"use strict";
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.isSpecialTile = exports.playerTileFn = exports.nextToNoOtherTileFn = exports.Board = void 0;
var SpaceType_1 = require("../SpaceType");
var TileType_1 = require("../common/TileType");
var AresHandler_1 = require("../ares/AresHandler");
var Board = (function () {
    function Board(spaces) {
        var _this = this;
        this.spaces = spaces;
        this.maxX = 0;
        this.maxY = 0;
        this.adjacentSpaces = new Map();
        this.maxX = Math.max.apply(Math, spaces.map(function (s) { return s.x; }));
        this.maxY = Math.max.apply(Math, spaces.map(function (s) { return s.y; }));
        spaces.forEach(function (space) {
            _this.adjacentSpaces.set(space.id, _this.computeAdjacentSpaces(space));
        });
    }
    ;
    Board.prototype.getSpace = function (id) {
        var space = this.spaces.find(function (space) { return space.id === id; });
        if (space === undefined) {
            throw new Error("Can't find space with id " + id);
        }
        return space;
    };
    Board.prototype.computeAdjacentSpaces = function (space) {
        var middleRow = this.maxY / 2;
        if (space.spaceType !== SpaceType_1.SpaceType.COLONY) {
            if (space.y < 0 || space.y > this.maxY) {
                throw new Error('Unexpected space y value: ' + space.y);
            }
            if (space.x < 0 || space.x > this.maxX) {
                throw new Error('Unexpected space x value: ' + space.x);
            }
            var leftSpace = [space.x - 1, space.y];
            var rightSpace = [space.x + 1, space.y];
            var topLeftSpace = [space.x, space.y - 1];
            var topRightSpace = [space.x, space.y - 1];
            var bottomLeftSpace = [space.x, space.y + 1];
            var bottomRightSpace = [space.x, space.y + 1];
            if (space.y < middleRow) {
                bottomLeftSpace[0]--;
                topRightSpace[0]++;
            }
            else if (space.y === middleRow) {
                bottomRightSpace[0]++;
                topRightSpace[0]++;
            }
            else {
                bottomRightSpace[0]++;
                topLeftSpace[0]--;
            }
            var coords = [
                topLeftSpace,
                topRightSpace,
                rightSpace,
                bottomRightSpace,
                bottomLeftSpace,
                leftSpace,
            ];
            var spaces = [];
            var _loop_1 = function (x, y) {
                var adj = this_1.spaces.find(function (adj) {
                    return space !== adj && adj.spaceType !== SpaceType_1.SpaceType.COLONY &&
                        adj.x === x && adj.y === y;
                });
                if (adj !== undefined) {
                    spaces.push(adj);
                }
            };
            var this_1 = this;
            for (var _i = 0, coords_1 = coords; _i < coords_1.length; _i++) {
                var _a = coords_1[_i], x = _a[0], y = _a[1];
                _loop_1(x, y);
            }
            return spaces;
        }
        return [];
    };
    Board.prototype.getAdjacentSpaces = function (space) {
        var spaces = this.adjacentSpaces.get(space.id);
        if (spaces === undefined) {
            throw new Error("Unexpected space ID " + space.id);
        }
        return __spreadArray([], spaces, true);
    };
    Board.prototype.getSpaceByTileCard = function (cardName) {
        return this.spaces.find(function (space) { return space.tile !== undefined && space.tile.card === cardName; });
    };
    Board.prototype.getOceansOnBoard = function (countUpgradedOceans) {
        if (countUpgradedOceans === void 0) { countUpgradedOceans = true; }
        return this.getOceansTiles(countUpgradedOceans).length;
    };
    Board.prototype.getOceansTiles = function (countUpgradedOceans) {
        var spaces = this.spaces.filter(function (space) { return Board.isOceanSpace(space); });
        if (!countUpgradedOceans) {
            spaces = spaces.filter(function (space) { var _a; return space.tile && !TileType_1.OCEAN_UPGRADE_TILES.has((_a = space.tile) === null || _a === void 0 ? void 0 : _a.tileType); });
        }
        return spaces;
    };
    Board.prototype.getSpaces = function (spaceType, _player) {
        return this.spaces.filter(function (space) { return space.spaceType === spaceType; });
    };
    Board.prototype.getEmptySpaces = function () {
        return this.spaces.filter(function (space) { return space.tile === undefined; });
    };
    Board.prototype.getAvailableSpacesForCity = function (player) {
        var _this = this;
        return this.getAvailableSpacesOnLand(player).filter(function (space) { return _this.getAdjacentSpaces(space).some(function (adjacentSpace) { return Board.isCitySpace(adjacentSpace); }) === false; });
    };
    Board.prototype.getAvailableSpacesForMarker = function (player) {
        var _this = this;
        var spaces = this.getAvailableSpacesOnLand(player)
            .filter(function (space) { return _this.getAdjacentSpaces(space).find(function (adj) { return adj.player === player; }) !== undefined; });
        return spaces.filter(function (space, index) { return spaces.indexOf(space) === index; });
    };
    Board.prototype.getAvailableSpacesForGreenery = function (player) {
        var _this = this;
        var spacesOnLand = this.getAvailableSpacesOnLand(player);
        if (player.game.gameOptions.pathfindersExpansion === true) {
            spacesOnLand = spacesOnLand.filter(function (space) {
                return !_this.getAdjacentSpaces(space).some(function (neighbor) { var _a; return ((_a = neighbor.tile) === null || _a === void 0 ? void 0 : _a.tileType) === TileType_1.TileType.RED_CITY; });
            });
        }
        var spacesForGreenery = spacesOnLand
            .filter(function (space) { return _this.getAdjacentSpaces(space).find(function (adj) { return adj.tile !== undefined && adj.player === player && adj.tile.tileType !== TileType_1.TileType.OCEAN; }) !== undefined; });
        if (spacesForGreenery.length > 0) {
            return spacesForGreenery;
        }
        return spacesOnLand;
    };
    Board.prototype.getAvailableSpacesForOcean = function (player) {
        return this.getSpaces(SpaceType_1.SpaceType.OCEAN, player)
            .filter(function (space) { return space.tile === undefined &&
            (space.player === undefined || space.player === player); });
    };
    Board.prototype.getAvailableSpacesOnLand = function (player) {
        var landSpaces = this.getSpaces(SpaceType_1.SpaceType.LAND, player).filter(function (space) {
            var _a;
            var hasPlayerMarker = space.player !== undefined;
            var safeForPlayer = !hasPlayerMarker || space.player === player;
            var playableSpace = space.tile === undefined || AresHandler_1.AresHandler.hasHazardTile(space);
            var blockedByDesperateMeasures = ((_a = space.tile) === null || _a === void 0 ? void 0 : _a.protectedHazard) === true;
            return safeForPlayer && playableSpace && !blockedByDesperateMeasures;
        });
        return landSpaces;
    };
    Board.prototype.getNthAvailableLandSpace = function (distance, direction, player, predicate) {
        var _this = this;
        if (player === void 0) { player = undefined; }
        if (predicate === void 0) { predicate = function (_x) { return true; }; }
        var spaces = this.spaces.filter(function (space) {
            return _this.canPlaceTile(space) && (space.player === undefined || space.player === player);
        }).filter(predicate);
        var idx = (direction === 1) ? distance : (spaces.length - (distance + 1));
        if (spaces.length === 0) {
            throw new Error('no spaces available');
        }
        while (idx < 0) {
            idx += spaces.length;
        }
        while (idx >= spaces.length) {
            idx -= spaces.length;
        }
        return spaces[idx];
    };
    Board.prototype.getNonReservedLandSpaces = function () {
        return this.spaces.filter(function (space) {
            return (space.spaceType === SpaceType_1.SpaceType.LAND || space.spaceType === SpaceType_1.SpaceType.COVE) &&
                (space.tile === undefined || AresHandler_1.AresHandler.hasHazardTile(space)) &&
                space.player === undefined;
        });
    };
    Board.prototype.canPlaceTile = function (space) {
        return space.tile === undefined && space.spaceType === SpaceType_1.SpaceType.LAND;
    };
    Board.isCitySpace = function (space) {
        return space.tile !== undefined && TileType_1.CITY_TILES.has(space.tile.tileType);
    };
    Board.isOceanSpace = function (space) {
        return space.tile !== undefined && TileType_1.OCEAN_TILES.has(space.tile.tileType);
    };
    Board.prototype.serialize = function () {
        return {
            spaces: this.spaces.map(function (space) {
                var _a;
                return {
                    id: space.id,
                    spaceType: space.spaceType,
                    tile: space.tile,
                    player: (_a = space.player) === null || _a === void 0 ? void 0 : _a.id,
                    bonus: space.bonus,
                    adjacency: space.adjacency,
                    x: space.x,
                    y: space.y,
                };
            }),
        };
    };
    Board.deserializeSpace = function (serialized, players) {
        var playerId = serialized.player;
        var player = players.find(function (p) { return p.id === playerId; });
        var space = {
            id: serialized.id,
            spaceType: serialized.spaceType,
            bonus: serialized.bonus,
            x: serialized.x,
            y: serialized.y,
        };
        if (serialized.tile !== undefined) {
            space.tile = serialized.tile;
        }
        if (player !== undefined) {
            space.player = player;
        }
        if (serialized.adjacency !== undefined) {
            space.adjacency = serialized.adjacency;
        }
        return space;
    };
    Board.deserializeSpaces = function (spaces, players) {
        return spaces.map(function (space) { return Board.deserializeSpace(space, players); });
    };
    return Board;
}());
exports.Board = Board;
function nextToNoOtherTileFn(board) {
    return function (space) { return board.getAdjacentSpaces(space).every(function (space) { return space.tile === undefined; }); };
}
exports.nextToNoOtherTileFn = nextToNoOtherTileFn;
;
function playerTileFn(player) {
    return function (space) { var _a; return ((_a = space.player) === null || _a === void 0 ? void 0 : _a.id) === player.id; };
}
exports.playerTileFn = playerTileFn;
function isSpecialTile(space) {
    var _a;
    switch ((_a = space.tile) === null || _a === void 0 ? void 0 : _a.tileType) {
        case TileType_1.TileType.GREENERY:
        case TileType_1.TileType.OCEAN:
        case TileType_1.TileType.CITY:
        case TileType_1.TileType.MOON_COLONY:
        case TileType_1.TileType.MOON_MINE:
        case TileType_1.TileType.MOON_ROAD:
        case undefined:
            return false;
        default:
            return true;
    }
}
exports.isSpecialTile = isSpecialTile;
;
