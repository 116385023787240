"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.Sabotage = void 0;
var Card_1 = require("../Card");
var CardType_1 = require("../CardType");
var OrOptions_1 = require("../../inputs/OrOptions");
var Resources_1 = require("../../common/Resources");
var CardName_1 = require("../../CardName");
var SelectOption_1 = require("../../inputs/SelectOption");
var CardRenderer_1 = require("../render/CardRenderer");
var Size_1 = require("../render/Size");
var Options_1 = require("../Options");
var Sabotage = (function (_super) {
    __extends(Sabotage, _super);
    function Sabotage() {
        return _super.call(this, {
            cardType: CardType_1.CardType.EVENT,
            name: CardName_1.CardName.SABOTAGE,
            cost: 1,
            metadata: {
                cardNumber: '121',
                renderData: CardRenderer_1.CardRenderer.builder(function (b) {
                    b.minus().titanium(3, { all: Options_1.all, digit: Options_1.digit }).nbsp.or(Size_1.Size.SMALL).nbsp;
                    b.minus().steel(4, { all: Options_1.all, digit: Options_1.digit }).br.or(Size_1.Size.SMALL).nbsp;
                    b.minus().megacredits(7, { all: Options_1.all });
                }),
                description: 'Remove up to 3 titanium from any player, or 4 steel, or 7 M€.',
            },
        }) || this;
    }
    Sabotage.prototype.play = function (player) {
        if (player.game.isSoloMode())
            return undefined;
        var availablePlayerTargets = player.game.getPlayers().filter(function (p) { return p.id !== player.id; });
        var availableActions = new OrOptions_1.OrOptions();
        availablePlayerTargets.forEach(function (target) {
            if (target.titanium > 0 && !target.alloysAreProtected()) {
                var amountRemoved = Math.min(3, target.titanium);
                var optionTitle = 'Remove ' + amountRemoved + ' titanium from ' + target.name;
                availableActions.options.push(new SelectOption_1.SelectOption(optionTitle, 'Confirm', function () {
                    target.deductResource(Resources_1.Resources.TITANIUM, 3, { log: true, from: player });
                    return undefined;
                }));
            }
            if (target.steel > 0 && !target.alloysAreProtected()) {
                var amountRemoved = Math.min(4, target.steel);
                var optionTitle = 'Remove ' + amountRemoved + ' steel from ' + target.name;
                availableActions.options.push(new SelectOption_1.SelectOption(optionTitle, 'Confirm', function () {
                    target.deductResource(Resources_1.Resources.STEEL, 4, { log: true, from: player });
                    return undefined;
                }));
            }
            if (target.megaCredits > 0) {
                var amountRemoved = Math.min(7, target.megaCredits);
                var optionTitle = 'Remove ' + amountRemoved + ' M€ from ' + target.name;
                availableActions.options.push(new SelectOption_1.SelectOption(optionTitle, 'Confirm', function () {
                    target.deductResource(Resources_1.Resources.MEGACREDITS, 7, { log: true, from: player });
                    return undefined;
                }));
            }
        });
        if (availableActions.options.length > 0) {
            availableActions.options.push(new SelectOption_1.SelectOption('Do not remove resource', 'Confirm', function () {
                return undefined;
            }));
            return availableActions;
        }
        return undefined;
    };
    return Sabotage;
}(Card_1.Card));
exports.Sabotage = Sabotage;
