"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.BufferGasStandardProject = void 0;
var CardName_1 = require("../../CardName");
var CardRenderer_1 = require("../render/CardRenderer");
var StandardProjectCard_1 = require("../StandardProjectCard");
var BufferGasStandardProject = (function (_super) {
    __extends(BufferGasStandardProject, _super);
    function BufferGasStandardProject() {
        return _super.call(this, {
            name: CardName_1.CardName.BUFFER_GAS_STANDARD_PROJECT,
            cost: 16,
            tr: { tr: 1 },
            metadata: {
                cardNumber: 'SP3',
                renderData: CardRenderer_1.CardRenderer.builder(function (b) {
                    return b.standardProject('Spend 16 M€ to increase your TR 1 step. Solo games only.', function (eb) {
                        eb.megacredits(16).startAction.tr(1);
                    });
                }),
            },
        }) || this;
    }
    BufferGasStandardProject.prototype.actionEssence = function (player) {
        player.increaseTerraformRating();
    };
    return BufferGasStandardProject;
}(StandardProjectCard_1.StandardProjectCard));
exports.BufferGasStandardProject = BufferGasStandardProject;
