"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.MiningColony = void 0;
var Tags_1 = require("../Tags");
var CardType_1 = require("../CardType");
var CardName_1 = require("../../CardName");
var Resources_1 = require("../../common/Resources");
var BuildColony_1 = require("../../deferredActions/BuildColony");
var Card_1 = require("../Card");
var CardRenderer_1 = require("../render/CardRenderer");
var MiningColony = (function (_super) {
    __extends(MiningColony, _super);
    function MiningColony() {
        return _super.call(this, {
            cost: 20,
            tags: [Tags_1.Tags.SPACE],
            name: CardName_1.CardName.MINING_COLONY,
            cardType: CardType_1.CardType.AUTOMATED,
            metadata: {
                cardNumber: 'C25',
                renderData: CardRenderer_1.CardRenderer.builder(function (b) {
                    b.production(function (pb) { return pb.titanium(1); }).colonies(1);
                }),
                description: 'Increase your titanium production 1 step. Place a colony.',
            },
        }) || this;
    }
    MiningColony.prototype.canPlay = function (player) {
        return player.hasAvailableColonyTileToBuildOn();
    };
    MiningColony.prototype.play = function (player) {
        player.game.defer(new BuildColony_1.BuildColony(player, false, 'Select colony for Mining Colony'));
        player.addProduction(Resources_1.Resources.TITANIUM, 1);
        return undefined;
    };
    return MiningColony;
}(Card_1.Card));
exports.MiningColony = MiningColony;
