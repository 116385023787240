"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.Arklight = void 0;
var Tags_1 = require("../Tags");
var ResourceType_1 = require("../../common/ResourceType");
var Resources_1 = require("../../common/Resources");
var CardType_1 = require("../CardType");
var CardName_1 = require("../../CardName");
var Card_1 = require("../Card");
var ICard_1 = require("../ICard");
var CardRenderer_1 = require("../render/CardRenderer");
var Options_1 = require("../Options");
var Arklight = (function (_super) {
    __extends(Arklight, _super);
    function Arklight() {
        var _this = _super.call(this, {
            name: CardName_1.CardName.ARKLIGHT,
            tags: [Tags_1.Tags.ANIMAL],
            startingMegaCredits: 45,
            resourceType: ResourceType_1.ResourceType.ANIMAL,
            cardType: CardType_1.CardType.CORPORATION,
            victoryPoints: ICard_1.VictoryPoints.resource(1, 2),
            metadata: {
                cardNumber: 'R04',
                description: 'You start with 45 M€. Increase your M€ production 2 steps. 1 VP per 2 animals on this card.',
                renderData: CardRenderer_1.CardRenderer.builder(function (b) {
                    b.megacredits(45).nbsp.production(function (pb) { return pb.megacredits(2); });
                    b.corpBox('effect', function (ce) {
                        ce.effect('When you play an animal or plant tag, including this, add 1 animal to this card.', function (eb) {
                            eb.animals(1, { played: Options_1.played }).slash().plants(1, { played: Options_1.played }).startEffect.animals(1);
                        });
                        ce.vSpace();
                    });
                }),
            },
        }) || this;
        _this.resourceCount = 0;
        return _this;
    }
    Arklight.prototype.play = function (player) {
        player.addProduction(Resources_1.Resources.MEGACREDITS, 2);
        player.addResourceTo(this);
        return undefined;
    };
    Arklight.prototype.onCardPlayed = function (player, card) {
        if (player.isCorporation(CardName_1.CardName.ARKLIGHT)) {
            player.addResourceTo(this, card.tags.filter(function (cardTag) { return cardTag === Tags_1.Tags.ANIMAL || cardTag === Tags_1.Tags.PLANT; }).length);
        }
    };
    return Arklight;
}(Card_1.Card));
exports.Arklight = Arklight;
