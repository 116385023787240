"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.MartianZoo = void 0;
var Tags_1 = require("../Tags");
var CardType_1 = require("../CardType");
var CardName_1 = require("../../CardName");
var ResourceType_1 = require("../../common/ResourceType");
var CardRequirements_1 = require("../CardRequirements");
var Card_1 = require("../Card");
var CardRenderer_1 = require("../render/CardRenderer");
var Resources_1 = require("../../common/Resources");
var Options_1 = require("../Options");
var MartianZoo = (function (_super) {
    __extends(MartianZoo, _super);
    function MartianZoo() {
        var _this = _super.call(this, {
            cost: 12,
            tags: [Tags_1.Tags.ANIMAL, Tags_1.Tags.BUILDING],
            name: CardName_1.CardName.MARTIAN_ZOO,
            cardType: CardType_1.CardType.ACTIVE,
            resourceType: ResourceType_1.ResourceType.ANIMAL,
            requirements: CardRequirements_1.CardRequirements.builder(function (b) { return b.cities(2, { all: Options_1.all }); }),
            victoryPoints: 1,
            metadata: {
                cardNumber: 'C24',
                renderData: CardRenderer_1.CardRenderer.builder(function (b) {
                    b.effect('When you play an Earth tag, place an animal here.', function (eb) {
                        eb.earth(1, { played: Options_1.played }).startEffect.animals(1);
                    }).br;
                    b.action('Gain 1M€ per animal here.', function (eb) {
                        eb.empty().startAction.megacredits(1).slash().animals(1);
                    });
                }),
                description: {
                    text: 'Requires 2 city tiles in play.',
                    align: 'left',
                },
            },
        }) || this;
        _this.resourceCount = 0;
        return _this;
    }
    MartianZoo.prototype.onCardPlayed = function (player, card) {
        if (card.tags.includes(Tags_1.Tags.EARTH)) {
            player.addResourceTo(this, card.tags.filter(function (tag) { return tag === Tags_1.Tags.EARTH; }).length);
        }
    };
    MartianZoo.prototype.canAct = function () {
        return this.resourceCount > 0;
    };
    MartianZoo.prototype.action = function (player) {
        player.addResource(Resources_1.Resources.MEGACREDITS, this.resourceCount, { log: true });
        return undefined;
    };
    MartianZoo.prototype.play = function () {
        return undefined;
    };
    return MartianZoo;
}(Card_1.Card));
exports.MartianZoo = MartianZoo;
