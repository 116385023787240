"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.GeologicalExpedition = void 0;
var Card_1 = require("../Card");
var CardType_1 = require("../CardType");
var CardName_1 = require("../../CardName");
var CardRenderer_1 = require("../render/CardRenderer");
var Tags_1 = require("../Tags");
var SpaceBonus_1 = require("../../SpaceBonus");
var BoardType_1 = require("../../boards/BoardType");
var Resources_1 = require("../../common/Resources");
var OrOptions_1 = require("../../inputs/OrOptions");
var SelectOption_1 = require("../../inputs/SelectOption");
var DeferredAction_1 = require("../../deferredActions/DeferredAction");
var VALID_BONUSES = [
    SpaceBonus_1.SpaceBonus.TITANIUM,
    SpaceBonus_1.SpaceBonus.STEEL,
    SpaceBonus_1.SpaceBonus.PLANT,
    SpaceBonus_1.SpaceBonus.HEAT,
    SpaceBonus_1.SpaceBonus.MEGACREDITS,
    SpaceBonus_1.SpaceBonus.ANIMAL,
    SpaceBonus_1.SpaceBonus.MICROBE,
    SpaceBonus_1.SpaceBonus.POWER,
    SpaceBonus_1.SpaceBonus.DATA,
    SpaceBonus_1.SpaceBonus.SCIENCE,
];
var GeologicalExpedition = (function (_super) {
    __extends(GeologicalExpedition, _super);
    function GeologicalExpedition() {
        return _super.call(this, {
            cardType: CardType_1.CardType.ACTIVE,
            name: CardName_1.CardName.GEOLOGICAL_EXPEDITION,
            cost: 18,
            tags: [Tags_1.Tags.MARS, Tags_1.Tags.SCIENCE],
            victoryPoints: 2,
            metadata: {
                cardNumber: 'Pf17',
                renderData: CardRenderer_1.CardRenderer.builder(function (b) {
                    b.effect('When you place a tile on Mars gain 1 additional resource on the space. If the space has no bonus, gain 1 steel', function (eb) {
                        eb.emptyTile().startEffect.plus().wild(1).or().steel(1).asterix();
                    }).br;
                }),
            },
        }) || this;
    }
    GeologicalExpedition.prototype.onTilePlaced = function (cardOwner, activePlayer, space, boardType) {
        var _a;
        if (boardType !== BoardType_1.BoardType.MARS) {
            return;
        }
        if (cardOwner !== activePlayer) {
            return;
        }
        if (((_a = space.tile) === null || _a === void 0 ? void 0 : _a.covers) !== undefined) {
            return;
        }
        var bonuses = space.bonus;
        if (bonuses.length === 0) {
            activePlayer.addResource(Resources_1.Resources.STEEL, 1, { log: true });
            return;
        }
        var filtered = bonuses.filter(function (bonus) { return VALID_BONUSES.includes(bonus); });
        var unique = Array.from(new Set(filtered));
        var options = new OrOptions_1.OrOptions();
        options.title = 'Select an additional bonus from this space.';
        unique.forEach(function (bonus) {
            options.options.push(new SelectOption_1.SelectOption(SpaceBonus_1.SpaceBonus.toString(bonus), 'Select', function () {
                activePlayer.game.grantSpaceBonus(activePlayer, bonus, 1);
                return undefined;
            }));
        });
        if (options.options.length === 1) {
            options.options[0].cb();
            return;
        }
        if (options.options.length === 0) {
            return;
        }
        var action = new DeferredAction_1.DeferredAction(activePlayer, function () { return options; });
        action.priority = DeferredAction_1.Priority.GAIN_RESOURCE_OR_PRODUCTION;
        activePlayer.game.defer(action);
    };
    GeologicalExpedition.prototype.play = function () {
        return undefined;
    };
    return GeologicalExpedition;
}(Card_1.Card));
exports.GeologicalExpedition = GeologicalExpedition;
