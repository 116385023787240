"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.CorporateStronghold = void 0;
var Card_1 = require("../Card");
var CardType_1 = require("../CardType");
var Tags_1 = require("../Tags");
var SelectSpace_1 = require("../../inputs/SelectSpace");
var Resources_1 = require("../../common/Resources");
var CardName_1 = require("../../CardName");
var CardRenderer_1 = require("../render/CardRenderer");
var Units_1 = require("../../Units");
var CorporateStronghold = (function (_super) {
    __extends(CorporateStronghold, _super);
    function CorporateStronghold() {
        return _super.call(this, {
            cardType: CardType_1.CardType.AUTOMATED,
            name: CardName_1.CardName.CORPORATE_STRONGHOLD,
            tags: [Tags_1.Tags.CITY, Tags_1.Tags.BUILDING],
            cost: 11,
            productionBox: Units_1.Units.of({ energy: -1, megacredits: 3 }),
            victoryPoints: -2,
            metadata: {
                cardNumber: '182',
                description: 'Decrease your Energy production 1 step and increase your M€ production 3 steps. Place a City tile.',
                renderData: CardRenderer_1.CardRenderer.builder(function (b) {
                    b.production(function (pb) {
                        pb.minus().energy(1).br;
                        pb.plus().megacredits(3);
                    }).nbsp.nbsp.city();
                }),
            },
        }) || this;
    }
    CorporateStronghold.prototype.canPlay = function (player) {
        return player.getProduction(Resources_1.Resources.ENERGY) >= 1 &&
            player.game.board.getAvailableSpacesForCity(player).length > 0;
    };
    CorporateStronghold.prototype.play = function (player) {
        return new SelectSpace_1.SelectSpace('Select space for city tile', player.game.board.getAvailableSpacesForCity(player), function (space) {
            player.game.addCityTile(player, space.id);
            player.addProduction(Resources_1.Resources.ENERGY, -1);
            player.addProduction(Resources_1.Resources.MEGACREDITS, 3);
            return undefined;
        });
    };
    return CorporateStronghold;
}(Card_1.Card));
exports.CorporateStronghold = CorporateStronghold;
