"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var vue_1 = require("vue");
var CardRenderItem_1 = require("@/cards/render/CardRenderItem");
var ICardRenderDescription_1 = require("@/cards/render/ICardRenderDescription");
var CardRenderSymbol_1 = require("@/cards/render/CardRenderSymbol");
var CardRenderer_1 = require("@/cards/render/CardRenderer");
var CardRenderer_2 = require("@/cards/render/CardRenderer");
var CardRenderItemComponent_vue_1 = require("@/client/components/card/CardRenderItemComponent.vue");
var CardProductionBoxComponent_vue_1 = require("@/client/components/card/CardProductionBoxComponent.vue");
var CardRenderEffectBoxComponent_vue_1 = require("@/client/components/card/CardRenderEffectBoxComponent.vue");
var CardRenderCorpBoxComponent_vue_1 = require("@/client/components/card/CardRenderCorpBoxComponent.vue");
var CardRenderTileComponent_vue_1 = require("@/client/components/card/CardRenderTileComponent.vue");
var CardDescription_vue_1 = require("@/client/components/card/CardDescription.vue");
var CardRenderSymbolComponent_vue_1 = require("@/client/components/card/CardRenderSymbolComponent.vue");
var CardRenderer_3 = require("@/cards/render/CardRenderer");
exports.default = vue_1.default.extend({
    name: 'CardRowComponent',
    props: {
        componentData: {
            type: Object,
            required: true,
        },
    },
    components: {
        CardRenderSymbolComponent: CardRenderSymbolComponent_vue_1.default,
        CardRenderItemComponent: CardRenderItemComponent_vue_1.default,
        CardProductionBoxComponent: CardProductionBoxComponent_vue_1.default,
        CardRenderEffectBoxComponent: CardRenderEffectBoxComponent_vue_1.default,
        CardRenderCorpBoxComponent: CardRenderCorpBoxComponent_vue_1.default,
        CardRenderTileComponent: CardRenderTileComponent_vue_1.default,
        CardDescription: CardDescription_vue_1.default,
    },
    methods: {
        isItem: function () {
            return this.componentData instanceof CardRenderItem_1.CardRenderItem;
        },
        isSymbol: function () {
            return this.componentData instanceof CardRenderSymbol_1.CardRenderSymbol;
        },
        isProduction: function (item) {
            return item instanceof CardRenderer_1.CardRenderProductionBox;
        },
        isEffect: function () {
            return this.componentData instanceof CardRenderer_3.CardRenderEffect;
        },
        isDescription: function () {
            return typeof this.componentData === 'string' || this.componentData instanceof String || (0, ICardRenderDescription_1.isIDescription)(this.componentData);
        },
        isTile: function () {
            return this.componentData instanceof CardRenderer_2.CardRenderTile;
        },
        isCorpBox: function (item) {
            return item instanceof CardRenderer_3.CardRenderCorpBoxEffect || item instanceof CardRenderer_3.CardRenderCorpBoxAction;
        },
        corpBoxLabel: function () {
            if (this.componentData instanceof CardRenderer_3.CardRenderCorpBoxEffect) {
                return 'effect';
            }
            else if (this.componentData instanceof CardRenderer_3.CardRenderCorpBoxAction) {
                return 'action';
            }
            return 'n/a';
        },
    },
});
