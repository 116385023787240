"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.TheDarksideofTheMoonSyndicate = void 0;
var CardName_1 = require("../../CardName");
var CardType_1 = require("../CardType");
var Tags_1 = require("../Tags");
var CardRenderer_1 = require("../render/CardRenderer");
var ResourceType_1 = require("../../common/ResourceType");
var MoonExpansion_1 = require("../../moon/MoonExpansion");
var Multiset_1 = require("../../utils/Multiset");
var Resources_1 = require("../../common/Resources");
var OrOptions_1 = require("../../inputs/OrOptions");
var SelectOption_1 = require("../../inputs/SelectOption");
var StealResources_1 = require("../../deferredActions/StealResources");
var Size_1 = require("../render/Size");
var Phase_1 = require("../../Phase");
var Card_1 = require("../Card");
var Options_1 = require("../Options");
var TheDarksideofTheMoonSyndicate = (function (_super) {
    __extends(TheDarksideofTheMoonSyndicate, _super);
    function TheDarksideofTheMoonSyndicate() {
        var _this = _super.call(this, {
            cardType: CardType_1.CardType.CORPORATION,
            name: CardName_1.CardName.THE_DARKSIDE_OF_THE_MOON_SYNDICATE,
            tags: [Tags_1.Tags.MOON],
            startingMegaCredits: 40,
            resourceType: ResourceType_1.ResourceType.SYNDICATE_FLEET,
            metadata: {
                cardNumber: '',
                renderData: CardRenderer_1.CardRenderer.builder(function (b) {
                    b.megacredits(40).syndicateFleet(2).br;
                    b.text('You start with 40 M€ and 2 syndicate fleets on this card.', Size_1.Size.SMALL, false, false).br;
                    b.titanium(1).arrow(Size_1.Size.SMALL).syndicateFleet()
                        .slash(Size_1.Size.SMALL)
                        .syndicateFleet().arrow(Size_1.Size.SMALL).text('steal', Size_1.Size.TINY).megacredits(8, { all: Options_1.all }).br;
                    b.text('Action: Spend 1 titanium to add 1 syndicate fleet on this card OR remove 1 syndicate fleet from this card to steal 8M€ from any opponent.', Size_1.Size.TINY, false, false).br;
                    b
                        .effect('When you place a tile on the Moon, steal 2 M€ from opponents for each of their tiles next to yours.', function (eb) {
                        eb.emptyTile('normal', { size: Size_1.Size.SMALL, secondaryTag: Tags_1.Tags.MOON })
                            .startEffect
                            .text('STEAL').megacredits(2, { all: Options_1.all }).slash().emptyTile('normal', { size: Size_1.Size.SMALL }).emptyTile('normal', { size: Size_1.Size.SMALL, all: Options_1.all });
                    });
                }),
            },
        }) || this;
        _this.resourceCount = 0;
        return _this;
    }
    TheDarksideofTheMoonSyndicate.prototype.play = function (player) {
        player.addResourceTo(this, 2);
        return undefined;
    };
    TheDarksideofTheMoonSyndicate.prototype.canAct = function (player) {
        return player.titanium > 0 || this.resourceCount > 0;
    };
    TheDarksideofTheMoonSyndicate.prototype.action = function (player) {
        var _this = this;
        var orOptions = new OrOptions_1.OrOptions();
        if (player.titanium > 0) {
            orOptions.options.push(new SelectOption_1.SelectOption('Spend 1 titanium to add 1 syndicate fleet on this card', 'Add syndicate fleet', function () {
                player.titanium--;
                player.addResourceTo(_this);
                return undefined;
            }));
        }
        if (this.resourceCount > 0) {
            orOptions.options.push(new SelectOption_1.SelectOption('Remove 1 syndicate fleet from this card to steal 8M€ from any opponent.', 'Remove syndicate fleet', function () {
                player.removeResourceFrom(_this);
                player.game.defer(new StealResources_1.StealResources(player, Resources_1.Resources.MEGACREDITS, 8));
                return undefined;
            }));
        }
        if (orOptions.options.length === 1) {
            return orOptions.options[0].cb();
        }
        return orOptions;
    };
    TheDarksideofTheMoonSyndicate.prototype.onTilePlaced = function (cardOwner, activePlayer, space) {
        if (activePlayer.game.phase === Phase_1.Phase.SOLAR) {
            return;
        }
        if (activePlayer !== cardOwner) {
            return undefined;
        }
        if (space.tile === undefined) {
            return undefined;
        }
        var game = activePlayer.game;
        if (MoonExpansion_1.MoonExpansion.MOON_TILES.has(space.tile.tileType)) {
            var costs_1 = new Multiset_1.Multiset();
            MoonExpansion_1.MoonExpansion.moonData(game).moon.getAdjacentSpaces(space).forEach(function (space) {
                if (space.tile !== undefined && space.player !== undefined && space.player !== activePlayer) {
                    costs_1.add(space.player, 2);
                }
            });
            costs_1.entries().forEach(function (_a) {
                var target = _a[0], qty = _a[1];
                var adjustedQuantity = Math.min(qty, target.megaCredits);
                activePlayer.addResource(Resources_1.Resources.MEGACREDITS, adjustedQuantity, { log: true });
                target.deductResource(Resources_1.Resources.MEGACREDITS, adjustedQuantity, { log: true, from: activePlayer });
            });
        }
        return undefined;
    };
    return TheDarksideofTheMoonSyndicate;
}(Card_1.Card));
exports.TheDarksideofTheMoonSyndicate = TheDarksideofTheMoonSyndicate;
