"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.Vitor = void 0;
var Tags_1 = require("../Tags");
var Card_1 = require("../Card");
var OrOptions_1 = require("../../inputs/OrOptions");
var SelectOption_1 = require("../../inputs/SelectOption");
var CardName_1 = require("../../CardName");
var CardType_1 = require("../CardType");
var CardRenderer_1 = require("../render/CardRenderer");
var Vitor = (function (_super) {
    __extends(Vitor, _super);
    function Vitor() {
        return _super.call(this, {
            cardType: CardType_1.CardType.CORPORATION,
            name: CardName_1.CardName.VITOR,
            tags: [Tags_1.Tags.EARTH],
            startingMegaCredits: 48,
            initialActionText: 'Fund an award for free',
            metadata: {
                cardNumber: 'R35',
                description: 'You start with 45 M€. As your first action, fund an award for free.',
                renderData: CardRenderer_1.CardRenderer.builder(function (b) {
                    b.br.br;
                    b.megacredits(45).nbsp.award();
                    b.corpBox('effect', function (ce) {
                        ce.effect('When you play a card with a NON-NEGATIVE VP icon, including this, gain 3 M€.', function (eb) {
                            eb.vpIcon().asterix().startEffect.megacredits(3);
                        });
                    });
                }),
            },
        }) || this;
    }
    Vitor.prototype.selectAwardToFund = function (player, award) {
        return new SelectOption_1.SelectOption('Fund ' + award.name + ' award', 'Confirm', function () {
            player.game.fundAward(player, award);
            return undefined;
        });
    };
    Vitor.prototype.initialAction = function (player) {
        var _this = this;
        var game = player.game;
        if (game.isSoloMode())
            return;
        var freeAward = new OrOptions_1.OrOptions();
        freeAward.title = 'Select award to fund';
        freeAward.buttonLabel = 'Confirm';
        var availableAwards = game.awards.filter(function (award) { return !game.fundedAwards.map(function (fa) { return fa.award; }).includes(award); });
        freeAward.options = availableAwards.map(function (award) { return _this.selectAwardToFund(player, award); });
        return freeAward;
    };
    Vitor.prototype.onCardPlayed = function (player, card) {
        if (!player.isCorporation(this.name)) {
            return;
        }
        var victoryPoints = card.metadata.victoryPoints;
        if (victoryPoints === undefined)
            return;
        if (typeof (victoryPoints) === 'number') {
            if (victoryPoints <= 0)
                return;
        }
        else {
            if (victoryPoints.points <= 0)
                return;
        }
        player.megaCredits += 3;
    };
    Vitor.prototype.play = function (_player) {
        return undefined;
    };
    return Vitor;
}(Card_1.Card));
exports.Vitor = Vitor;
