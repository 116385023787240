"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.ConstantStruggle = void 0;
var IGlobalEvent_1 = require("./IGlobalEvent");
var GlobalEventName_1 = require("./GlobalEventName");
var PartyName_1 = require("../parties/PartyName");
var Resources_1 = require("../../common/Resources");
var PathfindersExpansion_1 = require("../../pathfinders/PathfindersExpansion");
var Tags_1 = require("../../cards/Tags");
var CardRenderer_1 = require("../../cards/render/CardRenderer");
var Size_1 = require("../../cards/render/Size");
var RENDER_DATA = CardRenderer_1.CardRenderer.builder(function (b) {
    b.minus().megacredits(10).influence({ size: Size_1.Size.SMALL }).planetaryTrack().text('2');
});
var ConstantStruggle = (function (_super) {
    __extends(ConstantStruggle, _super);
    function ConstantStruggle() {
        return _super.call(this, {
            name: GlobalEventName_1.GlobalEventName.CONSTANT_STRUGGLE,
            description: 'Pay 10M€, reduced by 1M€ per influence. Raise every planetary track 2 steps. Nobody gains the "rising player" bonus.',
            revealedDelegate: PartyName_1.PartyName.KELVINISTS,
            currentDelegate: PartyName_1.PartyName.REDS,
            renderData: RENDER_DATA,
        }) || this;
    }
    ConstantStruggle.prototype.resolve = function (game, turmoil) {
        var _this = this;
        game.getPlayers().forEach(function (player) {
            var influence = turmoil.getPlayerInfluence(player);
            var deducted = Math.max(10 - influence, 0);
            player.deductResource(Resources_1.Resources.MEGACREDITS, deducted, { log: true, from: _this.name });
        });
        PathfindersExpansion_1.PathfindersExpansion.raiseTrackForGlobalEvent(Tags_1.Tags.VENUS, this.name, game, 2, false);
        PathfindersExpansion_1.PathfindersExpansion.raiseTrackForGlobalEvent(Tags_1.Tags.EARTH, this.name, game, 2, false);
        PathfindersExpansion_1.PathfindersExpansion.raiseTrackForGlobalEvent(Tags_1.Tags.MARS, this.name, game, 2, false);
        PathfindersExpansion_1.PathfindersExpansion.raiseTrackForGlobalEvent(Tags_1.Tags.JOVIAN, this.name, game, 2, false);
        PathfindersExpansion_1.PathfindersExpansion.raiseTrackForGlobalEvent(Tags_1.Tags.MOON, this.name, game, 2, false);
    };
    return ConstantStruggle;
}(IGlobalEvent_1.GlobalEvent));
exports.ConstantStruggle = ConstantStruggle;
