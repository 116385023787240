"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.RobinHaulings = void 0;
var Card_1 = require("../Card");
var Tags_1 = require("../Tags");
var CardName_1 = require("../../CardName");
var CardType_1 = require("../CardType");
var CardRenderer_1 = require("../render/CardRenderer");
var ResourceType_1 = require("../../common/ResourceType");
var AddResourcesToCard_1 = require("../../deferredActions/AddResourcesToCard");
var Options_1 = require("../Options");
var constants_1 = require("../../constants");
var OrOptions_1 = require("../../inputs/OrOptions");
var SelectOption_1 = require("../../inputs/SelectOption");
var RobinHaulings = (function (_super) {
    __extends(RobinHaulings, _super);
    function RobinHaulings() {
        var _this = _super.call(this, {
            cardType: CardType_1.CardType.CORPORATION,
            name: CardName_1.CardName.ROBIN_HAULINGS,
            tags: [Tags_1.Tags.MARS, Tags_1.Tags.VENUS],
            startingMegaCredits: 39,
            resourceType: ResourceType_1.ResourceType.FLOATER,
            metadata: {
                cardNumber: 'PfC9',
                description: 'You start with 39 M€',
                renderData: CardRenderer_1.CardRenderer.builder(function (b) {
                    b.megacredits(39).br;
                    b.effect('When ever you play a card with a Venus tag add 1 floater to any card.', function (eb) {
                        eb.venus(1, { played: Options_1.played }).startEffect.floaters(1).asterix();
                    });
                    b.br;
                    b.action('Remove 3 floaters from this card to raise Venus 1 step or raise oxygen 1 step', function (ab) {
                        ab.floaters(3, { digit: true }).startAction.venus(1).or().oxygen(1);
                    });
                }),
            },
        }) || this;
        _this.resourceCount = 0;
        return _this;
    }
    RobinHaulings.prototype.play = function (player) {
        player.addResourceTo(this, 1);
        return undefined;
    };
    RobinHaulings.prototype.onCardPlayed = function (player, card) {
        if (player.isCorporation(CardName_1.CardName.ROBIN_HAULINGS) && card.tags.includes(Tags_1.Tags.VENUS)) {
            player.game.defer(new AddResourcesToCard_1.AddResourcesToCard(player, ResourceType_1.ResourceType.FLOATER));
        }
    };
    RobinHaulings.prototype.canRaiseVenus = function (player) {
        return player.game.getVenusScaleLevel() < constants_1.MAX_VENUS_SCALE && player.canAfford(0, { tr: { venus: 1 } });
    };
    RobinHaulings.prototype.canRaiseOxygen = function (player) {
        return player.game.getOxygenLevel() < constants_1.MAX_OXYGEN_LEVEL && player.canAfford(0, { tr: { oxygen: 1 } });
    };
    RobinHaulings.prototype.canAct = function (player) {
        if (this.resourceCount < 3)
            return false;
        return this.canRaiseVenus(player) || this.canRaiseOxygen(player);
    };
    RobinHaulings.prototype.action = function (player) {
        var _this = this;
        var options = new OrOptions_1.OrOptions();
        if (this.canRaiseVenus(player)) {
            options.options.push(new SelectOption_1.SelectOption('Spend 3 floaters to raise Venus 1 step', 'OK', function () {
                player.game.increaseVenusScaleLevel(player, 1);
                _this.resourceCount -= 3;
                return undefined;
            }));
        }
        ;
        if (this.canRaiseOxygen(player)) {
            options.options.push(new SelectOption_1.SelectOption('Spend 3 floaters to raise oxygen 1 step', 'OK', function () {
                player.game.increaseOxygenLevel(player, 1);
                _this.resourceCount -= 3;
                return undefined;
            }));
        }
        ;
        if (options.options.length === 0) {
            return undefined;
        }
        if (options.options.length === 1) {
            return options.options[0];
        }
        return options;
    };
    return RobinHaulings;
}(Card_1.Card));
exports.RobinHaulings = RobinHaulings;
