"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.AirScrappingStandardProjectVariant = void 0;
var CardName_1 = require("../../CardName");
var CardRenderer_1 = require("../render/CardRenderer");
var Tags_1 = require("../Tags");
var AirScrappingStandardProject_1 = require("./AirScrappingStandardProject");
var Size_1 = require("../render/Size");
var Options_1 = require("../Options");
var AirScrappingStandardProjectVariant = (function (_super) {
    __extends(AirScrappingStandardProjectVariant, _super);
    function AirScrappingStandardProjectVariant() {
        return _super.call(this, {
            name: CardName_1.CardName.AIR_SCRAPPING_STANDARD_PROJECT_VARIANT,
            cost: 15,
            tr: { venus: 1 },
            metadata: {
                cardNumber: 'SP1a',
                renderData: CardRenderer_1.CardRenderer.builder(function (b) {
                    b.standardProject('Spend 15M€, less 1M€ per Venus tag you have, to raise Venus 1 step.', function (eb) {
                        eb.megacredits(15).text('(').megacredits(-1).slash().venus(1, { played: Options_1.played }).text(')').startAction.venus(1);
                    });
                    b.br.text('(max -5M€)', Size_1.Size.SMALL);
                }),
            },
        }) || this;
    }
    AirScrappingStandardProjectVariant.prototype.discount = function (player) {
        var tagCount = player.getTagCount(Tags_1.Tags.VENUS);
        var discount = Math.min(tagCount, 5);
        return discount + _super.prototype.discount.call(this, player);
    };
    return AirScrappingStandardProjectVariant;
}(AirScrappingStandardProject_1.AirScrappingStandardProject));
exports.AirScrappingStandardProjectVariant = AirScrappingStandardProjectVariant;
