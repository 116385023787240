"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.EcologicalZone = void 0;
var Tags_1 = require("../Tags");
var Card_1 = require("../Card");
var ICard_1 = require("../ICard");
var CardType_1 = require("../CardType");
var ResourceType_1 = require("../../common/ResourceType");
var TileType_1 = require("../../common/TileType");
var SelectSpace_1 = require("../../inputs/SelectSpace");
var CardName_1 = require("../../CardName");
var CardRequirements_1 = require("../CardRequirements");
var CardRenderer_1 = require("../render/CardRenderer");
var Phase_1 = require("../../Phase");
var Options_1 = require("../Options");
var EcologicalZone = (function (_super) {
    __extends(EcologicalZone, _super);
    function EcologicalZone(name, cost, adjacencyBonus, metadata) {
        if (name === void 0) { name = CardName_1.CardName.ECOLOGICAL_ZONE; }
        if (cost === void 0) { cost = 12; }
        if (adjacencyBonus === void 0) { adjacencyBonus = undefined; }
        if (metadata === void 0) { metadata = {
            description: {
                text: 'Requires that YOU have a greenery tile. Place this tile adjacent to ANY greenery.',
                align: 'left',
            },
            cardNumber: '128',
            renderData: CardRenderer_1.CardRenderer.builder(function (b) {
                b.effect('When you play an animal or plant tag /including these/, add an animal to this card.', function (eb) {
                    eb.animals(1, { played: Options_1.played }).slash().plants(1, { played: Options_1.played }).startEffect.animals(1);
                }).br;
                b.vpText('1 VP per 2 Animals on this card.').tile(TileType_1.TileType.ECOLOGICAL_ZONE, true).asterix();
            }),
        }; }
        var _this = _super.call(this, {
            cardType: CardType_1.CardType.ACTIVE,
            name: name,
            tags: [Tags_1.Tags.ANIMAL, Tags_1.Tags.PLANT],
            cost: cost,
            resourceType: ResourceType_1.ResourceType.ANIMAL,
            adjacencyBonus: adjacencyBonus,
            victoryPoints: ICard_1.VictoryPoints.resource(1, 2),
            requirements: CardRequirements_1.CardRequirements.builder(function (b) { return b.greeneries(); }),
            metadata: metadata,
        }) || this;
        _this.resourceCount = 0;
        return _this;
    }
    EcologicalZone.prototype.getAvailableSpaces = function (player) {
        return player.game.board.getAvailableSpacesOnLand(player)
            .filter(function (space) { return player.game.board.getAdjacentSpaces(space).filter(function (adjacentSpace) { return adjacentSpace.tile !== undefined &&
            adjacentSpace.tile.tileType === TileType_1.TileType.GREENERY; }).length > 0; });
    };
    EcologicalZone.prototype.canPlay = function (player) {
        return this.getAvailableSpaces(player).length > 0;
    };
    EcologicalZone.prototype.onCardPlayed = function (player, card) {
        player.addResourceTo(this, card.tags.filter(function (tag) { return tag === Tags_1.Tags.ANIMAL || tag === Tags_1.Tags.PLANT; }).length);
    };
    EcologicalZone.prototype.play = function (player) {
        var _this = this;
        if (player.game.phase === Phase_1.Phase.PRELUDES && player.playedCards.length > 0 && player.playedCards[player.playedCards.length - 1].name === CardName_1.CardName.ECOLOGY_EXPERTS) {
            player.addResourceTo(this, 1);
        }
        return new SelectSpace_1.SelectSpace('Select space next to greenery for special tile', this.getAvailableSpaces(player), function (requestedSpace) {
            player.game.addTile(player, requestedSpace.spaceType, requestedSpace, {
                tileType: TileType_1.TileType.ECOLOGICAL_ZONE,
            });
            requestedSpace.adjacency = _this.adjacencyBonus;
            return undefined;
        });
    };
    return EcologicalZone;
}(Card_1.Card));
exports.EcologicalZone = EcologicalZone;
