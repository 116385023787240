"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.DesperateMeasures = void 0;
var Card_1 = require("../Card");
var CardName_1 = require("../../CardName");
var CardType_1 = require("../CardType");
var SelectSpace_1 = require("../../inputs/SelectSpace");
var TileType_1 = require("../../common/TileType");
var AresHandler_1 = require("../../ares/AresHandler");
var CardRenderer_1 = require("../render/CardRenderer");
var DesperateMeasures = (function (_super) {
    __extends(DesperateMeasures, _super);
    function DesperateMeasures() {
        return _super.call(this, {
            cardType: CardType_1.CardType.EVENT,
            name: CardName_1.CardName.DESPERATE_MEASURES,
            cost: 1,
            victoryPoints: -2,
            metadata: {
                cardNumber: 'A04',
                description: 'Place a bronze cube on a dust storm tile and raise oxygen 1 step, or place a bronze cube on an erosion tile and raise the temperature 1 step. The hazard tile with the bronze cube cannot be removed.',
                renderData: CardRenderer_1.CardRenderer.builder(function (b) {
                    b.resourceCube().asterix().br;
                    b.temperature(1).slash().oxygen(1);
                }),
            },
        }) || this;
    }
    DesperateMeasures.prototype.getHazardTiles = function (game) {
        return game.board.spaces.filter(function (space) { return AresHandler_1.AresHandler.hasHazardTile(space); });
    };
    DesperateMeasures.prototype.canPlay = function (player) {
        return this.getHazardTiles(player.game).length > 0;
    };
    DesperateMeasures.prototype.play = function (player) {
        return new SelectSpace_1.SelectSpace('Select a hazard space to protect', this.getHazardTiles(player.game), function (space) {
            space.tile.protectedHazard = true;
            var tileType = space.tile.tileType;
            if (TileType_1.TileType.DUST_STORM_MILD === tileType || TileType_1.TileType.DUST_STORM_SEVERE === tileType) {
                player.game.increaseOxygenLevel(player, 1);
            }
            else {
                player.game.increaseTemperature(player, 1);
            }
            return undefined;
        });
    };
    return DesperateMeasures;
}(Card_1.Card));
exports.DesperateMeasures = DesperateMeasures;
