"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PaymentWidgetMixin = exports.unit = void 0;
var CardName_1 = require("@/CardName");
var constants_1 = require("@/constants");
exports.unit = ['megaCredits', 'titanium', 'steel', 'heat', 'microbes', 'floaters', 'science', 'seeds'];
exports.PaymentWidgetMixin = {
    'name': 'PaymentWidgetMixin',
    'methods': {
        asModel: function () {
            return this;
        },
        getMegaCreditsMax: function () {
            var model = this.asModel();
            return Math.min(model.playerView.thisPlayer.megaCredits, model.$data.cost);
        },
        getResourceRate: function (resourceName) {
            switch (resourceName) {
                case 'titanium':
                    return this.asModel().playerView.thisPlayer.titaniumValue;
                case 'steel':
                    return this.asModel().playerView.thisPlayer.steelValue;
                case 'microbes':
                    return 2;
                case 'floaters':
                    return 3;
                case 'seeds':
                    return constants_1.SEED_VALUE;
                default:
                    return 1;
            }
        },
        reduceValue: function (target, delta) {
            var currentValue = this.asModel()[target];
            if (currentValue === undefined) {
                throw new Error("can not reduceValue for " + target + " on this");
            }
            var adjustedDelta = Math.min(delta, currentValue);
            if (adjustedDelta === 0)
                return;
            this.asModel()[target] -= adjustedDelta;
            if (target !== 'megaCredits')
                this.setRemainingMCValue();
        },
        addValue: function (target, delta, max) {
            var currentValue = this.asModel()[target];
            if (currentValue === undefined) {
                throw new Error("can not addValue for " + target + " on this");
            }
            var maxValue = max !== undefined ? max : this.getAmount(target);
            if (target === 'megaCredits') {
                maxValue = this.getMegaCreditsMax();
            }
            if (currentValue === maxValue)
                return;
            if (maxValue === undefined) {
                throw new Error("unable to determine maxValue for " + target);
            }
            var adjustedDelta = Math.min(delta, maxValue - currentValue);
            if (adjustedDelta === 0)
                return;
            this.asModel()[target] += adjustedDelta;
            if (target !== 'megaCredits')
                this.setRemainingMCValue();
        },
        setRemainingMCValue: function () {
            var _a;
            var ta = this.asModel();
            var remainingMC = ta.$data.cost;
            for (var _i = 0, unit_1 = exports.unit; _i < unit_1.length; _i++) {
                var resource = unit_1[_i];
                if (resource === 'megaCredits')
                    continue;
                var value = ((_a = ta[resource]) !== null && _a !== void 0 ? _a : 0) * this.getResourceRate(resource);
                remainingMC -= value;
            }
            ta['megaCredits'] = Math.max(0, Math.min(this.getMegaCreditsMax(), remainingMC));
        },
        setMaxValue: function (target, max) {
            var currentValue = this.asModel()[target];
            if (currentValue === undefined) {
                throw new Error("can not setMaxValue for " + target + " on this");
            }
            var cost = this.asModel().$data.cost;
            var amountNeed = cost;
            if (target !== 'science' && target !== 'heat') {
                amountNeed = Math.floor(cost / this.getResourceRate(target));
            }
            var amountHave = this.getAmount(target);
            while (currentValue < amountHave && currentValue < amountNeed) {
                this.addValue(target, 1, max);
                currentValue++;
            }
        },
        getAmount: function (target) {
            var _a;
            var amount = undefined;
            var model = this.asModel();
            switch (target) {
                case 'heat':
                case 'steel':
                case 'titanium':
                case 'megaCredits':
                    amount = model.playerView.thisPlayer[target];
                    break;
                case 'floaters':
                case 'microbes':
                case 'science':
                case 'seeds':
                    amount = model.playerinput[target];
                    break;
            }
            ;
            if (amount === undefined) {
                return 0;
            }
            if (target === 'floaters' && ((_a = this.asModel().$data.card) === null || _a === void 0 ? void 0 : _a.name) === CardName_1.CardName.STRATOSPHERIC_BIRDS) {
                amount = Math.max(amount - 1, 0);
            }
            return amount;
        },
    },
};
