"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.EconomicHelp = void 0;
var Card_1 = require("../Card");
var CardType_1 = require("../CardType");
var CardName_1 = require("../../CardName");
var CardRenderer_1 = require("../render/CardRenderer");
var Resources_1 = require("../../common/Resources");
var PathfindersExpansion_1 = require("../../pathfinders/PathfindersExpansion");
var Tags_1 = require("../Tags");
var Size_1 = require("../render/Size");
var Options_1 = require("../Options");
var EconomicHelp = (function (_super) {
    __extends(EconomicHelp, _super);
    function EconomicHelp() {
        return _super.call(this, {
            cardType: CardType_1.CardType.EVENT,
            name: CardName_1.CardName.ECONOMIC_HELP,
            cost: 9,
            metadata: {
                cardNumber: 'Pf42',
                renderData: CardRenderer_1.CardRenderer.builder(function (b) {
                    b.planetaryTrack().text('3').or().text('2')
                        .venus(1, { played: Options_1.played }).or(Size_1.Size.SMALL)
                        .earth(1, { played: Options_1.played }).or(Size_1.Size.SMALL).br;
                    b.mars(1, { played: Options_1.played }).or(Size_1.Size.SMALL)
                        .jovian({ amount: 1, played: Options_1.played }).or(Size_1.Size.SMALL)
                        .moon(1, { played: Options_1.played }).br;
                    b.production(function (pb) { return pb.megacredits(1); });
                }),
                description: 'Raise the lowest planetary influence track by 3. When tied, raise all lowest tracks by 2. ' +
                    'Increase your M€ production 1 step',
            },
        }) || this;
    }
    EconomicHelp.prototype.play = function (player) {
        var data = player.game.pathfindersData;
        if (data === undefined) {
            return undefined;
        }
        var values = [data.earth, data.jovian, data.mars];
        if (player.game.gameOptions.moonExpansion === true)
            values.push(data.moon);
        if (player.game.gameOptions.venusNextExtension === true)
            values.push(data.venus);
        var lowest = Math.min.apply(Math, (values.filter(function (v) { return v >= 0; })));
        var count = values.filter(function (v) { return v === lowest; }).length;
        var increment = (count === 1) ? 3 : 2;
        if (data.earth === lowest)
            PathfindersExpansion_1.PathfindersExpansion.raiseTrack(Tags_1.Tags.EARTH, player, increment);
        if (data.jovian === lowest)
            PathfindersExpansion_1.PathfindersExpansion.raiseTrack(Tags_1.Tags.JOVIAN, player, increment);
        if (data.mars === lowest)
            PathfindersExpansion_1.PathfindersExpansion.raiseTrack(Tags_1.Tags.MARS, player, increment);
        if (data.moon === lowest && player.game.gameOptions.moonExpansion === true)
            PathfindersExpansion_1.PathfindersExpansion.raiseTrack(Tags_1.Tags.MOON, player, increment);
        if (data.venus === lowest && player.game.gameOptions.venusNextExtension === true)
            PathfindersExpansion_1.PathfindersExpansion.raiseTrack(Tags_1.Tags.VENUS, player, increment);
        player.addProduction(Resources_1.Resources.MEGACREDITS, 1);
        return undefined;
    };
    return EconomicHelp;
}(Card_1.Card));
exports.EconomicHelp = EconomicHelp;
