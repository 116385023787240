"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.GHGImportFromVenus = void 0;
var Tags_1 = require("../Tags");
var CardType_1 = require("../CardType");
var Resources_1 = require("../../common/Resources");
var CardName_1 = require("../../CardName");
var CardRenderer_1 = require("../render/CardRenderer");
var Card_1 = require("../Card");
var GHGImportFromVenus = (function (_super) {
    __extends(GHGImportFromVenus, _super);
    function GHGImportFromVenus() {
        return _super.call(this, {
            name: CardName_1.CardName.GHG_IMPORT_FROM_VENUS,
            cardType: CardType_1.CardType.EVENT,
            tags: [Tags_1.Tags.SPACE, Tags_1.Tags.VENUS],
            cost: 23,
            tr: { venus: 1 },
            metadata: {
                description: 'Raise Venus 1 step. Increase your heat production 3 steps.',
                cardNumber: '228',
                renderData: CardRenderer_1.CardRenderer.builder(function (b) {
                    b.venus(1).production(function (pb) {
                        pb.heat(3);
                    });
                }),
            },
        }) || this;
    }
    ;
    GHGImportFromVenus.prototype.play = function (player) {
        player.addProduction(Resources_1.Resources.HEAT, 3);
        player.game.increaseVenusScaleLevel(player, 1);
        return undefined;
    };
    return GHGImportFromVenus;
}(Card_1.Card));
exports.GHGImportFromVenus = GHGImportFromVenus;
