"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.Potatoes = void 0;
var Tags_1 = require("./../Tags");
var Card_1 = require("../Card");
var CardType_1 = require("./../CardType");
var Resources_1 = require("../../common/Resources");
var CardName_1 = require("../../CardName");
var CardRenderer_1 = require("../render/CardRenderer");
var Potatoes = (function (_super) {
    __extends(Potatoes, _super);
    function Potatoes() {
        return _super.call(this, {
            cardType: CardType_1.CardType.AUTOMATED,
            name: CardName_1.CardName.POTATOES,
            tags: [Tags_1.Tags.PLANT],
            cost: 2,
            metadata: {
                cardNumber: 'X28',
                renderData: CardRenderer_1.CardRenderer.builder(function (b) {
                    b.minus().plants(2).nbsp.production(function (pb) { return pb.megacredits(2); });
                }),
                description: 'Lose 2 plants. Increase your M€ production 2 steps.',
            },
        }) || this;
    }
    Potatoes.prototype.canPlay = function (player) {
        var viralEnhancers = player.playedCards.find(function (card) { return card.name === CardName_1.CardName.VIRAL_ENHANCERS; });
        var hasEnoughPlants = player.plants >= 2 || player.plants >= 1 && viralEnhancers !== undefined;
        return hasEnoughPlants;
    };
    Potatoes.prototype.play = function (player) {
        player.plants -= 2;
        player.addProduction(Resources_1.Resources.MEGACREDITS, 2);
        return undefined;
    };
    return Potatoes;
}(Card_1.Card));
exports.Potatoes = Potatoes;
