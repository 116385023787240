"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Tags = void 0;
var Tags;
(function (Tags) {
    Tags["BUILDING"] = "building";
    Tags["SPACE"] = "space";
    Tags["SCIENCE"] = "science";
    Tags["ENERGY"] = "power";
    Tags["EARTH"] = "earth";
    Tags["JOVIAN"] = "jovian";
    Tags["VENUS"] = "venus";
    Tags["PLANT"] = "plant";
    Tags["MICROBE"] = "microbe";
    Tags["ANIMAL"] = "animal";
    Tags["CITY"] = "city";
    Tags["MOON"] = "moon";
    Tags["MARS"] = "mars";
    Tags["WILDCARD"] = "wild";
    Tags["EVENT"] = "event";
    Tags["CLONE"] = "clone";
})(Tags = exports.Tags || (exports.Tags = {}));
