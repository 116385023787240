"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Astrodrill = void 0;
var Tags_1 = require("../Tags");
var Card_1 = require("../Card");
var CardName_1 = require("../../CardName");
var ResourceType_1 = require("../../common/ResourceType");
var SelectOption_1 = require("../../inputs/SelectOption");
var SelectCard_1 = require("../../inputs/SelectCard");
var OrOptions_1 = require("../../inputs/OrOptions");
var LogHelper_1 = require("../../LogHelper");
var Resources_1 = require("../../common/Resources");
var CardType_1 = require("../CardType");
var CardRenderer_1 = require("../render/CardRenderer");
var Size_1 = require("../render/Size");
var Options_1 = require("../Options");
var Astrodrill = (function (_super) {
    __extends(Astrodrill, _super);
    function Astrodrill() {
        var _this = _super.call(this, {
            cardType: CardType_1.CardType.CORPORATION,
            name: CardName_1.CardName.ASTRODRILL,
            tags: [Tags_1.Tags.SPACE],
            startingMegaCredits: 35,
            resourceType: ResourceType_1.ResourceType.ASTEROID,
            metadata: {
                cardNumber: 'R21',
                description: 'You start with 35 M€ and 3 asteroid resources.',
                renderData: CardRenderer_1.CardRenderer.builder(function (b) {
                    b.br;
                    b.megacredits(35).nbsp.asteroids(3, { digit: Options_1.digit });
                    b.corpBox('action', function (ce) {
                        ce.vSpace(Size_1.Size.LARGE);
                        ce.action(undefined, function (eb) {
                            eb.empty().startAction.asteroids(1).asterix().slash().wild(1).or();
                        });
                        ce.vSpace();
                        ce.action('Add an asteroid resource to ANY card OR gain any standard resource, OR remove an asteroid resource from this card to gain 3 titanium.', function (eb) {
                            eb.asteroids(1).startAction.titanium(3, { digit: Options_1.digit });
                        });
                    });
                }),
            },
        }) || this;
        _this.resourceCount = 0;
        return _this;
    }
    Astrodrill.prototype.canAct = function () {
        return true;
    };
    Astrodrill.prototype.action = function (player) {
        var _this = this;
        var asteroidCards = player.getResourceCards(ResourceType_1.ResourceType.ASTEROID);
        var opts = [];
        var gainStandardResource = new SelectOption_1.SelectOption('Gain a standard resource', 'Gain', function () {
            return new OrOptions_1.OrOptions(new SelectOption_1.SelectOption('Gain 1 titanium', 'Gain titanium', function () {
                player.addResource(Resources_1.Resources.TITANIUM, 1, { log: true });
                return undefined;
            }), new SelectOption_1.SelectOption('Gain 1 steel', 'Gain steel', function () {
                player.addResource(Resources_1.Resources.STEEL, 1, { log: true });
                return undefined;
            }), new SelectOption_1.SelectOption('Gain 1 plant', 'Gain plant', function () {
                player.addResource(Resources_1.Resources.PLANTS, 1, { log: true });
                return undefined;
            }), new SelectOption_1.SelectOption('Gain 1 energy', 'Gain energy', function () {
                player.addResource(Resources_1.Resources.ENERGY, 1, { log: true });
                return undefined;
            }), new SelectOption_1.SelectOption('Gain 1 heat', 'Gain heat', function () {
                player.addResource(Resources_1.Resources.HEAT, 1, { log: true });
                return undefined;
            }), new SelectOption_1.SelectOption('Gain 1 M€', 'Gain M€', function () {
                player.addResource(Resources_1.Resources.MEGACREDITS, 1, { log: true });
                return undefined;
            }));
        });
        var addResourceToSelf = new SelectOption_1.SelectOption('Add 1 asteroid to this card', 'Add asteroid', function () {
            player.addResourceTo(_this, { log: true });
            return undefined;
        });
        var addResource = new SelectCard_1.SelectCard('Select card to add 1 asteroid', 'Add asteroid', asteroidCards, function (foundCards) {
            player.addResourceTo(foundCards[0], { log: true });
            return undefined;
        });
        var spendResource = new SelectOption_1.SelectOption('Remove 1 asteroid on this card to gain 3 titanium', 'Remove asteroid', function () {
            _this.resourceCount--;
            player.titanium += 3;
            LogHelper_1.LogHelper.logRemoveResource(player, _this, 1, 'gain 3 titanium');
            return undefined;
        });
        if (this.resourceCount > 0)
            opts.push(spendResource);
        asteroidCards.length === 1 ? opts.push(addResourceToSelf) : opts.push(addResource);
        opts.push(gainStandardResource);
        return new (OrOptions_1.OrOptions.bind.apply(OrOptions_1.OrOptions, __spreadArray([void 0], opts, false)))();
    };
    Astrodrill.prototype.play = function () {
        this.resourceCount = 3;
        return undefined;
    };
    return Astrodrill;
}(Card_1.Card));
exports.Astrodrill = Astrodrill;
