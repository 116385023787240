"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.Diversity = void 0;
var IGlobalEvent_1 = require("./IGlobalEvent");
var GlobalEventName_1 = require("./GlobalEventName");
var PartyName_1 = require("../parties/PartyName");
var Resources_1 = require("../../common/Resources");
var CardRenderer_1 = require("../../cards/render/CardRenderer");
var Size_1 = require("../../cards/render/Size");
var RENDER_DATA = CardRenderer_1.CardRenderer.builder(function (b) {
    b.text('9').diverseTag(1).influence({ size: Size_1.Size.SMALL }).colon().megacredits(10);
});
var Diversity = (function (_super) {
    __extends(Diversity, _super);
    function Diversity() {
        return _super.call(this, {
            name: GlobalEventName_1.GlobalEventName.DIVERSITY,
            description: 'Gain 10 M€ if you have 9 or more different tags. Influence counts as unique tags.',
            revealedDelegate: PartyName_1.PartyName.SCIENTISTS,
            currentDelegate: PartyName_1.PartyName.SCIENTISTS,
            renderData: RENDER_DATA,
        }) || this;
    }
    Diversity.prototype.resolve = function (game, turmoil) {
        var _this = this;
        game.getPlayers().forEach(function (player) {
            if (player.getDistinctTagCount(false) + turmoil.getPlayerInfluence(player) >= 9) {
                player.addResource(Resources_1.Resources.MEGACREDITS, 10, { log: true, from: _this.name });
            }
        });
    };
    return Diversity;
}(IGlobalEvent_1.GlobalEvent));
exports.Diversity = Diversity;
