"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.RareEarthElements = void 0;
var Card_1 = require("../Card");
var CardType_1 = require("../CardType");
var CardName_1 = require("../../CardName");
var CardRenderer_1 = require("../render/CardRenderer");
var Resources_1 = require("../../common/Resources");
var Tags_1 = require("../Tags");
var Board_1 = require("../../boards/Board");
var RareEarthElements = (function (_super) {
    __extends(RareEarthElements, _super);
    function RareEarthElements() {
        return _super.call(this, {
            cardType: CardType_1.CardType.AUTOMATED,
            name: CardName_1.CardName.RARE_EARTH_ELEMENTS,
            cost: 5,
            tags: [Tags_1.Tags.EARTH, Tags_1.Tags.MARS],
            metadata: {
                cardNumber: 'Pf06',
                renderData: CardRenderer_1.CardRenderer.builder(function (b) {
                    b.production((function (pb) { return pb.megacredits(1); })).slash().specialTile();
                }),
                description: 'Increase your M€ production by 1 for every special tile you own on Mars.',
            },
        }) || this;
    }
    RareEarthElements.prototype.play = function (player) {
        var spaces = player.game.board.spaces
            .filter((0, Board_1.playerTileFn)(player))
            .filter(Board_1.isSpecialTile);
        player.addProduction(Resources_1.Resources.MEGACREDITS, spaces.length, { log: true });
        return undefined;
    };
    return RareEarthElements;
}(Card_1.Card));
exports.RareEarthElements = RareEarthElements;
