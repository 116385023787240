"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.AsteroidRights = void 0;
var Card_1 = require("../Card");
var CardName_1 = require("../../CardName");
var CardType_1 = require("../CardType");
var ResourceType_1 = require("../../common/ResourceType");
var Tags_1 = require("../Tags");
var Resources_1 = require("../../common/Resources");
var LogHelper_1 = require("../../LogHelper");
var SelectCard_1 = require("../../inputs/SelectCard");
var OrOptions_1 = require("../../inputs/OrOptions");
var SelectOption_1 = require("../../inputs/SelectOption");
var SelectHowToPayDeferred_1 = require("../../deferredActions/SelectHowToPayDeferred");
var CardRenderer_1 = require("../render/CardRenderer");
var AsteroidRights = (function (_super) {
    __extends(AsteroidRights, _super);
    function AsteroidRights() {
        var _this = _super.call(this, {
            cardType: CardType_1.CardType.ACTIVE,
            name: CardName_1.CardName.ASTEROID_RIGHTS,
            tags: [Tags_1.Tags.EARTH, Tags_1.Tags.SPACE],
            cost: 10,
            resourceType: ResourceType_1.ResourceType.ASTEROID,
            metadata: {
                cardNumber: 'X31',
                description: 'Add 2 asteroids to this card.',
                renderData: CardRenderer_1.CardRenderer.builder(function (b) {
                    b.action('Spend 1 M€ to add 1 asteroid to ANY card.', function (eb) {
                        eb.megacredits(1).startAction.asteroids(1).asterix().nbsp.or();
                    }).br;
                    b.action('Spend 1 asteroid here to increase M€ production 1 step OR gain 2 titanium.', function (eb) {
                        eb.asteroids(1)
                            .startAction.production(function (pb) { return pb.megacredits(1); })
                            .or()
                            .titanium(2);
                    }).br;
                    b.asteroids(2);
                }),
            },
        }) || this;
        _this.resourceCount = 0;
        return _this;
    }
    AsteroidRights.prototype.play = function () {
        this.resourceCount = 2;
        return undefined;
    };
    AsteroidRights.prototype.canAct = function (player) {
        return player.canAfford(1) || this.resourceCount > 0;
    };
    AsteroidRights.prototype.action = function (player) {
        var _this = this;
        var canAddAsteroid = player.canAfford(1);
        var hasAsteroids = this.resourceCount > 0;
        var asteroidCards = player.getResourceCards(ResourceType_1.ResourceType.ASTEROID);
        var gainTitaniumOption = new SelectOption_1.SelectOption('Remove 1 asteroid on this card to gain 2 titanium', 'Remove asteroid', function () {
            _this.resourceCount--;
            player.titanium += 2;
            LogHelper_1.LogHelper.logRemoveResource(player, _this, 1, 'gain 2 titanium');
            return undefined;
        });
        var increaseMcProdOption = new SelectOption_1.SelectOption('Remove 1 asteroid on this card to increase M€ production 1 step', 'Remove asteroid', function () {
            _this.resourceCount--;
            player.addProduction(Resources_1.Resources.MEGACREDITS, 1);
            LogHelper_1.LogHelper.logRemoveResource(player, _this, 1, 'increase M€ production 1 step');
            return undefined;
        });
        var addAsteroidToSelf = new SelectOption_1.SelectOption('Add 1 asteroid to this card', 'Add asteroid', function () {
            player.game.defer(new SelectHowToPayDeferred_1.SelectHowToPayDeferred(player, 1, { title: 'Select how to pay for asteroid' }));
            player.addResourceTo(_this, { log: true });
            return undefined;
        });
        var addAsteroidOption = new SelectCard_1.SelectCard('Select card to add 1 asteroid', 'Add asteroid', asteroidCards, function (foundCards) {
            player.game.defer(new SelectHowToPayDeferred_1.SelectHowToPayDeferred(player, 1, { title: 'Select how to pay for asteroid' }));
            player.addResourceTo(foundCards[0], { log: true });
            return undefined;
        });
        if (!canAddAsteroid)
            return new OrOptions_1.OrOptions(gainTitaniumOption, increaseMcProdOption);
        if (!hasAsteroids) {
            if (asteroidCards.length === 1)
                return addAsteroidToSelf.cb();
            return addAsteroidOption;
        }
        var opts = [];
        opts.push(gainTitaniumOption);
        opts.push(increaseMcProdOption);
        asteroidCards.length === 1 ? opts.push(addAsteroidToSelf) : opts.push(addAsteroidOption);
        return new (OrOptions_1.OrOptions.bind.apply(OrOptions_1.OrOptions, __spreadArray([void 0], opts, false)))();
    };
    return AsteroidRights;
}(Card_1.Card));
exports.AsteroidRights = AsteroidRights;
