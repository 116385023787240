"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.HE3ProductionQuotas = void 0;
var CardName_1 = require("../../CardName");
var CardType_1 = require("../CardType");
var Tags_1 = require("../Tags");
var CardRenderer_1 = require("../render/CardRenderer");
var CardRequirements_1 = require("../CardRequirements");
var PartyName_1 = require("../../turmoil/parties/PartyName");
var MoonExpansion_1 = require("../../moon/MoonExpansion");
var TileType_1 = require("../../common/TileType");
var Card_1 = require("../Card");
var Size_1 = require("../render/Size");
var Options_1 = require("../Options");
var HE3ProductionQuotas = (function (_super) {
    __extends(HE3ProductionQuotas, _super);
    function HE3ProductionQuotas() {
        return _super.call(this, {
            name: CardName_1.CardName.HE3_PRODUCTION_QUOTAS,
            cardType: CardType_1.CardType.EVENT,
            tags: [Tags_1.Tags.MOON],
            cost: 10,
            tr: { moonMining: 1 },
            requirements: CardRequirements_1.CardRequirements.builder(function (b) { return b.party(PartyName_1.PartyName.KELVINISTS).miningTiles(1, { all: Options_1.all }); }),
            metadata: {
                description: 'Requires that Kelvinists are ruling or that you have 2 delegates there, and 1 mine tile on the Moon. ' +
                    'Pay 1 steel per mine tile on the Moon to gain 4 heat per mine tile on the Moon. Raise the Mining Rate 1 step.',
                cardNumber: 'M57',
                renderData: CardRenderer_1.CardRenderer.builder(function (b) {
                    b.minus().steel(1).slash().moonMine({ size: Size_1.Size.SMALL, all: Options_1.all })
                        .colon().text('4').heat(1).slash().moonMine({ size: Size_1.Size.SMALL, all: Options_1.all }).br;
                    b.moonMiningRate();
                }),
            },
        }) || this;
    }
    ;
    HE3ProductionQuotas.prototype.canPlay = function (player) {
        var moonTiles = MoonExpansion_1.MoonExpansion.tiles(player.game, TileType_1.TileType.MOON_MINE, { surfaceOnly: true });
        if (player.steel < moonTiles.length) {
            return false;
        }
        return true;
    };
    HE3ProductionQuotas.prototype.play = function (player) {
        var moonTiles = MoonExpansion_1.MoonExpansion.tiles(player.game, TileType_1.TileType.MOON_MINE, { surfaceOnly: true });
        player.steel -= moonTiles.length;
        player.heat += (4 * moonTiles.length);
        MoonExpansion_1.MoonExpansion.raiseMiningRate(player);
        return undefined;
    };
    return HE3ProductionQuotas;
}(Card_1.Card));
exports.HE3ProductionQuotas = HE3ProductionQuotas;
