"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.SocialEvents = void 0;
var Card_1 = require("../Card");
var CardType_1 = require("../CardType");
var CardName_1 = require("../../CardName");
var CardRenderer_1 = require("../render/CardRenderer");
var Tags_1 = require("../Tags");
var Options_1 = require("../Options");
var SocialEvents = (function (_super) {
    __extends(SocialEvents, _super);
    function SocialEvents() {
        return _super.call(this, {
            cardType: CardType_1.CardType.EVENT,
            name: CardName_1.CardName.SOCIAL_EVENTS,
            cost: 18,
            tags: [Tags_1.Tags.EARTH, Tags_1.Tags.MARS],
            metadata: {
                cardNumber: '...',
                renderData: CardRenderer_1.CardRenderer.builder(function (b) {
                    b.tr(1).slash().mars(2, { played: Options_1.played });
                }),
                description: 'Gain 1 TR for every 2 Mars tags you have (including this one.)',
            },
        }) || this;
    }
    SocialEvents.prototype.getExpectedTr = function (player) {
        return Math.floor((player.getTagCount(Tags_1.Tags.MARS) + 1) / 2);
    };
    SocialEvents.prototype.canPlay = function (player) {
        return player.canAfford(player.getCardCost(this), { tr: { tr: this.getExpectedTr(player) } });
    };
    SocialEvents.prototype.play = function (player) {
        player.increaseTerraformRatingSteps(this.getExpectedTr(player));
        return undefined;
    };
    return SocialEvents;
}(Card_1.Card));
exports.SocialEvents = SocialEvents;
