"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.EarlySettlement = void 0;
var Tags_1 = require("../Tags");
var PreludeCard_1 = require("./PreludeCard");
var Resources_1 = require("../../common/Resources");
var CardName_1 = require("../../CardName");
var PlaceCityTile_1 = require("../../deferredActions/PlaceCityTile");
var CardRenderer_1 = require("../render/CardRenderer");
var Units_1 = require("../../Units");
var EarlySettlement = (function (_super) {
    __extends(EarlySettlement, _super);
    function EarlySettlement() {
        return _super.call(this, {
            name: CardName_1.CardName.EARLY_SETTLEMENT,
            tags: [Tags_1.Tags.BUILDING, Tags_1.Tags.CITY],
            productionBox: Units_1.Units.of({ plants: 1 }),
            metadata: {
                cardNumber: 'P09',
                renderData: CardRenderer_1.CardRenderer.builder(function (b) {
                    b.production(function (pb) { return pb.plants(1); }).city();
                }),
                description: 'Increase your plant production 1 step. Place a city tile.',
            },
        }) || this;
    }
    EarlySettlement.prototype.play = function (player) {
        player.addProduction(Resources_1.Resources.PLANTS, 1);
        player.game.defer(new PlaceCityTile_1.PlaceCityTile(player));
        return undefined;
    };
    return EarlySettlement;
}(PreludeCard_1.PreludeCard));
exports.EarlySettlement = EarlySettlement;
