"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CardRenderDynamicVictoryPoints = void 0;
var CardRenderItemType_1 = require("./CardRenderItemType");
var CardRenderItem_1 = require("./CardRenderItem");
var Size_1 = require("./Size");
var ResourceType_1 = require("../../common/ResourceType");
var Tags_1 = require("../../cards/Tags");
var RESOURCE_TO_ITEM_TYPE = new Map([
    [ResourceType_1.ResourceType.MICROBE, CardRenderItemType_1.CardRenderItemType.MICROBES],
    [ResourceType_1.ResourceType.ANIMAL, CardRenderItemType_1.CardRenderItemType.ANIMALS],
    [ResourceType_1.ResourceType.CAMP, CardRenderItemType_1.CardRenderItemType.CAMPS],
    [ResourceType_1.ResourceType.DATA, CardRenderItemType_1.CardRenderItemType.DATA_RESOURCE],
    [ResourceType_1.ResourceType.SCIENCE, CardRenderItemType_1.CardRenderItemType.SCIENCE],
    [ResourceType_1.ResourceType.RESOURCE_CUBE, CardRenderItemType_1.CardRenderItemType.RESOURCE_CUBE],
    [ResourceType_1.ResourceType.PRESERVATION, CardRenderItemType_1.CardRenderItemType.PRESERVATION],
    [ResourceType_1.ResourceType.ASTEROID, CardRenderItemType_1.CardRenderItemType.ASTEROIDS],
    [ResourceType_1.ResourceType.FIGHTER, CardRenderItemType_1.CardRenderItemType.FIGHTER],
    [ResourceType_1.ResourceType.FLOATER, CardRenderItemType_1.CardRenderItemType.FLOATERS],
    [ResourceType_1.ResourceType.VENUSIAN_HABITAT, CardRenderItemType_1.CardRenderItemType.VENUSIAN_HABITAT],
    [ResourceType_1.ResourceType.SPECIALIZED_ROBOT, CardRenderItemType_1.CardRenderItemType.SPECIALIZED_ROBOT],
]);
var CardRenderDynamicVictoryPoints = (function () {
    function CardRenderDynamicVictoryPoints(item, points, target) {
        this.item = item;
        this.points = points;
        this.target = target;
        this.targetOneOrMore = false;
        this.anyPlayer = false;
    }
    CardRenderDynamicVictoryPoints.prototype.getPointsHtml = function () {
        if (this.item === undefined && this.points === 0 && this.target === 0)
            return '?';
        if (this.item === undefined)
            return "" + this.points;
        if (this.target === this.points || this.target === 1)
            return this.points + "/";
        return this.points + "/" + this.target;
    };
    CardRenderDynamicVictoryPoints.asteroids = function (points, target) {
        return new CardRenderDynamicVictoryPoints(new CardRenderItem_1.CardRenderItem(CardRenderItemType_1.CardRenderItemType.ASTEROIDS), points, target);
    };
    CardRenderDynamicVictoryPoints.resource = function (type, points, target) {
        var itemType = RESOURCE_TO_ITEM_TYPE.get(type);
        if (itemType === undefined) {
            throw new Error('Unknown item type ' + type);
        }
        return new CardRenderDynamicVictoryPoints(new CardRenderItem_1.CardRenderItem(itemType), points, target);
    };
    CardRenderDynamicVictoryPoints.tag = function (type, points, target) {
        var map = new Map([
            [Tags_1.Tags.JOVIAN, CardRenderItemType_1.CardRenderItemType.JOVIAN],
            [Tags_1.Tags.MOON, CardRenderItemType_1.CardRenderItemType.MOON],
            [Tags_1.Tags.VENUS, CardRenderItemType_1.CardRenderItemType.VENUS],
        ]);
        var itemType = map.get(type);
        if (itemType === undefined) {
            throw new Error('Unknown item type ' + type);
        }
        return new CardRenderDynamicVictoryPoints(new CardRenderItem_1.CardRenderItem(itemType, 1, { played: true }), points, target);
    };
    CardRenderDynamicVictoryPoints.oceans = function (points, target) {
        var item = new CardRenderItem_1.CardRenderItem(CardRenderItemType_1.CardRenderItemType.OCEANS);
        item.size = Size_1.Size.SMALL;
        return new CardRenderDynamicVictoryPoints(item, points, target);
    };
    CardRenderDynamicVictoryPoints.cities = function (points, target, any) {
        if (any === void 0) { any = false; }
        var item = new CardRenderItem_1.CardRenderItem(CardRenderItemType_1.CardRenderItemType.CITY);
        item.size = Size_1.Size.SMALL;
        item.anyPlayer = any;
        return new CardRenderDynamicVictoryPoints(item, points, target);
    };
    CardRenderDynamicVictoryPoints.searchForLife = function () {
        var item = new CardRenderDynamicVictoryPoints(new CardRenderItem_1.CardRenderItem(CardRenderItemType_1.CardRenderItemType.SCIENCE), 3, 3);
        item.targetOneOrMore = true;
        return item;
    };
    CardRenderDynamicVictoryPoints.colonies = function (points, target, any) {
        if (any === void 0) { any = false; }
        var item = new CardRenderItem_1.CardRenderItem(CardRenderItemType_1.CardRenderItemType.COLONIES);
        item.size = Size_1.Size.SMALL;
        item.anyPlayer = any;
        return new CardRenderDynamicVictoryPoints(item, points, target);
    };
    CardRenderDynamicVictoryPoints.moonMiningTile = function (points, any) {
        if (any === void 0) { any = false; }
        var item = new CardRenderItem_1.CardRenderItem(CardRenderItemType_1.CardRenderItemType.MOON_MINE);
        item.size = Size_1.Size.SMALL;
        item.anyPlayer = any;
        return new CardRenderDynamicVictoryPoints(item, points, points);
    };
    CardRenderDynamicVictoryPoints.moonColonyTile = function (points) {
        var item = new CardRenderItem_1.CardRenderItem(CardRenderItemType_1.CardRenderItemType.MOON_COLONY);
        item.size = Size_1.Size.SMALL;
        return new CardRenderDynamicVictoryPoints(item, points, 1);
    };
    CardRenderDynamicVictoryPoints.moonRoadTile = function (points, any) {
        if (any === void 0) { any = false; }
        var item = new CardRenderItem_1.CardRenderItem(CardRenderItemType_1.CardRenderItemType.MOON_ROAD);
        item.size = Size_1.Size.SMALL;
        item.anyPlayer = any;
        return new CardRenderDynamicVictoryPoints(item, points, 1);
    };
    CardRenderDynamicVictoryPoints.questionmark = function () {
        return new CardRenderDynamicVictoryPoints(undefined, 0, 0);
    };
    CardRenderDynamicVictoryPoints.any = function (points) {
        var item = new CardRenderDynamicVictoryPoints(undefined, points, points);
        item.anyPlayer = true;
        return item;
    };
    return CardRenderDynamicVictoryPoints;
}());
exports.CardRenderDynamicVictoryPoints = CardRenderDynamicVictoryPoints;
