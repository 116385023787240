"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.MudSlides = void 0;
var IGlobalEvent_1 = require("./IGlobalEvent");
var GlobalEventName_1 = require("./GlobalEventName");
var PartyName_1 = require("../parties/PartyName");
var Resources_1 = require("../../common/Resources");
var TileType_1 = require("../../common/TileType");
var CardRenderer_1 = require("../../cards/render/CardRenderer");
var Size_1 = require("../../cards/render/Size");
var RENDER_DATA = CardRenderer_1.CardRenderer.builder(function (b) {
    b.minus().megacredits(4).slash().oceans(1).emptyTile().influence({ size: Size_1.Size.SMALL });
});
var MudSlides = (function (_super) {
    __extends(MudSlides, _super);
    function MudSlides() {
        return _super.call(this, {
            name: GlobalEventName_1.GlobalEventName.MUD_SLIDES,
            description: 'Lose 4 M€ for each tile adjacent to ocean (max 5, then reduced by influence).',
            revealedDelegate: PartyName_1.PartyName.KELVINISTS,
            currentDelegate: PartyName_1.PartyName.GREENS,
            renderData: RENDER_DATA,
        }) || this;
    }
    MudSlides.prototype.resolve = function (game, turmoil) {
        var _this = this;
        game.getPlayers().forEach(function (player) {
            var tiles = game.board.spaces.filter(function (space) { return (space.player !== undefined && space.player === player && space.tile !== undefined) &&
                game.board.getAdjacentSpaces(space)
                    .filter(function (space) { return (space.tile !== undefined &&
                    space.tile.tileType === TileType_1.TileType.OCEAN); }).length > 0; }).length;
            var amount = Math.min(5, tiles) - turmoil.getPlayerInfluence(player);
            if (amount > 0) {
                player.deductResource(Resources_1.Resources.MEGACREDITS, 4 * amount, { log: true, from: _this.name });
            }
        });
    };
    return MudSlides;
}(IGlobalEvent_1.GlobalEvent));
exports.MudSlides = MudSlides;
