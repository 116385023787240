"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.TitaniumMarketMonopolists = void 0;
var CardName_1 = require("../../CardName");
var CardType_1 = require("../CardType");
var CardRequirements_1 = require("../CardRequirements");
var CardRenderer_1 = require("../render/CardRenderer");
var Resources_1 = require("../../common/Resources");
var MarketCard_1 = require("./MarketCard");
var Options_1 = require("../Options");
var TitaniumMarketMonopolists = (function (_super) {
    __extends(TitaniumMarketMonopolists, _super);
    function TitaniumMarketMonopolists() {
        return _super.call(this, Resources_1.Resources.TITANIUM, { from: 2, to: 1, limit: 4 }, { from: 1, to: 4, limit: 4 }, {
            name: CardName_1.CardName.TITANIUM_MARKET_MONOPOLISTS,
            cardType: CardType_1.CardType.ACTIVE,
            cost: 21,
            requirements: CardRequirements_1.CardRequirements.builder(function (b) { return b.miningRate(3); }),
            metadata: {
                description: 'Requires Mining Rate to be 3 or higher.',
                cardNumber: 'M29',
                renderData: CardRenderer_1.CardRenderer.builder(function (b) {
                    b.action('Spend 2X M€ to gain X titanium [max 8 M€]', function (eb) {
                        eb.megacredits(2, { multiplier: Options_1.multiplier }).startAction.text('X').titanium(1).asterix();
                    }).br;
                    b.or().br;
                    b.action('Spend X titanium to gain 4X M€ [max 4 titanium].', function (eb) {
                        eb.text('X').titanium(1).startAction.text('X').megacredits(4).asterix();
                    });
                }),
            },
        }) || this;
    }
    return TitaniumMarketMonopolists;
}(MarketCard_1.MarketCard));
exports.TitaniumMarketMonopolists = TitaniumMarketMonopolists;
