"use strict";
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
var vue_1 = require("vue");
var CardTitle_vue_1 = require("./CardTitle.vue");
var CardNumber_vue_1 = require("./CardNumber.vue");
var CardResourceCounter_vue_1 = require("./CardResourceCounter.vue");
var CardCost_vue_1 = require("./CardCost.vue");
var CardExtraContent_vue_1 = require("./CardExtraContent.vue");
var CardExpansion_vue_1 = require("./CardExpansion.vue");
var CardTags_vue_1 = require("./CardTags.vue");
var CardType_1 = require("@/cards/CardType");
var CardContent_vue_1 = require("./CardContent.vue");
var Tags_1 = require("@/cards/Tags");
var PreferencesManager_1 = require("@/client/utils/PreferencesManager");
var ResourceType_1 = require("@/common/ResourceType");
var ClientCardManifest_1 = require("@/client/cards/ClientCardManifest");
exports.default = vue_1.default.extend({
    name: 'Card',
    components: {
        CardTitle: CardTitle_vue_1.default,
        CardResourceCounter: CardResourceCounter_vue_1.default,
        CardCost: CardCost_vue_1.default,
        CardExtraContent: CardExtraContent_vue_1.default,
        CardExpansion: CardExpansion_vue_1.default,
        CardTags: CardTags_vue_1.default,
        CardContent: CardContent_vue_1.default,
        CardNumber: CardNumber_vue_1.default,
    },
    props: {
        'card': {
            type: Object,
            required: true,
        },
        'actionUsed': {
            type: Boolean,
            required: false,
            default: false,
        },
    },
    data: function () {
        var cardName = this.card.name;
        var cam = (0, ClientCardManifest_1.getCard)(cardName);
        if (cam === undefined) {
            throw new Error("Can't find card " + cardName);
        }
        return {
            cardInstance: cam.card,
            expansion: cam.module,
        };
    },
    methods: {
        getCardExpansion: function () {
            return this.expansion;
        },
        getCard: function () {
            return this.cardInstance;
        },
        getTags: function () {
            var _this = this;
            var _a;
            var type = this.getCardType();
            var tags = __spreadArray([], ((_a = this.getCard()) === null || _a === void 0 ? void 0 : _a.tags) || [], true);
            tags.forEach(function (tag, idx) {
                if (tag === Tags_1.Tags.CLONE && _this.card.cloneTag !== undefined) {
                    tags[idx] = _this.card.cloneTag;
                }
            });
            if (type === CardType_1.CardType.EVENT) {
                tags.push(Tags_1.Tags.EVENT);
            }
            return tags;
        },
        getCost: function () {
            var _a;
            var cost = (_a = this.getCard()) === null || _a === void 0 ? void 0 : _a.cost;
            var type = this.getCardType();
            return cost === undefined || type === CardType_1.CardType.PRELUDE || type === CardType_1.CardType.CORPORATION ? undefined : cost;
        },
        getReducedCost: function () {
            var cost = this.card.calculatedCost;
            var type = this.getCardType();
            return cost === undefined || type === CardType_1.CardType.PRELUDE || type === CardType_1.CardType.CORPORATION ? undefined : cost;
        },
        getCardType: function () {
            var _a;
            return (_a = this.getCard()) === null || _a === void 0 ? void 0 : _a.cardType;
        },
        getCardNumber: function () {
            var _a;
            return String((_a = this.getCardMetadata()) === null || _a === void 0 ? void 0 : _a.cardNumber);
        },
        getCardClasses: function (card) {
            var classes = ['card-container', 'filterDiv', 'hover-hide-res'];
            classes.push('card-' + card.name.toLowerCase().replace(/ /g, '-'));
            if (this.actionUsed || card.isDisabled) {
                classes.push('card-unavailable');
            }
            if (this.isStandardProject()) {
                classes.push('card-standard-project');
            }
            var learnerModeOff = PreferencesManager_1.PreferencesManager.load('learner_mode') === '0';
            if (learnerModeOff && this.isStandardProject() && card.isDisabled) {
                classes.push('card-hide');
            }
            return classes.join(' ');
        },
        getCardMetadata: function () {
            var _a;
            return (_a = this.getCard()) === null || _a === void 0 ? void 0 : _a.metadata;
        },
        getCardRequirements: function () {
            var _a;
            return (_a = this.getCard()) === null || _a === void 0 ? void 0 : _a.requirements;
        },
        getResourceAmount: function (card) {
            return card.resources !== undefined ? card.resources : 0;
        },
        isCorporationCard: function () {
            return this.getCardType() === CardType_1.CardType.CORPORATION;
        },
        isStandardProject: function () {
            return this.getCardType() === CardType_1.CardType.STANDARD_PROJECT || this.getCardType() === CardType_1.CardType.STANDARD_ACTION;
        },
    },
    computed: {
        hasResourceType: function () {
            return this.card.resourceType !== undefined || this.cardInstance.resourceType !== undefined;
        },
        resourceType: function () {
            if (this.card.resourceType !== undefined)
                return this.card.resourceType;
            if (this.cardInstance.resourceType !== undefined)
                return this.cardInstance.resourceType;
            return ResourceType_1.ResourceType.RESOURCE_CUBE;
        },
    },
});
