"use strict";
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
var vue_1 = require("vue");
exports.default = vue_1.default.extend({
    name: 'Award',
    props: {
        award: {
            type: Object,
            required: true,
        },
        showScores: {
            type: Boolean,
            default: false,
        },
    },
    data: function () {
        return {
            showDescription: false,
        };
    },
    methods: {
        toggleDescription: function () {
            this.showDescription = !this.showDescription;
        },
    },
    computed: {
        maAwardClass: function () {
            return 'ma-name--' + this.award.award.name.replace(/ /g, '-').toLowerCase();
        },
        sortedScores: function () {
            return __spreadArray([], this.award.scores, true).sort(function (s1, s2) { return s2.playerScore - s1.playerScore; });
        },
    },
});
