"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var vue_1 = require("vue");
var CardName_1 = require("@/CardName");
exports.default = vue_1.default.extend({
    name: 'CardCorporationLogo',
    props: {
        title: {
            type: String,
            required: true,
        },
    },
    computed: {
        CardName: function () {
            return CardName_1.CardName;
        },
    },
});
