"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.MartianIndustries = void 0;
var Tags_1 = require("../Tags");
var PreludeCard_1 = require("./PreludeCard");
var Resources_1 = require("../../common/Resources");
var CardName_1 = require("../../CardName");
var CardRenderer_1 = require("../render/CardRenderer");
var Units_1 = require("../../Units");
var MartianIndustries = (function (_super) {
    __extends(MartianIndustries, _super);
    function MartianIndustries() {
        return _super.call(this, {
            name: CardName_1.CardName.MARTIAN_INDUSTRIES,
            tags: [Tags_1.Tags.BUILDING],
            productionBox: Units_1.Units.of({ energy: 1, steel: 1 }),
            startingMegacredits: 6,
            metadata: {
                cardNumber: 'P18',
                renderData: CardRenderer_1.CardRenderer.builder(function (b) {
                    b.production(function (pb) { return pb.energy(1).steel(1); }).br;
                    b.megacredits(6);
                }),
                description: 'Increase your energy and steel production 1 step. Gain 6 M€.',
            },
        }) || this;
    }
    MartianIndustries.prototype.play = function (player) {
        player.addProduction(Resources_1.Resources.ENERGY, 1);
        player.addProduction(Resources_1.Resources.STEEL, 1);
        player.megaCredits += 6;
        return undefined;
    };
    return MartianIndustries;
}(PreludeCard_1.PreludeCard));
exports.MartianIndustries = MartianIndustries;
