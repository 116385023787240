"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var vue_1 = require("vue");
var Reward_vue_1 = require("./Reward.vue");
exports.default = vue_1.default.extend({
    name: 'Rewards',
    props: {
        rewards: {
            type: Object,
        },
        type: {
            type: String,
        },
        gameOptions: {
            type: Object,
        },
    },
    components: {
        Reward: Reward_vue_1.default,
    },
    data: function () {
        return {};
    },
    computed: {
        myReward: function () {
            switch (this.type) {
                case 'risingPlayer':
                    return this.rewards.risingPlayer;
                case 'everyone':
                    return this.rewards.everyone.concat(this.rewards.mostTags);
                default:
                    return [];
            }
        },
        mostTags: function () {
            return this.type === 'everyone' && this.rewards.mostTags.length > 0;
        },
    },
});
