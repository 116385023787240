"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.MARS_FIRST_POLICY_4 = exports.MARS_FIRST_POLICY_3 = exports.MARS_FIRST_POLICY_2 = exports.MARS_FIRST_POLICY_1 = exports.MARS_FIRST_BONUS_2 = exports.MARS_FIRST_BONUS_1 = exports.MarsFirst = void 0;
var Party_1 = require("./Party");
var PartyName_1 = require("./PartyName");
var Tags_1 = require("../../cards/Tags");
var Resources_1 = require("../../common/Resources");
var SpaceType_1 = require("../../SpaceType");
var Phase_1 = require("../../Phase");
var SelectHowToPayDeferred_1 = require("../../deferredActions/SelectHowToPayDeferred");
var constants_1 = require("../../constants");
var MarsFirst = (function (_super) {
    __extends(MarsFirst, _super);
    function MarsFirst() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.name = PartyName_1.PartyName.MARS;
        _this.description = 'Focused on Martian development and independence.';
        _this.bonuses = [exports.MARS_FIRST_BONUS_1, exports.MARS_FIRST_BONUS_2];
        _this.policies = [exports.MARS_FIRST_POLICY_1, exports.MARS_FIRST_POLICY_2, exports.MARS_FIRST_POLICY_3, exports.MARS_FIRST_POLICY_4];
        return _this;
    }
    return MarsFirst;
}(Party_1.Party));
exports.MarsFirst = MarsFirst;
var MarsFirstBonus01 = (function () {
    function MarsFirstBonus01() {
        this.id = 'mb01';
        this.description = 'Gain 1 M€ for each Building tag you have';
        this.isDefault = true;
    }
    MarsFirstBonus01.prototype.getScore = function (player) {
        return player.getTagCount(Tags_1.Tags.BUILDING, 'raw');
    };
    MarsFirstBonus01.prototype.grant = function (game) {
        var _this = this;
        game.getPlayers().forEach(function (player) {
            player.addResource(Resources_1.Resources.MEGACREDITS, _this.getScore(player));
        });
    };
    return MarsFirstBonus01;
}());
var MarsFirstBonus02 = (function () {
    function MarsFirstBonus02() {
        this.id = 'mb02';
        this.description = 'Gain 1 M€ for each tile you have ON MARS';
        this.isDefault = false;
    }
    MarsFirstBonus02.prototype.getScore = function (player) {
        var boardSpaces = player.game.board.spaces;
        return boardSpaces.filter(function (space) { return space.tile !== undefined && space.player === player && space.spaceType !== SpaceType_1.SpaceType.COLONY; }).length;
    };
    MarsFirstBonus02.prototype.grant = function (game) {
        var _this = this;
        game.getPlayers().forEach(function (player) {
            player.addResource(Resources_1.Resources.MEGACREDITS, _this.getScore(player));
        });
    };
    return MarsFirstBonus02;
}());
var MarsFirstPolicy01 = (function () {
    function MarsFirstPolicy01() {
        this.isDefault = true;
        this.id = 'mfp01';
        this.description = 'When you place a tile ON MARS, gain 1 steel';
    }
    MarsFirstPolicy01.prototype.onTilePlaced = function (player, space) {
        if (space.tile && space.spaceType !== SpaceType_1.SpaceType.COLONY && player.game.phase === Phase_1.Phase.ACTION) {
            player.addResource(Resources_1.Resources.STEEL, 1);
        }
    };
    return MarsFirstPolicy01;
}());
var MarsFirstPolicy02 = (function () {
    function MarsFirstPolicy02() {
        this.id = 'mfp02';
        this.description = 'When you play a Building tag, gain 2 M€';
        this.isDefault = false;
    }
    MarsFirstPolicy02.prototype.onCardPlayed = function (player, card) {
        if (card.tags.includes(Tags_1.Tags.BUILDING))
            player.addResource(Resources_1.Resources.MEGACREDITS, 2);
    };
    return MarsFirstPolicy02;
}());
var MarsFirstPolicy03 = (function () {
    function MarsFirstPolicy03() {
        this.id = 'mfp03';
        this.description = 'Your steel resources are worth 1 M€ extra';
        this.isDefault = false;
    }
    return MarsFirstPolicy03;
}());
var MarsFirstPolicy04 = (function () {
    function MarsFirstPolicy04() {
        this.id = 'mfp04';
        this.description = 'Spend 4 M€ to draw a Building card (Turmoil Mars First)';
        this.isDefault = false;
    }
    MarsFirstPolicy04.prototype.canAct = function (player) {
        return player.canAfford(4) && player.politicalAgendasActionUsedCount < constants_1.POLITICAL_AGENDAS_MAX_ACTION_USES;
    };
    MarsFirstPolicy04.prototype.action = function (player) {
        var game = player.game;
        game.log('${0} used Turmoil Mars First action', function (b) { return b.player(player); });
        player.politicalAgendasActionUsedCount += 1;
        game.defer(new SelectHowToPayDeferred_1.SelectHowToPayDeferred(player, 4, {
            title: 'Select how to pay for Turmoil Mars First action',
            afterPay: function () {
                player.drawCard(1, { tag: Tags_1.Tags.BUILDING });
            },
        }));
        return undefined;
    };
    return MarsFirstPolicy04;
}());
exports.MARS_FIRST_BONUS_1 = new MarsFirstBonus01();
exports.MARS_FIRST_BONUS_2 = new MarsFirstBonus02();
exports.MARS_FIRST_POLICY_1 = new MarsFirstPolicy01();
exports.MARS_FIRST_POLICY_2 = new MarsFirstPolicy02();
exports.MARS_FIRST_POLICY_3 = new MarsFirstPolicy03();
exports.MARS_FIRST_POLICY_4 = new MarsFirstPolicy04();
