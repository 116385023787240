"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.Extremophiles = void 0;
var Tags_1 = require("../Tags");
var CardType_1 = require("../CardType");
var ResourceType_1 = require("../../common/ResourceType");
var SelectCard_1 = require("../../inputs/SelectCard");
var CardName_1 = require("../../CardName");
var CardRequirements_1 = require("../CardRequirements");
var CardRenderer_1 = require("../render/CardRenderer");
var Card_1 = require("../Card");
var ICard_1 = require("../ICard");
var Extremophiles = (function (_super) {
    __extends(Extremophiles, _super);
    function Extremophiles() {
        var _this = _super.call(this, {
            name: CardName_1.CardName.EXTREMOPHILES,
            cardType: CardType_1.CardType.ACTIVE,
            tags: [Tags_1.Tags.VENUS, Tags_1.Tags.MICROBE],
            cost: 3,
            resourceType: ResourceType_1.ResourceType.MICROBE,
            victoryPoints: ICard_1.VictoryPoints.resource(1, 3),
            requirements: CardRequirements_1.CardRequirements.builder(function (b) { return b.tag(Tags_1.Tags.SCIENCE, 2); }),
            metadata: {
                cardNumber: '224',
                description: 'Requires 2 Science tags.',
                renderData: CardRenderer_1.CardRenderer.builder(function (b) {
                    b.action('Add 1 microbe to ANY card.', function (eb) {
                        eb.empty().startAction.microbes(1).asterix();
                    }).br;
                    b.vpText('1 VP for every 3rd Microbe on this card.');
                }),
            },
        }) || this;
        _this.resourceCount = 0;
        return _this;
    }
    ;
    Extremophiles.prototype.play = function () {
        return undefined;
    };
    Extremophiles.prototype.canAct = function () {
        return true;
    };
    Extremophiles.prototype.action = function (player) {
        var microbeCards = player.getResourceCards(ResourceType_1.ResourceType.MICROBE);
        if (microbeCards.length === 1) {
            player.addResourceTo(this, { log: true });
            return undefined;
        }
        return new SelectCard_1.SelectCard('Select card to add 1 microbe', 'Add microbe', microbeCards, function (foundCards) {
            player.addResourceTo(foundCards[0], { log: true });
            return undefined;
        });
    };
    return Extremophiles;
}(Card_1.Card));
exports.Extremophiles = Extremophiles;
