"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.SteelMarketMonopolists = void 0;
var CardName_1 = require("../../CardName");
var CardType_1 = require("../CardType");
var CardRenderer_1 = require("../render/CardRenderer");
var CardRequirements_1 = require("../CardRequirements");
var Resources_1 = require("../../common/Resources");
var MarketCard_1 = require("./MarketCard");
var Options_1 = require("../Options");
var SteelMarketMonopolists = (function (_super) {
    __extends(SteelMarketMonopolists, _super);
    function SteelMarketMonopolists() {
        return _super.call(this, Resources_1.Resources.STEEL, { from: 3, to: 2, limit: 3 }, { from: 1, to: 3, limit: 3 }, {
            name: CardName_1.CardName.STEEL_MARKET_MONOPOLISTS,
            cardType: CardType_1.CardType.ACTIVE,
            cost: 15,
            requirements: CardRequirements_1.CardRequirements.builder(function (b) { return b.miningRate(3); }),
            metadata: {
                description: 'Requires Mining Rate to be 3 or higher.',
                cardNumber: 'M28',
                renderData: CardRenderer_1.CardRenderer.builder(function (b) {
                    b.action('Spend 3X M€ to gain 2X steel (max 9 M€)', function (eb) {
                        eb.megacredits(3, { multiplier: Options_1.multiplier }).startAction.text('x').steel(2).asterix();
                    }).br;
                    b.or().br;
                    b.action('Spend X steel to gain 3X M€ (max 3 steel).', function (eb) {
                        eb.text('X').steel(1).startAction.text('x').megacredits(3).asterix();
                    });
                }),
            },
        }) || this;
    }
    return SteelMarketMonopolists;
}(MarketCard_1.MarketCard));
exports.SteelMarketMonopolists = SteelMarketMonopolists;
