"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.TollStation = void 0;
var Tags_1 = require("../Tags");
var Card_1 = require("../Card");
var CardType_1 = require("../CardType");
var Resources_1 = require("../../common/Resources");
var CardName_1 = require("../../CardName");
var CardRenderer_1 = require("../render/CardRenderer");
var Options_1 = require("../Options");
var TollStation = (function (_super) {
    __extends(TollStation, _super);
    function TollStation() {
        return _super.call(this, {
            cardType: CardType_1.CardType.AUTOMATED,
            name: CardName_1.CardName.TOLL_STATION,
            tags: [Tags_1.Tags.SPACE],
            cost: 12,
            metadata: {
                cardNumber: '099',
                renderData: CardRenderer_1.CardRenderer.builder(function (b) {
                    b.production(function (pb) {
                        pb.megacredits(1).slash().space({ played: Options_1.played, all: Options_1.all }).asterix();
                    });
                }),
                description: 'Increase your M€ production 1 step for each space tag your OPPONENTS have.',
            },
        }) || this;
    }
    TollStation.prototype.play = function (player) {
        var amount = player.game.getPlayers()
            .filter(function (aPlayer) { return aPlayer !== player; })
            .map(function (opponent) { return opponent.getTagCount(Tags_1.Tags.SPACE, 'raw'); })
            .reduce(function (a, c) { return a + c; }, 0);
        player.addProduction(Resources_1.Resources.MEGACREDITS, amount, { log: true });
        return undefined;
    };
    return TollStation;
}(Card_1.Card));
exports.TollStation = TollStation;
