"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.OutdoorSports = void 0;
var CardName_1 = require("../../CardName");
var CardType_1 = require("../CardType");
var CardRequirements_1 = require("../CardRequirements");
var CardRenderer_1 = require("../render/CardRenderer");
var Card_1 = require("../Card");
var Options_1 = require("../Options");
var Board_1 = require("../../boards/Board");
var Resources_1 = require("../../common/Resources");
var OutdoorSports = (function (_super) {
    __extends(OutdoorSports, _super);
    function OutdoorSports() {
        return _super.call(this, {
            cardType: CardType_1.CardType.AUTOMATED,
            name: CardName_1.CardName.OUTDOOR_SPORTS,
            cost: 8,
            victoryPoints: 1,
            requirements: CardRequirements_1.CardRequirements.builder(function (b) { return b.cities(1, { all: Options_1.all, text: ' next to' }).oceans(1); }),
            metadata: {
                cardNumber: 'X38',
                renderData: CardRenderer_1.CardRenderer.builder(function (b) {
                    b.production(function (pb) {
                        pb.megacredits(2);
                    });
                }),
                description: 'Requires any city adjacent to an ocean. Increase your M€ production 2 steps.',
            },
        }) || this;
    }
    OutdoorSports.prototype.canPlay = function (player) {
        var board = player.game.board;
        var oceans = board.getOceansTiles(true);
        return oceans.some(function (ocean) { return board.getAdjacentSpaces(ocean).some(function (space) { return Board_1.Board.isCitySpace(space); }); });
    };
    OutdoorSports.prototype.play = function (player) {
        player.addProduction(Resources_1.Resources.MEGACREDITS, 2);
        return undefined;
    };
    return OutdoorSports;
}(Card_1.Card));
exports.OutdoorSports = OutdoorSports;
