"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.Stratopolis = void 0;
var Tags_1 = require("../Tags");
var CardType_1 = require("../CardType");
var SpaceName_1 = require("../../SpaceName");
var SpaceType_1 = require("../../SpaceType");
var Resources_1 = require("../../common/Resources");
var ResourceType_1 = require("../../common/ResourceType");
var SelectCard_1 = require("../../inputs/SelectCard");
var CardName_1 = require("../../CardName");
var CardRequirements_1 = require("../CardRequirements");
var CardRenderer_1 = require("../render/CardRenderer");
var Card_1 = require("../Card");
var ICard_1 = require("../ICard");
var Stratopolis = (function (_super) {
    __extends(Stratopolis, _super);
    function Stratopolis() {
        var _this = _super.call(this, {
            name: CardName_1.CardName.STRATOPOLIS,
            cardType: CardType_1.CardType.ACTIVE,
            tags: [Tags_1.Tags.CITY, Tags_1.Tags.VENUS],
            cost: 22,
            resourceType: ResourceType_1.ResourceType.FLOATER,
            victoryPoints: ICard_1.VictoryPoints.resource(1, 3),
            requirements: CardRequirements_1.CardRequirements.builder(function (b) { return b.tag(Tags_1.Tags.SCIENCE, 2); }),
            metadata: {
                cardNumber: '248',
                renderData: CardRenderer_1.CardRenderer.builder(function (b) {
                    b.action('Add 2 floaters to ANY VENUS CARD.', function (eb) {
                        eb.empty().startAction.floaters(2, { secondaryTag: Tags_1.Tags.VENUS });
                    }).br;
                    b.production(function (pb) { return pb.megacredits(2); }).city().asterix();
                    b.vpText('1 VP for every 3rd Floater on this card.');
                }),
                description: {
                    text: 'Requires 2 science tags. Increase your M€ production 2 steps. Place a City tile ON THE RESERVED AREA',
                    align: 'left',
                },
            },
        }) || this;
        _this.resourceCount = 0;
        return _this;
    }
    ;
    Stratopolis.prototype.play = function (player) {
        player.addProduction(Resources_1.Resources.MEGACREDITS, 2);
        player.game.addCityTile(player, SpaceName_1.SpaceName.STRATOPOLIS, SpaceType_1.SpaceType.COLONY);
        return undefined;
    };
    Stratopolis.prototype.getResCards = function (player) {
        var resourceCards = player.getResourceCards(ResourceType_1.ResourceType.FLOATER);
        return resourceCards.filter(function (card) { return card.tags.some(function (cardTag) { return cardTag === Tags_1.Tags.VENUS; }); });
    };
    Stratopolis.prototype.canAct = function () {
        return true;
    };
    Stratopolis.prototype.action = function (player) {
        var cards = this.getResCards(player);
        if (cards.length === 1) {
            player.addResourceTo(cards[0], { qty: 2, log: true });
            return undefined;
        }
        return new SelectCard_1.SelectCard('Select card to add 2 floaters', 'Add floater(s)', cards, function (foundCards) {
            player.addResourceTo(foundCards[0], { qty: 2, log: true });
            return undefined;
        });
    };
    return Stratopolis;
}(Card_1.Card));
exports.Stratopolis = Stratopolis;
