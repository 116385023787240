"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ExecutiveOrder = void 0;
var PreludeCard_1 = require("../prelude/PreludeCard");
var CardName_1 = require("../../CardName");
var SendDelegateToArea_1 = require("../../deferredActions/SendDelegateToArea");
var CardRenderer_1 = require("../render/CardRenderer");
var Resources_1 = require("../../common/Resources");
var Turmoil_1 = require("../../turmoil/Turmoil");
var DeferredAction_1 = require("../../deferredActions/DeferredAction");
var OrOptions_1 = require("../../inputs/OrOptions");
var SelectOption_1 = require("../../inputs/SelectOption");
var ExecutiveOrder = (function (_super) {
    __extends(ExecutiveOrder, _super);
    function ExecutiveOrder() {
        return _super.call(this, {
            name: CardName_1.CardName.EXECUTIVE_ORDER,
            metadata: {
                cardNumber: 'Y31',
                renderData: CardRenderer_1.CardRenderer.builder(function (b) {
                    b.text('PLAY').globalEvent().asterix();
                    b.br.br.br;
                    b.delegates(2).megacredits(10).br.br;
                }),
                description: 'Draw 4 global events. Play 1 as the CURRENT GLOBAL EVENT and discard the rest. Place 2 delegates in any party. Gain 10 M€.',
            },
        }) || this;
    }
    ExecutiveOrder.prototype.play = function (player) {
        player.addResource(Resources_1.Resources.MEGACREDITS, 10, { log: true });
        var turmoil = Turmoil_1.Turmoil.getTurmoil(player.game);
        var globalEvents = [];
        for (var i = 0; i < 4; i++) {
            globalEvents.push(turmoil.globalEventDealer.draw());
        }
        player.game.defer(new DeferredAction_1.DeferredAction(player, function () {
            return new (OrOptions_1.OrOptions.bind.apply(OrOptions_1.OrOptions, __spreadArray([void 0], globalEvents.map(function (event) {
                var description = event.name + ': ' + event.description + ' Neutral delegate added: ' + event.currentDelegate;
                return new SelectOption_1.SelectOption(description, 'Select', function () {
                    turmoil.currentGlobalEvent = event;
                    turmoil.sendDelegateToParty('NEUTRAL', event.currentDelegate, player.game);
                    globalEvents.forEach(function (ge) {
                        if (ge.name !== event.name) {
                            turmoil.globalEventDealer.discardedGlobalEvents.push(ge);
                        }
                    });
                    return undefined;
                });
            }), false)))();
        }));
        player.game.defer(new SendDelegateToArea_1.SendDelegateToArea(player, 'Select where to send 2 delegates', { count: 2, source: 'reserve' }));
        return undefined;
    };
    return ExecutiveOrder;
}(PreludeCard_1.PreludeCard));
exports.ExecutiveOrder = ExecutiveOrder;
