"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.PioneerSettlement = void 0;
var Tags_1 = require("../Tags");
var CardType_1 = require("../CardType");
var CardName_1 = require("../../CardName");
var Resources_1 = require("../../common/Resources");
var ColonyName_1 = require("../../colonies/ColonyName");
var BuildColony_1 = require("../../deferredActions/BuildColony");
var CardRenderer_1 = require("../render/CardRenderer");
var CardRequirements_1 = require("../CardRequirements");
var Card_1 = require("../Card");
var Options_1 = require("../Options");
var PioneerSettlement = (function (_super) {
    __extends(PioneerSettlement, _super);
    function PioneerSettlement() {
        return _super.call(this, {
            cost: 13,
            tags: [Tags_1.Tags.SPACE],
            name: CardName_1.CardName.PIONEER_SETTLEMENT,
            cardType: CardType_1.CardType.AUTOMATED,
            requirements: CardRequirements_1.CardRequirements.builder(function (b) { return b.colonies(1, { max: Options_1.max }); }),
            victoryPoints: 2,
            metadata: {
                cardNumber: 'C29',
                renderData: CardRenderer_1.CardRenderer.builder(function (b) {
                    b.production(function (pb) { return pb.megacredits(-2); });
                    b.nbsp.colonies(1);
                }),
                description: 'Requires that you have no more than 1 colony. Decrease your M€ production 2 steps. Place a colony.',
            },
        }) || this;
    }
    PioneerSettlement.prototype.canPlay = function (player) {
        if (player.hasAvailableColonyTileToBuildOn() === false) {
            return false;
        }
        var lunaIsAvailable = false;
        var coloniesCount = 0;
        var hasOneColonyMax = player.game.colonies.every(function (colony) {
            if (colony.name === ColonyName_1.ColonyName.LUNA &&
                colony.isColonyFull() === false &&
                colony.colonies.includes(player.id) === false) {
                lunaIsAvailable = true;
            }
            coloniesCount += colony.colonies.filter(function (owner) { return owner === player.id; }).length;
            if (coloniesCount > 1) {
                return false;
            }
            return true;
        });
        if (hasOneColonyMax === false) {
            return false;
        }
        var megaCreditsProduction = player.getProduction(Resources_1.Resources.MEGACREDITS);
        if (megaCreditsProduction === -4 && player.isCorporation(CardName_1.CardName.POSEIDON)) {
            return true;
        }
        else if (megaCreditsProduction <= -4) {
            if (lunaIsAvailable === false) {
                return false;
            }
            this.warning = 'You will only be able to build the colony on Luna.';
        }
        return true;
    };
    PioneerSettlement.prototype.play = function (player) {
        var openColonies = player.getProduction(Resources_1.Resources.MEGACREDITS) <= -4 ?
            player.game.colonies.filter(function (colony) { return colony.name === ColonyName_1.ColonyName.LUNA; }) :
            undefined;
        player.game.defer(new BuildColony_1.BuildColony(player, false, 'Select colony for Pioneer Settlement', openColonies));
        player.addProduction(Resources_1.Resources.MEGACREDITS, -2);
        return undefined;
    };
    return PioneerSettlement;
}(Card_1.Card));
exports.PioneerSettlement = PioneerSettlement;
