"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Election = void 0;
var IGlobalEvent_1 = require("./IGlobalEvent");
var GlobalEventName_1 = require("./GlobalEventName");
var PartyName_1 = require("../parties/PartyName");
var Tags_1 = require("../../cards/Tags");
var Board_1 = require("../../boards/Board");
var CardRenderer_1 = require("../../cards/render/CardRenderer");
var Options_1 = require("../../cards/Options");
var Size_1 = require("../../cards/render/Size");
var RENDER_DATA = CardRenderer_1.CardRenderer.builder(function (b) {
    b.influence().plus().building(1, { played: Options_1.played }).plus().city().colon().br;
    b.text('1st: ').tr(2, { size: Size_1.Size.SMALL }).nbsp.text('2nd: ').tr(1, { size: Size_1.Size.SMALL });
});
var Election = (function (_super) {
    __extends(Election, _super);
    function Election() {
        return _super.call(this, {
            name: GlobalEventName_1.GlobalEventName.ELECTION,
            description: 'Count your influence plus Building tags and City tiles (no limits). The player with most (or 10 in solo) gains 2 TR, the 2nd (or 5 in solo) gains 1 TR (ties are friendly).',
            revealedDelegate: PartyName_1.PartyName.GREENS,
            currentDelegate: PartyName_1.PartyName.MARS,
            renderData: RENDER_DATA,
        }) || this;
    }
    Election.prototype.resolve = function (game, turmoil) {
        var _this = this;
        if (game.isSoloMode()) {
            if (this.getScore(game.getPlayers()[0], turmoil, game) >= 10) {
                game.getPlayers()[0].increaseTerraformRatingSteps(2, { log: true });
            }
            else if (this.getScore(game.getPlayers()[0], turmoil, game) >= 1) {
                game.getPlayers()[0].increaseTerraformRatingSteps(1, { log: true });
            }
        }
        else {
            var players = __spreadArray([], game.getPlayers(), true).sort(function (p1, p2) { return _this.getScore(p2, turmoil, game) - _this.getScore(p1, turmoil, game); });
            if (this.getScore(players[0], turmoil, game) > this.getScore(players[1], turmoil, game)) {
                players[0].increaseTerraformRatingSteps(2, { log: true });
                players.shift();
                if (players.length === 1) {
                    players[0].increaseTerraformRatingSteps(1, { log: true });
                }
                else if (players.length > 1) {
                    if (this.getScore(players[0], turmoil, game) > this.getScore(players[1], turmoil, game)) {
                        players[0].increaseTerraformRatingSteps(1, { log: true });
                    }
                    else {
                        var score = this.getScore(players[0], turmoil, game);
                        while (players.length > 0 && this.getScore(players[0], turmoil, game) === score) {
                            players[0].increaseTerraformRatingSteps(1, { log: true });
                            players.shift();
                        }
                    }
                }
            }
            else {
                var score = this.getScore(players[0], turmoil, game);
                while (players.length > 0 && this.getScore(players[0], turmoil, game) === score) {
                    players[0].increaseTerraformRatingSteps(2, { log: true });
                    players.shift();
                }
            }
        }
    };
    Election.prototype.getScore = function (player, turmoil, game) {
        var score = player.getTagCount(Tags_1.Tags.BUILDING, 'raw') + turmoil.getPlayerInfluence(player);
        var cities = game.board.spaces.filter(function (space) { return Board_1.Board.isCitySpace(space) && space.player === player; }).length;
        return score + cities;
    };
    return Election;
}(IGlobalEvent_1.GlobalEvent));
exports.Election = Election;
