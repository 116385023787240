"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ScienceTagCard = void 0;
var Tags_1 = require("../Tags");
var CardType_1 = require("../CardType");
var CardName_1 = require("../../CardName");
var ScienceTagCard = (function () {
    function ScienceTagCard() {
    }
    Object.defineProperty(ScienceTagCard.prototype, "cost", {
        get: function () {
            return 0;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(ScienceTagCard.prototype, "tags", {
        get: function () {
            return [Tags_1.Tags.SCIENCE];
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(ScienceTagCard.prototype, "name", {
        get: function () {
            return CardName_1.CardName.SCIENCE_TAG_BLANK_CARD;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(ScienceTagCard.prototype, "cardType", {
        get: function () {
            return CardType_1.CardType.PROXY;
        },
        enumerable: false,
        configurable: true
    });
    ScienceTagCard.prototype.canPlay = function () {
        return false;
    };
    Object.defineProperty(ScienceTagCard.prototype, "metadata", {
        get: function () {
            throw new Error('ScienceTagCard is a proxy card, not a real card. Should not render');
        },
        enumerable: false,
        configurable: true
    });
    ScienceTagCard.prototype.play = function () {
        return undefined;
    };
    Object.defineProperty(ScienceTagCard.prototype, "resourceCount", {
        get: function () {
            return 0;
        },
        enumerable: false,
        configurable: true
    });
    ScienceTagCard.prototype.getVictoryPoints = function () {
        return 0;
    };
    return ScienceTagCard;
}());
exports.ScienceTagCard = ScienceTagCard;
