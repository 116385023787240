"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.IntragenSanctuaryHeadquarters = void 0;
var CardName_1 = require("../../CardName");
var CardType_1 = require("../CardType");
var Tags_1 = require("../Tags");
var CardRenderer_1 = require("../render/CardRenderer");
var ResourceType_1 = require("../../common/ResourceType");
var PlaceMoonColonyTile_1 = require("../../moon/PlaceMoonColonyTile");
var Card_1 = require("../Card");
var ICard_1 = require("../ICard");
var Options_1 = require("../Options");
var IntragenSanctuaryHeadquarters = (function (_super) {
    __extends(IntragenSanctuaryHeadquarters, _super);
    function IntragenSanctuaryHeadquarters() {
        var _this = _super.call(this, {
            cardType: CardType_1.CardType.CORPORATION,
            name: CardName_1.CardName.INTRAGEN_SANCTUARY_HEADQUARTERS,
            tags: [Tags_1.Tags.ANIMAL, Tags_1.Tags.MOON],
            startingMegaCredits: 38,
            resourceType: ResourceType_1.ResourceType.ANIMAL,
            initialActionText: 'Place a colony tile on the Moon.',
            victoryPoints: ICard_1.VictoryPoints.resource(1, 2),
            metadata: {
                description: 'You start with 38 M€. ' +
                    'As your first action, place a colony tile on the Moon and raise the Colony Rate 1 step.',
                cardNumber: '',
                renderData: CardRenderer_1.CardRenderer.builder(function (b) {
                    b.megacredits(38).br;
                    b.effect('When any player plays an animal tag (including this), add 1 animal on this card.', function (eb) {
                        eb.animals(1, { played: Options_1.played }).startEffect.animals(1);
                    }).br,
                        b.text('1 VP for every 2 animals on this card.').br;
                }),
            },
        }) || this;
        _this.resourceCount = 0;
        return _this;
    }
    IntragenSanctuaryHeadquarters.prototype.initialAction = function (player) {
        player.game.defer(new PlaceMoonColonyTile_1.PlaceMoonColonyTile(player));
        return undefined;
    };
    IntragenSanctuaryHeadquarters.prototype.play = function () {
        this.resourceCount = 1;
        return undefined;
    };
    ;
    IntragenSanctuaryHeadquarters.prototype.onCorpCardPlayed = function (player, card) {
        return this.onCardPlayed(player, card);
    };
    IntragenSanctuaryHeadquarters.prototype.onCardPlayed = function (player, card) {
        var count = card.tags.filter(function (tag) { return tag === Tags_1.Tags.ANIMAL; }).length;
        player.addResourceTo(this, count);
        return undefined;
    };
    return IntragenSanctuaryHeadquarters;
}(Card_1.Card));
exports.IntragenSanctuaryHeadquarters = IntragenSanctuaryHeadquarters;
