"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.OpenCity = void 0;
var Card_1 = require("../Card");
var CardType_1 = require("../CardType");
var Tags_1 = require("../Tags");
var SelectSpace_1 = require("../../inputs/SelectSpace");
var Resources_1 = require("../../common/Resources");
var CardName_1 = require("../../CardName");
var CardRequirements_1 = require("../CardRequirements");
var CardRenderer_1 = require("../render/CardRenderer");
var Units_1 = require("../../Units");
var OpenCity = (function (_super) {
    __extends(OpenCity, _super);
    function OpenCity() {
        return _super.call(this, {
            cardType: CardType_1.CardType.AUTOMATED,
            name: CardName_1.CardName.OPEN_CITY,
            tags: [Tags_1.Tags.CITY, Tags_1.Tags.BUILDING],
            cost: 23,
            productionBox: Units_1.Units.of({ energy: -1, megacredits: 4 }),
            requirements: CardRequirements_1.CardRequirements.builder(function (b) { return b.oxygen(12); }),
            victoryPoints: 1,
            metadata: {
                cardNumber: '108',
                renderData: CardRenderer_1.CardRenderer.builder(function (b) {
                    b.production(function (pb) {
                        pb.minus().energy(1).br;
                        pb.plus().megacredits(4);
                    }).city().plants(2);
                }),
                description: {
                    text: 'Requires 12% oxygen. Gain 2 plants. Place a City tile. Decrease your Energy production 1 step and increase your M€ production 4 steps.',
                    align: 'left',
                },
            },
        }) || this;
    }
    OpenCity.prototype.canPlay = function (player) {
        return player.getProduction(Resources_1.Resources.ENERGY) >= 1 && player.game.board.getAvailableSpacesForCity(player).length > 0;
    };
    OpenCity.prototype.play = function (player) {
        return new SelectSpace_1.SelectSpace('Select space for city tile', player.game.board.getAvailableSpacesForCity(player), function (space) {
            player.game.addCityTile(player, space.id);
            player.addProduction(Resources_1.Resources.ENERGY, -1);
            player.addProduction(Resources_1.Resources.MEGACREDITS, 4);
            player.plants += 2;
            return undefined;
        });
    };
    return OpenCity;
}(Card_1.Card));
exports.OpenCity = OpenCity;
