"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.MetallicAsteroid = void 0;
var Card_1 = require("../Card");
var CardName_1 = require("../../CardName");
var SelectSpace_1 = require("../../inputs/SelectSpace");
var SpaceBonus_1 = require("../../SpaceBonus");
var SpaceType_1 = require("../../SpaceType");
var TileType_1 = require("../../common/TileType");
var CardType_1 = require("../CardType");
var Tags_1 = require("../Tags");
var RemoveAnyPlants_1 = require("../../deferredActions/RemoveAnyPlants");
var CardRenderer_1 = require("../render/CardRenderer");
var Options_1 = require("../Options");
var MetallicAsteroid = (function (_super) {
    __extends(MetallicAsteroid, _super);
    function MetallicAsteroid() {
        return _super.call(this, {
            cardType: CardType_1.CardType.EVENT,
            name: CardName_1.CardName.METALLIC_ASTEROID,
            tags: [Tags_1.Tags.SPACE],
            cost: 13,
            metadata: {
                cardNumber: 'A13',
                renderData: CardRenderer_1.CardRenderer.builder(function (b) {
                    b.temperature(1).titanium(1).br;
                    b.minus().plants(4, { digit: Options_1.digit, all: Options_1.all });
                    b.tile(TileType_1.TileType.METALLIC_ASTEROID, false, true);
                }),
                description: 'Raise temperature 1 step and gain 1 titanium. Remove up to 4 plants from any player. Place this tile which grants an ADJACENCY BONUS of 1 titanium.',
            },
        }) || this;
    }
    MetallicAsteroid.prototype.play = function (player) {
        var _this = this;
        player.titanium++;
        player.game.increaseTemperature(player, 1);
        player.game.defer(new RemoveAnyPlants_1.RemoveAnyPlants(player, 4));
        return new SelectSpace_1.SelectSpace('Select space for Metallic Asteroid tile', player.game.board.getAvailableSpacesOnLand(player), function (space) {
            player.game.addTile(player, SpaceType_1.SpaceType.LAND, space, {
                tileType: TileType_1.TileType.METALLIC_ASTEROID,
                card: _this.name,
            });
            space.adjacency = { bonus: [SpaceBonus_1.SpaceBonus.TITANIUM] };
            return undefined;
        });
    };
    return MetallicAsteroid;
}(Card_1.Card));
exports.MetallicAsteroid = MetallicAsteroid;
