"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.GreeneryStandardProject = void 0;
var CardName_1 = require("../../../CardName");
var CardRenderer_1 = require("../../render/CardRenderer");
var StandardProjectCard_1 = require("../../StandardProjectCard");
var PlaceGreeneryTile_1 = require("../../../deferredActions/PlaceGreeneryTile");
var GreeneryStandardProject = (function (_super) {
    __extends(GreeneryStandardProject, _super);
    function GreeneryStandardProject() {
        return _super.call(this, {
            name: CardName_1.CardName.GREENERY_STANDARD_PROJECT,
            cost: 23,
            tr: { oxygen: 1 },
            metadata: {
                cardNumber: 'SP6',
                renderData: CardRenderer_1.CardRenderer.builder(function (b) {
                    return b.standardProject('Spend 23 M€ to place a greenery tile and raise oxygen 1 step.', function (eb) {
                        eb.megacredits(23).startAction.greenery();
                    });
                }),
            },
        }) || this;
    }
    GreeneryStandardProject.prototype.canPayWith = function (player) {
        if (player.isCorporation(CardName_1.CardName.SOYLENT_SEEDLING_SYSTEMS)) {
            return { seeds: true };
        }
        else {
            return {};
        }
    };
    GreeneryStandardProject.prototype.canAct = function (player) {
        if (player.game.board.getAvailableSpacesForGreenery(player).length === 0)
            return false;
        return _super.prototype.canAct.call(this, player);
    };
    GreeneryStandardProject.prototype.actionEssence = function (player) {
        player.game.defer(new PlaceGreeneryTile_1.PlaceGreeneryTile(player, 'Select space for greenery'));
    };
    return GreeneryStandardProject;
}(StandardProjectCard_1.StandardProjectCard));
exports.GreeneryStandardProject = GreeneryStandardProject;
