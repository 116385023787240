"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
var vue_1 = require("vue");
var StackedCards_vue_1 = require("@/client/components/StackedCards.vue");
var PlayerMixin_1 = require("@/client/mixins/PlayerMixin");
var Card_vue_1 = require("@/client/components/card/Card.vue");
var Button_vue_1 = require("@/client/components/common/Button.vue");
exports.default = vue_1.default.extend({
    name: 'OtherPlayer',
    props: {
        player: {
            type: Object,
        },
        playerIndex: {
            type: Number,
        },
    },
    components: {
        Button: Button_vue_1.default,
        'stacked-cards': StackedCards_vue_1.default,
        Card: Card_vue_1.default,
    },
    methods: __assign(__assign({}, PlayerMixin_1.PlayerMixin.methods), { hideMe: function () {
            this.$root.setVisibilityState('pinned_player_' + this.playerIndex, false);
        }, isVisible: function () {
            return this.$root.getVisibilityState('pinned_player_' + this.playerIndex);
        } }),
});
