"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
var vue_1 = require("vue");
var PaymentWidgetMixin_1 = require("@/client/mixins/PaymentWidgetMixin");
var PreferencesManager_1 = require("@/client/utils/PreferencesManager");
var Button_vue_1 = require("@/client/components/common/Button.vue");
var useNewVersion = true;
exports.default = vue_1.default.extend({
    name: 'SelectHowToPay',
    props: {
        playerView: {
            type: Object,
        },
        playerinput: {
            type: Object,
        },
        onsave: {
            type: Function,
        },
        showsave: {
            type: Boolean,
        },
        showtitle: {
            type: Boolean,
        },
    },
    computed: {
        thisPlayer: function () {
            return this.playerView.thisPlayer;
        },
    },
    components: {
        Button: Button_vue_1.default,
    },
    data: function () {
        var model = {
            cost: 0,
            heat: 0,
            megaCredits: 0,
            steel: 0,
            titanium: 0,
            microbes: 0,
            floaters: 0,
            seeds: 0,
            warning: undefined,
        };
        return model;
    },
    mounted: function () {
        var _this = this;
        vue_1.default.nextTick(function () {
            _this.setInitialCost();
            _this.$data.megaCredits = _this.getMegaCreditsMax();
            if (useNewVersion) {
                _this.setDefaultValues();
            }
            else {
                _this.setDefaultSteelValue();
                _this.setDefaultTitaniumValue();
                _this.setDefaultHeatValue();
            }
        });
    },
    methods: __assign(__assign({}, PaymentWidgetMixin_1.PaymentWidgetMixin.methods), { hasWarning: function () {
            return this.$data.warning !== undefined;
        }, setInitialCost: function () {
            this.$data.cost = this.playerinput.amount;
        }, canUse: function (target) {
            switch (target) {
                case 'steel': return this.canUseSteel();
                case 'titanium': return this.canUseTitanium();
                case 'heat': return this.canUseHeat();
                case 'seeds': return this.canUseSeeds();
            }
            return false;
        }, setDefaultValue: function (amountCovered, target) {
            if (!this.canUse(target))
                return 0;
            var amount = this.getAmount(target);
            if (amount === 0)
                return 0;
            var cost = this.$data.cost;
            var resourceRate = this.getResourceRate(target);
            var qty = Math.ceil(Math.max(cost - this.getAmount('megaCredits') - amountCovered, 0) / resourceRate);
            qty = Math.min(qty, amount);
            var contributingValue = qty * resourceRate;
            var greedy = target !== 'heat';
            if (greedy === true) {
                while (qty < amount && contributingValue <= cost - resourceRate) {
                    qty++;
                    contributingValue += resourceRate;
                }
            }
            this.$data[target] = qty;
            return contributingValue;
        }, setDefaultValues: function () {
            var cost = this.$data.cost;
            var targets = ['seeds', 'steel', 'titanium', 'heat'];
            var megaCredits = this.getAmount('megaCredits');
            var amountCovered = 0;
            for (var _i = 0, targets_1 = targets; _i < targets_1.length; _i++) {
                var target = targets_1[_i];
                amountCovered += this.setDefaultValue(amountCovered, target);
            }
            this.$data.megaCredits = Math.min(megaCredits, Math.max(cost - amountCovered, 0));
        }, setDefaultSteelValue: function () {
            if (!this.canAffordWithMcOnly() && this.canUseSteel()) {
                var requiredSteelQty = Math.ceil(Math.max(this.$data.cost - this.thisPlayer.megaCredits, 0) / this.thisPlayer.steelValue);
                if (requiredSteelQty > this.thisPlayer.steel) {
                    this.$data.steel = this.thisPlayer.steel;
                }
                else {
                    var currentSteelValue = requiredSteelQty * this.thisPlayer.steelValue;
                    while (currentSteelValue <= this.$data.cost - this.thisPlayer.steelValue && requiredSteelQty < this.thisPlayer.steel) {
                        requiredSteelQty++;
                        currentSteelValue = requiredSteelQty * this.thisPlayer.steelValue;
                    }
                    this.$data.steel = requiredSteelQty;
                }
                var discountedCost = this.$data.cost - (this.$data.steel * this.thisPlayer.steelValue);
                this.$data.megaCredits = Math.max(discountedCost, 0);
            }
            else {
                this.$data.steel = 0;
            }
        }, setDefaultTitaniumValue: function () {
            if (!this.canAffordWithMcOnly() && this.canUseTitanium()) {
                var requiredTitaniumQty = Math.ceil(Math.max(this.$data.cost - this.thisPlayer.megaCredits - (this.$data.steel * this.thisPlayer.steelValue), 0) / this.thisPlayer.titaniumValue);
                if (requiredTitaniumQty > this.thisPlayer.titanium) {
                    this.$data.titanium = this.thisPlayer.titanium;
                }
                else {
                    var currentTitaniumValue = requiredTitaniumQty * this.thisPlayer.titaniumValue;
                    while (currentTitaniumValue <= this.$data.cost - this.thisPlayer.titaniumValue && requiredTitaniumQty < this.thisPlayer.titanium) {
                        requiredTitaniumQty++;
                        currentTitaniumValue = requiredTitaniumQty * this.thisPlayer.titaniumValue;
                    }
                    this.$data.titanium = requiredTitaniumQty;
                }
                var discountedCost = this.$data.cost - (this.$data.steel * this.thisPlayer.steelValue) - (this.$data.titanium * this.thisPlayer.titaniumValue);
                this.$data.megaCredits = Math.max(discountedCost, 0);
            }
            else {
                this.$data.titanium = 0;
            }
        }, setDefaultHeatValue: function () {
            if (!this.canAffordWithMcOnly() && this.canUseHeat()) {
                this.$data.heat = Math.max(this.$data.cost - this.thisPlayer.megaCredits - (this.$data.steel * this.thisPlayer.steelValue) - (this.$data.titanium * this.thisPlayer.titaniumValue), 0);
            }
            else {
                this.$data.heat = 0;
            }
            var discountedCost = this.$data.cost - (this.$data.steel * this.thisPlayer.steelValue) - (this.$data.titanium * this.thisPlayer.titaniumValue) - this.$data.heat;
            this.$data.megaCredits = Math.max(discountedCost, 0);
        }, canAffordWithMcOnly: function () {
            return this.thisPlayer.megaCredits >= this.$data.cost;
        }, canUseHeat: function () {
            return this.playerinput.canUseHeat && this.thisPlayer.heat > 0;
        }, canUseSteel: function () {
            return this.playerinput.canUseSteel && this.thisPlayer.steel > 0;
        }, canUseTitanium: function () {
            return this.playerinput.canUseTitanium && this.thisPlayer.titanium > 0;
        }, canUseSeeds: function () {
            var _a;
            return this.playerinput.canUseSeeds && ((_a = this.playerinput.seeds) !== null && _a !== void 0 ? _a : 0 > 0);
        }, saveData: function () {
            var targets = ['seeds', 'steel', 'titanium', 'heat', 'megaCredits'];
            var htp = {
                heat: this.$data.heat,
                megaCredits: this.$data.megaCredits,
                steel: this.$data.steel,
                titanium: this.$data.titanium,
                seeds: this.$data.seeds,
                microbes: 0,
                floaters: 0,
                science: 0,
            };
            var totalSpent = 0;
            for (var _i = 0, targets_2 = targets; _i < targets_2.length; _i++) {
                var target = targets_2[_i];
                if (htp[target] > this.getAmount(target)) {
                    this.$data.warning = "You do not have enough " + target;
                    return;
                }
                totalSpent += htp[target] * this.getResourceRate(target);
            }
            var requiredAmt = this.playerinput.amount || 0;
            if (requiredAmt > 0 && totalSpent < requiredAmt) {
                this.$data.warning = 'Haven\'t spent enough';
                return;
            }
            if (requiredAmt === 0) {
                htp.heat = 0;
                htp.megaCredits = 0;
            }
            if (requiredAmt > 0 && totalSpent > requiredAmt) {
                var diff = totalSpent - requiredAmt;
                for (var _a = 0, targets_3 = targets; _a < targets_3.length; _a++) {
                    var target = targets_3[_a];
                    if (htp[target] && diff >= this.getResourceRate(target)) {
                        this.$data.warning = "You cannot overspend " + target;
                        return;
                    }
                }
            }
            var showAlert = PreferencesManager_1.PreferencesManager.load('show_alerts') === '1';
            if (requiredAmt > 0 && totalSpent > requiredAmt && showAlert) {
                var diff = totalSpent - requiredAmt;
                if (confirm('Warning: You are overpaying by ' + diff + ' M€')) {
                    this.onsave([[JSON.stringify(htp)]]);
                }
                else {
                    this.$data.warning = 'Please adjust payment amount';
                    return;
                }
            }
            else {
                this.onsave([[JSON.stringify(htp)]]);
            }
        } }),
});
