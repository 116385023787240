"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.IPathfindersData = void 0;
var Tags_1 = require("../cards/Tags");
var IPathfindersData;
(function (IPathfindersData) {
    function serialize(pathfindersData) {
        if (pathfindersData === undefined) {
            return undefined;
        }
        return {
            venus: pathfindersData.venus,
            earth: pathfindersData.earth,
            mars: pathfindersData.mars,
            jovian: pathfindersData.jovian,
            moon: pathfindersData.moon,
            vps: pathfindersData.vps,
        };
    }
    IPathfindersData.serialize = serialize;
    function deserialize(pathfindersData) {
        return {
            venus: pathfindersData.venus,
            earth: pathfindersData.earth,
            mars: pathfindersData.mars,
            jovian: pathfindersData.jovian,
            moon: pathfindersData.moon,
            vps: pathfindersData.vps,
        };
    }
    IPathfindersData.deserialize = deserialize;
    function getValue(pathfindersData, tag) {
        switch (tag) {
            case Tags_1.Tags.VENUS:
                return pathfindersData.venus;
            case Tags_1.Tags.EARTH:
                return pathfindersData.earth;
            case Tags_1.Tags.MARS:
                return pathfindersData.mars;
            case Tags_1.Tags.JOVIAN:
                return pathfindersData.jovian;
            case Tags_1.Tags.MOON:
                return pathfindersData.moon;
            default:
                return -1;
        }
    }
    IPathfindersData.getValue = getValue;
    function setValue(pathfindersData, tag, value) {
        switch (tag) {
            case Tags_1.Tags.VENUS:
                pathfindersData.venus = value;
                break;
            case Tags_1.Tags.EARTH:
                pathfindersData.earth = value;
                break;
            case Tags_1.Tags.MARS:
                pathfindersData.mars = value;
                break;
            case Tags_1.Tags.JOVIAN:
                pathfindersData.jovian = value;
                break;
            case Tags_1.Tags.MOON:
                pathfindersData.moon = value;
                break;
        }
    }
    IPathfindersData.setValue = setValue;
})(IPathfindersData = exports.IPathfindersData || (exports.IPathfindersData = {}));
