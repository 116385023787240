"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var vue_1 = require("vue");
var Tags_1 = require("@/cards/Tags");
var utils_1 = require("@/utils/utils");
var PlanetaryTrack_vue_1 = require("@/client/components/pathfinders/PlanetaryTrack.vue");
var PlanetaryTracks_1 = require("@/pathfinders/PlanetaryTracks");
exports.default = vue_1.default.extend({
    name: 'PlanetaryTracks',
    props: {
        tracks: {
            type: Object,
        },
        gameOptions: {
            type: Object,
        },
    },
    components: {
        PlanetaryTrack: PlanetaryTrack_vue_1.default,
    },
    data: function () {
        return {
            venus: Tags_1.Tags.VENUS,
            earth: Tags_1.Tags.EARTH,
            mars: Tags_1.Tags.MARS,
            jovian: Tags_1.Tags.JOVIAN,
            moon: Tags_1.Tags.MOON,
            range: (0, utils_1.range)(23),
            rewards: PlanetaryTracks_1.PlanetaryTracks.initialize(),
        };
    },
});
