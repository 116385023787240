"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.NobelLabs = void 0;
var Card_1 = require("../Card");
var CardType_1 = require("../CardType");
var CardName_1 = require("../../CardName");
var CardRenderer_1 = require("../render/CardRenderer");
var Tags_1 = require("../Tags");
var CardRequirements_1 = require("../CardRequirements");
var ResourceType_1 = require("../../common/ResourceType");
var AddResourcesToCard_1 = require("../../deferredActions/AddResourcesToCard");
var Options_1 = require("../Options");
var NobelLabs = (function (_super) {
    __extends(NobelLabs, _super);
    function NobelLabs() {
        return _super.call(this, {
            cardType: CardType_1.CardType.ACTIVE,
            name: CardName_1.CardName.NOBEL_LABS,
            cost: 9,
            tags: [Tags_1.Tags.PLANT, Tags_1.Tags.ANIMAL, Tags_1.Tags.MARS],
            requirements: CardRequirements_1.CardRequirements.builder(function (b) { return b.tag(Tags_1.Tags.SCIENCE, 4); }),
            metadata: {
                cardNumber: 'Pf55',
                renderData: CardRenderer_1.CardRenderer.builder(function (b) {
                    b.action('Add 2 microbes OR 2 data OR 2 floaters to ANY card.', function (eb) {
                        eb.empty().startAction.microbes(2, { digit: Options_1.digit }).slash().data({ amount: 2, digit: Options_1.digit }).slash().floaters(2, { digit: Options_1.digit });
                    });
                }),
                description: 'Requires 4 Science tags.',
            },
        }) || this;
    }
    NobelLabs.prototype.canAct = function (player) {
        return player.getResourceCards().some(NobelLabs.PREDICATE);
    };
    NobelLabs.prototype.action = function (player) {
        player.game.defer(new AddResourcesToCard_1.AddResourcesToCard(player, undefined, { filter: NobelLabs.PREDICATE, count: 2 }));
        return undefined;
    };
    NobelLabs.prototype.play = function () {
        return undefined;
    };
    NobelLabs.RESOURCE_TYPES = [ResourceType_1.ResourceType.MICROBE, ResourceType_1.ResourceType.DATA, ResourceType_1.ResourceType.FLOATER];
    NobelLabs.PREDICATE = function (card) { return card.resourceType !== undefined && NobelLabs.RESOURCE_TYPES.includes(card.resourceType); };
    return NobelLabs;
}(Card_1.Card));
exports.NobelLabs = NobelLabs;
