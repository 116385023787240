"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.AerialMappers = void 0;
var Tags_1 = require("../Tags");
var CardType_1 = require("../CardType");
var ResourceType_1 = require("../../common/ResourceType");
var OrOptions_1 = require("../../inputs/OrOptions");
var SelectOption_1 = require("../../inputs/SelectOption");
var SelectCard_1 = require("../../inputs/SelectCard");
var CardName_1 = require("../../CardName");
var LogHelper_1 = require("../../LogHelper");
var CardRenderer_1 = require("../render/CardRenderer");
var Size_1 = require("../render/Size");
var Card_1 = require("../Card");
var AerialMappers = (function (_super) {
    __extends(AerialMappers, _super);
    function AerialMappers() {
        var _this = _super.call(this, {
            name: CardName_1.CardName.AERIAL_MAPPERS,
            cardType: CardType_1.CardType.ACTIVE,
            tags: [Tags_1.Tags.VENUS],
            cost: 11,
            resourceType: ResourceType_1.ResourceType.FLOATER,
            victoryPoints: 1,
            metadata: {
                cardNumber: '213',
                renderData: CardRenderer_1.CardRenderer.builder(function (b) {
                    b.action('Add floater to ANY card.', function (be) {
                        be.empty().startAction.floaters(1).asterix();
                    }).br;
                    b.or(Size_1.Size.SMALL).br;
                    b.action('Spend one floater here to draw 1 card.', function (be) {
                        be.floaters(1).startAction.cards(1);
                    });
                }),
            },
        }) || this;
        _this.resourceCount = 0;
        return _this;
    }
    ;
    AerialMappers.prototype.play = function () {
        return undefined;
    };
    AerialMappers.prototype.canAct = function () {
        return true;
    };
    AerialMappers.prototype.action = function (player) {
        var _this = this;
        var floaterCards = player.getResourceCards(ResourceType_1.ResourceType.FLOATER);
        var opts = [];
        if (floaterCards.length === 1 && this.resourceCount === 0) {
            player.addResourceTo(this, { qty: 1, log: true });
            return undefined;
        }
        var addResourceToSelf = new SelectOption_1.SelectOption('Add 1 floater to this card', 'Add floater', function () {
            player.addResourceTo(_this, { qty: 1, log: true });
            return undefined;
        });
        var addResource = new SelectCard_1.SelectCard('Select card to add 1 floater', 'Add floater', floaterCards, function (foundCards) {
            player.addResourceTo(foundCards[0], { log: true });
            return undefined;
        });
        var spendResource = new SelectOption_1.SelectOption('Remove 1 floater on this card and draw a card', 'Remove floater', function () {
            _this.resourceCount--;
            LogHelper_1.LogHelper.logRemoveResource(player, _this, 1, 'draw a card');
            player.drawCard();
            return undefined;
        });
        if (this.resourceCount > 0) {
            opts.push(spendResource);
            floaterCards.length === 1 ? opts.push(addResourceToSelf) : opts.push(addResource);
        }
        else {
            return addResource;
        }
        return new (OrOptions_1.OrOptions.bind.apply(OrOptions_1.OrOptions, __spreadArray([void 0], opts, false)))();
    };
    return AerialMappers;
}(Card_1.Card));
exports.AerialMappers = AerialMappers;
