"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.SelectResources = void 0;
var AndOptions_1 = require("./AndOptions");
var SelectAmount_1 = require("./SelectAmount");
var Units_1 = require("../Units");
var SelectResources = (function (_super) {
    __extends(SelectResources, _super);
    function SelectResources(player, count, title, units) {
        if (units === void 0) { units = Units_1.Units.of({}); }
        var _this = _super.apply(this, __spreadArray([function () {
                var array = [
                    units.megacredits,
                    units.steel,
                    units.titanium,
                    units.plants,
                    units.energy,
                    units.heat
                ];
                if (array.find(function (count) { return count < 0; })) {
                    throw new Error('No resource amount may be negative.');
                }
                var sum = array.reduce(function (a, b) { return a + b; }, 0);
                if (sum !== _this.count) {
                    throw new Error("Select " + _this.count + " resources.");
                }
                _this.player.addUnits(_this.units, { log: true });
                return undefined;
            }], SelectResources.makeOptions(count, units), false)) || this;
        _this.player = player;
        _this.count = count;
        _this.title = title;
        _this.units = units;
        return _this;
    }
    SelectResources.makeOptions = function (count, units) {
        var selectMegacredit = new SelectAmount_1.SelectAmount('Megacredits', 'Select', function (amount) {
            units.megacredits = amount;
            return undefined;
        }, 0, count);
        var selectSteel = new SelectAmount_1.SelectAmount('Steel', 'Select', function (amount) {
            units.steel = amount;
            return undefined;
        }, 0, count);
        var selectTitanium = new SelectAmount_1.SelectAmount('Titanium', 'Select', function (amount) {
            units.titanium = amount;
            return undefined;
        }, 0, count);
        var selectPlants = new SelectAmount_1.SelectAmount('Plants', 'Select', function (amount) {
            units.plants = amount;
            return undefined;
        }, 0, count);
        var selectEnergy = new SelectAmount_1.SelectAmount('Energy', 'Select', function (amount) {
            units.energy = amount;
            return undefined;
        }, 0, count);
        var selectHeat = new SelectAmount_1.SelectAmount('Heat', 'Select', function (amount) {
            units.heat = amount;
            return undefined;
        }, 0, count);
        return [selectMegacredit, selectSteel, selectTitanium, selectPlants, selectEnergy, selectHeat];
    };
    return SelectResources;
}(AndOptions_1.AndOptions));
exports.SelectResources = SelectResources;
