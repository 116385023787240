"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.MartianNatureWonders = void 0;
var Card_1 = require("../Card");
var CardType_1 = require("../CardType");
var CardName_1 = require("../../CardName");
var CardRenderer_1 = require("../render/CardRenderer");
var Tags_1 = require("../Tags");
var SelectSpace_1 = require("../../inputs/SelectSpace");
var AddResourcesToCard_1 = require("../../deferredActions/AddResourcesToCard");
var ResourceType_1 = require("../../common/ResourceType");
var TileType_1 = require("../../common/TileType");
var MartianNatureWonders = (function (_super) {
    __extends(MartianNatureWonders, _super);
    function MartianNatureWonders() {
        return _super.call(this, {
            cardType: CardType_1.CardType.AUTOMATED,
            name: CardName_1.CardName.MARTIAN_NATURE_WONDERS,
            cost: 13,
            tags: [Tags_1.Tags.MARS],
            victoryPoints: 2,
            metadata: {
                cardNumber: 'Pf10',
                renderData: CardRenderer_1.CardRenderer.builder(function (b) {
                    b.resourceCube().asterix().br;
                    b.data({ amount: 2 }).asterix;
                }),
                description: 'Place a neutral player cube on a non-reserved space. No tile can be placed on that space this game. ' +
                    'Gather any bonus on that space, but no bonuses from adjacent spaces. Place 2 data on any card.',
            },
        }) || this;
    }
    MartianNatureWonders.prototype.canPlay = function (player) {
        return player.game.board.getAvailableSpacesOnLand(player).length > 0;
    };
    MartianNatureWonders.prototype.play = function (player) {
        player.game.defer(new AddResourcesToCard_1.AddResourcesToCard(player, ResourceType_1.ResourceType.DATA, { count: 2 }));
        return new SelectSpace_1.SelectSpace('Select a Martian Natural Wonder space', player.game.board.getAvailableSpacesOnLand(player), function (space) {
            player.game.simpleAddTile(player, space, { tileType: TileType_1.TileType.MARTIAN_NATURE_WONDERS });
            player.game.grantSpaceBonuses(player, space);
            return undefined;
        });
    };
    return MartianNatureWonders;
}(Card_1.Card));
exports.MartianNatureWonders = MartianNatureWonders;
