"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.Pristar = void 0;
var ResourceType_1 = require("../../common/ResourceType");
var CardName_1 = require("../../CardName");
var Card_1 = require("../Card");
var ICard_1 = require("../ICard");
var CardType_1 = require("../CardType");
var CardRenderer_1 = require("../render/CardRenderer");
var Size_1 = require("../render/Size");
var Pristar = (function (_super) {
    __extends(Pristar, _super);
    function Pristar() {
        var _this = _super.call(this, {
            name: CardName_1.CardName.PRISTAR,
            startingMegaCredits: 53,
            resourceType: ResourceType_1.ResourceType.PRESERVATION,
            cardType: CardType_1.CardType.CORPORATION,
            victoryPoints: ICard_1.VictoryPoints.resource(1, 1),
            metadata: {
                cardNumber: 'R07',
                description: 'You start with 53 M€. Decrease your TR 2 steps. 1 VP per preservation resource here.',
                renderData: CardRenderer_1.CardRenderer.builder(function (b) {
                    b.br.br.br;
                    b.megacredits(53).nbsp.nbsp.minus().tr(2, { size: Size_1.Size.SMALL });
                    b.corpBox('effect', function (ce) {
                        ce.effect('During production phase, if you did not get TR so far this generation, add one preservation resource here and gain 6 M€.', function (eb) {
                            eb.tr(1, { size: Size_1.Size.SMALL, cancelled: true }).startEffect.preservation(1).megacredits(6);
                        });
                    });
                }),
            },
        }) || this;
        _this.resourceCount = 0;
        return _this;
    }
    Pristar.prototype.play = function (player) {
        player.decreaseTerraformRatingSteps(2);
        return undefined;
    };
    Pristar.prototype.onProductionPhase = function (player) {
        if (!(player.hasIncreasedTerraformRatingThisGeneration)) {
            player.megaCredits += 6;
            player.addResourceTo(this, 1);
        }
        return undefined;
    };
    return Pristar;
}(Card_1.Card));
exports.Pristar = Pristar;
