"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.CommercialDistrict = void 0;
var Tags_1 = require("../Tags");
var Card_1 = require("../Card");
var CardType_1 = require("../CardType");
var TileType_1 = require("../../common/TileType");
var SelectSpace_1 = require("../../inputs/SelectSpace");
var Resources_1 = require("../../common/Resources");
var CardName_1 = require("../../CardName");
var Board_1 = require("../../boards/Board");
var CardRenderer_1 = require("../render/CardRenderer");
var CardRenderDynamicVictoryPoints_1 = require("../render/CardRenderDynamicVictoryPoints");
var Units_1 = require("../../Units");
var CommercialDistrict = (function (_super) {
    __extends(CommercialDistrict, _super);
    function CommercialDistrict(name, adjacencyBonus, metadata) {
        if (name === void 0) { name = CardName_1.CardName.COMMERCIAL_DISTRICT; }
        if (adjacencyBonus === void 0) { adjacencyBonus = undefined; }
        if (metadata === void 0) { metadata = {
            cardNumber: '085',
            description: 'Place this tile. Decrease your energy production 1 step and increase your M€ production 4 steps.',
            renderData: CardRenderer_1.CardRenderer.builder(function (b) {
                b.production(function (pb) {
                    pb.minus().energy(1).br;
                    pb.plus().megacredits(4).br;
                }).nbsp.nbsp.tile(TileType_1.TileType.COMMERCIAL_DISTRICT, true).br;
                b.vpText('1 VP per adjacent city tile.');
            }),
            victoryPoints: CardRenderDynamicVictoryPoints_1.CardRenderDynamicVictoryPoints.cities(1, 1, true),
        }; }
        return _super.call(this, {
            cardType: CardType_1.CardType.AUTOMATED,
            name: name,
            tags: [Tags_1.Tags.BUILDING],
            cost: 16,
            adjacencyBonus: adjacencyBonus,
            productionBox: Units_1.Units.of({ energy: -1, megacredits: 4 }),
            victoryPoints: 'special',
            metadata: metadata,
        }) || this;
    }
    CommercialDistrict.prototype.canPlay = function (player) {
        return player.getProduction(Resources_1.Resources.ENERGY) >= 1 &&
            player.game.board.getAvailableSpacesOnLand(player).length > 0;
    };
    CommercialDistrict.prototype.getVictoryPoints = function (player) {
        var usedSpace = player.game.board.getSpaceByTileCard(this.name);
        if (usedSpace !== undefined) {
            return player.game.board.getAdjacentSpaces(usedSpace).filter(function (adjacentSpace) { return Board_1.Board.isCitySpace(adjacentSpace); }).length;
        }
        return 0;
    };
    CommercialDistrict.prototype.play = function (player) {
        var _this = this;
        return new SelectSpace_1.SelectSpace('Select space for special tile', player.game.board.getAvailableSpacesOnLand(player), function (foundSpace) {
            player.game.addTile(player, foundSpace.spaceType, foundSpace, {
                tileType: TileType_1.TileType.COMMERCIAL_DISTRICT,
                card: _this.name,
            });
            foundSpace.adjacency = _this.adjacencyBonus;
            player.addProduction(Resources_1.Resources.ENERGY, -1);
            player.addProduction(Resources_1.Resources.MEGACREDITS, 4);
            return undefined;
        });
    };
    return CommercialDistrict;
}(Card_1.Card));
exports.CommercialDistrict = CommercialDistrict;
