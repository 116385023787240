"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.CrashSiteCleanup = void 0;
var Card_1 = require("../Card");
var CardType_1 = require("../CardType");
var CardName_1 = require("../../CardName");
var SelectOption_1 = require("../../inputs/SelectOption");
var OrOptions_1 = require("../../inputs/OrOptions");
var Resources_1 = require("../../common/Resources");
var CardRequirements_1 = require("../CardRequirements");
var CardRenderer_1 = require("../render/CardRenderer");
var CrashSiteCleanup = (function (_super) {
    __extends(CrashSiteCleanup, _super);
    function CrashSiteCleanup() {
        return _super.call(this, {
            cardType: CardType_1.CardType.EVENT,
            name: CardName_1.CardName.CRASH_SITE_CLEANUP,
            cost: 4,
            requirements: CardRequirements_1.CardRequirements.builder(function (b) { return b.plantsRemoved(); }),
            victoryPoints: 1,
            metadata: {
                description: 'Requires that a player removed ANOTHER PLAYER\'s plants this generation. Gain 1 titanium or 2 steel.',
                cardNumber: 'X17',
                renderData: CardRenderer_1.CardRenderer.builder(function (b) {
                    b.titanium(1).nbsp.or().nbsp.steel(2);
                }),
            },
        }) || this;
    }
    CrashSiteCleanup.prototype.play = function (player) {
        var gainTitanium = new SelectOption_1.SelectOption('Gain 1 titanium', 'Gain titanium', function () {
            player.addResource(Resources_1.Resources.TITANIUM, 1, { log: true });
            return undefined;
        });
        var gain2Steel = new SelectOption_1.SelectOption('Gain 2 steel', 'Gain steel', function () {
            player.addResource(Resources_1.Resources.STEEL, 2, { log: true });
            return undefined;
        });
        return new OrOptions_1.OrOptions(gainTitanium, gain2Steel);
    };
    CrashSiteCleanup.resourceHook = function (player, resource, amount, from) {
        if (from === player || amount >= 0) {
            return;
        }
        if (resource === Resources_1.Resources.PLANTS && amount < 0) {
            player.game.someoneHasRemovedOtherPlayersPlants = true;
        }
    };
    return CrashSiteCleanup;
}(Card_1.Card));
exports.CrashSiteCleanup = CrashSiteCleanup;
