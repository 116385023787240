"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.CheungShingMARS = void 0;
var Tags_1 = require("../Tags");
var Resources_1 = require("../../common/Resources");
var Card_1 = require("../Card");
var CardName_1 = require("../../CardName");
var CardType_1 = require("../CardType");
var CardRenderer_1 = require("../render/CardRenderer");
var Units_1 = require("../../Units");
var Options_1 = require("../Options");
var CheungShingMARS = (function (_super) {
    __extends(CheungShingMARS, _super);
    function CheungShingMARS() {
        return _super.call(this, {
            cardType: CardType_1.CardType.CORPORATION,
            name: CardName_1.CardName.CHEUNG_SHING_MARS,
            tags: [Tags_1.Tags.BUILDING],
            startingMegaCredits: 44,
            productionBox: Units_1.Units.of({ megacredits: 3 }),
            cardDiscount: { tag: Tags_1.Tags.BUILDING, amount: 2 },
            metadata: {
                cardNumber: 'R16',
                description: 'You start with 3 M€ production and 44 M€.',
                renderData: CardRenderer_1.CardRenderer.builder(function (b) {
                    b.br.br;
                    b.production(function (pb) { return pb.megacredits(3); }).nbsp.megacredits(44);
                    b.corpBox('effect', function (ce) {
                        ce.effect('When you play a building tag, you pay 2 M€ less for it.', function (eb) {
                            eb.building(1, { played: Options_1.played }).startEffect.megacredits(-2);
                        });
                    });
                }),
            },
        }) || this;
    }
    CheungShingMARS.prototype.getCardDiscount = function (_player, card) {
        return card.tags.filter(function (tag) { return tag === Tags_1.Tags.BUILDING; }).length * 2;
    };
    CheungShingMARS.prototype.play = function (player) {
        player.addProduction(Resources_1.Resources.MEGACREDITS, 3);
        return undefined;
    };
    return CheungShingMARS;
}(Card_1.Card));
exports.CheungShingMARS = CheungShingMARS;
