"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.Heather = void 0;
var Tags_1 = require("../Tags");
var Card_1 = require("../Card");
var CardType_1 = require("../CardType");
var Resources_1 = require("../../common/Resources");
var CardName_1 = require("../../CardName");
var CardRequirements_1 = require("../CardRequirements");
var CardRenderer_1 = require("../render/CardRenderer");
var Heather = (function (_super) {
    __extends(Heather, _super);
    function Heather() {
        return _super.call(this, {
            cardType: CardType_1.CardType.AUTOMATED,
            name: CardName_1.CardName.HEATHER,
            tags: [Tags_1.Tags.PLANT],
            cost: 6,
            requirements: CardRequirements_1.CardRequirements.builder(function (b) { return b.temperature(-14); }),
            metadata: {
                cardNumber: '178',
                renderData: CardRenderer_1.CardRenderer.builder(function (b) {
                    b.production(function (pb) { return pb.plants(1); }).plants(1);
                }),
                description: 'Requires -14 C° or warmer. Increase your plant production 1 step. Gain 1 plant.',
            },
        }) || this;
    }
    Heather.prototype.play = function (player) {
        player.addProduction(Resources_1.Resources.PLANTS, 1);
        player.plants++;
        return undefined;
    };
    return Heather;
}(Card_1.Card));
exports.Heather = Heather;
