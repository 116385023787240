"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.LavaTubeSettlement = void 0;
var Tags_1 = require("../Tags");
var Card_1 = require("../Card");
var CardType_1 = require("../CardType");
var LavaFlows_1 = require("../base/LavaFlows");
var Resources_1 = require("../../common/Resources");
var CardName_1 = require("../../CardName");
var BoardName_1 = require("../../boards/BoardName");
var PlaceCityTile_1 = require("../../deferredActions/PlaceCityTile");
var CardRenderer_1 = require("../render/CardRenderer");
var Units_1 = require("../../Units");
var LavaTubeSettlement = (function (_super) {
    __extends(LavaTubeSettlement, _super);
    function LavaTubeSettlement() {
        return _super.call(this, {
            cardType: CardType_1.CardType.AUTOMATED,
            name: CardName_1.CardName.LAVA_TUBE_SETTLEMENT,
            tags: [Tags_1.Tags.BUILDING, Tags_1.Tags.CITY],
            cost: 15,
            productionBox: Units_1.Units.of({ energy: -1, megacredits: 2 }),
            metadata: {
                cardNumber: 'P37',
                renderData: CardRenderer_1.CardRenderer.builder(function (b) {
                    b.production(function (pb) {
                        pb.minus().energy(1).br;
                        pb.plus().megacredits(2);
                    }).br;
                    b.city().asterix();
                }),
                description: 'Decrease your Energy production 1 step and increase your M€ production 2 steps. Place a City Tile on a VOLCANIC AREA regardless of adjacent cities.',
            },
        }) || this;
    }
    LavaTubeSettlement.prototype.getSpacesForCity = function (player) {
        if (player.game.gameOptions.boardName === BoardName_1.BoardName.HELLAS) {
            return player.game.board.getAvailableSpacesForCity(player);
        }
        return LavaFlows_1.LavaFlows.getVolcanicSpaces(player);
    };
    LavaTubeSettlement.prototype.canPlay = function (player) {
        return this.getSpacesForCity(player).length > 0 && player.getProduction(Resources_1.Resources.ENERGY) >= 1;
    };
    LavaTubeSettlement.prototype.play = function (player) {
        player.addProduction(Resources_1.Resources.MEGACREDITS, 2);
        player.addProduction(Resources_1.Resources.ENERGY, -1);
        player.game.defer(new PlaceCityTile_1.PlaceCityTile(player, 'Select either Tharsis Tholus, Ascraeus Mons, Pavonis Mons or Arsia Mons', this.getSpacesForCity(player)));
        return undefined;
    };
    return LavaTubeSettlement;
}(Card_1.Card));
exports.LavaTubeSettlement = LavaTubeSettlement;
