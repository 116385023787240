"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.DesignedOrganisms = void 0;
var Card_1 = require("../Card");
var CardType_1 = require("../CardType");
var CardName_1 = require("../../CardName");
var CardRenderer_1 = require("../render/CardRenderer");
var Resources_1 = require("../../common/Resources");
var Tags_1 = require("../Tags");
var CardRequirements_1 = require("../CardRequirements");
var AddResourcesToCard_1 = require("../../deferredActions/AddResourcesToCard");
var ResourceType_1 = require("../../common/ResourceType");
var Units_1 = require("../../Units");
var DesignedOrganisms = (function (_super) {
    __extends(DesignedOrganisms, _super);
    function DesignedOrganisms() {
        return _super.call(this, {
            cardType: CardType_1.CardType.AUTOMATED,
            name: CardName_1.CardName.DESIGNED_ORGANISMS,
            cost: 12,
            tags: [Tags_1.Tags.MARS, Tags_1.Tags.ENERGY, Tags_1.Tags.BUILDING],
            requirements: CardRequirements_1.CardRequirements.builder(function (b) { return b.tag(Tags_1.Tags.SCIENCE, 5); }),
            productionBox: Units_1.Units.of({ plants: 2 }),
            metadata: {
                cardNumber: 'Pf21',
                renderData: CardRenderer_1.CardRenderer.builder(function (b) {
                    b.production(function (pb) { return pb.plants(2); }).plants(3).br;
                    b.microbes(3).asterix().animals(1).asterix();
                }),
                description: 'Requires 5 science tags. Increase your plant production 2 steps. Gain 3 plants. ' +
                    'Add 3 microbes to ANY card. Add 1 animal to ANY card.',
            },
        }) || this;
    }
    DesignedOrganisms.prototype.play = function (player) {
        player.addResource(Resources_1.Resources.PLANTS, 3);
        player.addProduction(Resources_1.Resources.PLANTS, 2);
        player.game.defer(new AddResourcesToCard_1.AddResourcesToCard(player, ResourceType_1.ResourceType.MICROBE, { count: 3 }));
        player.game.defer(new AddResourcesToCard_1.AddResourcesToCard(player, ResourceType_1.ResourceType.ANIMAL, { count: 1 }));
        return undefined;
    };
    return DesignedOrganisms;
}(Card_1.Card));
exports.DesignedOrganisms = DesignedOrganisms;
