"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.CrewTraining = void 0;
var PreludeCard_1 = require("../prelude/PreludeCard");
var CardName_1 = require("../../CardName");
var CardRenderer_1 = require("../render/CardRenderer");
var Tags_1 = require("../Tags");
var DeclareCloneTag_1 = require("../../pathfinders/DeclareCloneTag");
var Size_1 = require("../render/Size");
var Options_1 = require("../Options");
var CrewTraining = (function (_super) {
    __extends(CrewTraining, _super);
    function CrewTraining() {
        var _this = _super.call(this, {
            name: CardName_1.CardName.CREW_TRAINING,
            metadata: {
                cardNumber: 'P08',
                renderData: CardRenderer_1.CardRenderer.builder(function (b) {
                    b.planetaryTrack().text('2')
                        .venus(1, { played: Options_1.played }).or(Size_1.Size.SMALL)
                        .earth(1, { played: Options_1.played }).or(Size_1.Size.SMALL).br;
                    b.mars(1, { played: Options_1.played }).or(Size_1.Size.SMALL)
                        .jovian({ amount: 1, played: Options_1.played }).or(Size_1.Size.SMALL)
                        .moon(1, { played: Options_1.played }).br;
                    b.tr(2);
                }),
                description: 'Choose a planet tag. This card counts as having 2 of that tag. ' +
                    'Raise the planetary influence track accordingly. Gain 2 TR.',
            },
        }) || this;
        _this.cloneTag = Tags_1.Tags.CLONE;
        return _this;
    }
    Object.defineProperty(CrewTraining.prototype, "tags", {
        get: function () {
            return [this.cloneTag, this.cloneTag];
        },
        enumerable: false,
        configurable: true
    });
    CrewTraining.prototype.play = function (player) {
        player.increaseTerraformRatingSteps(2);
        player.game.defer(new DeclareCloneTag_1.DeclareCloneTag(player, this));
        return undefined;
    };
    return CrewTraining;
}(PreludeCard_1.PreludeCard));
exports.CrewTraining = CrewTraining;
