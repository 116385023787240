"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.GHGFactories = void 0;
var Tags_1 = require("../Tags");
var Card_1 = require("../Card");
var CardType_1 = require("../CardType");
var Resources_1 = require("../../common/Resources");
var CardName_1 = require("../../CardName");
var CardRenderer_1 = require("../render/CardRenderer");
var Units_1 = require("../../Units");
var Options_1 = require("../Options");
var GHGFactories = (function (_super) {
    __extends(GHGFactories, _super);
    function GHGFactories() {
        return _super.call(this, {
            cardType: CardType_1.CardType.AUTOMATED,
            name: CardName_1.CardName.GHG_FACTORIES,
            tags: [Tags_1.Tags.BUILDING],
            cost: 11,
            productionBox: Units_1.Units.of({ energy: -1, heat: 4 }),
            metadata: {
                cardNumber: '126',
                renderData: CardRenderer_1.CardRenderer.builder(function (b) {
                    b.production(function (pb) {
                        pb.minus().energy(1).br;
                        pb.plus().heat(4, { digit: Options_1.digit });
                    });
                }),
                description: 'Decrease your Energy production 1 step and increase your heat production 4 steps.',
            },
        }) || this;
    }
    GHGFactories.prototype.canPlay = function (player) {
        return player.getProduction(Resources_1.Resources.ENERGY) >= 1;
    };
    GHGFactories.prototype.play = function (player) {
        player.addProduction(Resources_1.Resources.ENERGY, -1);
        player.addProduction(Resources_1.Resources.HEAT, 4);
        return undefined;
    };
    return GHGFactories;
}(Card_1.Card));
exports.GHGFactories = GHGFactories;
