"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.CoreMine = void 0;
var CardName_1 = require("../../CardName");
var Tags_1 = require("../Tags");
var PreludeCard_1 = require("../prelude/PreludeCard");
var CardRenderer_1 = require("../render/CardRenderer");
var PlaceMoonMineTile_1 = require("../../moon/PlaceMoonMineTile");
var CardRenderItem_1 = require("../render/CardRenderItem");
var Units_1 = require("../../Units");
var TileType_1 = require("../../common/TileType");
var CoreMine = (function (_super) {
    __extends(CoreMine, _super);
    function CoreMine() {
        var _this = _super.call(this, {
            name: CardName_1.CardName.CORE_MINE,
            tags: [Tags_1.Tags.MOON],
            productionBox: Units_1.Units.of({ titanium: 1 }),
            metadata: {
                description: 'Place a mine tile on the Moon and raise the Mining Rate 1 step. Increase your titanium production 1 step.',
                cardNumber: '',
                renderData: CardRenderer_1.CardRenderer.builder(function (b) {
                    b.production(function (pb) { return pb.titanium(1); }).moonMine({ secondaryTag: CardRenderItem_1.AltSecondaryTag.MOON_MINING_RATE });
                }),
            },
        }) || this;
        _this.tilesBuilt = [TileType_1.TileType.MOON_MINE];
        return _this;
    }
    CoreMine.prototype.play = function (player) {
        player.adjustProduction(this.productionBox, { log: true });
        player.game.defer(new PlaceMoonMineTile_1.PlaceMoonMineTile(player));
        return undefined;
    };
    return CoreMine;
}(PreludeCard_1.PreludeCard));
exports.CoreMine = CoreMine;
