"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.ProjectInspection = void 0;
var Card_1 = require("../Card");
var CardType_1 = require("../CardType");
var CardName_1 = require("../../CardName");
var ICard_1 = require("../ICard");
var SelectCard_1 = require("../../inputs/SelectCard");
var CardRenderer_1 = require("../render/CardRenderer");
var Size_1 = require("../render/Size");
var ProjectInspection = (function (_super) {
    __extends(ProjectInspection, _super);
    function ProjectInspection() {
        return _super.call(this, {
            cardType: CardType_1.CardType.EVENT,
            name: CardName_1.CardName.PROJECT_INSPECTION,
            cost: 0,
            metadata: {
                cardNumber: 'X02',
                renderData: CardRenderer_1.CardRenderer.builder(function (b) {
                    b.text('Use a card action that has been used this generation.', Size_1.Size.SMALL, true);
                }),
            },
        }) || this;
    }
    ProjectInspection.prototype.getActionCards = function (player) {
        var result = [];
        if (player.corporationCard !== undefined && player.getActionsThisGeneration().has(player.corporationCard.name)) {
            if (player.corporationCard.name !== CardName_1.CardName.PLAYWRIGHTS || player.corporationCard.getCheckLoops() < 2) {
                if ((0, ICard_1.isIActionCard)(player.corporationCard) && player.corporationCard.canAct(player)) {
                    result.push(player.corporationCard);
                }
            }
        }
        for (var _i = 0, _a = player.playedCards; _i < _a.length; _i++) {
            var playedCard = _a[_i];
            if ((0, ICard_1.isIActionCard)(playedCard) && playedCard.canAct(player) && player.getActionsThisGeneration().has(playedCard.name)) {
                result.push(playedCard);
            }
        }
        return result;
    };
    ProjectInspection.prototype.canPlay = function (player) {
        return this.getActionCards(player).length > 0;
    };
    ProjectInspection.prototype.play = function (player) {
        var _this = this;
        var actionCards = this.getActionCards(player);
        if (actionCards.length === 0) {
            return undefined;
        }
        return new SelectCard_1.SelectCard('Perform an action from a played card again', 'Take action', actionCards, function (foundCards) {
            var foundCard = foundCards[0];
            player.game.log('${0} used ${1} action with ${2}', function (b) { return b.player(player).card(foundCard).card(_this); });
            return foundCard.action(player);
        });
    };
    return ProjectInspection;
}(Card_1.Card));
exports.ProjectInspection = ProjectInspection;
