"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.NanotechIndustries = void 0;
var CardName_1 = require("../../CardName");
var CardType_1 = require("../CardType");
var Tags_1 = require("../Tags");
var CardRenderer_1 = require("../render/CardRenderer");
var ResourceType_1 = require("../../common/ResourceType");
var AddResourcesToCard_1 = require("../../deferredActions/AddResourcesToCard");
var MoonCards_1 = require("../../moon/MoonCards");
var Card_1 = require("../Card");
var ICard_1 = require("../ICard");
var NanotechIndustries = (function (_super) {
    __extends(NanotechIndustries, _super);
    function NanotechIndustries() {
        var _this = _super.call(this, {
            cardType: CardType_1.CardType.CORPORATION,
            name: CardName_1.CardName.NANOTECH_INDUSTRIES,
            tags: [Tags_1.Tags.SCIENCE, Tags_1.Tags.MOON],
            startingMegaCredits: 42,
            resourceType: ResourceType_1.ResourceType.SCIENCE,
            initialActionText: 'Draw 3 cards and keep 2.',
            victoryPoints: ICard_1.VictoryPoints.resource(1, 2),
            metadata: {
                cardNumber: 'MC1',
                renderData: CardRenderer_1.CardRenderer.builder(function (b) {
                    b.megacredits(42).cards(3).minus().cards(1).br;
                    b.action('Add 1 science resource to ANY card [except those giving 2 or more VP per 1 science resource.]', function (eb) {
                        eb.empty().startAction.science(1).asterix();
                    });
                }),
                description: 'You start with 42 M€. As your first action, draw 3 cards. Take 2 of them into hand, and discard the rest. ' +
                    '1 VP for every 2 science resources here.',
            },
        }) || this;
        _this.resourceCount = 0;
        return _this;
    }
    NanotechIndustries.prototype.play = function () {
        return undefined;
    };
    NanotechIndustries.prototype.initialAction = function (player) {
        return player.drawCardKeepSome(3, { keepMax: 2 });
    };
    NanotechIndustries.prototype.canAct = function () {
        return true;
    };
    NanotechIndustries.prototype.action = function (player) {
        player.game.defer(new AddResourcesToCard_1.AddResourcesToCard(player, ResourceType_1.ResourceType.SCIENCE, { filter: function (card) { return MoonCards_1.MoonCards.scienceCardsWithLessThan2VP.has(card.name); } }));
        return undefined;
    };
    return NanotechIndustries;
}(Card_1.Card));
exports.NanotechIndustries = NanotechIndustries;
