"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.LobbyHalls = void 0;
var Card_1 = require("../Card");
var CardType_1 = require("../CardType");
var CardName_1 = require("../../CardName");
var CardRenderer_1 = require("../render/CardRenderer");
var Tags_1 = require("../Tags");
var Units_1 = require("../../Units");
var SendDelegateToArea_1 = require("../../deferredActions/SendDelegateToArea");
var DeclareCloneTag_1 = require("../../pathfinders/DeclareCloneTag");
var Turmoil_1 = require("../../turmoil/Turmoil");
var PathfindersExpansion_1 = require("../../pathfinders/PathfindersExpansion");
var LobbyHalls = (function (_super) {
    __extends(LobbyHalls, _super);
    function LobbyHalls() {
        var _this = _super.call(this, {
            cardType: CardType_1.CardType.AUTOMATED,
            name: CardName_1.CardName.LOBBY_HALLS,
            cost: 11,
            productionBox: Units_1.Units.of({ megacredits: 2 }),
            metadata: {
                cardNumber: 'PfTBD',
                renderData: CardRenderer_1.CardRenderer.builder(function (b) {
                    b.production(function (pb) { return pb.megacredits(2); }).delegates(1);
                }),
                description: 'Increase your M€ production 2 steps. Place 1 delegate from reserve in any party.',
            },
        }) || this;
        _this.cloneTag = Tags_1.Tags.CLONE;
        return _this;
    }
    Object.defineProperty(LobbyHalls.prototype, "tags", {
        get: function () {
            return [this.cloneTag, Tags_1.Tags.BUILDING];
        },
        enumerable: false,
        configurable: true
    });
    LobbyHalls.prototype.play = function (player) {
        player.game.defer(new DeclareCloneTag_1.DeclareCloneTag(player, this, function (tag) { return PathfindersExpansion_1.PathfindersExpansion.raiseTrack(tag, player, 1); }));
        player.adjustProduction(this.productionBox);
        var turmoil = Turmoil_1.Turmoil.getTurmoil(player.game);
        if (turmoil.getAvailableDelegateCount(player.id, 'reserve') > 0) {
            player.game.defer(new SendDelegateToArea_1.SendDelegateToArea(player, undefined, { source: 'reserve' }));
        }
        return undefined;
    };
    return LobbyHalls;
}(Card_1.Card));
exports.LobbyHalls = LobbyHalls;
