"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.PrefabricationofHumanHabitats = void 0;
var Card_1 = require("../Card");
var CardType_1 = require("../CardType");
var CardName_1 = require("../../CardName");
var CardRenderer_1 = require("../render/CardRenderer");
var Resources_1 = require("../../common/Resources");
var CardRequirements_1 = require("../CardRequirements");
var Tags_1 = require("../Tags");
var Size_1 = require("../render/Size");
var Options_1 = require("../Options");
var PrefabricationofHumanHabitats = (function (_super) {
    __extends(PrefabricationofHumanHabitats, _super);
    function PrefabricationofHumanHabitats() {
        return _super.call(this, {
            cardType: CardType_1.CardType.ACTIVE,
            name: CardName_1.CardName.PREFABRICATION_OF_HUMAN_HABITATS,
            cost: 8,
            tags: [Tags_1.Tags.BUILDING, Tags_1.Tags.CITY],
            requirements: CardRequirements_1.CardRequirements.builder(function (b) { return b.production(Resources_1.Resources.STEEL); }),
            metadata: {
                cardNumber: 'Pf02',
                renderData: CardRenderer_1.CardRenderer.builder(function (b) {
                    b.effect('Cards with a city tag cost 2M€ less.', function (eb) {
                        eb.city({ size: Size_1.Size.MEDIUM, played: Options_1.played }).startEffect.megacredits(-2);
                    });
                    b.br;
                    b.effect('The City standard project costs 2M€ less. STEEL MAY BE USED as if you were playing a Building card.', function (eb) {
                        eb.city().asterix().startEffect.megacredits(23).openBrackets.steel(1).closeBrackets;
                    });
                }),
                description: 'Requires that you have steel production.',
            },
        }) || this;
    }
    PrefabricationofHumanHabitats.prototype.getCardDiscount = function (_player, card) {
        return card.tags.includes(Tags_1.Tags.CITY) ? 2 : 0;
    };
    PrefabricationofHumanHabitats.prototype.play = function () {
        return undefined;
    };
    return PrefabricationofHumanHabitats;
}(Card_1.Card));
exports.PrefabricationofHumanHabitats = PrefabricationofHumanHabitats;
