"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.SponsoredProjects = void 0;
var IGlobalEvent_1 = require("./IGlobalEvent");
var GlobalEventName_1 = require("./GlobalEventName");
var PartyName_1 = require("../parties/PartyName");
var CardRenderer_1 = require("../../cards/render/CardRenderer");
var RENDER_DATA = CardRenderer_1.CardRenderer.builder(function (b) {
    b.cards(1).text('w/rsrs').colon().wild(1).br.cards(1).slash().influence();
});
var SponsoredProjects = (function (_super) {
    __extends(SponsoredProjects, _super);
    function SponsoredProjects() {
        return _super.call(this, {
            name: GlobalEventName_1.GlobalEventName.SPONSORED_PROJECTS,
            description: 'All cards with resources on them gain 1 resource. Draw 1 card for each influence.',
            revealedDelegate: PartyName_1.PartyName.SCIENTISTS,
            currentDelegate: PartyName_1.PartyName.GREENS,
            renderData: RENDER_DATA,
        }) || this;
    }
    SponsoredProjects.prototype.resolve = function (game, turmoil) {
        game.getPlayers().forEach(function (player) {
            player.getCardsWithResources().forEach(function (card) { return card.resourceCount && player.addResourceTo(card); });
            player.drawCard(turmoil.getPlayerInfluence(player));
        });
    };
    return SponsoredProjects;
}(IGlobalEvent_1.GlobalEvent));
exports.SponsoredProjects = SponsoredProjects;
