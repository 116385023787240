"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CorrosiveRainDeferredAction = void 0;
var SelectCard_1 = require("../inputs/SelectCard");
var OrOptions_1 = require("../inputs/OrOptions");
var Resources_1 = require("../common/Resources");
var ResourceType_1 = require("../common/ResourceType");
var SelectOption_1 = require("../inputs/SelectOption");
var DeferredAction_1 = require("./DeferredAction");
var GlobalEventName_1 = require("../turmoil/globalEvents/GlobalEventName");
var CorrosiveRainDeferredAction = (function () {
    function CorrosiveRainDeferredAction(player, title) {
        if (title === void 0) { title = 'Remove 2 floaters from a card or lose up to 10 M€'; }
        this.player = player;
        this.title = title;
        this.priority = DeferredAction_1.Priority.DEFAULT;
    }
    CorrosiveRainDeferredAction.prototype.execute = function () {
        var _this = this;
        var floaterCards = this.player.getCardsWithResources().filter(function (card) { return card.resourceType === ResourceType_1.ResourceType.FLOATER &&
            card.resourceCount !== undefined &&
            card.resourceCount >= 2; });
        if (floaterCards.length === 0) {
            this.player.deductResource(Resources_1.Resources.MEGACREDITS, 10, { log: true, from: GlobalEventName_1.GlobalEventName.CORROSIVE_RAIN });
            return undefined;
        }
        var selectAction = new OrOptions_1.OrOptions();
        var payMC = new SelectOption_1.SelectOption('Lose up to 10 M€', 'Lose M€', function () {
            _this.player.deductResource(Resources_1.Resources.MEGACREDITS, 10);
            return undefined;
        });
        var removeFloaters = new SelectCard_1.SelectCard('Select card to remove 2 floaters from', 'Remove floaters', floaterCards, function (foundCards) {
            _this.player.removeResourceFrom(foundCards[0], 2);
            return undefined;
        });
        selectAction.options.push(payMC, removeFloaters);
        return selectAction;
    };
    return CorrosiveRainDeferredAction;
}());
exports.CorrosiveRainDeferredAction = CorrosiveRainDeferredAction;
