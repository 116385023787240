"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.MartianInsuranceGroup = void 0;
var Card_1 = require("../Card");
var Tags_1 = require("../Tags");
var Resources_1 = require("../../common/Resources");
var CardName_1 = require("../../CardName");
var CardType_1 = require("../CardType");
var CardRenderer_1 = require("../render/CardRenderer");
var Options_1 = require("../Options");
var MartianInsuranceGroup = (function (_super) {
    __extends(MartianInsuranceGroup, _super);
    function MartianInsuranceGroup() {
        return _super.call(this, {
            cardType: CardType_1.CardType.CORPORATION,
            name: CardName_1.CardName.MARTIAN_INSURANCE_GROUP,
            tags: [Tags_1.Tags.MARS],
            startingMegaCredits: 42,
            metadata: {
                cardNumber: 'PfC12',
                description: 'You start with 42 M€ and 1 M€ production.',
                renderData: CardRenderer_1.CardRenderer.builder(function (b) {
                    b.br;
                    b.megacredits(42).production(function (pb) { return pb.megacredits(1); });
                    b.corpBox('effect', function (ce) {
                        ce.effect('Whenever you play an event card, raise your M€ production 1 step.', function (eb) {
                            eb.event({ played: Options_1.played }).startEffect.production(function (pb) { return pb.megacredits(1); });
                        });
                    });
                }),
            },
        }) || this;
    }
    MartianInsuranceGroup.prototype.play = function (player) {
        player.addProduction(Resources_1.Resources.MEGACREDITS, 1);
        return undefined;
    };
    MartianInsuranceGroup.prototype.onCardPlayed = function (player, card) {
        var _a;
        if (((_a = player.corporationCard) === null || _a === void 0 ? void 0 : _a.name) === this.name && card.cardType === CardType_1.CardType.EVENT) {
            player.addProduction(Resources_1.Resources.MEGACREDITS, 1, { log: true });
        }
    };
    return MartianInsuranceGroup;
}(Card_1.Card));
exports.MartianInsuranceGroup = MartianInsuranceGroup;
