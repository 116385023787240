"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.AquiferTurbines = void 0;
var Tags_1 = require("../Tags");
var PreludeCard_1 = require("./PreludeCard");
var CardName_1 = require("../../CardName");
var PlaceOceanTile_1 = require("../../deferredActions/PlaceOceanTile");
var SelectHowToPayDeferred_1 = require("../../deferredActions/SelectHowToPayDeferred");
var CardRenderer_1 = require("../render/CardRenderer");
var Units_1 = require("../../Units");
var AquiferTurbines = (function (_super) {
    __extends(AquiferTurbines, _super);
    function AquiferTurbines() {
        return _super.call(this, {
            name: CardName_1.CardName.AQUIFER_TURBINES,
            tags: [Tags_1.Tags.ENERGY],
            productionBox: Units_1.Units.of({ energy: 2 }),
            startingMegacredits: -3,
            metadata: {
                cardNumber: 'P02',
                renderData: CardRenderer_1.CardRenderer.builder(function (b) {
                    b.oceans(1).production(function (pb) { return pb.energy(2); }).br;
                    b.megacredits(-3);
                }),
                description: 'Place an Ocean tile. Increase your energy production 2 steps. Pay 3 M€.',
            },
        }) || this;
    }
    AquiferTurbines.prototype.canPlay = function (player) {
        return player.canAfford(3);
    };
    AquiferTurbines.prototype.play = function (player) {
        player.adjustProduction(this.productionBox);
        player.game.defer(new PlaceOceanTile_1.PlaceOceanTile(player));
        player.game.defer(new SelectHowToPayDeferred_1.SelectHowToPayDeferred(player, 3));
        return undefined;
    };
    return AquiferTurbines;
}(PreludeCard_1.PreludeCard));
exports.AquiferTurbines = AquiferTurbines;
