"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.BiomassCombustors = void 0;
var Tags_1 = require("../Tags");
var Card_1 = require("../Card");
var CardType_1 = require("../CardType");
var Resources_1 = require("../../common/Resources");
var CardName_1 = require("../../CardName");
var DecreaseAnyProduction_1 = require("../../deferredActions/DecreaseAnyProduction");
var CardRenderer_1 = require("../render/CardRenderer");
var CardRequirements_1 = require("../CardRequirements");
var Options_1 = require("../Options");
var BiomassCombustors = (function (_super) {
    __extends(BiomassCombustors, _super);
    function BiomassCombustors() {
        return _super.call(this, {
            cardType: CardType_1.CardType.AUTOMATED,
            name: CardName_1.CardName.BIOMASS_COMBUSTORS,
            tags: [Tags_1.Tags.ENERGY, Tags_1.Tags.BUILDING],
            cost: 4,
            victoryPoints: -1,
            requirements: CardRequirements_1.CardRequirements.builder(function (b) { return b.oxygen(6); }),
            metadata: {
                description: 'Requires 6% oxygen. Decrease any Plant production 1 step and increase your Energy production 2 steps.',
                cardNumber: '183',
                renderData: CardRenderer_1.CardRenderer.builder(function (b) {
                    b.production(function (pb) {
                        pb.minus().plants(-1, { all: Options_1.all }).br;
                        pb.plus().energy(2);
                    });
                }),
            },
        }) || this;
    }
    BiomassCombustors.prototype.canPlay = function (player) {
        return player.game.someoneHasResourceProduction(Resources_1.Resources.PLANTS, 1);
    };
    BiomassCombustors.prototype.play = function (player) {
        this.produce(player);
        return undefined;
    };
    BiomassCombustors.prototype.produce = function (player) {
        player.addProduction(Resources_1.Resources.ENERGY, 2);
        player.game.defer(new DecreaseAnyProduction_1.DecreaseAnyProduction(player, Resources_1.Resources.PLANTS, { count: 1 }));
    };
    return BiomassCombustors;
}(Card_1.Card));
exports.BiomassCombustors = BiomassCombustors;
