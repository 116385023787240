"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.CassiniStation = void 0;
var Card_1 = require("../Card");
var CardType_1 = require("../CardType");
var CardName_1 = require("../../CardName");
var CardRenderer_1 = require("../render/CardRenderer");
var Tags_1 = require("../Tags");
var Resources_1 = require("../../common/Resources");
var ResourceType_1 = require("../../common/ResourceType");
var Options_1 = require("../Options");
var SelectCard_1 = require("../../inputs/SelectCard");
var CassiniStation = (function (_super) {
    __extends(CassiniStation, _super);
    function CassiniStation() {
        return _super.call(this, {
            cardType: CardType_1.CardType.AUTOMATED,
            name: CardName_1.CardName.CASSINI_STATION,
            cost: 23,
            tags: [Tags_1.Tags.ENERGY, Tags_1.Tags.SCIENCE, Tags_1.Tags.SPACE],
            metadata: {
                cardNumber: 'Pf62',
                renderData: CardRenderer_1.CardRenderer.builder(function (b) {
                    b.production(function (pb) { return pb.energy(1).slash().colonies(1, { all: Options_1.all }); }).br;
                    b.floaters(2).or().data({ amount: 3 });
                }),
                description: 'Increase your energy production 1 step for every colony in play. ' +
                    'Add 2 floaters on any card OR add 3 data on any card.',
            },
        }) || this;
    }
    CassiniStation.prototype.play = function (player) {
        var coloniesCount = 0;
        player.game.colonies.forEach(function (colony) {
            coloniesCount += colony.colonies.length;
        });
        player.addProduction(Resources_1.Resources.ENERGY, coloniesCount, { log: true });
        var cards = __spreadArray(__spreadArray([], player.getResourceCards(ResourceType_1.ResourceType.FLOATER), true), player.getResourceCards(ResourceType_1.ResourceType.DATA), true);
        if (cards.length === 0) {
            return undefined;
        }
        var input = new SelectCard_1.SelectCard('Select card to gain 2 floaters or 3 data', 'Add resources', cards, function (selected) {
            var card = selected[0];
            if (card.resourceType === ResourceType_1.ResourceType.FLOATER) {
                player.addResourceTo(card, { qty: 2, log: true });
            }
            else {
                player.addResourceTo(card, { qty: 3, log: true });
            }
            return undefined;
        });
        if (cards.length === 1) {
            input.cb(cards);
            return undefined;
        }
        return input;
    };
    return CassiniStation;
}(Card_1.Card));
exports.CassiniStation = CassiniStation;
