"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.FieldCappedCity = void 0;
var Tags_1 = require("../Tags");
var Card_1 = require("../Card");
var CardType_1 = require("../CardType");
var SelectSpace_1 = require("../../inputs/SelectSpace");
var Resources_1 = require("../../common/Resources");
var CardName_1 = require("../../CardName");
var CardRenderer_1 = require("../render/CardRenderer");
var Units_1 = require("../../Units");
var FieldCappedCity = (function (_super) {
    __extends(FieldCappedCity, _super);
    function FieldCappedCity() {
        return _super.call(this, {
            cardType: CardType_1.CardType.AUTOMATED,
            name: CardName_1.CardName.FIELD_CAPPED_CITY,
            tags: [Tags_1.Tags.CITY, Tags_1.Tags.BUILDING, Tags_1.Tags.ENERGY],
            cost: 29,
            productionBox: Units_1.Units.of({ energy: 1, megacredits: 2 }),
            metadata: {
                cardNumber: 'X21',
                description: 'Increase your M€ production 2 steps, increase your energy production 1 step, gain 3 plants, and place a city tile.',
                renderData: CardRenderer_1.CardRenderer.builder(function (b) {
                    b.production(function (pb) {
                        pb.megacredits(2).br;
                        pb.energy(1);
                    }).nbsp.city().br;
                    b.plants(3);
                }),
            },
        }) || this;
    }
    FieldCappedCity.prototype.canPlay = function (player) {
        return player.game.board.getAvailableSpacesForCity(player).length > 0;
    };
    FieldCappedCity.prototype.play = function (player) {
        return new SelectSpace_1.SelectSpace('Select space for city tile', player.game.board.getAvailableSpacesForCity(player), function (space) {
            player.game.addCityTile(player, space.id);
            player.plants += 3;
            player.addProduction(Resources_1.Resources.ENERGY, 1);
            player.addProduction(Resources_1.Resources.MEGACREDITS, 2);
            return undefined;
        });
    };
    return FieldCappedCity;
}(Card_1.Card));
exports.FieldCappedCity = FieldCappedCity;
