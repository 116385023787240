"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.RedInfluence = void 0;
var IGlobalEvent_1 = require("./IGlobalEvent");
var GlobalEventName_1 = require("./GlobalEventName");
var PartyName_1 = require("../parties/PartyName");
var Resources_1 = require("../../common/Resources");
var CardRenderer_1 = require("../../cards/render/CardRenderer");
var Options_1 = require("../../cards/Options");
var RENDER_DATA = CardRenderer_1.CardRenderer.builder(function (b) {
    b.br.br.br.megacredits(-3).slash().tr(5, { digit: Options_1.digit, over: 10 }).nbsp.production(function (pb) { return pb.megacredits(1); }).slash().influence();
});
var RedInfluence = (function (_super) {
    __extends(RedInfluence, _super);
    function RedInfluence() {
        return _super.call(this, {
            name: GlobalEventName_1.GlobalEventName.RED_INFLUENCE,
            description: 'Lose 3 M€ for each set of 5 TR over 10 (max 5 sets). Increase M€ production 1 step per influence.',
            revealedDelegate: PartyName_1.PartyName.KELVINISTS,
            currentDelegate: PartyName_1.PartyName.REDS,
            renderData: RENDER_DATA,
        }) || this;
    }
    RedInfluence.prototype.resolve = function (game, turmoil) {
        var _this = this;
        game.getPlayers().forEach(function (player) {
            var amount = Math.floor((player.getTerraformRating() - 10) / 5);
            if (amount > 0) {
                player.addResource(Resources_1.Resources.MEGACREDITS, amount * -3, { log: true, from: _this.name });
            }
            player.addProduction(Resources_1.Resources.MEGACREDITS, turmoil.getPlayerInfluence(player), { log: true, from: _this.name });
        });
    };
    return RedInfluence;
}(IGlobalEvent_1.GlobalEvent));
exports.RedInfluence = RedInfluence;
