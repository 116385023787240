"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.AquiferStandardProject = void 0;
var CardName_1 = require("../../../CardName");
var CardRenderer_1 = require("../../render/CardRenderer");
var PlaceOceanTile_1 = require("../../../deferredActions/PlaceOceanTile");
var StandardProjectCard_1 = require("../../StandardProjectCard");
var AquiferStandardProject = (function (_super) {
    __extends(AquiferStandardProject, _super);
    function AquiferStandardProject() {
        return _super.call(this, {
            name: CardName_1.CardName.AQUIFER_STANDARD_PROJECT,
            cost: 18,
            tr: { oceans: 1 },
            metadata: {
                cardNumber: 'SP2',
                renderData: CardRenderer_1.CardRenderer.builder(function (b) {
                    return b.standardProject('Spend 18 M€ to place an ocean tile.', function (eb) {
                        eb.megacredits(18).startAction.oceans(1);
                    });
                }),
            },
        }) || this;
    }
    AquiferStandardProject.prototype.canAct = function (player) {
        if (!player.game.canAddOcean())
            return false;
        return _super.prototype.canAct.call(this, player);
    };
    AquiferStandardProject.prototype.actionEssence = function (player) {
        player.game.defer(new PlaceOceanTile_1.PlaceOceanTile(player, 'Select space for ocean'));
    };
    return AquiferStandardProject;
}(StandardProjectCard_1.StandardProjectCard));
exports.AquiferStandardProject = AquiferStandardProject;
