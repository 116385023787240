"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var vue_1 = require("vue");
var PlayerResources_vue_1 = require("@/client/components/overview/PlayerResources.vue");
var PlayerTags_vue_1 = require("@/client/components/overview/PlayerTags.vue");
var PlayerStatus_vue_1 = require("@/client/components/overview/PlayerStatus.vue");
var utils_1 = require("@/utils/utils");
var utils_2 = require("@/utils/utils");
var PlayerMixin_1 = require("@/client/mixins/PlayerMixin");
var Button_vue_1 = require("@/client/components/common/Button.vue");
var isPinned = function (root, playerIndex) {
    return root.getVisibilityState('pinned_player_' + playerIndex);
};
var showPlayerData = function (root, playerIndex) {
    root.setVisibilityState('pinned_player_' + playerIndex, true);
};
exports.default = vue_1.default.extend({
    name: 'PlayerInfo',
    props: {
        player: {
            type: Object,
        },
        playerView: {
            type: Object,
        },
        firstForGen: {
            type: Boolean,
            default: false,
        },
        actionLabel: {
            type: String,
            default: '',
        },
        playerIndex: {
            type: Number,
        },
        hideZeroTags: {
            type: Boolean,
            default: false,
        },
    },
    components: {
        Button: Button_vue_1.default,
        PlayerResources: PlayerResources_vue_1.default,
        PlayerTags: PlayerTags_vue_1.default,
        'player-status': PlayerStatus_vue_1.default,
    },
    mixins: [PlayerMixin_1.PlayerMixin],
    methods: {
        getClasses: function () {
            var classes = ['player-info'];
            classes.push((0, utils_1.playerColorClass)(this.player.color, 'bg_transparent'));
            return classes.join(' ');
        },
        getPlayerStatusAndResClasses: function () {
            var classes = ['player-status-and-res'];
            return classes.join(' ');
        },
        pinPlayer: function () {
            var _this = this;
            var hiddenPlayersIndexes = [];
            var playerPinned = isPinned(this.$root, this.playerIndex);
            hiddenPlayersIndexes = (0, utils_2.range)(this.playerView.players.length - 1);
            if (!playerPinned) {
                showPlayerData(this.$root, this.playerIndex);
                hiddenPlayersIndexes = hiddenPlayersIndexes.filter(function (index) { return index !== _this.playerIndex; });
            }
            for (var i = 0; i < hiddenPlayersIndexes.length; i++) {
                if (hiddenPlayersIndexes.includes(i)) {
                    this.$root.setVisibilityState('pinned_player_' + i, false);
                }
            }
        },
        buttonLabel: function () {
            return isPinned(this.$root, this.playerIndex) ? 'hide' : 'show';
        },
        togglePlayerDetails: function () {
            var _a;
            if (this.player.color === ((_a = this.playerView.thisPlayer) === null || _a === void 0 ? void 0 : _a.color)) {
                var el = document.getElementsByClassName('sidebar_icon--cards')[0];
                el.click();
                return;
            }
            this.pinPlayer();
        },
        getNrPlayedCards: function () {
            return this.player.playedCards.length;
        },
        getAvailableBlueActionCount: function () {
            return this.player.availableBlueCardActionCount;
        },
    },
});
