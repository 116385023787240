"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.Birds = void 0;
var Tags_1 = require("../Tags");
var Card_1 = require("../Card");
var ICard_1 = require("../ICard");
var CardType_1 = require("../CardType");
var Resources_1 = require("../../common/Resources");
var ResourceType_1 = require("../../common/ResourceType");
var CardName_1 = require("../../CardName");
var DecreaseAnyProduction_1 = require("../../deferredActions/DecreaseAnyProduction");
var CardRequirements_1 = require("../CardRequirements");
var CardRenderer_1 = require("../render/CardRenderer");
var Options_1 = require("../Options");
var Birds = (function (_super) {
    __extends(Birds, _super);
    function Birds() {
        var _this = _super.call(this, {
            cardType: CardType_1.CardType.ACTIVE,
            name: CardName_1.CardName.BIRDS,
            tags: [Tags_1.Tags.ANIMAL],
            cost: 10,
            resourceType: ResourceType_1.ResourceType.ANIMAL,
            requirements: CardRequirements_1.CardRequirements.builder(function (b) { return b.oxygen(13); }),
            victoryPoints: ICard_1.VictoryPoints.resource(1, 1),
            metadata: {
                cardNumber: '072',
                description: 'Requires 13% oxygen. Decrease any plant production 2 steps. 1 VP per Animal on this card.',
                renderData: CardRenderer_1.CardRenderer.builder(function (b) {
                    b.action('Add an animal to this card.', function (eb) {
                        eb.empty().startAction.animals(1);
                    }).br;
                    b.production(function (pb) {
                        pb.minus().plants(-2, { all: Options_1.all });
                    });
                }),
            },
        }) || this;
        _this.resourceCount = 0;
        return _this;
    }
    Birds.prototype.canPlay = function (player) {
        return player.game.someoneHasResourceProduction(Resources_1.Resources.PLANTS, 2);
    };
    Birds.prototype.play = function (player) {
        player.game.defer(new DecreaseAnyProduction_1.DecreaseAnyProduction(player, Resources_1.Resources.PLANTS, { count: 2 }));
        return undefined;
    };
    Birds.prototype.canAct = function () {
        return true;
    };
    Birds.prototype.action = function (player) {
        player.addResourceTo(this);
        return undefined;
    };
    return Birds;
}(Card_1.Card));
exports.Birds = Birds;
