"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.deserializeProjectCard = exports.serializeProjectCard = void 0;
var MiningCard_1 = require("./base/MiningCard");
var ICloneTagCard_1 = require("./pathfinders/ICloneTagCard");
var SelfReplicatingRobots_1 = require("./promo/SelfReplicatingRobots");
function serializeProjectCard(c) {
    var result = {
        name: c.name,
    };
    if (c.bonusResource !== undefined) {
        result.bonusResource = c.bonusResource;
    }
    if (c.resourceCount !== undefined) {
        result.resourceCount = c.resourceCount;
    }
    if (c instanceof SelfReplicatingRobots_1.SelfReplicatingRobots) {
        result.targetCards = c.targetCards.map(function (t) {
            return {
                card: { name: t.card.name },
                resourceCount: t.resourceCount,
            };
        });
    }
    if ((0, ICloneTagCard_1.isICloneTagCard)(c)) {
        result.cloneTag = c.cloneTag;
    }
    return result;
}
exports.serializeProjectCard = serializeProjectCard;
function deserializeProjectCard(element, cardFinder) {
    var card = cardFinder.getProjectCardByName(element.name);
    if (element.resourceCount !== undefined) {
        card.resourceCount = element.resourceCount;
    }
    if (element.cloneTag !== undefined) {
        card.cloneTag = element.cloneTag;
    }
    if (card instanceof SelfReplicatingRobots_1.SelfReplicatingRobots && element.targetCards !== undefined) {
        card.targetCards = [];
        element.targetCards.forEach(function (targetCard) {
            var foundTargetCard = cardFinder.getProjectCardByName(targetCard.card.name);
            if (foundTargetCard !== undefined) {
                card.targetCards.push({
                    card: foundTargetCard,
                    resourceCount: targetCard.resourceCount,
                });
            }
            else {
                console.warn('did not find card for SelfReplicatingRobots', targetCard);
            }
        });
    }
    if (card instanceof MiningCard_1.MiningCard && element.bonusResource !== undefined) {
        card.bonusResource = Array.isArray(element.bonusResource) ? element.bonusResource : [element.bonusResource];
    }
    return card;
}
exports.deserializeProjectCard = deserializeProjectCard;
