"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.FreyjaBiodomes = void 0;
var Tags_1 = require("../Tags");
var CardType_1 = require("../CardType");
var Resources_1 = require("../../common/Resources");
var ResourceType_1 = require("../../common/ResourceType");
var SelectCard_1 = require("../../inputs/SelectCard");
var CardName_1 = require("../../CardName");
var CardRequirements_1 = require("../CardRequirements");
var CardRenderer_1 = require("../render/CardRenderer");
var Card_1 = require("../Card");
var FreyjaBiodomes = (function (_super) {
    __extends(FreyjaBiodomes, _super);
    function FreyjaBiodomes() {
        return _super.call(this, {
            name: CardName_1.CardName.FREYJA_BIODOMES,
            cardType: CardType_1.CardType.AUTOMATED,
            tags: [Tags_1.Tags.PLANT, Tags_1.Tags.VENUS],
            cost: 14,
            requirements: CardRequirements_1.CardRequirements.builder(function (b) { return b.venus(10); }),
            victoryPoints: 2,
            metadata: {
                cardNumber: '227',
                renderData: CardRenderer_1.CardRenderer.builder(function (b) {
                    b.microbes(2, { secondaryTag: Tags_1.Tags.VENUS }).or().animals(2, { secondaryTag: Tags_1.Tags.VENUS }).br;
                    b.production(function (pb) { return pb.minus().energy(1).nbsp.plus().megacredits(2); });
                }),
                description: {
                    text: 'Requires 10% on the Venus track. Add 2 Microbes or 2 Animals to another Venus card. Production: energy -1, M€ +2.',
                    align: 'left',
                },
            },
        }) || this;
    }
    ;
    FreyjaBiodomes.prototype.canPlay = function (player) {
        return player.getProduction(Resources_1.Resources.ENERGY) >= 1;
    };
    FreyjaBiodomes.prototype.getResCards = function (player) {
        var resourceCards = player.getResourceCards(ResourceType_1.ResourceType.ANIMAL);
        resourceCards = resourceCards.concat(player.getResourceCards(ResourceType_1.ResourceType.MICROBE));
        return resourceCards.filter(function (card) { return card.tags.includes(Tags_1.Tags.VENUS); });
    };
    FreyjaBiodomes.prototype.play = function (player) {
        var cards = this.getResCards(player);
        if (cards.length > 1) {
            return new SelectCard_1.SelectCard('Select card to add 2 resources', 'Add resources', cards, function (foundCards) {
                player.addResourceTo(foundCards[0], { qty: 2, log: true });
                player.addProduction(Resources_1.Resources.ENERGY, -1);
                player.addProduction(Resources_1.Resources.MEGACREDITS, 2);
                return undefined;
            });
        }
        if (cards.length === 1) {
            player.addResourceTo(cards[0], { qty: 2, log: true });
        }
        player.addProduction(Resources_1.Resources.ENERGY, -1);
        player.addProduction(Resources_1.Resources.MEGACREDITS, 2);
        return undefined;
    };
    return FreyjaBiodomes;
}(Card_1.Card));
exports.FreyjaBiodomes = FreyjaBiodomes;
