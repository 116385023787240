"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.SinusIridiumRoadNetwork = void 0;
var CardName_1 = require("../../CardName");
var CardType_1 = require("../CardType");
var PlaceMoonRoadTile_1 = require("../../moon/PlaceMoonRoadTile");
var Tags_1 = require("../Tags");
var CardRenderer_1 = require("../render/CardRenderer");
var Units_1 = require("../../Units");
var TileType_1 = require("../../common/TileType");
var MoonCard_1 = require("./MoonCard");
var CardRenderItem_1 = require("../render/CardRenderItem");
var SinusIridiumRoadNetwork = (function (_super) {
    __extends(SinusIridiumRoadNetwork, _super);
    function SinusIridiumRoadNetwork() {
        return _super.call(this, {
            name: CardName_1.CardName.SINUS_IRIDIUM_ROAD_NETWORK,
            cardType: CardType_1.CardType.AUTOMATED,
            tags: [Tags_1.Tags.MOON],
            cost: 15,
            productionBox: Units_1.Units.of({ energy: -1, megacredits: 3 }),
            reserveUnits: Units_1.Units.of({ steel: 1 }),
            tr: { moonLogistics: 1 },
            metadata: {
                description: 'Decrease your energy production 1 step and increase your M€ production 3 steps. ' +
                    'Spend 1 steel. ' +
                    'Place a road tile on the Moon and raise the Logistics Rate 1 step.',
                cardNumber: 'M11',
                renderData: CardRenderer_1.CardRenderer.builder(function (b) {
                    b.minus().steel(1).br;
                    b.production(function (pb) {
                        pb.minus().energy(1).nbsp.megacredits(3);
                    }).br;
                    b.moonRoad({ secondaryTag: CardRenderItem_1.AltSecondaryTag.MOON_LOGISTICS_RATE });
                }),
            },
        }, {
            tilesBuilt: [TileType_1.TileType.MOON_ROAD],
        }) || this;
    }
    SinusIridiumRoadNetwork.prototype.play = function (player) {
        _super.prototype.play.call(this, player);
        player.game.defer(new PlaceMoonRoadTile_1.PlaceMoonRoadTile(player));
        return undefined;
    };
    return SinusIridiumRoadNetwork;
}(MoonCard_1.MoonCard));
exports.SinusIridiumRoadNetwork = SinusIridiumRoadNetwork;
