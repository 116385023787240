"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.GMOContract = void 0;
var Tags_1 = require("../Tags");
var Card_1 = require("../Card");
var CardName_1 = require("../../CardName");
var CardType_1 = require("../CardType");
var PartyName_1 = require("../../turmoil/parties/PartyName");
var Resources_1 = require("../../common/Resources");
var DeferredAction_1 = require("../../deferredActions/DeferredAction");
var CardRenderer_1 = require("../render/CardRenderer");
var CardRequirements_1 = require("../CardRequirements");
var Options_1 = require("../Options");
var GMOContract = (function (_super) {
    __extends(GMOContract, _super);
    function GMOContract() {
        return _super.call(this, {
            cardType: CardType_1.CardType.ACTIVE,
            name: CardName_1.CardName.GMO_CONTRACT,
            tags: [Tags_1.Tags.MICROBE, Tags_1.Tags.SCIENCE],
            cost: 3,
            requirements: CardRequirements_1.CardRequirements.builder(function (b) { return b.party(PartyName_1.PartyName.GREENS); }),
            metadata: {
                description: 'Requires that Greens are ruling or that you have 2 delegates there.',
                cardNumber: 'T06',
                renderData: CardRenderer_1.CardRenderer.builder(function (b) {
                    b.effect('Each time you play a plant, animal or microbe tag, including this, gain 2 M€.', function (be) {
                        be.animals(1, { played: Options_1.played }).slash().plants(1, { played: Options_1.played }).slash().microbes(1, { played: Options_1.played });
                        be.startEffect.megacredits(2);
                    });
                }),
            },
        }) || this;
    }
    GMOContract.prototype.onCardPlayed = function (player, card) {
        var amount = card.tags.filter(function (tag) { return tag === Tags_1.Tags.ANIMAL || tag === Tags_1.Tags.PLANT || tag === Tags_1.Tags.MICROBE; }).length;
        if (amount > 0) {
            player.game.defer(new DeferredAction_1.DeferredAction(player, function () {
                player.addResource(Resources_1.Resources.MEGACREDITS, amount * 2);
                return undefined;
            }));
        }
    };
    GMOContract.prototype.play = function () {
        return undefined;
    };
    return GMOContract;
}(Card_1.Card));
exports.GMOContract = GMOContract;
