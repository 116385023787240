"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.CometForVenus = void 0;
var Tags_1 = require("../Tags");
var CardType_1 = require("../CardType");
var SelectPlayer_1 = require("../../inputs/SelectPlayer");
var Resources_1 = require("../../common/Resources");
var CardName_1 = require("../../CardName");
var CardRenderer_1 = require("../render/CardRenderer");
var Card_1 = require("../Card");
var OrOptions_1 = require("../../inputs/OrOptions");
var SelectOption_1 = require("../../inputs/SelectOption");
var Options_1 = require("../Options");
var CometForVenus = (function (_super) {
    __extends(CometForVenus, _super);
    function CometForVenus() {
        return _super.call(this, {
            name: CardName_1.CardName.COMET_FOR_VENUS,
            cardType: CardType_1.CardType.EVENT,
            tags: [Tags_1.Tags.SPACE],
            cost: 11,
            tr: { venus: 1 },
            metadata: {
                description: 'Raise Venus 1 step. Remove up to 4M€ from any player WITH A VENUS TAG IN PLAY.',
                cardNumber: '218',
                renderData: CardRenderer_1.CardRenderer.builder(function (b) {
                    b.venus(1).nbsp.nbsp.minus().megacredits(4, { all: Options_1.all, secondaryTag: Tags_1.Tags.VENUS });
                }),
            },
        }) || this;
    }
    ;
    CometForVenus.prototype.play = function (player) {
        var venusTagPlayers = player.game.getPlayers().filter(function (otherPlayer) { return otherPlayer.id !== player.id && otherPlayer.getTagCount(Tags_1.Tags.VENUS, 'raw') > 0; });
        if (player.game.isSoloMode() || venusTagPlayers.length === 0) {
            player.game.increaseVenusScaleLevel(player, 1);
            return undefined;
        }
        if (venusTagPlayers.length > 0) {
            return new OrOptions_1.OrOptions(new SelectPlayer_1.SelectPlayer(Array.from(venusTagPlayers), 'Select player to remove up to 4 M€ from', 'Remove M€', function (selectedPlayer) {
                selectedPlayer.deductResource(Resources_1.Resources.MEGACREDITS, 4, { log: true, from: player });
                player.game.increaseVenusScaleLevel(player, 1);
                return undefined;
            }), new SelectOption_1.SelectOption('Do not remove M€', 'Confirm', function () {
                player.game.increaseVenusScaleLevel(player, 1);
                return undefined;
            }));
        }
        return undefined;
    };
    return CometForVenus;
}(Card_1.Card));
exports.CometForVenus = CometForVenus;
