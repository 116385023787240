"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var vue_1 = require("vue");
var Awards_vue_1 = require("@/client/components/Awards.vue");
var Board_vue_1 = require("@/client/components/Board.vue");
var Colony_vue_1 = require("@/client/components/Colony.vue");
var PlanetaryTracks_vue_1 = require("@/client/components/pathfinders/PlanetaryTracks.vue");
var DynamicTitle_vue_1 = require("@/client/components/common/DynamicTitle.vue");
var LogPanel_vue_1 = require("@/client/components/LogPanel.vue");
var MoonBoard_vue_1 = require("@/client/components/moon/MoonBoard.vue");
var Milestone_vue_1 = require("@/client/components/Milestone.vue");
var Sidebar_vue_1 = require("@/client/components/Sidebar.vue");
var Turmoil_vue_1 = require("@/client/components/Turmoil.vue");
var WaitingFor_vue_1 = require("@/client/components/WaitingFor.vue");
var PlayersOverview_vue_1 = require("@/client/components/overview/PlayersOverview.vue");
var utils_1 = require("@/utils/utils");
exports.default = vue_1.default.extend({
    name: 'SpectatorHome',
    data: function () {
        return {
            hideTiles: false,
            waitingForTimeout: this.settings.waitingForTimeout,
        };
    },
    props: {
        spectator: {
            type: Object,
        },
        settings: {
            type: Object,
        },
    },
    computed: {
        game: function () {
            return this.spectator.game;
        },
    },
    components: {
        Awards: Awards_vue_1.default,
        Board: Board_vue_1.default,
        Colony: Colony_vue_1.default,
        DynamicTitle: DynamicTitle_vue_1.default,
        LogPanel: LogPanel_vue_1.default,
        Milestone: Milestone_vue_1.default,
        MoonBoard: MoonBoard_vue_1.default,
        PlanetaryTracks: PlanetaryTracks_vue_1.default,
        PlayersOverview: PlayersOverview_vue_1.default,
        Sidebar: Sidebar_vue_1.default,
        Turmoil: Turmoil_vue_1.default,
        WaitingFor: WaitingFor_vue_1.default,
    },
    methods: {
        forceRerender: function () {
            var root = this.$root;
            root.updateSpectator();
        },
        range: function (n) {
            return (0, utils_1.range)(n);
        },
    },
});
