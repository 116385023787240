"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.RestrictedArea = void 0;
var Tags_1 = require("../Tags");
var Card_1 = require("../Card");
var CardType_1 = require("../CardType");
var TileType_1 = require("../../common/TileType");
var SelectSpace_1 = require("../../inputs/SelectSpace");
var CardName_1 = require("../../CardName");
var SelectHowToPayDeferred_1 = require("../../deferredActions/SelectHowToPayDeferred");
var CardRenderer_1 = require("../render/CardRenderer");
var RestrictedArea = (function (_super) {
    __extends(RestrictedArea, _super);
    function RestrictedArea(name, adjacencyBonus, metadata) {
        if (name === void 0) { name = CardName_1.CardName.RESTRICTED_AREA; }
        if (adjacencyBonus === void 0) { adjacencyBonus = undefined; }
        if (metadata === void 0) { metadata = {
            cardNumber: '199',
            renderData: CardRenderer_1.CardRenderer.builder(function (b) {
                b.action('Spend 2 M€ to draw a card.', function (eb) {
                    eb.megacredits(2).startAction.cards(1);
                }).br;
                b.tile(TileType_1.TileType.RESTRICTED_AREA, true);
            }),
            description: 'Place this tile.',
        }; }
        return _super.call(this, {
            cardType: CardType_1.CardType.ACTIVE,
            name: name,
            tags: [Tags_1.Tags.SCIENCE],
            cost: 11,
            adjacencyBonus: adjacencyBonus,
            metadata: metadata,
        }) || this;
    }
    RestrictedArea.prototype.canPlay = function (player) {
        return player.game.board.getAvailableSpacesOnLand(player).length > 0;
    };
    RestrictedArea.prototype.play = function (player) {
        var _this = this;
        return new SelectSpace_1.SelectSpace('Select space for tile', player.game.board.getAvailableSpacesOnLand(player), function (foundSpace) {
            player.game.addTile(player, foundSpace.spaceType, foundSpace, { tileType: TileType_1.TileType.RESTRICTED_AREA });
            foundSpace.adjacency = _this.adjacencyBonus;
            return undefined;
        });
    };
    RestrictedArea.prototype.canAct = function (player) {
        return player.canAfford(2);
    };
    RestrictedArea.prototype.action = function (player) {
        player.game.defer(new SelectHowToPayDeferred_1.SelectHowToPayDeferred(player, 2, { title: 'Select how to pay for action' }));
        player.drawCard();
        return undefined;
    };
    return RestrictedArea;
}(Card_1.Card));
exports.RestrictedArea = RestrictedArea;
