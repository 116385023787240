"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var vue_1 = require("vue");
var GameModule_1 = require("@/GameModule");
var MODULE_TO_CSS = new Map([
    [GameModule_1.GameModule.CorpEra, 'corporate-icon'],
    [GameModule_1.GameModule.Promo, 'promo-icon'],
    [GameModule_1.GameModule.Venus, 'venus-icon'],
    [GameModule_1.GameModule.Colonies, 'colonies-icon'],
    [GameModule_1.GameModule.Prelude, 'prelude-icon'],
    [GameModule_1.GameModule.Turmoil, 'turmoil-icon'],
    [GameModule_1.GameModule.Community, 'community-icon'],
    [GameModule_1.GameModule.Ares, 'ares-icon'],
    [GameModule_1.GameModule.Moon, 'moon-icon'],
    [GameModule_1.GameModule.Pathfinders, 'pathfinders-icon']
]);
exports.default = vue_1.default.extend({
    name: 'CardExpansion',
    props: {
        expansion: {
            type: String,
            required: true,
        },
        isCorporation: {
            type: Boolean,
            required: true,
        },
    },
    methods: {
        getClasses: function () {
            var classes = ['card-expansion', 'project-icon'];
            var expansionClass = MODULE_TO_CSS.get(this.expansion);
            if (expansionClass !== undefined) {
                classes.push(expansionClass);
            }
            if (this.isCorporation) {
                classes.push('card-corporation-expansion');
            }
            return classes.join(' ');
        },
    },
});
