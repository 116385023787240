"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.SmallDutyRovers = void 0;
var CardName_1 = require("../../CardName");
var CardType_1 = require("../CardType");
var Tags_1 = require("../Tags");
var CardRenderer_1 = require("../render/CardRenderer");
var MoonExpansion_1 = require("../../moon/MoonExpansion");
var SpaceType_1 = require("../../SpaceType");
var Resources_1 = require("../../common/Resources");
var Units_1 = require("../../Units");
var Size_1 = require("../render/Size");
var Card_1 = require("../Card");
var Options_1 = require("../Options");
var SmallDutyRovers = (function (_super) {
    __extends(SmallDutyRovers, _super);
    function SmallDutyRovers() {
        return _super.call(this, {
            name: CardName_1.CardName.SMALL_DUTY_ROVERS,
            cardType: CardType_1.CardType.AUTOMATED,
            tags: [Tags_1.Tags.MOON, Tags_1.Tags.SPACE],
            cost: 9,
            reserveUnits: Units_1.Units.of({ titanium: 1 }),
            tr: { moonLogistics: 1 },
            metadata: {
                description: 'Spend 1 titanium. Raise the Logistic Rate 1 step. Gain 1 M€ per colony tile, mine tile and road tile on the Moon.',
                cardNumber: 'M73',
                renderData: CardRenderer_1.CardRenderer.builder(function (b) {
                    b.minus().titanium(1).moonLogisticsRate().br;
                    b.megacredits(1).slash()
                        .moonColony({ size: Size_1.Size.SMALL, all: Options_1.all })
                        .moonMine({ size: Size_1.Size.SMALL, all: Options_1.all })
                        .moonRoad({ size: Size_1.Size.SMALL, all: Options_1.all });
                }),
            },
        }) || this;
    }
    ;
    SmallDutyRovers.prototype.play = function (player) {
        player.deductUnits(this.reserveUnits);
        MoonExpansion_1.MoonExpansion.raiseLogisticRate(player);
        var moonData = MoonExpansion_1.MoonExpansion.moonData(player.game);
        var gain = moonData.moon.spaces.filter(function (s) { return s.tile !== undefined && s.spaceType !== SpaceType_1.SpaceType.COLONY; }).length;
        player.addResource(Resources_1.Resources.MEGACREDITS, gain, { log: true });
        return undefined;
    };
    return SmallDutyRovers;
}(Card_1.Card));
exports.SmallDutyRovers = SmallDutyRovers;
