"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AresHandler = exports.HazardSeverity = void 0;
var CardName_1 = require("../CardName");
var SelectCard_1 = require("../inputs/SelectCard");
var Resources_1 = require("../common/Resources");
var ResourceType_1 = require("../common/ResourceType");
var SpaceBonus_1 = require("../SpaceBonus");
var TileType_1 = require("../common/TileType");
var Multiset_1 = require("../utils/Multiset");
var Phase_1 = require("../Phase");
var DeferredAction_1 = require("../deferredActions/DeferredAction");
var SelectHowToPayDeferred_1 = require("../deferredActions/SelectHowToPayDeferred");
var SelectProductionToLoseDeferred_1 = require("../deferredActions/SelectProductionToLoseDeferred");
var AresHazards_1 = require("./AresHazards");
var HazardSeverity;
(function (HazardSeverity) {
    HazardSeverity[HazardSeverity["NONE"] = 0] = "NONE";
    HazardSeverity[HazardSeverity["MILD"] = 1] = "MILD";
    HazardSeverity[HazardSeverity["SEVERE"] = 2] = "SEVERE";
})(HazardSeverity = exports.HazardSeverity || (exports.HazardSeverity = {}));
;
var AresHandler = (function () {
    function AresHandler() {
    }
    AresHandler.ifAres = function (game, cb) {
        if (game.gameOptions.aresExtension) {
            if (game.aresData === undefined) {
                console.log('Assertion failure: game.aresData is undefined');
                return;
            }
            cb(game.aresData);
        }
    };
    AresHandler.earnAdjacencyBonuses = function (aresData, player, space) {
        var _this = this;
        var incrementMilestone = false;
        player.game.board.getAdjacentSpaces(space).forEach(function (adjacentSpace) {
            if (_this.earnAdacencyBonus(adjacentSpace, player)) {
                incrementMilestone = true;
            }
        });
        if (incrementMilestone) {
            var milestoneResults = aresData.milestoneResults;
            var entry = milestoneResults.find(function (e) { return e.id === player.id; });
            if (entry === undefined) {
                throw new Error('Player ID not in the Ares milestone results map: ' + player.id);
            }
            entry.count++;
        }
    };
    AresHandler.earnAdacencyBonus = function (adjacentSpace, player) {
        var _a;
        if (adjacentSpace.adjacency === undefined || adjacentSpace.adjacency.bonus.length === 0) {
            return false;
        }
        var adjacentPlayer = adjacentSpace.player;
        if (adjacentPlayer === undefined) {
            throw new Error("A tile with an adjacency bonus must have an owner (" + adjacentSpace.x + ", " + adjacentSpace.y + ", " + adjacentSpace.adjacency.bonus);
        }
        var addResourceToCard = function (player, resourceType, resourceAsText) {
            var availableCards = player.getResourceCards(resourceType);
            if (availableCards.length === 0) {
            }
            else if (availableCards.length === 1) {
                player.addResourceTo(availableCards[0], { log: true });
            }
            else if (availableCards.length > 1) {
                player.game.defer(new DeferredAction_1.DeferredAction(player, function () { return new SelectCard_1.SelectCard('Select a card to add an ' + resourceAsText, 'Add ' + resourceAsText + 's', availableCards, function (selected) {
                    player.addResourceTo(selected[0], { log: true });
                    return undefined;
                }); }));
            }
        };
        var bonuses = new Multiset_1.Multiset();
        adjacentSpace.adjacency.bonus.forEach(function (bonus) {
            bonuses.add(bonus);
            switch (bonus) {
                case SpaceBonus_1.SpaceBonus.ANIMAL:
                    addResourceToCard(player, ResourceType_1.ResourceType.ANIMAL, 'animal');
                    break;
                case SpaceBonus_1.SpaceBonus.MEGACREDITS:
                    player.megaCredits++;
                    break;
                case SpaceBonus_1.SpaceBonus.POWER:
                    player.energy++;
                    break;
                case SpaceBonus_1.SpaceBonus.MICROBE:
                    addResourceToCard(player, ResourceType_1.ResourceType.MICROBE, 'microbe');
                    break;
                default:
                    player.game.grantSpaceBonus(player, bonus);
                    break;
            }
        });
        var bonusText = bonuses.entries().map(function (elem) { return elem[1] + " " + SpaceBonus_1.SpaceBonus.toString(elem[0]); }).join(', ');
        var tileText = adjacentSpace.tile !== undefined ? TileType_1.TileType.toString((_a = adjacentSpace.tile) === null || _a === void 0 ? void 0 : _a.tileType) : 'no tile';
        player.game.log('${0} gains ${1} for placing next to ${2}', function (b) { return b.player(player).string(bonusText).string(tileText); });
        var ownerBonus = 1;
        if (adjacentPlayer.cardIsInEffect(CardName_1.CardName.MARKETING_EXPERTS)) {
            ownerBonus = 2;
        }
        ;
        adjacentPlayer.megaCredits += ownerBonus;
        player.game.log('${0} gains ${1} M€ for a tile placed next to ${2}', function (b) { return b.player(adjacentPlayer).number(ownerBonus).string(tileText); });
        return true;
    };
    AresHandler.hasHazardTile = function (space) {
        return AresHandler.hazardSeverity(space) !== HazardSeverity.NONE;
    };
    AresHandler.hazardSeverity = function (space) {
        var _a;
        var type = (_a = space.tile) === null || _a === void 0 ? void 0 : _a.tileType;
        switch (type) {
            case TileType_1.TileType.DUST_STORM_MILD:
            case TileType_1.TileType.EROSION_MILD:
                return HazardSeverity.MILD;
            case TileType_1.TileType.DUST_STORM_SEVERE:
            case TileType_1.TileType.EROSION_SEVERE:
                return HazardSeverity.SEVERE;
            default:
                return HazardSeverity.NONE;
        }
    };
    AresHandler.computeAdjacencyCosts = function (game, space, subjectToHazardAdjacency) {
        var _this = this;
        var megaCreditCost = 0;
        var productionCost = 0;
        game.board.getAdjacentSpaces(space).forEach(function (adjacentSpace) {
            var _a;
            megaCreditCost += ((_a = adjacentSpace.adjacency) === null || _a === void 0 ? void 0 : _a.cost) || 0;
            if (subjectToHazardAdjacency === true) {
                var severity_1 = _this.hazardSeverity(adjacentSpace);
                switch (severity_1) {
                    case HazardSeverity.MILD:
                        productionCost += 1;
                        break;
                    case HazardSeverity.SEVERE:
                        productionCost += 2;
                        break;
                }
            }
        });
        var severity = this.hazardSeverity(space);
        switch (severity) {
            case HazardSeverity.MILD:
                megaCreditCost += 8;
                break;
            case HazardSeverity.SEVERE:
                megaCreditCost += 16;
                break;
        }
        return { megacredits: megaCreditCost, production: productionCost };
    };
    AresHandler.assertCanPay = function (player, space, subjectToHazardAdjacency) {
        if (player.game.phase === Phase_1.Phase.SOLAR) {
            return { megacredits: 0, production: 0 };
        }
        var cost = AresHandler.computeAdjacencyCosts(player.game, space, subjectToHazardAdjacency);
        var availableProductionUnits = (player.getProduction(Resources_1.Resources.MEGACREDITS) + 5) +
            player.getProduction(Resources_1.Resources.STEEL) +
            player.getProduction(Resources_1.Resources.TITANIUM) +
            player.getProduction(Resources_1.Resources.PLANTS) +
            player.getProduction(Resources_1.Resources.ENERGY) +
            player.getProduction(Resources_1.Resources.HEAT);
        if (availableProductionUnits >= cost.production && player.canAfford(cost.megacredits)) {
            return cost;
        }
        if (cost.production > 0) {
            throw new Error("Placing here costs " + cost.production + " units of production and " + cost.megacredits + " M\u20AC");
        }
        else {
            throw new Error("Placing here costs " + cost.megacredits + " M\u20AC");
        }
    };
    AresHandler.payAdjacencyAndHazardCosts = function (player, space, subjectToHazardAdjacency) {
        var cost = this.assertCanPay(player, space, subjectToHazardAdjacency);
        if (cost.production > 0) {
            player.game.defer(new SelectProductionToLoseDeferred_1.SelectProductionToLoseDeferred(player, cost.production));
        }
        if (cost.megacredits > 0) {
            player.game.log('${0} placing a tile here costs ${1} M€', function (b) { return b.player(player).number(cost.megacredits); });
            player.game.defer(new SelectHowToPayDeferred_1.SelectHowToPayDeferred(player, cost.megacredits, { title: 'Select how to pay additional placement costs.' }));
        }
    };
    AresHandler.canCover = function (space, newTile) {
        if (space.tile === undefined) {
            return true;
        }
        if (AresHandler.hasHazardTile(space) && space.tile.protectedHazard !== true) {
            return true;
        }
        if (space.tile.tileType === TileType_1.TileType.OCEAN && TileType_1.OCEAN_UPGRADE_TILES.has(newTile.tileType)) {
            return true;
        }
        return false;
    };
    AresHandler.onTemperatureChange = function (game, aresData) {
        AresHazards_1._AresHazardPlacement.onTemperatureChange(game, aresData);
    };
    AresHandler.onOceanPlaced = function (aresData, player) {
        AresHazards_1._AresHazardPlacement.onOceanPlaced(aresData, player);
    };
    AresHandler.onOxygenChange = function (game, aresData) {
        AresHazards_1._AresHazardPlacement.onOxygenChange(game, aresData);
    };
    AresHandler.grantBonusForRemovingHazard = function (player, initialTileType) {
        if (player.game.phase === Phase_1.Phase.SOLAR) {
            return;
        }
        var steps;
        switch (initialTileType) {
            case TileType_1.TileType.DUST_STORM_MILD:
            case TileType_1.TileType.EROSION_MILD:
                steps = 1;
                break;
            case TileType_1.TileType.DUST_STORM_SEVERE:
            case TileType_1.TileType.EROSION_SEVERE:
                steps = 2;
                break;
            default:
                return;
        }
        player.increaseTerraformRatingSteps(steps);
        player.game.log('${0}\'s TR increases ${1} step(s) for removing ${2}', function (b) { return b.player(player).number(steps).string(TileType_1.TileType.toString(initialTileType)); });
    };
    return AresHandler;
}());
exports.AresHandler = AresHandler;
