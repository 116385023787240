"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.DryDeserts = void 0;
var IGlobalEvent_1 = require("./IGlobalEvent");
var GlobalEventName_1 = require("./GlobalEventName");
var PartyName_1 = require("../parties/PartyName");
var RemoveOceanTile_1 = require("../../deferredActions/RemoveOceanTile");
var SelectResourcesDeferred_1 = require("../../deferredActions/SelectResourcesDeferred");
var CardRenderer_1 = require("../../cards/render/CardRenderer");
var RENDER_DATA = CardRenderer_1.CardRenderer.builder(function (b) {
    b.minus().oceans(1).br.wild(1).slash().influence();
});
var DryDeserts = (function (_super) {
    __extends(DryDeserts, _super);
    function DryDeserts() {
        return _super.call(this, {
            name: GlobalEventName_1.GlobalEventName.DRY_DESERTS,
            description: 'First player removes 1 ocean tile from the gameboard. Gain 1 standard resource per influence.',
            revealedDelegate: PartyName_1.PartyName.REDS,
            currentDelegate: PartyName_1.PartyName.UNITY,
            renderData: RENDER_DATA,
        }) || this;
    }
    DryDeserts.prototype.resolve = function (game, turmoil) {
        if (game.canRemoveOcean()) {
            game.defer(new RemoveOceanTile_1.RemoveOceanTile(game.getPlayers()[0], 'Dry Deserts Global Event - Remove an Ocean tile from the board'));
        }
        game.getPlayers().forEach(function (player) {
            var count = turmoil.getPlayerInfluence(player);
            if (count > 0) {
                game.defer(new SelectResourcesDeferred_1.SelectResourcesDeferred(player, count, 'Dry Deserts Global Event - Gain ' + count + ' resource(s) for influence'));
            }
        });
    };
    return DryDeserts;
}(IGlobalEvent_1.GlobalEvent));
exports.DryDeserts = DryDeserts;
