"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.SolarFlare = void 0;
var IGlobalEvent_1 = require("./IGlobalEvent");
var GlobalEventName_1 = require("./GlobalEventName");
var PartyName_1 = require("../parties/PartyName");
var Resources_1 = require("../../common/Resources");
var Tags_1 = require("../../cards/Tags");
var CardRenderer_1 = require("../../cards/render/CardRenderer");
var Size_1 = require("../../cards/render/Size");
var Options_1 = require("../../cards/Options");
var RENDER_DATA = CardRenderer_1.CardRenderer.builder(function (b) {
    b.minus().megacredits(3).slash().space({ played: Options_1.played }).influence({ size: Size_1.Size.SMALL });
});
var SolarFlare = (function (_super) {
    __extends(SolarFlare, _super);
    function SolarFlare() {
        return _super.call(this, {
            name: GlobalEventName_1.GlobalEventName.SOLAR_FLARE,
            description: 'Lose 3 M€ for each space tag (max 5, then reduced by influence).',
            revealedDelegate: PartyName_1.PartyName.UNITY,
            currentDelegate: PartyName_1.PartyName.KELVINISTS,
            renderData: RENDER_DATA,
        }) || this;
    }
    SolarFlare.prototype.resolve = function (game, turmoil) {
        var _this = this;
        game.getPlayers().forEach(function (player) {
            var amount = Math.min(5, player.getTagCount(Tags_1.Tags.SPACE, 'raw')) - turmoil.getPlayerInfluence(player);
            if (amount > 0) {
                player.addResource(Resources_1.Resources.MEGACREDITS, amount * -3, { log: true, from: _this.name });
            }
        });
    };
    return SolarFlare;
}(IGlobalEvent_1.GlobalEvent));
exports.SolarFlare = SolarFlare;
