"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.VictoryPoints = exports.isIActionCard = void 0;
function isIActionCard(object) {
    return object !== undefined && object.canAct !== undefined && object.action !== undefined;
}
exports.isIActionCard = isIActionCard;
var VictoryPoints;
(function (VictoryPoints) {
    function resource(points, per) {
        return { type: 'resource', points: points, per: per };
    }
    VictoryPoints.resource = resource;
    function tags(tag, points, per) {
        return { type: tag, points: points, per: per };
    }
    VictoryPoints.tags = tags;
})(VictoryPoints = exports.VictoryPoints || (exports.VictoryPoints = {}));
