"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.RedSpotObservatory = void 0;
var Tags_1 = require("../Tags");
var CardType_1 = require("../CardType");
var CardName_1 = require("../../CardName");
var ResourceType_1 = require("../../common/ResourceType");
var SelectOption_1 = require("../../inputs/SelectOption");
var OrOptions_1 = require("../../inputs/OrOptions");
var CardRenderer_1 = require("../render/CardRenderer");
var CardRequirements_1 = require("../CardRequirements");
var Card_1 = require("../Card");
var RedSpotObservatory = (function (_super) {
    __extends(RedSpotObservatory, _super);
    function RedSpotObservatory() {
        var _this = _super.call(this, {
            cost: 17,
            tags: [Tags_1.Tags.JOVIAN, Tags_1.Tags.SCIENCE],
            name: CardName_1.CardName.RED_SPOT_OBSERVATORY,
            cardType: CardType_1.CardType.ACTIVE,
            resourceType: ResourceType_1.ResourceType.FLOATER,
            victoryPoints: 2,
            requirements: CardRequirements_1.CardRequirements.builder(function (b) { return b.tag(Tags_1.Tags.SCIENCE, 3); }),
            metadata: {
                cardNumber: 'C32',
                renderData: CardRenderer_1.CardRenderer.builder(function (b) {
                    b.action('Add 1 floater to this card, or spend 1 floater here to draw a card.', function (eb) {
                        eb.empty().arrow().floaters(1).or();
                        eb.floaters(1).startAction.cards(1);
                    }).br;
                    b.cards(2);
                }),
                description: {
                    text: 'Requires 3 Science tags. Draw 2 cards.',
                    align: 'left',
                },
            },
        }) || this;
        _this.resourceCount = 0;
        return _this;
    }
    RedSpotObservatory.prototype.canAct = function () {
        return true;
    };
    RedSpotObservatory.prototype.action = function (player) {
        var _this = this;
        if (this.resourceCount < 1) {
            player.addResourceTo(this, 1);
            return undefined;
        }
        var opts = [];
        var addResource = new SelectOption_1.SelectOption('Add 1 floater on this card', 'Add floater', function () { return _this.addResource(player); });
        var spendResource = new SelectOption_1.SelectOption('Remove 1 floater on this card to draw a card', 'Remove floater', function () { return _this.spendResource(player); });
        opts.push(spendResource);
        opts.push(addResource);
        return new (OrOptions_1.OrOptions.bind.apply(OrOptions_1.OrOptions, __spreadArray([void 0], opts, false)))();
    };
    RedSpotObservatory.prototype.addResource = function (player) {
        player.addResourceTo(this, 1);
        return undefined;
    };
    RedSpotObservatory.prototype.spendResource = function (player) {
        this.resourceCount--;
        player.drawCard();
        return undefined;
    };
    RedSpotObservatory.prototype.play = function (player) {
        player.drawCard(2);
        return undefined;
    };
    return RedSpotObservatory;
}(Card_1.Card));
exports.RedSpotObservatory = RedSpotObservatory;
