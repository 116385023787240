"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.TradeWithCollegiumCopernicus = exports.CollegiumCopernicus = void 0;
var Card_1 = require("../Card");
var Tags_1 = require("../Tags");
var CardName_1 = require("../../CardName");
var CardType_1 = require("../CardType");
var CardRenderer_1 = require("../render/CardRenderer");
var Options_1 = require("../Options");
var ResourceType_1 = require("../../common/ResourceType");
var ColoniesHandler_1 = require("../../colonies/ColoniesHandler");
var DeferredAction_1 = require("../../deferredActions/DeferredAction");
var SelectColony_1 = require("../../inputs/SelectColony");
var AddResourcesToCard_1 = require("../../deferredActions/AddResourcesToCard");
function tradeCost(player) {
    return Math.max(0, 3 - player.colonyTradeDiscount);
}
var CollegiumCopernicus = (function (_super) {
    __extends(CollegiumCopernicus, _super);
    function CollegiumCopernicus() {
        return _super.call(this, {
            cardType: CardType_1.CardType.CORPORATION,
            name: CardName_1.CardName.COLLEGIUM_COPERNICUS,
            tags: [Tags_1.Tags.SCIENCE, Tags_1.Tags.EARTH],
            startingMegaCredits: 33,
            resourceType: ResourceType_1.ResourceType.DATA,
            initialActionText: 'Draw 2 cards with a science tag',
            metadata: {
                cardNumber: 'PfC4',
                description: 'You start with 33 M€. As your first action, draw 2 cards with a science tag.',
                renderData: CardRenderer_1.CardRenderer.builder(function (b) {
                    b.br;
                    b.megacredits(33).cards(2, { secondaryTag: Tags_1.Tags.SCIENCE }).br;
                    b.effect('When you play a card with a science tag (including this) add 1 data to any card.', function (eb) {
                        eb.science(1, { played: Options_1.played }).startEffect.data().asterix();
                    }).br;
                    b.action('Spend 3 data from this card to trade.', function (eb) {
                        eb.data({ amount: 3 }).startAction.trade();
                    });
                }),
            },
        }) || this;
    }
    CollegiumCopernicus.prototype.play = function (player) {
        this.addResource(player);
        return undefined;
    };
    CollegiumCopernicus.prototype.initialAction = function (player) {
        player.drawCard(2, { tag: Tags_1.Tags.SCIENCE });
        return undefined;
    };
    CollegiumCopernicus.prototype.onCorpCardPlayed = function (player, card) {
        return this.onCardPlayed(player, card);
    };
    CollegiumCopernicus.prototype.onCardPlayed = function (player, card) {
        if (card.tags.includes(Tags_1.Tags.SCIENCE) && player.isCorporation(this.name)) {
            this.addResource(player);
        }
    };
    CollegiumCopernicus.prototype.addResource = function (player) {
        player.game.defer(new AddResourcesToCard_1.AddResourcesToCard(player, ResourceType_1.ResourceType.DATA, { count: 1 }));
    };
    CollegiumCopernicus.prototype.canAct = function (player) {
        return ColoniesHandler_1.ColoniesHandler.canTrade(player) && this.resourceCount >= tradeCost(player);
    };
    CollegiumCopernicus.prototype.action = function (player) {
        var _this = this;
        var game = player.game;
        game.defer(new DeferredAction_1.DeferredAction(player, function () { return new SelectColony_1.SelectColony('Select colony tile to trade with for free', 'Select', ColoniesHandler_1.ColoniesHandler.tradeableColonies(game), function (colony) {
            _this.resourceCount -= tradeCost(player);
            game.log('${0} spent ${1} data to trade with ${2}', function (b) { return b.player(player).number(tradeCost(player)).colony(colony); });
            colony.trade(player);
            return undefined;
        }); }));
        return undefined;
    };
    return CollegiumCopernicus;
}(Card_1.Card));
exports.CollegiumCopernicus = CollegiumCopernicus;
var TradeWithCollegiumCopernicus = (function () {
    function TradeWithCollegiumCopernicus(player) {
        this.player = player;
        this.collegiumCopernicus = player.isCorporation(CardName_1.CardName.COLLEGIUM_COPERNICUS) ?
            player.corporationCard : undefined;
    }
    TradeWithCollegiumCopernicus.prototype.canUse = function () {
        var _a, _b;
        return ((_b = (_a = this.collegiumCopernicus) === null || _a === void 0 ? void 0 : _a.resourceCount) !== null && _b !== void 0 ? _b : 0) >= tradeCost(this.player) &&
            !this.player.getActionsThisGeneration().has(CardName_1.CardName.COLLEGIUM_COPERNICUS);
    };
    TradeWithCollegiumCopernicus.prototype.optionText = function () {
        return 'Pay 3 Data (use Collegium Copernicus action)';
    };
    TradeWithCollegiumCopernicus.prototype.trade = function (colony) {
        var _this = this;
        if (this.collegiumCopernicus !== undefined)
            this.collegiumCopernicus.resourceCount -= tradeCost(this.player);
        this.player.addActionThisGeneration(CardName_1.CardName.COLLEGIUM_COPERNICUS);
        this.player.game.log('${0} spent ${1} data to trade with ${2}', function (b) { return b.player(_this.player).number(tradeCost(_this.player)).colony(colony); });
        colony.trade(this.player);
    };
    return TradeWithCollegiumCopernicus;
}());
exports.TradeWithCollegiumCopernicus = TradeWithCollegiumCopernicus;
