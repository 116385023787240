"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.FloaterUrbanism = void 0;
var ICard_1 = require("../ICard");
var Card_1 = require("../Card");
var CardType_1 = require("../CardType");
var CardName_1 = require("../../CardName");
var CardRenderer_1 = require("../render/CardRenderer");
var ResourceType_1 = require("../../common/ResourceType");
var Tags_1 = require("../Tags");
var CardRequirements_1 = require("../CardRequirements");
var SelectCard_1 = require("../../inputs/SelectCard");
var FloaterUrbanism = (function (_super) {
    __extends(FloaterUrbanism, _super);
    function FloaterUrbanism() {
        var _this = _super.call(this, {
            cardType: CardType_1.CardType.ACTIVE,
            name: CardName_1.CardName.FLOATER_URBANISM,
            cost: 7,
            tags: [Tags_1.Tags.VENUS],
            resourceType: ResourceType_1.ResourceType.VENUSIAN_HABITAT,
            requirements: CardRequirements_1.CardRequirements.builder(function (b) { return b.tag(Tags_1.Tags.VENUS, 4); }),
            victoryPoints: ICard_1.VictoryPoints.resource(1, 1),
            metadata: {
                cardNumber: 'PfTMP',
                renderData: CardRenderer_1.CardRenderer.builder(function (b) {
                    b.action('Remove 1 floater from any card and add 1 Venusian habitat on this card.', function (ab) {
                        ab.floaters(1).startAction.venusianHabitat(1);
                    }).br;
                    b.vpText('1 VP for every Venusian habitat on this card.');
                }),
                description: 'Requires 4 Venus tags.',
            },
        }) || this;
        _this.resourceCount = 0;
        return _this;
    }
    FloaterUrbanism.prototype.canAct = function (player) {
        return player.getResourceCount(ResourceType_1.ResourceType.FLOATER) > 0;
    };
    FloaterUrbanism.prototype.action = function (player) {
        var _this = this;
        var cards = player.getCardsWithResources(ResourceType_1.ResourceType.FLOATER);
        var input = new SelectCard_1.SelectCard('Choose a card to move a floater to a Venusian habitat.', 'Choose', cards, function (selected) {
            player.removeResourceFrom(selected[0], 1, player.game, undefined, true);
            player.addResourceTo(_this, { log: true });
            return undefined;
        });
        if (cards.length === 0) {
            return undefined;
        }
        if (cards.length === 1) {
            input.cb(cards);
            return undefined;
        }
        return input;
    };
    FloaterUrbanism.prototype.play = function () {
        return undefined;
    };
    return FloaterUrbanism;
}(Card_1.Card));
exports.FloaterUrbanism = FloaterUrbanism;
