"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.Fish = void 0;
var Tags_1 = require("../Tags");
var Card_1 = require("../Card");
var ICard_1 = require("../ICard");
var CardType_1 = require("../CardType");
var ResourceType_1 = require("../../common/ResourceType");
var Resources_1 = require("../../common/Resources");
var CardName_1 = require("../../CardName");
var DecreaseAnyProduction_1 = require("../../deferredActions/DecreaseAnyProduction");
var CardRequirements_1 = require("../CardRequirements");
var CardRenderer_1 = require("../render/CardRenderer");
var Options_1 = require("../Options");
var Fish = (function (_super) {
    __extends(Fish, _super);
    function Fish() {
        var _this = _super.call(this, {
            cardType: CardType_1.CardType.ACTIVE,
            name: CardName_1.CardName.FISH,
            tags: [Tags_1.Tags.ANIMAL],
            cost: 9,
            resourceType: ResourceType_1.ResourceType.ANIMAL,
            victoryPoints: ICard_1.VictoryPoints.resource(1, 1),
            requirements: CardRequirements_1.CardRequirements.builder(function (b) { return b.temperature(2); }),
            metadata: {
                cardNumber: '052',
                renderData: CardRenderer_1.CardRenderer.builder(function (b) {
                    b.action('Add 1 Animal to this card.', function (eb) {
                        eb.empty().startAction.animals(1);
                    }).br;
                    b.production(function (pb) { return pb.minus().plants(1, { all: Options_1.all }); }).br;
                    b.vpText('1 VP for each Animal on this card.');
                }),
                description: {
                    text: 'Requires +2 C° or warmer. Decrease any Plant production 1 step.',
                    align: 'left',
                },
            },
        }) || this;
        _this.resourceCount = 0;
        return _this;
    }
    Fish.prototype.canPlay = function (player) {
        return player.game.someoneHasResourceProduction(Resources_1.Resources.PLANTS, 1);
    };
    Fish.prototype.play = function (player) {
        player.game.defer(new DecreaseAnyProduction_1.DecreaseAnyProduction(player, Resources_1.Resources.PLANTS, { count: 1 }));
        return undefined;
    };
    Fish.prototype.canAct = function () {
        return true;
    };
    Fish.prototype.action = function (player) {
        player.addResourceTo(this);
        return undefined;
    };
    return Fish;
}(Card_1.Card));
exports.Fish = Fish;
