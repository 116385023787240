"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.Airliners = void 0;
var CardType_1 = require("../CardType");
var CardName_1 = require("../../CardName");
var Resources_1 = require("../../common/Resources");
var ResourceType_1 = require("../../common/ResourceType");
var AddResourcesToCard_1 = require("../../deferredActions/AddResourcesToCard");
var CardRequirements_1 = require("../CardRequirements");
var Card_1 = require("../Card");
var CardRenderer_1 = require("../render/CardRenderer");
var Airliners = (function (_super) {
    __extends(Airliners, _super);
    function Airliners() {
        return _super.call(this, {
            cost: 11,
            name: CardName_1.CardName.AIRLINERS,
            cardType: CardType_1.CardType.AUTOMATED,
            requirements: CardRequirements_1.CardRequirements.builder(function (b) { return b.floaters(3); }),
            victoryPoints: 1,
            metadata: {
                cardNumber: 'C01',
                description: 'Requires that you have 3 floaters. Increase your M€ production 2 steps. Add 2 floaters to ANY card.',
                renderData: CardRenderer_1.CardRenderer.builder(function (b) {
                    b.production(function (pb) { return pb.megacredits(2); }).br;
                    b.floaters(2).asterix();
                }),
            },
        }) || this;
    }
    Airliners.prototype.play = function (player) {
        player.addProduction(Resources_1.Resources.MEGACREDITS, 2);
        player.game.defer(new AddResourcesToCard_1.AddResourcesToCard(player, ResourceType_1.ResourceType.FLOATER, { count: 2 }));
        return undefined;
    };
    return Airliners;
}(Card_1.Card));
exports.Airliners = Airliners;
