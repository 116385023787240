"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.MoholeArea = void 0;
var TileType_1 = require("../../common/TileType");
var Card_1 = require("../Card");
var CardType_1 = require("../CardType");
var SpaceType_1 = require("../../SpaceType");
var Tags_1 = require("../Tags");
var SelectSpace_1 = require("../../inputs/SelectSpace");
var Resources_1 = require("../../common/Resources");
var CardName_1 = require("../../CardName");
var CardRenderer_1 = require("../render/CardRenderer");
var Units_1 = require("../../Units");
var Options_1 = require("../Options");
var MoholeArea = (function (_super) {
    __extends(MoholeArea, _super);
    function MoholeArea(name, adjacencyBonus, metadata) {
        if (name === void 0) { name = CardName_1.CardName.MOHOLE_AREA; }
        if (adjacencyBonus === void 0) { adjacencyBonus = undefined; }
        if (metadata === void 0) { metadata = {
            cardNumber: '142',
            renderData: CardRenderer_1.CardRenderer.builder(function (b) {
                b.production(function (pb) { return pb.heat(4, { digit: Options_1.digit }); }).br;
                b.tile(TileType_1.TileType.MOHOLE_AREA, true);
            }),
            description: 'Increase your heat production 4 steps. Place this tile ON AN AREA RESERVED FOR OCEAN.',
        }; }
        return _super.call(this, {
            cardType: CardType_1.CardType.AUTOMATED,
            name: name,
            tags: [Tags_1.Tags.BUILDING],
            cost: 20,
            adjacencyBonus: adjacencyBonus,
            metadata: metadata,
            productionBox: Units_1.Units.of({ heat: 4 }),
        }) || this;
    }
    MoholeArea.prototype.play = function (player) {
        var _this = this;
        return new SelectSpace_1.SelectSpace('Select an ocean space for special tile', player.game.board.getAvailableSpacesForOcean(player), function (space) {
            player.game.addTile(player, SpaceType_1.SpaceType.OCEAN, space, { tileType: TileType_1.TileType.MOHOLE_AREA });
            space.adjacency = _this.adjacencyBonus;
            player.addProduction(Resources_1.Resources.HEAT, 4);
            return undefined;
        });
    };
    return MoholeArea;
}(Card_1.Card));
exports.MoholeArea = MoholeArea;
