"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.LunaMiningHub = void 0;
var CardName_1 = require("../../CardName");
var CardType_1 = require("../CardType");
var Tags_1 = require("../Tags");
var CardRequirements_1 = require("../CardRequirements");
var CardRenderer_1 = require("../render/CardRenderer");
var TileType_1 = require("../../common/TileType");
var CardRenderDynamicVictoryPoints_1 = require("../render/CardRenderDynamicVictoryPoints");
var MoonExpansion_1 = require("../../moon/MoonExpansion");
var Units_1 = require("../../Units");
var MoonCard_1 = require("./MoonCard");
var PlaceSpecialMoonTile_1 = require("../../moon/PlaceSpecialMoonTile");
var Size_1 = require("../render/Size");
var LunaMiningHub = (function (_super) {
    __extends(LunaMiningHub, _super);
    function LunaMiningHub() {
        return _super.call(this, {
            name: CardName_1.CardName.LUNA_MINING_HUB,
            cardType: CardType_1.CardType.AUTOMATED,
            tags: [Tags_1.Tags.BUILDING],
            cost: 16,
            productionBox: Units_1.Units.of({ steel: 1, titanium: 1 }),
            reserveUnits: Units_1.Units.of({ steel: 1, titanium: 1 }),
            tr: { moonMining: 1 },
            victoryPoints: 'special',
            requirements: CardRequirements_1.CardRequirements.builder(function (b) { return b.miningRate(5); }),
            metadata: {
                cardNumber: 'M14',
                description: {
                    text: '2 VP PER MINING TILE ADJACENT TO THIS TILE.',
                    align: 'left',
                },
                renderData: CardRenderer_1.CardRenderer.builder(function (b) {
                    b.text('Requires a Mining Rate of 5 or higher.', Size_1.Size.TINY, false, false).br;
                    b.minus().steel(1).minus().titanium(1).production(function (pb) { return pb.steel(1).titanium(1); }).br;
                    b.text('Spend 1 steel and 1 titanium and raise your steel and titanium production 1 step.', Size_1.Size.TINY, false, false).br;
                    b.tile(TileType_1.TileType.LUNA_MINING_HUB, true).moonMiningRate({ size: Size_1.Size.SMALL });
                    b.text('Place this tile on the Moon and raise the Mining Rate 1 step.', Size_1.Size.TINY, false, false);
                }),
                victoryPoints: CardRenderDynamicVictoryPoints_1.CardRenderDynamicVictoryPoints.moonMiningTile(2, true),
            },
        }) || this;
    }
    ;
    LunaMiningHub.prototype.play = function (player) {
        _super.prototype.play.call(this, player);
        player.game.defer(new PlaceSpecialMoonTile_1.PlaceSpecialMoonTile(player, {
            tileType: TileType_1.TileType.LUNA_MINING_HUB,
            card: this.name,
        }, 'Select a space for Luna Mining Hub.'));
        MoonExpansion_1.MoonExpansion.raiseMiningRate(player);
        return undefined;
    };
    LunaMiningHub.prototype.getVictoryPoints = function (player) {
        var moonData = MoonExpansion_1.MoonExpansion.moonData(player.game);
        var usedSpace = moonData.moon.getSpaceByTileCard(this.name);
        if (usedSpace !== undefined) {
            var adjacentSpaces = moonData.moon.getAdjacentSpaces(usedSpace);
            var adjacentMines = adjacentSpaces.filter(function (s) { return MoonExpansion_1.MoonExpansion.spaceHasType(s, TileType_1.TileType.MOON_MINE); });
            return 2 * adjacentMines.length;
        }
        return 0;
    };
    return LunaMiningHub;
}(MoonCard_1.MoonCard));
exports.LunaMiningHub = LunaMiningHub;
