"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.IndustrialCenter = void 0;
var Tags_1 = require("../Tags");
var Card_1 = require("../Card");
var CardType_1 = require("../CardType");
var TileType_1 = require("../../common/TileType");
var SelectSpace_1 = require("../../inputs/SelectSpace");
var Resources_1 = require("../../common/Resources");
var CardName_1 = require("../../CardName");
var Board_1 = require("../../boards/Board");
var SelectHowToPayDeferred_1 = require("../../deferredActions/SelectHowToPayDeferred");
var CardRenderer_1 = require("../render/CardRenderer");
var IndustrialCenter = (function (_super) {
    __extends(IndustrialCenter, _super);
    function IndustrialCenter(name, adjacencyBonus, metadata) {
        if (name === void 0) { name = CardName_1.CardName.INDUSTRIAL_CENTER; }
        if (adjacencyBonus === void 0) { adjacencyBonus = undefined; }
        if (metadata === void 0) { metadata = {
            cardNumber: '123',
            renderData: CardRenderer_1.CardRenderer.builder(function (b) {
                b.action('Spend 7 M€ to increase your steel production 1 step.', function (eb) {
                    eb.megacredits(7).startAction.production(function (pb) { return pb.steel(1); });
                }).br;
                b.tile(TileType_1.TileType.INDUSTRIAL_CENTER, true, false).asterix();
            }),
            description: 'Place this tile adjacent to a city tile.',
        }; }
        return _super.call(this, {
            cardType: CardType_1.CardType.ACTIVE,
            name: name,
            tags: [Tags_1.Tags.BUILDING],
            cost: 4,
            adjacencyBonus: adjacencyBonus,
            metadata: metadata,
        }) || this;
    }
    IndustrialCenter.prototype.getAvailableSpaces = function (player) {
        return player.game.board.getAvailableSpacesOnLand(player)
            .filter(function (space) { return player.game.board.getAdjacentSpaces(space).some(function (adjacentSpace) { return Board_1.Board.isCitySpace(adjacentSpace); }); });
    };
    IndustrialCenter.prototype.canPlay = function (player) {
        return this.getAvailableSpaces(player).length > 0;
    };
    IndustrialCenter.prototype.play = function (player) {
        var _this = this;
        return new SelectSpace_1.SelectSpace('Select space adjacent to a city tile', this.getAvailableSpaces(player), function (foundSpace) {
            player.game.addTile(player, foundSpace.spaceType, foundSpace, { tileType: TileType_1.TileType.INDUSTRIAL_CENTER });
            foundSpace.adjacency = _this.adjacencyBonus;
            return undefined;
        });
    };
    IndustrialCenter.prototype.canAct = function (player) {
        return player.canAfford(7);
    };
    IndustrialCenter.prototype.action = function (player) {
        player.game.defer(new SelectHowToPayDeferred_1.SelectHowToPayDeferred(player, 7, { title: 'Select how to pay for action' }));
        player.addProduction(Resources_1.Resources.STEEL, 1);
        return undefined;
    };
    return IndustrialCenter;
}(Card_1.Card));
exports.IndustrialCenter = IndustrialCenter;
