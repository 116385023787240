"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.ImportedNutrients = void 0;
var Tags_1 = require("./../Tags");
var Card_1 = require("../Card");
var CardType_1 = require("./../CardType");
var SelectCard_1 = require("../../inputs/SelectCard");
var ResourceType_1 = require("../../common/ResourceType");
var CardName_1 = require("../../CardName");
var CardRenderer_1 = require("../render/CardRenderer");
var Options_1 = require("../Options");
var ImportedNutrients = (function (_super) {
    __extends(ImportedNutrients, _super);
    function ImportedNutrients() {
        return _super.call(this, {
            cardType: CardType_1.CardType.EVENT,
            name: CardName_1.CardName.IMPORTED_NUTRIENTS,
            tags: [Tags_1.Tags.EARTH, Tags_1.Tags.SPACE],
            cost: 14,
            metadata: {
                cardNumber: 'X22',
                renderData: CardRenderer_1.CardRenderer.builder(function (b) {
                    b.plants(4, { digit: Options_1.digit }).nbsp.microbes(4, { digit: Options_1.digit }).asterix();
                }),
                description: 'Gain 4 plants and add 4 microbes to ANOTHER CARD.',
            },
        }) || this;
    }
    ImportedNutrients.prototype.play = function (player) {
        player.plants += 4;
        var microbeCards = player.getResourceCards(ResourceType_1.ResourceType.MICROBE);
        if (microbeCards.length === 1) {
            player.addResourceTo(microbeCards[0], 4);
            return undefined;
        }
        else if (microbeCards.length > 1) {
            return new SelectCard_1.SelectCard('Select card to add 4 microbes', 'Add microbes', microbeCards, function (foundCards) {
                player.addResourceTo(foundCards[0], 4);
                return undefined;
            });
        }
        return undefined;
    };
    return ImportedNutrients;
}(Card_1.Card));
exports.ImportedNutrients = ImportedNutrients;
