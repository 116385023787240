"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.PartyCardRequirement = exports.ProductionCardRequirement = exports.TagCardRequirement = exports.CardRequirement = void 0;
var RequirementType_1 = require("./RequirementType");
var Tags_1 = require("./Tags");
var Resources_1 = require("../common/Resources");
var ResourceType_1 = require("../common/ResourceType");
var TileType_1 = require("../common/TileType");
var GlobalParameter_1 = require("../GlobalParameter");
var MoonExpansion_1 = require("../moon/MoonExpansion");
var Turmoil_1 = require("../turmoil/Turmoil");
var CardRequirement = (function () {
    function CardRequirement(type, amount, options) {
        if (amount === void 0) { amount = 1; }
        var _a, _b;
        this.type = type;
        this.amount = amount;
        this.isMax = false;
        this.isAny = false;
        this.text = undefined;
        this.isMax = (_a = options === null || options === void 0 ? void 0 : options.max) !== null && _a !== void 0 ? _a : false;
        this.isAny = (_b = options === null || options === void 0 ? void 0 : options.all) !== null && _b !== void 0 ? _b : false;
        this.text = options === null || options === void 0 ? void 0 : options.text;
    }
    CardRequirement.prototype.satisfiesInequality = function (calculated) {
        if (this.isMax) {
            return calculated <= this.amount;
        }
        return calculated >= this.amount;
    };
    CardRequirement.prototype.satisfies = function (player) {
        var _this = this;
        switch (this.type) {
            case RequirementType_1.RequirementType.CHAIRMAN:
                return Turmoil_1.Turmoil.getTurmoil(player.game).chairman === player.id;
            case RequirementType_1.RequirementType.CITIES:
                if (this.isAny) {
                    return this.satisfiesInequality(player.game.getCitiesInPlay());
                }
                else {
                    return this.satisfiesInequality(player.getCitiesCount());
                }
            case RequirementType_1.RequirementType.COLONIES:
                var coloniesCount = player.game.colonies.map(function (colony) { return colony.colonies.filter(function (owner) { return owner === player.id; }).length; })
                    .reduce(function (sum, colonyCount) { return sum + colonyCount; });
                return this.satisfiesInequality(coloniesCount);
            case RequirementType_1.RequirementType.FLOATERS:
                return this.satisfiesInequality(player.getResourceCount(ResourceType_1.ResourceType.FLOATER));
            case RequirementType_1.RequirementType.GREENERIES:
                var greeneries = player.game.board.spaces.filter(function (space) { return space.tile !== undefined &&
                    space.tile.tileType === TileType_1.TileType.GREENERY &&
                    (space.player === player || _this.isAny); }).length;
                return this.satisfiesInequality(greeneries);
            case RequirementType_1.RequirementType.PARTY_LEADERS:
                var turmoil = Turmoil_1.Turmoil.getTurmoil(player.game);
                var parties = turmoil.parties.filter(function (party) { return party.partyLeader === player.id; }).length;
                return this.satisfiesInequality(parties);
            case RequirementType_1.RequirementType.OCEANS:
                return this.checkGlobalRequirement(player, GlobalParameter_1.GlobalParameter.OCEANS, this.amount, this.isMax);
            case RequirementType_1.RequirementType.OXYGEN:
                return this.checkGlobalRequirement(player, GlobalParameter_1.GlobalParameter.OXYGEN, this.amount, this.isMax);
            case RequirementType_1.RequirementType.TEMPERATURE:
                return this.checkGlobalRequirement(player, GlobalParameter_1.GlobalParameter.TEMPERATURE, this.amount, this.isMax);
            case RequirementType_1.RequirementType.VENUS:
                return this.checkGlobalRequirement(player, GlobalParameter_1.GlobalParameter.VENUS, this.amount, this.isMax);
            case RequirementType_1.RequirementType.TR:
                return this.satisfiesInequality(player.getTerraformRating());
            case RequirementType_1.RequirementType.REMOVED_PLANTS:
                return player.game.someoneHasRemovedOtherPlayersPlants;
            case RequirementType_1.RequirementType.RESOURCE_TYPES:
                var standardResources = [Resources_1.Resources.MEGACREDITS, Resources_1.Resources.STEEL, Resources_1.Resources.TITANIUM, Resources_1.Resources.PLANTS, Resources_1.Resources.ENERGY, Resources_1.Resources.HEAT]
                    .filter(function (res) { return player.getResource(res) > 0; }).length;
                var nonStandardResources = new Set(player.getCardsWithResources().map(function (card) { return card.resourceType; })).size;
                return this.satisfiesInequality(standardResources + nonStandardResources);
            case RequirementType_1.RequirementType.COLONY_RATE:
                return this.checkGlobalRequirement(player, GlobalParameter_1.GlobalParameter.MOON_COLONY_RATE, this.amount, this.isMax);
            case RequirementType_1.RequirementType.MINING_RATE:
                return this.checkGlobalRequirement(player, GlobalParameter_1.GlobalParameter.MOON_MINING_RATE, this.amount, this.isMax);
            case RequirementType_1.RequirementType.LOGISTIC_RATE:
                return this.checkGlobalRequirement(player, GlobalParameter_1.GlobalParameter.MOON_LOGISTICS_RATE, this.amount, this.isMax);
            case RequirementType_1.RequirementType.COLONY_TILES:
                return this.satisfiesInequality(MoonExpansion_1.MoonExpansion.tiles(player.game, TileType_1.TileType.MOON_COLONY, { surfaceOnly: true, ownedBy: this.isAny ? undefined : player }).length);
            case RequirementType_1.RequirementType.MINING_TILES:
                return this.satisfiesInequality(MoonExpansion_1.MoonExpansion.tiles(player.game, TileType_1.TileType.MOON_MINE, { surfaceOnly: true, ownedBy: this.isAny ? undefined : player }).length);
            case RequirementType_1.RequirementType.ROAD_TILES:
                return this.satisfiesInequality(MoonExpansion_1.MoonExpansion.tiles(player.game, TileType_1.TileType.MOON_ROAD, { surfaceOnly: true, ownedBy: this.isAny ? undefined : player }).length);
            case RequirementType_1.RequirementType.TAG:
            case RequirementType_1.RequirementType.PARTY:
            case RequirementType_1.RequirementType.PRODUCTION:
                throw "Use subclass satisfies() for requirement type " + this.type;
        }
    };
    CardRequirement.prototype.checkGlobalRequirement = function (player, parameter, level, max) {
        if (max === void 0) { max = false; }
        var currentLevel;
        var playerRequirementsBonus = player.getRequirementsBonus(parameter);
        switch (parameter) {
            case GlobalParameter_1.GlobalParameter.OCEANS:
                currentLevel = player.game.board.getOceansOnBoard();
                break;
            case GlobalParameter_1.GlobalParameter.OXYGEN:
                currentLevel = player.game.getOxygenLevel();
                break;
            case GlobalParameter_1.GlobalParameter.TEMPERATURE:
                currentLevel = player.game.getTemperature();
                playerRequirementsBonus *= 2;
                break;
            case GlobalParameter_1.GlobalParameter.VENUS:
                currentLevel = player.game.getVenusScaleLevel();
                playerRequirementsBonus *= 2;
                break;
            case GlobalParameter_1.GlobalParameter.MOON_COLONY_RATE:
                currentLevel = MoonExpansion_1.MoonExpansion.moonData(player.game).colonyRate;
                break;
            case GlobalParameter_1.GlobalParameter.MOON_MINING_RATE:
                currentLevel = MoonExpansion_1.MoonExpansion.moonData(player.game).miningRate;
                break;
            case GlobalParameter_1.GlobalParameter.MOON_LOGISTICS_RATE:
                currentLevel = MoonExpansion_1.MoonExpansion.moonData(player.game).logisticRate;
                break;
            default:
                console.warn("Unknown GlobalParameter provided: " + parameter);
                return false;
        }
        if (max) {
            return currentLevel <= level + playerRequirementsBonus;
        }
        else {
            return currentLevel >= level - playerRequirementsBonus;
        }
    };
    return CardRequirement;
}());
exports.CardRequirement = CardRequirement;
var TagCardRequirement = (function (_super) {
    __extends(TagCardRequirement, _super);
    function TagCardRequirement(tag, amount, options) {
        var _this = _super.call(this, RequirementType_1.RequirementType.TAG, amount, options) || this;
        _this.tag = tag;
        return _this;
    }
    TagCardRequirement.prototype.satisfies = function (player) {
        var _this = this;
        var mode = this.isMax !== true ? 'default' : 'raw';
        var tagCount = player.getTagCount(this.tag, mode);
        if (this.isAny) {
            player.game.getPlayers().forEach(function (p) {
                if (p.id !== player.id) {
                    tagCount += p.getTagCount(_this.tag, 'raw');
                }
            });
        }
        if (this.tag === Tags_1.Tags.SCIENCE && player.hasTurmoilScienceTagBonus)
            tagCount += 1;
        return this.satisfiesInequality(tagCount);
    };
    return TagCardRequirement;
}(CardRequirement));
exports.TagCardRequirement = TagCardRequirement;
var ProductionCardRequirement = (function (_super) {
    __extends(ProductionCardRequirement, _super);
    function ProductionCardRequirement(resource, amount, options) {
        var _this = _super.call(this, RequirementType_1.RequirementType.PRODUCTION, amount, options) || this;
        _this.resource = resource;
        return _this;
    }
    ProductionCardRequirement.prototype.satisfies = function (player) {
        return this.satisfiesInequality(player.getProduction(this.resource));
    };
    return ProductionCardRequirement;
}(CardRequirement));
exports.ProductionCardRequirement = ProductionCardRequirement;
var PartyCardRequirement = (function (_super) {
    __extends(PartyCardRequirement, _super);
    function PartyCardRequirement(party) {
        var _this = _super.call(this, RequirementType_1.RequirementType.PARTY) || this;
        _this.party = party;
        return _this;
    }
    PartyCardRequirement.prototype.satisfies = function (player) {
        return Turmoil_1.Turmoil.getTurmoil(player.game).canPlay(player, this.party);
    };
    return PartyCardRequirement;
}(CardRequirement));
exports.PartyCardRequirement = PartyCardRequirement;
