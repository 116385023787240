"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var vue_1 = require("vue");
var PreferencesManager_1 = require("@/client/utils/PreferencesManager");
var SoundManager_1 = require("@/client/utils/SoundManager");
var constants = require("@/constants");
var utils_1 = require("@/utils/utils");
var ui_update_timeout_id;
var documentTitleTimer;
exports.default = vue_1.default.extend({
    name: 'waiting-for',
    props: {
        playerView: {
            type: Object,
        },
        players: {
            type: Array,
        },
        settings: {
            type: Object,
        },
        waitingfor: {
            type: Object,
        },
    },
    data: function () {
        return {
            waitingForTimeout: this.settings.waitingForTimeout,
        };
    },
    methods: {
        animateTitle: function () {
            var sequence = '\u25D1\u25D2\u25D0\u25D3';
            var first = document.title[0];
            var position = sequence.indexOf(first);
            var next = sequence[0];
            if (position !== -1 && position < sequence.length - 1) {
                next = sequence[position + 1];
            }
            document.title = next + ' ' + this.$t(constants.APP_NAME);
        },
        onsave: function (out) {
            var _this = this;
            var xhr = new XMLHttpRequest();
            var root = this.$root;
            var showAlert = this.$root.showAlert;
            if (root.isServerSideRequestInProgress) {
                console.warn('Server request in progress');
                return;
            }
            root.isServerSideRequestInProgress = true;
            xhr.open('POST', '/player/input?id=' + this.playerView.id);
            xhr.responseType = 'json';
            xhr.onload = function () {
                if (xhr.status === 200) {
                    root.screen = 'empty';
                    root.playerView = xhr.response;
                    root.playerkey++;
                    root.screen = 'player-home';
                    if (_this.playerView.game.phase === 'end' && window.location.pathname !== '/the-end') {
                        (window).location = (window).location;
                    }
                }
                else if (xhr.status === 400 && xhr.responseType === 'json') {
                    showAlert(xhr.response.message);
                }
                else {
                    showAlert('Unexpected response from server. Please try again.');
                }
                root.isServerSideRequestInProgress = false;
            };
            xhr.send(JSON.stringify(out));
            xhr.onerror = function () {
                root.isServerSideRequestInProgress = false;
            };
        },
        waitForUpdate: function () {
            var _this = this;
            var vueApp = this;
            var root = this.$root;
            clearTimeout(ui_update_timeout_id);
            var askForUpdate = function () {
                var xhr = new XMLHttpRequest();
                xhr.open('GET', '/api/waitingfor' + window.location.search + '&gameAge=' + _this.playerView.game.gameAge + '&undoCount=' + _this.playerView.game.undoCount);
                xhr.onerror = function () {
                    root.showAlert('Unable to reach the server. The server may be restarting or down for maintenance.', function () { return vueApp.waitForUpdate(); });
                };
                xhr.onload = function () {
                    if (xhr.status === 200) {
                        var result = xhr.response;
                        if (result.result === 'GO') {
                            root.updatePlayer();
                            if (Notification.permission !== 'granted') {
                                Notification.requestPermission();
                            }
                            else if (Notification.permission === 'granted') {
                                try {
                                    new Notification(constants.APP_NAME, {
                                        icon: '/favicon.ico',
                                        body: 'It\'s your turn!',
                                    });
                                }
                                catch (e) {
                                    console.warn('unable to create notification', e);
                                }
                            }
                            var soundsEnabled = PreferencesManager_1.PreferencesManager.load('enable_sounds') === '1';
                            if (soundsEnabled)
                                SoundManager_1.SoundManager.playActivePlayerSound();
                            return;
                        }
                        else if (result.result === 'REFRESH') {
                            if ((0, utils_1.isPlayerId)(_this.playerView.id)) {
                                root.updatePlayer();
                            }
                            else {
                                root.updateSpectator();
                            }
                            return;
                        }
                        vueApp.waitForUpdate();
                    }
                    else {
                        root.showAlert("Received unexpected response from server (" + xhr.status + "). This is often due to the server restarting.", function () { return vueApp.waitForUpdate(); });
                    }
                };
                xhr.responseType = 'json';
                xhr.send();
            };
            ui_update_timeout_id = window.setTimeout(askForUpdate, this.waitingForTimeout);
        },
    },
    mounted: function () {
        var _this = this;
        document.title = this.$t(constants.APP_NAME);
        window.clearInterval(documentTitleTimer);
        if (this.waitingfor === undefined) {
            this.waitForUpdate();
        }
        if (this.playerView.players.length > 1 && this.waitingfor !== undefined) {
            documentTitleTimer = window.setInterval(function () { return _this.animateTitle(); }, 1000);
        }
    },
});
