"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GrantResourceDeferred = void 0;
var OrOptions_1 = require("../inputs/OrOptions");
var SelectCard_1 = require("../inputs/SelectCard");
var DeferredAction_1 = require("../deferredActions/DeferredAction");
var SelectResources_1 = require("../inputs/SelectResources");
var GrantResourceDeferred = (function () {
    function GrantResourceDeferred(player, wild) {
        if (wild === void 0) { wild = true; }
        this.player = player;
        this.wild = wild;
        this.priority = DeferredAction_1.Priority.GAIN_RESOURCE_OR_PRODUCTION;
    }
    GrantResourceDeferred.prototype.execute = function () {
        var _this = this;
        var options = new OrOptions_1.OrOptions();
        options.title = 'Choose your resource bonus';
        options.options.push(new SelectResources_1.SelectResources(this.player, 1, 'Gain 1 standard resource.'));
        if (this.wild) {
            var cards = this.player.getResourceCards(undefined);
            if (cards.length > 0) {
                options.options.push(new SelectCard_1.SelectCard('Add resource to card', 'Add resource', this.player.getResourceCards(undefined), function (selected) {
                    _this.player.addResourceTo(selected[0], { qty: 1, log: true });
                    return undefined;
                }));
                options.title = 'Choose your wild resource bonus.';
            }
        }
        return options;
    };
    return GrantResourceDeferred;
}());
exports.GrantResourceDeferred = GrantResourceDeferred;
