"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DecreaseAnyProduction = void 0;
var Resources_1 = require("../common/Resources");
var SelectPlayer_1 = require("../inputs/SelectPlayer");
var DeferredAction_1 = require("./DeferredAction");
var DecreaseAnyProduction = (function () {
    function DecreaseAnyProduction(player, resource, options, title) {
        if (options === void 0) { options = {
            count: 1,
            stealing: false,
        }; }
        if (title === void 0) { title = 'Select player to decrease ' + resource + ' production by ' + options.count + ' step(s)'; }
        this.player = player;
        this.resource = resource;
        this.options = options;
        this.title = title;
        this.priority = DeferredAction_1.Priority.ATTACK_OPPONENT;
    }
    DecreaseAnyProduction.prototype.execute = function () {
        var _this = this;
        if (this.player.game.isSoloMode())
            return undefined;
        var candidates = this.player.game.getPlayers().filter(function (p) { return !p.productionIsProtected(); });
        if (this.resource === Resources_1.Resources.MEGACREDITS) {
            candidates = candidates.filter(function (p) { return p.getProduction(_this.resource) >= _this.options.count - 5; });
        }
        else {
            candidates = candidates.filter(function (p) { return p.getProduction(_this.resource) >= _this.options.count; });
        }
        if (this.resource === Resources_1.Resources.STEEL || this.resource === Resources_1.Resources.TITANIUM) {
            candidates = candidates.filter(function (candidate) { return !candidate.alloysAreProtected(); });
        }
        if (candidates.length === 0) {
            return undefined;
        }
        if (candidates.length === 1) {
            candidates[0].addProduction(this.resource, -this.options.count, { log: true, from: this.player, stealing: this.options.stealing });
            return undefined;
        }
        return new SelectPlayer_1.SelectPlayer(candidates, this.title, 'Decrease', function (found) {
            found.addProduction(_this.resource, -_this.options.count, { log: true, from: _this.player, stealing: _this.options.stealing });
            return undefined;
        });
    };
    return DecreaseAnyProduction;
}());
exports.DecreaseAnyProduction = DecreaseAnyProduction;
