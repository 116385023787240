"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.Splice = void 0;
var Tags_1 = require("../Tags");
var Card_1 = require("../Card");
var SelectOption_1 = require("../../inputs/SelectOption");
var OrOptions_1 = require("../../inputs/OrOptions");
var ResourceType_1 = require("../../common/ResourceType");
var CardName_1 = require("../../CardName");
var CardType_1 = require("../CardType");
var CardRenderer_1 = require("../render/CardRenderer");
var Size_1 = require("../render/Size");
var Resources_1 = require("../../common/Resources");
var Options_1 = require("../Options");
var Splice = (function (_super) {
    __extends(Splice, _super);
    function Splice() {
        return _super.call(this, {
            cardType: CardType_1.CardType.CORPORATION,
            name: CardName_1.CardName.SPLICE,
            tags: [Tags_1.Tags.MICROBE],
            startingMegaCredits: 48,
            initialActionText: 'Draw a card with a microbe tag',
            metadata: {
                cardNumber: 'R28',
                description: 'You start with 44 M€. As your first action, reveal cards until you have revealed a microbe tag. Take it and discard the rest.',
                renderData: CardRenderer_1.CardRenderer.builder(function (b) {
                    b.megacredits(44).nbsp.cards(1, { secondaryTag: Tags_1.Tags.MICROBE });
                    b.corpBox('effect', function (ce) {
                        ce.vSpace(Size_1.Size.LARGE);
                        ce.effect(undefined, function (eb) {
                            eb.microbes(1, { played: Options_1.played, all: Options_1.all }).startEffect;
                            eb.megacredits(2, { all: Options_1.all }).or().microbes(1, { all: Options_1.all }).asterix();
                        });
                        ce.vSpace();
                        ce.effect('when a microbe tag is played, incl. this, THAT PLAYER gains 2 M€, or adds a microbe to THAT card, and you gain 2 M€.', function (eb) {
                            eb.microbes(1, { played: Options_1.played, all: Options_1.all }).startEffect;
                            eb.megacredits(2);
                        });
                    });
                }),
            },
        }) || this;
    }
    Splice.prototype.initialAction = function (player) {
        player.drawCard(1, { tag: Tags_1.Tags.MICROBE });
        return undefined;
    };
    Splice.prototype.onCardPlayed = function (player, card) {
        return this._onCardPlayed(player, card);
    };
    Splice.prototype.onCorpCardPlayed = function (player, card) {
        return this._onCardPlayed(player, card);
    };
    Splice.prototype._onCardPlayed = function (player, card) {
        if (card.tags.includes(Tags_1.Tags.MICROBE) === false) {
            return undefined;
        }
        var gainPerMicrobe = 2;
        var microbeTagsCount = card.tags.filter(function (tag) { return tag === Tags_1.Tags.MICROBE; }).length;
        var megacreditsGain = microbeTagsCount * gainPerMicrobe;
        var addResource = new SelectOption_1.SelectOption('Add a microbe resource to this card', 'Add microbe', function () {
            player.addResourceTo(card);
            return undefined;
        });
        var getMegacredits = new SelectOption_1.SelectOption("Gain " + megacreditsGain + " MC", 'Gain M€', function () {
            player.addResource(Resources_1.Resources.MEGACREDITS, megacreditsGain, { log: true });
            return undefined;
        });
        player.game.getCardPlayer(this.name).addResource(Resources_1.Resources.MEGACREDITS, megacreditsGain, { log: true });
        if (card.resourceType !== undefined && card.resourceType === ResourceType_1.ResourceType.MICROBE) {
            return new OrOptions_1.OrOptions(addResource, getMegacredits);
        }
        else {
            player.addResource(Resources_1.Resources.MEGACREDITS, megacreditsGain, { log: true });
            return undefined;
        }
    };
    Splice.prototype.play = function () {
        return undefined;
    };
    return Splice;
}(Card_1.Card));
exports.Splice = Splice;
