"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.OrbitalCleanup = void 0;
var Card_1 = require("../Card");
var CardName_1 = require("../../CardName");
var CardType_1 = require("../CardType");
var Tags_1 = require("../Tags");
var Resources_1 = require("../../common/Resources");
var CardRenderer_1 = require("../render/CardRenderer");
var Options_1 = require("../Options");
var OrbitalCleanup = (function (_super) {
    __extends(OrbitalCleanup, _super);
    function OrbitalCleanup() {
        return _super.call(this, {
            cardType: CardType_1.CardType.ACTIVE,
            name: CardName_1.CardName.ORBITAL_CLEANUP,
            tags: [Tags_1.Tags.EARTH, Tags_1.Tags.SPACE],
            cost: 14,
            victoryPoints: 2,
            metadata: {
                cardNumber: 'X08',
                renderData: CardRenderer_1.CardRenderer.builder(function (b) {
                    b.action('Gain 1 M€ per Science tag you have.', function (eb) {
                        eb.empty().startAction.megacredits(1).slash().science(1, { played: Options_1.played });
                    }).br;
                    b.production(function (pb) {
                        pb.megacredits(-2);
                    });
                }),
                description: 'Decrease your M€ production 2 steps.',
            },
        }) || this;
    }
    OrbitalCleanup.prototype.canPlay = function (player) {
        return player.getProduction(Resources_1.Resources.MEGACREDITS) >= -3;
    };
    OrbitalCleanup.prototype.play = function (player) {
        player.addProduction(Resources_1.Resources.MEGACREDITS, -2);
        return undefined;
    };
    OrbitalCleanup.prototype.canAct = function () {
        return true;
    };
    OrbitalCleanup.prototype.action = function (player) {
        player.addResource(Resources_1.Resources.MEGACREDITS, player.getTagCount(Tags_1.Tags.SCIENCE), { log: true });
        return undefined;
    };
    return OrbitalCleanup;
}(Card_1.Card));
exports.OrbitalCleanup = OrbitalCleanup;
