"use strict";
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
var vue_1 = require("vue");
var SpaceType_1 = require("@/SpaceType");
var MoonSpace_vue_1 = require("@/client/components/moon/MoonSpace.vue");
var MoonParamLevel = (function () {
    function MoonParamLevel(value, isActive, strValue) {
        this.value = value;
        this.isActive = isActive;
        this.strValue = strValue;
    }
    return MoonParamLevel;
}());
exports.default = vue_1.default.extend({
    name: 'MoonBoard',
    props: {
        model: {
            type: Object,
        },
        hideTiles: {
            type: Boolean,
            default: false,
        },
    },
    components: {
        MoonSpace: MoonSpace_vue_1.default,
    },
    methods: {
        getAllNonColonySpaces: function () {
            var boardSpaces = __spreadArray([], this.model.spaces, true);
            boardSpaces.sort(function (space1, space2) {
                return parseInt(space1.id) - parseInt(space2.id);
            });
            return boardSpaces.filter(function (s) {
                return s.spaceType !== SpaceType_1.SpaceType.COLONY;
            });
        },
        getSpaceById: function (spaceId) {
            for (var _i = 0, _a = this.model.spaces; _i < _a.length; _i++) {
                var space = _a[_i];
                if (space.id === spaceId) {
                    return space;
                }
            }
            throw 'Board space not found by id \'' + spaceId + '\'';
        },
        getValuesForParameter: function (targetParameter) {
            var curValue;
            switch (targetParameter) {
                case 'logistics':
                    curValue = this.model.logisticsRate;
                    break;
                case 'mining':
                    curValue = this.model.miningRate;
                    break;
                case 'colony':
                    curValue = this.model.colonyRate;
                    break;
                default:
                    throw 'Wrong parameter to get values from';
            }
            var values = [];
            for (var value = 8; value >= 0; value -= 1) {
                var strValue = value.toString();
                values.push(new MoonParamLevel(value, value === curValue, strValue));
            }
            return values;
        },
        getScaleCSS: function (paramLevel) {
            var css = 'global-numbers-value val-' + paramLevel.value + ' ';
            if (paramLevel.isActive) {
                css += 'val-is-active';
            }
            return css;
        },
    },
});
