"use strict";
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
var vue_1 = require("vue");
var constants = require("@/constants");
var BoardSpace_vue_1 = require("@/client/components/BoardSpace.vue");
var SpaceType_1 = require("@/SpaceType");
var GlobalParamLevel = (function () {
    function GlobalParamLevel(value, isActive, strValue) {
        this.value = value;
        this.isActive = isActive;
        this.strValue = strValue;
    }
    return GlobalParamLevel;
}());
exports.default = vue_1.default.extend({
    name: 'board',
    props: {
        spaces: {
            type: Array,
        },
        venusNextExtension: {
            type: Boolean,
        },
        venusScaleLevel: {
            type: Number,
        },
        altVenusBoard: {
            type: Boolean,
        },
        boardName: {
            type: String,
        },
        oceans_count: {
            type: Number,
        },
        oxygen_level: {
            type: Number,
        },
        temperature: {
            type: Number,
        },
        aresExtension: {
            type: Boolean,
        },
        pathfindersExpansion: {
            type: Boolean,
        },
        aresData: {
            type: Object,
        },
        hideTiles: {
            type: Boolean,
            default: false,
        },
    },
    components: {
        BoardSpace: BoardSpace_vue_1.default,
    },
    data: function () {
        return {
            constants: constants,
        };
    },
    methods: {
        getAllSpacesOnMars: function () {
            var boardSpaces = __spreadArray([], this.spaces, true);
            boardSpaces.sort(function (space1, space2) {
                return parseInt(space1.id) - parseInt(space2.id);
            });
            return boardSpaces.filter(function (s) {
                return s.spaceType !== SpaceType_1.SpaceType.COLONY;
            });
        },
        getSpaceById: function (spaceId) {
            for (var _i = 0, _a = this.spaces; _i < _a.length; _i++) {
                var space = _a[_i];
                if (space.id === spaceId) {
                    return space;
                }
            }
            throw 'Board space not found by id \'' + spaceId + '\'';
        },
        getValuesForParameter: function (targetParameter) {
            var values = [];
            var startValue;
            var endValue;
            var step;
            var curValue;
            var strValue;
            switch (targetParameter) {
                case 'oxygen':
                    startValue = constants.MIN_OXYGEN_LEVEL;
                    endValue = constants.MAX_OXYGEN_LEVEL;
                    step = 1;
                    curValue = this.oxygen_level;
                    break;
                case 'temperature':
                    startValue = constants.MIN_TEMPERATURE;
                    endValue = constants.MAX_TEMPERATURE;
                    step = 2;
                    curValue = this.temperature;
                    break;
                case 'venus':
                    startValue = constants.MIN_VENUS_SCALE;
                    endValue = constants.MAX_VENUS_SCALE;
                    step = 2;
                    curValue = this.venusScaleLevel;
                    break;
                default:
                    throw 'Wrong parameter to get values from';
            }
            for (var value = endValue; value >= startValue; value -= step) {
                strValue = (targetParameter === 'temperature' && value > 0) ? '+' + value : value.toString();
                values.push(new GlobalParamLevel(value, value === curValue, strValue));
            }
            return values;
        },
        getScaleCSS: function (paramLevel) {
            var css = 'global-numbers-value val-' + paramLevel.value + ' ';
            if (paramLevel.isActive) {
                css += 'val-is-active';
            }
            return css;
        },
        oceansValue: function () {
            var oceans_count = this.oceans_count || 0;
            var leftover = constants.MAX_OCEAN_TILES - oceans_count;
            if (leftover === 0) {
                return '<img width="26" src="/assets/misc/circle-checkmark.png" class="board-ocean-checkmark" :alt="$t(\'Completed!\')">';
            }
            else {
                return oceans_count + "/" + constants.MAX_OCEAN_TILES;
            }
        },
        getGameBoardClassName: function () {
            return this.venusNextExtension ? 'board-cont board-with-venus' : 'board-cont board-without-venus';
        },
    },
});
