"use strict";
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.DeclareCloneTag = void 0;
var DeferredAction_1 = require("../deferredActions/DeferredAction");
var Tags_1 = require("../cards/Tags");
var OrOptions_1 = require("../inputs/OrOptions");
var SelectOption_1 = require("../inputs/SelectOption");
var CardType_1 = require("../cards/CardType");
var DeclareCloneTag = (function () {
    function DeclareCloneTag(player, card, cb, title) {
        if (cb === void 0) { cb = function () { }; }
        if (title === void 0) { title = ''; }
        this.player = player;
        this.card = card;
        this.cb = cb;
        this.title = title;
        this.priority = DeferredAction_1.Priority.DECLARE_CLONE_TAG;
        if (this.title === '') {
            this.title = "Assign the clone tag for " + card.name;
        }
    }
    DeclareCloneTag.prototype.execute = function () {
        var _this = this;
        var tags = [Tags_1.Tags.EARTH, Tags_1.Tags.JOVIAN, Tags_1.Tags.MARS];
        if (this.player.game.gameOptions.venusNextExtension === true) {
            tags.push(Tags_1.Tags.VENUS);
        }
        if (this.player.game.gameOptions.moonExpansion === true) {
            tags.push(Tags_1.Tags.MOON);
        }
        var options = tags.map(function (tag) {
            return new SelectOption_1.SelectOption(tag, 'Choose', function () {
                _this.card.cloneTag = tag;
                _this.player.game.log('${0} turned the clone tag on ${1} into a ${2} tag', function (b) { return b.player(_this.player).card(_this.card).string(tag); });
                if ([CardType_1.CardType.AUTOMATED, CardType_1.CardType.ACTIVE, CardType_1.CardType.EVENT, CardType_1.CardType.PRELUDE].includes(_this.card.cardType)) {
                    _this.player.onCardPlayed(_this.card);
                }
                _this.cb(tag);
                return undefined;
            });
        });
        var orOptions = new (OrOptions_1.OrOptions.bind.apply(OrOptions_1.OrOptions, __spreadArray([void 0], options, false)))();
        orOptions.title = 'Select a new tag for this clone tag.';
        return orOptions;
    };
    return DeclareCloneTag;
}());
exports.DeclareCloneTag = DeclareCloneTag;
