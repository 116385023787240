"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.MartianRepository = void 0;
var Card_1 = require("../Card");
var ICard_1 = require("../ICard");
var CardType_1 = require("../CardType");
var CardName_1 = require("../../CardName");
var CardRenderer_1 = require("../render/CardRenderer");
var Tags_1 = require("../Tags");
var Resources_1 = require("../../common/Resources");
var ResourceType_1 = require("../../common/ResourceType");
var Units_1 = require("../../Units");
var Options_1 = require("../Options");
var MartianRepository = (function (_super) {
    __extends(MartianRepository, _super);
    function MartianRepository() {
        var _this = _super.call(this, {
            cardType: CardType_1.CardType.ACTIVE,
            name: CardName_1.CardName.MARTIAN_REPOSITORY,
            cost: 12,
            tags: [Tags_1.Tags.MARS, Tags_1.Tags.MARS, Tags_1.Tags.BUILDING],
            resourceType: ResourceType_1.ResourceType.DATA,
            productionBox: Units_1.Units.of({ energy: -1 }),
            victoryPoints: ICard_1.VictoryPoints.resource(1, 3),
            metadata: {
                cardNumber: 'Pf29',
                renderData: CardRenderer_1.CardRenderer.builder(function (b) {
                    b.effect('For every science or Mars tag you play (including these) add 1 data to this card.', function (eb) {
                        eb.science(1, { played: Options_1.played }).mars(1, { played: Options_1.played }).startEffect.data();
                    }).br;
                    b.minus().production(function (pb) { return pb.energy(1); });
                }),
                description: 'Decrease your energy production 1 step. 1 VP for every 3 data here.',
            },
        }) || this;
        _this.resourceCount = 0;
        return _this;
    }
    MartianRepository.prototype.onCardPlayed = function (player, card) {
        var qty = card.tags.filter(function (tag) { return tag === Tags_1.Tags.SCIENCE || tag === Tags_1.Tags.MARS; }).length;
        if (qty > 0)
            player.addResourceTo(this, { qty: qty, log: true });
    };
    MartianRepository.prototype.canPlay = function (player) {
        return player.getProduction(Resources_1.Resources.ENERGY) > 0;
    };
    MartianRepository.prototype.play = function (player) {
        player.addProduction(Resources_1.Resources.ENERGY, -1);
        return undefined;
    };
    return MartianRepository;
}(Card_1.Card));
exports.MartianRepository = MartianRepository;
