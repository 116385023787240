"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.SpaceRaceToMars = void 0;
var IGlobalEvent_1 = require("./IGlobalEvent");
var GlobalEventName_1 = require("./GlobalEventName");
var PartyName_1 = require("../parties/PartyName");
var Resources_1 = require("../../common/Resources");
var Board_1 = require("../../boards/Board");
var MoonExpansion_1 = require("../../moon/MoonExpansion");
var CardRenderer_1 = require("../../cards/render/CardRenderer");
var RENDER_DATA = CardRenderer_1.CardRenderer.builder(function (b) {
    b.production(function (pb) { return pb.megacredits(1); }).slash().specialTile().br;
    b.energy(1).slash().influence();
});
var SpaceRaceToMars = (function (_super) {
    __extends(SpaceRaceToMars, _super);
    function SpaceRaceToMars() {
        return _super.call(this, {
            name: GlobalEventName_1.GlobalEventName.SPACE_RACE_TO_MARS,
            description: 'Increase your MC production 1 step for every special tile you own (max 5.) Gain 1 energy for every influence you have',
            revealedDelegate: PartyName_1.PartyName.SCIENTISTS,
            currentDelegate: PartyName_1.PartyName.MARS,
            renderData: RENDER_DATA,
        }) || this;
    }
    SpaceRaceToMars.prototype.resolve = function (game, turmoil) {
        var _this = this;
        game.getPlayers().forEach(function (player) {
            var specialTileCount = _this.specialTileCount(player);
            var bonus = Math.min(specialTileCount, 5);
            player.addProduction(Resources_1.Resources.MEGACREDITS, bonus, { log: true, from: _this.name });
            player.addResource(Resources_1.Resources.ENERGY, turmoil.getPlayerInfluence(player), { log: true, from: _this.name });
        });
    };
    SpaceRaceToMars.prototype.specialTileCount = function (player) {
        var spaces = player.game.board.spaces
            .filter((0, Board_1.playerTileFn)(player))
            .filter(Board_1.isSpecialTile);
        var marsCount = spaces.length;
        var moonCount = MoonExpansion_1.MoonExpansion.ifElseMoon(player.game, function (moonData) {
            return moonData.moon.spaces
                .filter((0, Board_1.playerTileFn)(player))
                .filter(Board_1.isSpecialTile)
                .length;
        }, function () { return 0; });
        return marsCount + moonCount;
    };
    return SpaceRaceToMars;
}(IGlobalEvent_1.GlobalEvent));
exports.SpaceRaceToMars = SpaceRaceToMars;
