"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.PointLuna = void 0;
var Tags_1 = require("../Tags");
var Resources_1 = require("../../common/Resources");
var Card_1 = require("../Card");
var CardName_1 = require("../../CardName");
var CardType_1 = require("../CardType");
var CardRenderer_1 = require("../render/CardRenderer");
var Options_1 = require("../Options");
var PointLuna = (function (_super) {
    __extends(PointLuna, _super);
    function PointLuna() {
        return _super.call(this, {
            cardType: CardType_1.CardType.CORPORATION,
            name: CardName_1.CardName.POINT_LUNA,
            tags: [Tags_1.Tags.SPACE, Tags_1.Tags.EARTH],
            startingMegaCredits: 38,
            metadata: {
                cardNumber: 'R10',
                description: 'You start with 1 titanium production and 38 M€.',
                renderData: CardRenderer_1.CardRenderer.builder(function (b) {
                    b.br;
                    b.production(function (pb) { return pb.titanium(1); }).nbsp.megacredits(38);
                    b.corpBox('effect', function (ce) {
                        ce.effect('When you play an Earth tag, including this, draw a card.', function (eb) {
                            eb.earth(1, { played: Options_1.played }).startEffect.cards(1);
                        });
                    });
                }),
            },
        }) || this;
    }
    PointLuna.prototype.onCardPlayed = function (player, card) {
        var tagCount = card.tags.filter(function (tag) { return tag === Tags_1.Tags.EARTH; }).length;
        if (player.isCorporation(this.name) && card.tags.includes(Tags_1.Tags.EARTH)) {
            player.drawCard(tagCount);
        }
    };
    PointLuna.prototype.play = function (player) {
        player.addProduction(Resources_1.Resources.TITANIUM, 1);
        player.drawCard();
        return undefined;
    };
    return PointLuna;
}(Card_1.Card));
exports.PointLuna = PointLuna;
