"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.Livestock = void 0;
var Tags_1 = require("../Tags");
var Card_1 = require("../Card");
var ICard_1 = require("../ICard");
var CardType_1 = require("../CardType");
var Resources_1 = require("../../common/Resources");
var ResourceType_1 = require("../../common/ResourceType");
var CardName_1 = require("../../CardName");
var CardRequirements_1 = require("../CardRequirements");
var CardRenderer_1 = require("../render/CardRenderer");
var Livestock = (function (_super) {
    __extends(Livestock, _super);
    function Livestock() {
        var _this = _super.call(this, {
            cardType: CardType_1.CardType.ACTIVE,
            name: CardName_1.CardName.LIVESTOCK,
            tags: [Tags_1.Tags.ANIMAL],
            cost: 13,
            resourceType: ResourceType_1.ResourceType.ANIMAL,
            victoryPoints: ICard_1.VictoryPoints.resource(1, 1),
            requirements: CardRequirements_1.CardRequirements.builder(function (b) { return b.oxygen(9); }),
            metadata: {
                cardNumber: '184',
                renderData: CardRenderer_1.CardRenderer.builder(function (b) {
                    b.action('Add 1 Animal to this card.', function (eb) {
                        eb.empty().startAction.animals(1);
                    }).br;
                    b.production(function (pb) {
                        pb.minus().plants(1).nbsp.plus().megacredits(2);
                    }).br;
                    b.vpText('1 VP for each Animal on this card.');
                }),
                description: {
                    text: 'Requires 9% oxygen. Decrease your Plant production 1 step and increase your M€ production 2 steps',
                    align: 'left',
                },
            },
        }) || this;
        _this.resourceCount = 0;
        return _this;
    }
    Livestock.prototype.canPlay = function (player) {
        return player.getProduction(Resources_1.Resources.PLANTS) >= 1;
    };
    Livestock.prototype.play = function (player) {
        player.addProduction(Resources_1.Resources.PLANTS, -1);
        player.addProduction(Resources_1.Resources.MEGACREDITS, 2);
        return undefined;
    };
    Livestock.prototype.canAct = function () {
        return true;
    };
    Livestock.prototype.action = function (player) {
        player.addResourceTo(this);
        return undefined;
    };
    return Livestock;
}(Card_1.Card));
exports.Livestock = Livestock;
