"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.AsteroidMiningConsortium = void 0;
var Tags_1 = require("../Tags");
var Card_1 = require("../Card");
var CardType_1 = require("../CardType");
var Resources_1 = require("../../common/Resources");
var CardName_1 = require("../../CardName");
var DecreaseAnyProduction_1 = require("../../deferredActions/DecreaseAnyProduction");
var CardRequirements_1 = require("../CardRequirements");
var CardRenderer_1 = require("../render/CardRenderer");
var Options_1 = require("../Options");
var AsteroidMiningConsortium = (function (_super) {
    __extends(AsteroidMiningConsortium, _super);
    function AsteroidMiningConsortium() {
        return _super.call(this, {
            cardType: CardType_1.CardType.AUTOMATED,
            name: CardName_1.CardName.ASTEROID_MINING_CONSORTIUM,
            tags: [Tags_1.Tags.JOVIAN],
            cost: 13,
            victoryPoints: 1,
            requirements: CardRequirements_1.CardRequirements.builder(function (b) { return b.production(Resources_1.Resources.TITANIUM); }),
            metadata: {
                description: 'Requires that you have titanium production. Decrease any titanium production 1 step and increase your own 1 step.',
                cardNumber: '002',
                renderData: CardRenderer_1.CardRenderer.builder(function (b) {
                    b.production(function (pb) {
                        pb.minus().titanium(-1, { all: Options_1.all }).br;
                        pb.plus().titanium(1);
                    });
                }),
            },
        }) || this;
    }
    AsteroidMiningConsortium.prototype.play = function (player) {
        var decreaseAction = new DecreaseAnyProduction_1.DecreaseAnyProduction(player, Resources_1.Resources.TITANIUM, { count: 1, stealing: true });
        player.game.defer(decreaseAction);
        player.addProduction(Resources_1.Resources.TITANIUM, 1);
        return undefined;
    };
    return AsteroidMiningConsortium;
}(Card_1.Card));
exports.AsteroidMiningConsortium = AsteroidMiningConsortium;
