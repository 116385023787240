"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.SponsoredAcademies = void 0;
var Tags_1 = require("../Tags");
var CardType_1 = require("../CardType");
var CardName_1 = require("../../CardName");
var DeferredAction_1 = require("../../deferredActions/DeferredAction");
var DiscardCards_1 = require("../../deferredActions/DiscardCards");
var CardRenderer_1 = require("../render/CardRenderer");
var DrawCards_1 = require("../../deferredActions/DrawCards");
var Card_1 = require("../Card");
var Options_1 = require("../Options");
var SponsoredAcademies = (function (_super) {
    __extends(SponsoredAcademies, _super);
    function SponsoredAcademies() {
        return _super.call(this, {
            name: CardName_1.CardName.SPONSORED_ACADEMIES,
            cardType: CardType_1.CardType.AUTOMATED,
            tags: [Tags_1.Tags.EARTH, Tags_1.Tags.SCIENCE],
            cost: 9,
            victoryPoints: 1,
            metadata: {
                cardNumber: '247',
                renderData: CardRenderer_1.CardRenderer.builder(function (b) {
                    b.minus().cards(1).br;
                    b.plus().cards(3, { digit: Options_1.digit }).asterix().nbsp.plus().cards(1, { all: Options_1.all }).asterix();
                }),
                description: 'Discard 1 card from your hand and THEN draw 3 cards. All OPPONENTS draw 1 card.',
            },
        }) || this;
    }
    ;
    SponsoredAcademies.prototype.canPlay = function (player) {
        return player.cardsInHand.length > 1;
    };
    SponsoredAcademies.prototype.play = function (player) {
        player.game.defer(new DiscardCards_1.DiscardCards(player), DeferredAction_1.Priority.DISCARD_BEFORE_DRAW);
        player.game.defer(DrawCards_1.DrawCards.keepAll(player, 3));
        var otherPlayers = player.game.getPlayers().filter(function (p) { return p.id !== player.id; });
        for (var _i = 0, otherPlayers_1 = otherPlayers; _i < otherPlayers_1.length; _i++) {
            var p = otherPlayers_1[_i];
            player.game.defer(DrawCards_1.DrawCards.keepAll(p));
        }
        return undefined;
    };
    return SponsoredAcademies;
}(Card_1.Card));
exports.SponsoredAcademies = SponsoredAcademies;
