"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.Mercury = void 0;
var Colony_1 = require("../../colonies/Colony");
var ColonyName_1 = require("../../colonies/ColonyName");
var ColonyBenefit_1 = require("../../colonies/ColonyBenefit");
var Resources_1 = require("../../common/Resources");
var Mercury = (function (_super) {
    __extends(Mercury, _super);
    function Mercury() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.name = ColonyName_1.ColonyName.MERCURY;
        _this.description = 'Production';
        _this.buildType = ColonyBenefit_1.ColonyBenefit.COPY_TRADE;
        _this.tradeType = ColonyBenefit_1.ColonyBenefit.GAIN_PRODUCTION;
        _this.tradeResource = [
            Resources_1.Resources.HEAT, Resources_1.Resources.HEAT, Resources_1.Resources.HEAT,
            Resources_1.Resources.STEEL, Resources_1.Resources.STEEL,
            Resources_1.Resources.TITANIUM, Resources_1.Resources.TITANIUM,
        ];
        _this.colonyBonusType = ColonyBenefit_1.ColonyBenefit.GAIN_RESOURCES;
        _this.colonyBonusResource = Resources_1.Resources.MEGACREDITS;
        _this.colonyBonusQuantity = 2;
        _this.shouldIncreaseTrack = Colony_1.ShouldIncreaseTrack.ASK;
        return _this;
    }
    return Mercury;
}(Colony_1.Colony));
exports.Mercury = Mercury;
