"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.PhobosSpaceHaven = void 0;
var Tags_1 = require("../Tags");
var Card_1 = require("../Card");
var CardType_1 = require("../CardType");
var SpaceName_1 = require("../../SpaceName");
var SpaceType_1 = require("../../SpaceType");
var Resources_1 = require("../../common/Resources");
var CardName_1 = require("../../CardName");
var CardRenderer_1 = require("../render/CardRenderer");
var PhobosSpaceHaven = (function (_super) {
    __extends(PhobosSpaceHaven, _super);
    function PhobosSpaceHaven() {
        return _super.call(this, {
            cardType: CardType_1.CardType.AUTOMATED,
            name: CardName_1.CardName.PHOBOS_SPACE_HAVEN,
            tags: [Tags_1.Tags.SPACE, Tags_1.Tags.CITY],
            cost: 25,
            victoryPoints: 3,
            metadata: {
                cardNumber: '021',
                renderData: CardRenderer_1.CardRenderer.builder(function (b) {
                    b.production(function (pb) { return pb.titanium(1); }).nbsp.city().asterix();
                }),
                description: 'Increase your titanium production 1 step and place a City tile ON THE RESERVED AREA.',
            },
        }) || this;
    }
    PhobosSpaceHaven.prototype.play = function (player) {
        player.game.addCityTile(player, SpaceName_1.SpaceName.PHOBOS_SPACE_HAVEN, SpaceType_1.SpaceType.COLONY);
        player.addProduction(Resources_1.Resources.TITANIUM, 1);
        return undefined;
    };
    return PhobosSpaceHaven;
}(Card_1.Card));
exports.PhobosSpaceHaven = PhobosSpaceHaven;
