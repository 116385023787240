"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.ElectroCatapult = void 0;
var Tags_1 = require("../Tags");
var Card_1 = require("../Card");
var CardType_1 = require("../CardType");
var OrOptions_1 = require("../../inputs/OrOptions");
var SelectOption_1 = require("../../inputs/SelectOption");
var Resources_1 = require("../../common/Resources");
var CardName_1 = require("../../CardName");
var CardRequirements_1 = require("../CardRequirements");
var CardRenderer_1 = require("../render/CardRenderer");
var Units_1 = require("../../Units");
var Options_1 = require("../Options");
var ElectroCatapult = (function (_super) {
    __extends(ElectroCatapult, _super);
    function ElectroCatapult() {
        return _super.call(this, {
            cardType: CardType_1.CardType.ACTIVE,
            name: CardName_1.CardName.ELECTRO_CATAPULT,
            tags: [Tags_1.Tags.BUILDING],
            cost: 17,
            productionBox: Units_1.Units.of({ energy: -1 }),
            victoryPoints: 1,
            requirements: CardRequirements_1.CardRequirements.builder(function (b) { return b.oxygen(8, { max: Options_1.max }); }),
            metadata: {
                cardNumber: '069',
                description: {
                    text: 'Oxygen must be 8% or less. Decrease your energy production 1 step.',
                    align: 'left',
                },
                renderData: CardRenderer_1.CardRenderer.builder(function (b) {
                    b.action('Spend 1 plant or 1 steel to gain 7 M€.', function (eb) {
                        eb.plants(1).slash().steel(1).startAction.megacredits(7);
                    }).br;
                    b.production(function (pb) { return pb.minus().energy(1); });
                }),
            },
        }) || this;
    }
    ElectroCatapult.prototype.canPlay = function (player) {
        return player.getProduction(Resources_1.Resources.ENERGY) >= 1;
    };
    ElectroCatapult.prototype.canAct = function (player) {
        return player.plants > 0 || player.steel > 0;
    };
    ElectroCatapult.prototype.action = function (player) {
        var _this = this;
        if (player.plants > 0 && player.steel > 0) {
            return new OrOptions_1.OrOptions(new SelectOption_1.SelectOption('Spend 1 plant to gain 7 M€', 'Spend plant', function () {
                player.plants--;
                player.megaCredits += 7;
                _this.log(player, Resources_1.Resources.PLANTS);
                return undefined;
            }), new SelectOption_1.SelectOption('Spend 1 steel to gain 7 M€', 'Spend steel', function () {
                player.steel--;
                player.megaCredits += 7;
                _this.log(player, Resources_1.Resources.STEEL);
                return undefined;
            }));
        }
        else if (player.plants > 0) {
            player.plants--;
            this.log(player, Resources_1.Resources.PLANTS);
            player.megaCredits += 7;
        }
        else if (player.steel > 0) {
            player.steel--;
            this.log(player, Resources_1.Resources.STEEL);
            player.megaCredits += 7;
        }
        return undefined;
    };
    ElectroCatapult.prototype.play = function (player) {
        player.addProduction(Resources_1.Resources.ENERGY, -1);
        return undefined;
    };
    ElectroCatapult.prototype.log = function (player, resource) {
        player.game.log('${0} spent 1 ${1} to gain 7 M€', function (b) { return b.player(player).string(resource); });
    };
    return ElectroCatapult;
}(Card_1.Card));
exports.ElectroCatapult = ElectroCatapult;
