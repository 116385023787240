"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.EcologicalZoneAres = void 0;
var CardName_1 = require("../../CardName");
var EcologicalZone_1 = require("../base/EcologicalZone");
var SpaceBonus_1 = require("../../SpaceBonus");
var TileType_1 = require("../../common/TileType");
var CardRenderer_1 = require("../render/CardRenderer");
var Options_1 = require("../Options");
var EcologicalZoneAres = (function (_super) {
    __extends(EcologicalZoneAres, _super);
    function EcologicalZoneAres() {
        return _super.call(this, CardName_1.CardName.ECOLOGICAL_ZONE_ARES, 11, { bonus: [SpaceBonus_1.SpaceBonus.ANIMAL] }, {
            description: {
                text: 'Requires that YOU have a greenery tile. Place this tile adjacent to ANY greenery.',
                align: 'left',
            },
            cardNumber: 'A08',
            renderData: CardRenderer_1.CardRenderer.builder(function (b) {
                b.effect('When you play an animal or plant tag /including these/, add an animal to this card.', function (eb) {
                    eb.animals(1, { played: Options_1.played }).slash().plants(1, { played: Options_1.played }).startEffect;
                    eb.animals(1).tile(TileType_1.TileType.ECOLOGICAL_ZONE, false, true);
                }).br;
                b.vpText('The tile grants an ADJACENCY BONUS of 1 animal. 1 VP per 2 Animals on this card.');
            }),
        }) || this;
    }
    return EcologicalZoneAres;
}(EcologicalZone_1.EcologicalZone));
exports.EcologicalZoneAres = EcologicalZoneAres;
