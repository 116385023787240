"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.StrategicBasePlanning = void 0;
var PreludeCard_1 = require("../prelude/PreludeCard");
var CardName_1 = require("../../CardName");
var CardRenderer_1 = require("../render/CardRenderer");
var Resources_1 = require("../../common/Resources");
var PlaceCityTile_1 = require("../../deferredActions/PlaceCityTile");
var BuildColony_1 = require("../../deferredActions/BuildColony");
var Tags_1 = require("../Tags");
var StrategicBasePlanning = (function (_super) {
    __extends(StrategicBasePlanning, _super);
    function StrategicBasePlanning() {
        return _super.call(this, {
            name: CardName_1.CardName.STRATEGIC_BASE_PLANNING,
            tags: [Tags_1.Tags.BUILDING],
            metadata: {
                cardNumber: 'P08',
                renderData: CardRenderer_1.CardRenderer.builder(function (b) {
                    b.minus().megacredits(8).city().colonies();
                }),
                description: 'Pay 8M€. Place a city. Place a colony.',
            },
        }) || this;
    }
    StrategicBasePlanning.prototype.play = function (player) {
        player.deductResource(Resources_1.Resources.MEGACREDITS, 8);
        player.game.defer(new PlaceCityTile_1.PlaceCityTile(player));
        player.game.defer(new BuildColony_1.BuildColony(player));
        return undefined;
    };
    return StrategicBasePlanning;
}(PreludeCard_1.PreludeCard));
exports.StrategicBasePlanning = StrategicBasePlanning;
