"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CardFinder = void 0;
var AllCards_1 = require("./cards/AllCards");
var CardFinder = (function () {
    function CardFinder() {
    }
    CardFinder.prototype.getCardByName = function (cardName, decks) {
        var found;
        AllCards_1.ALL_CARD_MANIFESTS.some(function (manifest) {
            decks(manifest).some(function (deck) {
                found = deck.findByCardName(cardName);
                return found;
            });
            return found;
        });
        if (found !== undefined) {
            return new found.Factory();
        }
        console.warn("card not found " + cardName);
        return undefined;
    };
    CardFinder.prototype.getCorporationCardByName = function (cardName) {
        return this.getCardByName(cardName, function (manifest) { return [manifest.corporationCards]; });
    };
    CardFinder.prototype.getProjectCardByName = function (cardName) {
        return this.getCardByName(cardName, function (manifest) { return [manifest.projectCards, manifest.preludeCards]; });
    };
    CardFinder.prototype.getPreludeByName = function (cardName) {
        return this.getCardByName(cardName, function (manifest) { return [manifest.preludeCards]; });
    };
    CardFinder.prototype.cardsFromJSON = function (cards) {
        var _this = this;
        if (cards === undefined) {
            console.warn('missing cards calling cardsFromJSON');
            return [];
        }
        var result = [];
        cards.forEach(function (element) {
            if (typeof element !== 'string') {
                element = element.name;
            }
            var card = _this.getProjectCardByName(element);
            if (card !== undefined) {
                result.push(card);
            }
            else {
                console.warn("card " + element + " not found while loading game.");
            }
        });
        return result;
    };
    CardFinder.prototype.corporationCardsFromJSON = function (cards) {
        var _this = this;
        if (cards === undefined) {
            console.warn('missing cards calling corporationCardsFromJSON');
            return [];
        }
        var result = [];
        cards.forEach(function (element) {
            if (typeof element !== 'string') {
                element = element.name;
            }
            var card = _this.getCorporationCardByName(element);
            if (card !== undefined) {
                result.push(card);
            }
            else {
                console.warn("corporation " + element + " not found while loading game.");
            }
        });
        return result;
    };
    return CardFinder;
}());
exports.CardFinder = CardFinder;
