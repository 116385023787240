"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.Ringcom = void 0;
var Card_1 = require("../Card");
var Tags_1 = require("../Tags");
var Resources_1 = require("../../common/Resources");
var CardName_1 = require("../../CardName");
var CardType_1 = require("../CardType");
var CardRenderer_1 = require("../render/CardRenderer");
var Options_1 = require("../Options");
var Ringcom = (function (_super) {
    __extends(Ringcom, _super);
    function Ringcom() {
        return _super.call(this, {
            cardType: CardType_1.CardType.CORPORATION,
            name: CardName_1.CardName.RINGCOM,
            tags: [Tags_1.Tags.JOVIAN],
            startingMegaCredits: 39,
            initialActionText: 'Draw 2 cards with a jovian tag',
            metadata: {
                cardNumber: 'PfC4',
                description: 'You start with 39 M€ and 3 M€ production. As your first action, draw 2 cards with a jovian tag.',
                renderData: CardRenderer_1.CardRenderer.builder(function (b) {
                    b.br;
                    b.megacredits(39).production(function (pb) { return pb.megacredits(3); });
                    b.cards(2, { secondaryTag: Tags_1.Tags.JOVIAN });
                    b.corpBox('effect', function (ce) {
                        ce.effect('When any player plays a card with a jovian tag (including this) gain 1 titanium.', function (eb) {
                            eb.jovian({ all: Options_1.all, played: Options_1.played }).startEffect.titanium(1);
                        });
                    });
                }),
            },
        }) || this;
    }
    Ringcom.prototype.play = function (player) {
        player.addProduction(Resources_1.Resources.MEGACREDITS, 3);
        return undefined;
    };
    Ringcom.prototype.initialAction = function (player) {
        player.drawCard(2, { tag: Tags_1.Tags.JOVIAN });
        this.onCardPlayed(player, this);
        return undefined;
    };
    Ringcom.prototype.onCorpCardPlayed = function (player, card) {
        return this.onCardPlayed(player, card);
    };
    Ringcom.prototype.onCardPlayed = function (player, card) {
        var _this = this;
        if (card.tags.includes(Tags_1.Tags.JOVIAN)) {
            player.game.getPlayers().forEach(function (p) {
                var _a;
                if (((_a = p.corporationCard) === null || _a === void 0 ? void 0 : _a.name) === _this.name) {
                    p.addResource(Resources_1.Resources.TITANIUM, 1, { log: true });
                }
            });
        }
    };
    return Ringcom;
}(Card_1.Card));
exports.Ringcom = Ringcom;
