"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.SolarStorm = void 0;
var Card_1 = require("../Card");
var CardType_1 = require("../CardType");
var CardName_1 = require("../../CardName");
var CardRenderer_1 = require("../render/CardRenderer");
var Resources_1 = require("../../common/Resources");
var Tags_1 = require("../Tags");
var RemoveResourcesFromCard_1 = require("../../deferredActions/RemoveResourcesFromCard");
var ResourceType_1 = require("../../common/ResourceType");
var Options_1 = require("../Options");
var SolarStorm = (function (_super) {
    __extends(SolarStorm, _super);
    function SolarStorm() {
        return _super.call(this, {
            cardType: CardType_1.CardType.EVENT,
            name: CardName_1.CardName.SOLAR_STORM,
            cost: 12,
            tags: [Tags_1.Tags.SPACE],
            tr: { temperature: 1 },
            metadata: {
                cardNumber: 'Pf32',
                renderData: CardRenderer_1.CardRenderer.builder(function (b) {
                    b.minus().plants(2, { all: Options_1.all }).asterix().nbsp.minus().data({ amount: 3, digit: Options_1.digit, all: Options_1.all }).br;
                    b.production(function (pb) { return pb.heat(1); }).nbsp.temperature(1);
                }),
                description: 'Every player loses 2 plants. Remove up to 3 data from any player. ' +
                    'Raise your heat production 1 step. Raise the temperature 1 step.',
            },
        }) || this;
    }
    SolarStorm.prototype.play = function (player) {
        player.addProduction(Resources_1.Resources.HEAT, 1);
        player.game.getPlayers().forEach(function (p) {
            if (!p.plantsAreProtected()) {
                p.deductResource(Resources_1.Resources.PLANTS, 2, { log: true, from: player });
            }
        });
        player.game.defer(new RemoveResourcesFromCard_1.RemoveResourcesFromCard(player, ResourceType_1.ResourceType.DATA, 3));
        player.game.increaseTemperature(player, 1);
        return undefined;
    };
    return SolarStorm;
}(Card_1.Card));
exports.SolarStorm = SolarStorm;
