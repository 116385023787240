"use strict";
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
var vue_1 = require("vue");
var Button_vue_1 = require("@/client/components/common/Button.vue");
var ClientCardManifest_1 = require("@/client/cards/ClientCardManifest");
var CardType_1 = require("@/cards/CardType");
var allItems = __spreadArray(__spreadArray(__spreadArray(__spreadArray([], (0, ClientCardManifest_1.getCards)((0, ClientCardManifest_1.byType)(CardType_1.CardType.AUTOMATED)), true), (0, ClientCardManifest_1.getCards)((0, ClientCardManifest_1.byType)(CardType_1.CardType.ACTIVE)), true), (0, ClientCardManifest_1.getCards)((0, ClientCardManifest_1.byType)(CardType_1.CardType.EVENT)), true), (0, ClientCardManifest_1.getCards)((0, ClientCardManifest_1.byType)(CardType_1.CardType.PRELUDE)), true).map(ClientCardManifest_1.toName)
    .sort(function (a, b) { return a.localeCompare(b); });
exports.default = vue_1.default.extend({
    name: 'CardsFilter',
    props: {},
    data: function () {
        return {
            selectedCardNames: [],
            foundCardNames: [],
            searchTerm: '',
        };
    },
    components: { Button: Button_vue_1.default },
    methods: {
        isPrelude: function (cardName) {
            var _a;
            return ((_a = (0, ClientCardManifest_1.getCard)(cardName)) === null || _a === void 0 ? void 0 : _a.card.cardType) === CardType_1.CardType.PRELUDE;
        },
        removeCard: function (cardNameToRemove) {
            this.selectedCardNames = this.selectedCardNames.filter(function (curCardName) { return curCardName !== cardNameToRemove; }).sort();
        },
        addCard: function (cardNameToAdd) {
            if (this.selectedCardNames.includes(cardNameToAdd))
                return;
            this.selectedCardNames.push(cardNameToAdd);
            this.selectedCardNames.sort();
            this.searchTerm = '';
        },
    },
    watch: {
        selectedCardNames: function (value) {
            this.$emit('cards-list-changed', value);
        },
        searchTerm: function (value) {
            var _this = this;
            if (value === '') {
                this.foundCardNames = [];
                return;
            }
            if (value.indexOf(',') !== -1) {
                var cardNames = new Set(value.split(',').map(function (c) { return c.trim(); }));
                for (var _i = 0, allItems_1 = allItems; _i < allItems_1.length; _i++) {
                    var item = allItems_1[_i];
                    if (cardNames.has(item)) {
                        this.addCard(item);
                    }
                }
                return;
            }
            var newCardNames = allItems.filter(function (candidate) { return !_this.selectedCardNames.includes(candidate) && candidate.toLowerCase().indexOf(value.toLowerCase()) !== -1; }).sort();
            this.foundCardNames = newCardNames.slice(0, 5);
        },
    },
});
