"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CardLoader = void 0;
var ColoniesCardManifest_1 = require("./cards/colonies/ColoniesCardManifest");
var PreludeCardManifest_1 = require("./cards/prelude/PreludeCardManifest");
var PromoCardManifest_1 = require("./cards/promo/PromoCardManifest");
var StandardCardManifests_1 = require("./cards/StandardCardManifests");
var TurmoilCardManifest_1 = require("./cards/turmoil/TurmoilCardManifest");
var VenusCardManifest_1 = require("./cards/venusNext/VenusCardManifest");
var CommunityCardManifest_1 = require("./cards/community/CommunityCardManifest");
var AresCardManifest_1 = require("./cards/ares/AresCardManifest");
var MoonCardManifest_1 = require("./cards/moon/MoonCardManifest");
var PathfindersCardManifest_1 = require("./cards/pathfinders/PathfindersCardManifest");
var CardName_1 = require("./CardName");
var GameModule_1 = require("./GameModule");
var CardLoader = (function () {
    function CardLoader(gameOptions) {
        this.gameOptions = gameOptions;
        var manifests = [
            [true, StandardCardManifests_1.BASE_CARD_MANIFEST],
            [gameOptions.corporateEra, StandardCardManifests_1.CORP_ERA_CARD_MANIFEST],
            [gameOptions.preludeExtension, PreludeCardManifest_1.PRELUDE_CARD_MANIFEST],
            [gameOptions.venusNextExtension, VenusCardManifest_1.VENUS_CARD_MANIFEST],
            [gameOptions.coloniesExtension, ColoniesCardManifest_1.COLONIES_CARD_MANIFEST],
            [gameOptions.turmoilExtension, TurmoilCardManifest_1.TURMOIL_CARD_MANIFEST],
            [gameOptions.aresExtension, AresCardManifest_1.ARES_CARD_MANIFEST],
            [gameOptions.promoCardsOption, PromoCardManifest_1.PROMO_CARD_MANIFEST],
            [gameOptions.communityCardsOption, CommunityCardManifest_1.COMMUNITY_CARD_MANIFEST],
            [gameOptions.moonExpansion, MoonCardManifest_1.MOON_CARD_MANIFEST],
            [gameOptions.pathfindersExpansion, PathfindersCardManifest_1.PATHFINDERS_CARD_MANIFEST],
        ];
        this.manifests = manifests.filter(function (a) { return a[0]; }).map(function (a) { return a[1]; });
    }
    CardLoader.include = function (gameOptions, cf) {
        if (cf.compatibility === undefined) {
            return true;
        }
        var expansions = Array.isArray(cf.compatibility) ? cf.compatibility : [cf.compatibility];
        return expansions.every(function (expansion) {
            switch (expansion) {
                case GameModule_1.GameModule.Venus:
                    return gameOptions.venusNextExtension;
                case GameModule_1.GameModule.Colonies:
                    return gameOptions.coloniesExtension;
                case GameModule_1.GameModule.Turmoil:
                    return gameOptions.turmoilExtension;
                case GameModule_1.GameModule.Pathfinders:
                    return gameOptions.pathfindersExpansion;
                default:
                    throw new Error("Unhandled expansion type " + expansion + " for card " + cf.cardName);
            }
        });
    };
    CardLoader.prototype.addDeck = function (cards, deck) {
        var _this = this;
        deck.factories.forEach(function (cf) {
            if (CardLoader.include(_this.gameOptions, cf)) {
                cards.push(new cf.Factory());
            }
        });
    };
    CardLoader.prototype.getProjectCards = function () {
        return this.getCards(function (manifest) { return manifest.projectCards; });
    };
    CardLoader.prototype.getStandardProjects = function () {
        return this.getCards(function (manifest) { return manifest.standardProjects; });
    };
    CardLoader.prototype.getCorporationCards = function () {
        return this.getCards(function (manifest) { return manifest.corporationCards; })
            .filter(function (card) { return card.name !== CardName_1.CardName.BEGINNER_CORPORATION; });
    };
    CardLoader.prototype.getPreludeCards = function () {
        return this.getCards(function (manifest) { return manifest.preludeCards; });
    };
    CardLoader.prototype.getCards = function (getDeck) {
        var _this = this;
        var cards = [];
        for (var _i = 0, _a = this.manifests; _i < _a.length; _i++) {
            var manifest = _a[_i];
            this.addDeck(cards, getDeck(manifest));
        }
        return cards.filter(function (card) {
            if (_this.gameOptions.cardsBlackList.includes(card.name))
                return false;
            for (var _i = 0, _a = _this.manifests; _i < _a.length; _i++) {
                var manifest = _a[_i];
                if (manifest.cardsToRemove.has(card.name))
                    return false;
            }
            return true;
        });
    };
    return CardLoader;
}());
exports.CardLoader = CardLoader;
