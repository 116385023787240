"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.Psychrophiles = void 0;
var Tags_1 = require("../Tags");
var Card_1 = require("../Card");
var CardType_1 = require("../CardType");
var ResourceType_1 = require("../../common/ResourceType");
var CardName_1 = require("../../CardName");
var CardRequirements_1 = require("../CardRequirements");
var CardRenderer_1 = require("../render/CardRenderer");
var Options_1 = require("../Options");
var Psychrophiles = (function (_super) {
    __extends(Psychrophiles, _super);
    function Psychrophiles() {
        var _this = _super.call(this, {
            cardType: CardType_1.CardType.ACTIVE,
            name: CardName_1.CardName.PSYCHROPHILES,
            tags: [Tags_1.Tags.MICROBE],
            cost: 2,
            resourceType: ResourceType_1.ResourceType.MICROBE,
            requirements: CardRequirements_1.CardRequirements.builder(function (b) { return b.temperature(-20, { max: Options_1.max }); }),
            metadata: {
                cardNumber: 'P39',
                renderData: CardRenderer_1.CardRenderer.builder(function (b) {
                    b.action('Add 1 microbe to this card.', function (eb) {
                        eb.empty().startAction.microbes(1);
                    }).br;
                    b.effect('When paying for a plant card, microbes here may be used as 2 M€ each.', function (eb) {
                        eb.plants(1, { played: Options_1.played }).startEffect.microbes(1).equals().megacredits(2);
                    });
                }),
                description: 'Temperature must be -20 C or lower.',
            },
        }) || this;
        _this.resourceCount = 0;
        return _this;
    }
    Psychrophiles.prototype.play = function () {
        return undefined;
    };
    Psychrophiles.prototype.canAct = function () {
        return true;
    };
    Psychrophiles.prototype.action = function (player) {
        player.addResourceTo(this);
        return undefined;
    };
    return Psychrophiles;
}(Card_1.Card));
exports.Psychrophiles = Psychrophiles;
