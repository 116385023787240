"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.EconomicEspionage = void 0;
var Card_1 = require("../Card");
var CardType_1 = require("../CardType");
var CardName_1 = require("../../CardName");
var CardRenderer_1 = require("../render/CardRenderer");
var Tags_1 = require("../Tags");
var ResourceType_1 = require("../../common/ResourceType");
var ICard_1 = require("../ICard");
var AddResourcesToCard_1 = require("../../deferredActions/AddResourcesToCard");
var SelectHowToPayDeferred_1 = require("../../deferredActions/SelectHowToPayDeferred");
var EconomicEspionage = (function (_super) {
    __extends(EconomicEspionage, _super);
    function EconomicEspionage() {
        var _this = _super.call(this, {
            cardType: CardType_1.CardType.ACTIVE,
            name: CardName_1.CardName.ECONOMIC_ESPIONAGE,
            cost: 8,
            tags: [Tags_1.Tags.EARTH],
            resourceType: ResourceType_1.ResourceType.DATA,
            victoryPoints: ICard_1.VictoryPoints.resource(1, 3),
            metadata: {
                cardNumber: 'Pf37',
                renderData: CardRenderer_1.CardRenderer.builder(function (b) {
                    b.action('Spend 2 M€ to add 1 data to ANY card.', function (eb) {
                        eb.megacredits(2).startAction.data({ amount: 1 });
                    }).br;
                }),
                description: '1VP for every 3 data here.',
            },
        }) || this;
        _this.resourceCount = 0;
        return _this;
    }
    EconomicEspionage.prototype.canAct = function (player) {
        return player.canAfford(2);
    };
    EconomicEspionage.prototype.action = function (player) {
        player.game.defer(new SelectHowToPayDeferred_1.SelectHowToPayDeferred(player, 2, {
            title: 'Select how to pay for action',
            afterPay: function () {
                player.game.defer(new AddResourcesToCard_1.AddResourcesToCard(player, ResourceType_1.ResourceType.DATA));
            },
        }));
        return undefined;
    };
    EconomicEspionage.prototype.play = function () {
        return undefined;
    };
    return EconomicEspionage;
}(Card_1.Card));
exports.EconomicEspionage = EconomicEspionage;
