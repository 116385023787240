"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var vue_1 = require("vue");
var CardRenderData_vue_1 = require("@/client/components/card/CardRenderData.vue");
var CardParty_vue_1 = require("@/client/components/card/CardParty.vue");
var GlobalEventDealer_1 = require("@/turmoil/globalEvents/GlobalEventDealer");
var CardDescription_vue_1 = require("@/client/components/card/CardDescription.vue");
var PreferencesManager_1 = require("../utils/PreferencesManager");
exports.default = vue_1.default.extend({
    name: 'global-event',
    components: {
        CardRenderData: CardRenderData_vue_1.default,
        CardParty: CardParty_vue_1.default,
        CardDescription: CardDescription_vue_1.default,
    },
    props: {
        globalEvent: {
            type: Object,
        },
        type: {
            type: String,
        },
    },
    data: function () {
        var globalEvent = (0, GlobalEventDealer_1.getGlobalEventByName)(this.globalEvent.name);
        if (globalEvent === undefined) {
            throw new Error("Can't find card " + this.globalEvent.name);
        }
        return {
            renderData: globalEvent.renderData,
        };
    },
    methods: {
        getCardRenderer: function () {
            return this.renderData;
        },
        partyNameClass: function (partyName) {
            if (partyName === undefined) {
                return '';
            }
            return 'event-party--' + partyName.toLowerCase().split(' ').join('_');
        },
        getClass: function () {
            var common = 'global-event';
            switch (this.type) {
                case 'coming':
                    return common + ' global-event--coming';
                case 'current':
                    return common + ' global-event--current';
                default:
                    return common;
            }
        },
        showIcons: function () {
            return PreferencesManager_1.PreferencesManager.loadBoolean('experimental_ui');
        },
    },
});
