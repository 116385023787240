"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.OzoneGenerators = void 0;
var Card_1 = require("../Card");
var CardType_1 = require("../CardType");
var CardName_1 = require("../../CardName");
var CardRenderer_1 = require("../render/CardRenderer");
var CardRequirements_1 = require("../CardRequirements");
var Tags_1 = require("../Tags");
var Resources_1 = require("../../common/Resources");
var OzoneGenerators = (function (_super) {
    __extends(OzoneGenerators, _super);
    function OzoneGenerators() {
        return _super.call(this, {
            cardType: CardType_1.CardType.ACTIVE,
            name: CardName_1.CardName.OZONE_GENERATORS,
            cost: 14,
            tags: [Tags_1.Tags.MARS, Tags_1.Tags.SPACE],
            requirements: CardRequirements_1.CardRequirements.builder(function (b) { return b.oxygen(6); }),
            metadata: {
                cardNumber: 'Pf36',
                renderData: CardRenderer_1.CardRenderer.builder(function (b) {
                    b.action('Spend 3 energy to gain 1 TR.', function (eb) { return eb.energy(3).startAction.tr(1); });
                }),
                description: 'Requires 6% Oxygen.',
            },
        }) || this;
    }
    OzoneGenerators.prototype.canAct = function (player) {
        return player.energy >= 3 && player.canAfford(0, { tr: { tr: 1 } });
    };
    OzoneGenerators.prototype.action = function (player) {
        player.deductResource(Resources_1.Resources.ENERGY, 3);
        player.increaseTerraformRating();
        player.game.log('${0} spent 3 energy to gain 1 TR', function (b) { return b.player(player); });
        return undefined;
    };
    OzoneGenerators.prototype.play = function () {
        return undefined;
    };
    return OzoneGenerators;
}(Card_1.Card));
exports.OzoneGenerators = OzoneGenerators;
