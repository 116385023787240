"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var vue_1 = require("vue");
var Rewards_vue_1 = require("@/client/components/pathfinders/Rewards.vue");
var utils_1 = require("@/utils/utils");
exports.default = vue_1.default.extend({
    name: 'PlanetaryTrack',
    props: {
        val: {
            type: Number,
        },
        type: String,
        rewards: {
            type: Object,
        },
        gameOptions: {
            type: Object,
        },
    },
    data: function () {
        return {
            range: (0, utils_1.range)(23),
        };
    },
    components: {
        Rewards: Rewards_vue_1.default,
    },
    methods: {
        getClass: function (idx) {
            return (idx === this.val) ? 'highlight' : '';
        },
    },
    computed: {
        icon: function () {
            switch (this.type) {
                case 'risingPlayer': return '^';
                case 'everyone': return '*';
                case 'mostTags': return '!';
                default: return '';
            }
        },
    },
});
