"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SelectHowToPay = void 0;
var PlayerInputTypes_1 = require("../PlayerInputTypes");
var SelectHowToPay = (function () {
    function SelectHowToPay(title, canUseSteel, canUseTitanium, canUseHeat, canUseSeeds, amount, cb) {
        this.title = title;
        this.canUseSteel = canUseSteel;
        this.canUseTitanium = canUseTitanium;
        this.canUseHeat = canUseHeat;
        this.canUseSeeds = canUseSeeds;
        this.amount = amount;
        this.cb = cb;
        this.inputType = PlayerInputTypes_1.PlayerInputTypes.SELECT_HOW_TO_PAY;
        this.buttonLabel = 'Pay';
    }
    return SelectHowToPay;
}());
exports.SelectHowToPay = SelectHowToPay;
