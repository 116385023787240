"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.Chimera = void 0;
var Card_1 = require("../Card");
var Tags_1 = require("../Tags");
var Resources_1 = require("../../common/Resources");
var CardName_1 = require("../../CardName");
var CardType_1 = require("../CardType");
var CardRenderer_1 = require("../render/CardRenderer");
var Options_1 = require("../Options");
var Chimera = (function (_super) {
    __extends(Chimera, _super);
    function Chimera() {
        return _super.call(this, {
            cardType: CardType_1.CardType.CORPORATION,
            name: CardName_1.CardName.CHIMERA,
            tags: [Tags_1.Tags.WILDCARD, Tags_1.Tags.WILDCARD],
            startingMegaCredits: 36,
            metadata: {
                cardNumber: 'PfC5',
                description: 'You start with 36 M€, 1 steel, and 1 titanium.',
                renderData: CardRenderer_1.CardRenderer.builder(function (b) {
                    b.megacredits(36).steel(1).titanium(1);
                    b.corpBox('effect', function (ce) {
                        ce.effect('When you perform an action, these wild tags count as any tags of your choice. ' +
                            'For claiming Milestones and Awards, both symbols count as one. ' +
                            '(Other wild tags still do not count toward Awards.)', function (ce) { return ce.wild(2, { played: Options_1.played }).startEffect.wild(2, { played: Options_1.played }).slash().wild(1, { played: Options_1.played }).asterix(); });
                    });
                }),
            },
        }) || this;
    }
    Chimera.prototype.play = function (player) {
        player.addResource(Resources_1.Resources.STEEL, 1);
        player.addResource(Resources_1.Resources.TITANIUM, 1);
        return undefined;
    };
    return Chimera;
}(Card_1.Card));
exports.Chimera = Chimera;
