"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.ArtificialPhotosynthesis = void 0;
var Tags_1 = require("../Tags");
var Card_1 = require("../Card");
var CardType_1 = require("../CardType");
var OrOptions_1 = require("../../inputs/OrOptions");
var SelectOption_1 = require("../../inputs/SelectOption");
var Resources_1 = require("../../common/Resources");
var CardName_1 = require("../../CardName");
var CardRenderer_1 = require("../render/CardRenderer");
var Size_1 = require("../render/Size");
var ArtificialPhotosynthesis = (function (_super) {
    __extends(ArtificialPhotosynthesis, _super);
    function ArtificialPhotosynthesis() {
        return _super.call(this, {
            cardType: CardType_1.CardType.AUTOMATED,
            name: CardName_1.CardName.ARTIFICIAL_PHOTOSYNTHESIS,
            tags: [Tags_1.Tags.SCIENCE],
            cost: 12,
            metadata: {
                description: 'Increase your plant production 1 step or your energy production 2 steps.',
                cardNumber: '115',
                renderData: CardRenderer_1.CardRenderer.builder(function (b) { return b.production(function (pb) { return pb.plants(1).or(Size_1.Size.SMALL).energy(2); }); }),
            },
        }) || this;
    }
    ArtificialPhotosynthesis.prototype.play = function (player) {
        return new OrOptions_1.OrOptions(new SelectOption_1.SelectOption('Increase your energy production 2 steps', 'Increase', function () {
            player.addProduction(Resources_1.Resources.ENERGY, 2, { log: true });
            return undefined;
        }), new SelectOption_1.SelectOption('Increase your plant production 1 step', 'Increase', function () {
            player.addProduction(Resources_1.Resources.PLANTS, 1, { log: true });
            return undefined;
        }));
    };
    return ArtificialPhotosynthesis;
}(Card_1.Card));
exports.ArtificialPhotosynthesis = ArtificialPhotosynthesis;
