"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.MarketCard = void 0;
var SelectHowToPayDeferred_1 = require("../../deferredActions/SelectHowToPayDeferred");
var OrOptions_1 = require("../../inputs/OrOptions");
var SelectAmount_1 = require("../../inputs/SelectAmount");
var SelectOption_1 = require("../../inputs/SelectOption");
var Resources_1 = require("../../common/Resources");
var Card_1 = require("../Card");
var MarketCard = (function (_super) {
    __extends(MarketCard, _super);
    function MarketCard(tradeResource, buyingTerms, sellingTerms, properties) {
        var _this = _super.call(this, properties) || this;
        _this.tradeResource = tradeResource;
        _this.buyingTerms = buyingTerms;
        _this.sellingTerms = sellingTerms;
        return _this;
    }
    MarketCard.prototype.play = function () {
        return undefined;
    };
    MarketCard.prototype.canBuy = function (player) {
        var availableMC = (player.canUseHeatAsMegaCredits) ? player.megaCredits + player.heat : player.megaCredits;
        return availableMC >= this.buyingTerms.from;
    };
    MarketCard.prototype.canSell = function (player) {
        return player.getResource(this.tradeResource) >= this.sellingTerms.from;
    };
    MarketCard.prototype.canAct = function (player) {
        return this.canBuy(player) || this.canSell(player);
    };
    MarketCard.prototype.action = function (player) {
        var _this = this;
        var offerBuy = this.canBuy(player);
        var offerSell = this.canSell(player);
        if (offerBuy && offerSell) {
            return new OrOptions_1.OrOptions(new SelectOption_1.SelectOption("Buy " + this.tradeResource, 'Buy', function () { return _this.getBuyingOption(player); }), new SelectOption_1.SelectOption("Sell " + this.tradeResource, 'Sell', function () { return _this.getSellingOption(player); }));
        }
        else if (offerBuy) {
            return this.getBuyingOption(player);
        }
        else if (offerSell) {
            return this.getSellingOption(player);
        }
        return undefined;
    };
    MarketCard.prototype.getBuyingOption = function (player) {
        var _this = this;
        var availableMC = (player.canUseHeatAsMegaCredits) ? player.megaCredits + player.heat : player.megaCredits;
        var terms = this.buyingTerms;
        var limit = Math.floor(availableMC / terms.from);
        limit = Math.min(limit, terms.limit);
        return new SelectAmount_1.SelectAmount("Select a number of trades (" + terms.from + " M\u20AC => " + terms.to + " " + this.tradeResource + ", max " + limit + ")", "Buy " + this.tradeResource, function (tradesRequested) {
            var cashDue = tradesRequested * terms.from;
            var unitsEarned = tradesRequested * terms.to;
            if (player.canUseHeatAsMegaCredits) {
                var howToPay = new SelectHowToPayDeferred_1.SelectHowToPayDeferred(player, cashDue, { afterPay: function () {
                        player.addResource(_this.tradeResource, unitsEarned);
                    } });
                player.game.defer(howToPay);
            }
            else {
                player.deductResource(Resources_1.Resources.MEGACREDITS, cashDue);
                player.addResource(_this.tradeResource, unitsEarned);
            }
            player.game.log('${0} gained ${1} ${2}', function (b) { return b.player(player).number(tradesRequested).string(_this.tradeResource); });
            return undefined;
        }, 1, limit);
    };
    MarketCard.prototype.getSellingOption = function (player) {
        var _this = this;
        var terms = this.sellingTerms;
        if (terms.from !== 1) {
            throw new Error('selling from !== 1 not yet supported.');
        }
        var limit = player.getResource(this.tradeResource);
        limit = Math.min(limit, terms.limit);
        return new SelectAmount_1.SelectAmount("Select a number of trades (" + terms.from + " " + this.tradeResource + " => " + terms.to + " M\u20AC, max " + limit + ")", "Sell " + this.tradeResource, function (unitsSold) {
            var cashEarned = unitsSold * terms.to;
            player.addResource(Resources_1.Resources.MEGACREDITS, cashEarned);
            player.deductResource(_this.tradeResource, unitsSold);
            player.game.log('${0} sold ${1} ${2}', function (b) { return b.player(player).number(unitsSold).string(_this.tradeResource); });
            return undefined;
        }, 1, limit);
    };
    return MarketCard;
}(Card_1.Card));
exports.MarketCard = MarketCard;
