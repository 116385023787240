"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var vue_1 = require("vue");
var constants_1 = require("@/constants");
var Resources_1 = require("@/common/Resources");
var PreferencesManager_1 = require("@/client/utils/PreferencesManager");
exports.default = vue_1.default.extend({
    name: 'PlayerResource',
    props: {
        type: {
            type: String,
        },
        canUseHeatAsMegaCredits: {
            type: Boolean,
            default: false,
        },
        count: {
            type: Number,
        },
        production: {
            type: Number,
        },
        plantsAreProtected: {
            type: Boolean,
            default: false,
        },
        steelValue: {
            type: Number,
            default: constants_1.DEFAULT_STEEL_VALUE,
        },
        titaniumValue: {
            type: Number,
            default: constants_1.DEFAULT_TITANIUM_VALUE,
        },
    },
    data: function () {
        return {};
    },
    methods: {
        mainCSS: function () {
            return 'resource_item--' + this.type;
        },
        iconCSS: function () {
            return 'resource_icon--' + this.type;
        },
        productionSign: function () {
            if (this.production > 0)
                return '+';
            return '';
        },
        displayPlantsProtectedIcon: function () {
            return this.type === Resources_1.Resources.PLANTS && this.plantsAreProtected;
        },
        showResourceValue: function () {
            var learnerModeOn = PreferencesManager_1.PreferencesManager.load('learner_mode') === '1';
            switch (this.type) {
                case Resources_1.Resources.STEEL:
                    return learnerModeOn || this.steelValue > constants_1.DEFAULT_STEEL_VALUE;
                case Resources_1.Resources.TITANIUM:
                    return learnerModeOn || this.titaniumValue > constants_1.DEFAULT_TITANIUM_VALUE;
                case Resources_1.Resources.HEAT:
                    return this.canUseHeatAsMegaCredits;
                default:
                    return false;
            }
        },
        getResourceValue: function () {
            if (this.type === Resources_1.Resources.STEEL) {
                return "" + this.steelValue;
            }
            else if (this.type === Resources_1.Resources.TITANIUM) {
                return "" + this.titaniumValue;
            }
            else if (this.type === Resources_1.Resources.HEAT && this.canUseHeatAsMegaCredits) {
                return '1';
            }
            else {
                return '';
            }
        },
    },
});
