"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SelectHowToPayDeferred = void 0;
var SelectHowToPay_1 = require("../inputs/SelectHowToPay");
var DeferredAction_1 = require("./DeferredAction");
var Resources_1 = require("../common/Resources");
var SelectHowToPayDeferred = (function () {
    function SelectHowToPayDeferred(player, amount, options) {
        if (options === void 0) { options = {}; }
        this.player = player;
        this.amount = amount;
        this.options = options;
        this.priority = DeferredAction_1.Priority.DEFAULT;
    }
    SelectHowToPayDeferred.prototype.mustPayWithMegacredits = function () {
        var _a, _b;
        if (this.player.canUseHeatAsMegaCredits && this.player.heat > 0) {
            return false;
        }
        if (this.options.canUseSteel && this.player.steel > 0) {
            return false;
        }
        if (this.options.canUseTitanium && this.player.titanium > 0) {
            return false;
        }
        if (this.options.canUseSeeds && ((_b = (_a = this.player.corporationCard) === null || _a === void 0 ? void 0 : _a.resourceCount) !== null && _b !== void 0 ? _b : 0 > 0)) {
            return false;
        }
        return true;
    };
    SelectHowToPayDeferred.prototype.execute = function () {
        var _this = this;
        if (this.mustPayWithMegacredits()) {
            this.player.deductResource(Resources_1.Resources.MEGACREDITS, this.amount);
            if (this.options.afterPay !== undefined) {
                this.options.afterPay();
            }
            return undefined;
        }
        return new SelectHowToPay_1.SelectHowToPay(this.options.title || 'Select how to pay for ' + this.amount + ' MCs', this.options.canUseSteel || false, this.options.canUseTitanium || false, this.player.canUseHeatAsMegaCredits, this.options.canUseSeeds || false, this.amount, function (howToPay) {
            _this.player.deductResource(Resources_1.Resources.STEEL, howToPay.steel);
            _this.player.deductResource(Resources_1.Resources.TITANIUM, howToPay.titanium);
            _this.player.deductResource(Resources_1.Resources.MEGACREDITS, howToPay.megaCredits);
            _this.player.deductResource(Resources_1.Resources.HEAT, howToPay.heat);
            if (howToPay.seeds > 0 && _this.player.corporationCard !== undefined) {
                _this.player.removeResourceFrom(_this.player.corporationCard, howToPay.seeds);
            }
            if (_this.options.afterPay !== undefined) {
                _this.options.afterPay();
            }
            return undefined;
        });
    };
    return SelectHowToPayDeferred;
}());
exports.SelectHowToPayDeferred = SelectHowToPayDeferred;
(function (SelectHowToPayDeferred) {
    ;
})(SelectHowToPayDeferred = exports.SelectHowToPayDeferred || (exports.SelectHowToPayDeferred = {}));
exports.SelectHowToPayDeferred = SelectHowToPayDeferred;
