"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.CoordinatedRaid = void 0;
var CardType_1 = require("../CardType");
var CardName_1 = require("../../CardName");
var Card_1 = require("../Card");
var CardRenderer_1 = require("../render/CardRenderer");
var CardRequirements_1 = require("../CardRequirements");
var SelectColony_1 = require("../../inputs/SelectColony");
var CoordinatedRaid = (function (_super) {
    __extends(CoordinatedRaid, _super);
    function CoordinatedRaid() {
        return _super.call(this, {
            cost: 5,
            name: CardName_1.CardName.COORDINATED_RAID,
            cardType: CardType_1.CardType.EVENT,
            requirements: CardRequirements_1.CardRequirements.builder(function (b) { return b.colonies(1); }),
            metadata: {
                cardNumber: 'Pf64',
                renderData: CardRenderer_1.CardRenderer.builder(function (b) { return b.trade().asterix(); }),
                description: 'Requires at least 1 colony in play. Send one of your unused Trade Fleets to any colony tile. ' +
                    'Collect the trade bonus and colony bonus for every colony on this tile. Other players do not get their colony bonuses from this action.',
            },
        }) || this;
    }
    CoordinatedRaid.prototype.canPlay = function (player) {
        return player.getFleetSize() > player.tradesThisGeneration;
    };
    CoordinatedRaid.prototype.play = function (player) {
        var colonies = player.game.colonies.filter(function (colony) { return colony.isActive; });
        return new SelectColony_1.SelectColony('Select colony tile for trade', 'trade', colonies, function (colony) {
            colony.trade(player, { selfishTrade: true });
            return undefined;
        });
    };
    return CoordinatedRaid;
}(Card_1.Card));
exports.CoordinatedRaid = CoordinatedRaid;
