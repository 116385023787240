"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.RoboticWorkforce = void 0;
var Tags_1 = require("../Tags");
var Card_1 = require("../Card");
var CardType_1 = require("../CardType");
var SelectCard_1 = require("../../inputs/SelectCard");
var CardName_1 = require("../../CardName");
var Resources_1 = require("../../common/Resources");
var CardRenderer_1 = require("../render/CardRenderer");
var Size_1 = require("../render/Size");
var Units_1 = require("../../Units");
var Options_1 = require("../Options");
var RoboticWorkforce = (function (_super) {
    __extends(RoboticWorkforce, _super);
    function RoboticWorkforce() {
        return _super.call(this, {
            cardType: CardType_1.CardType.AUTOMATED,
            name: CardName_1.CardName.ROBOTIC_WORKFORCE,
            tags: [Tags_1.Tags.SCIENCE],
            cost: 9,
            metadata: {
                cardNumber: '086',
                renderData: CardRenderer_1.CardRenderer.builder(function (b) {
                    b.text('Copy A', Size_1.Size.SMALL, true).nbsp;
                    b.production(function (pb) { return pb.building(1, { played: Options_1.played }); });
                }),
                description: 'Duplicate only the production box of one of your building cards.',
            },
        }) || this;
    }
    RoboticWorkforce.prototype.canPlay = function (player) {
        return this.getAvailableCards(player).length > 0;
    };
    RoboticWorkforce.prototype.isCardApplicable = function (card, player) {
        if (!card.tags.includes(Tags_1.Tags.BUILDING)) {
            return false;
        }
        if (card.name === CardName_1.CardName.BIOMASS_COMBUSTORS) {
            return player.game.someoneHasResourceProduction(Resources_1.Resources.PLANTS, 1);
        }
        if (card.name === CardName_1.CardName.HEAT_TRAPPERS) {
            return player.game.someoneHasResourceProduction(Resources_1.Resources.HEAT, 2);
        }
        if (card.name === CardName_1.CardName.GYROPOLIS) {
            return player.getProduction(Resources_1.Resources.ENERGY) >= 2;
        }
        if (card.produce !== undefined)
            return true;
        if (card.productionBox === undefined || card.productionBox === Units_1.Units.EMPTY)
            return false;
        return player.canAdjustProduction(card.productionBox);
    };
    RoboticWorkforce.prototype.getAvailableCards = function (player) {
        var _this = this;
        var availableCards = player.playedCards.filter(function (card) { return _this.isCardApplicable(card, player); });
        if (player.corporationCard !== undefined && this.isCardApplicable(player.corporationCard, player)) {
            availableCards.push(player.corporationCard);
        }
        return availableCards;
    };
    RoboticWorkforce.prototype.play = function (player) {
        var _this = this;
        var availableCards = this.getAvailableCards(player);
        if (availableCards.length === 0) {
            return undefined;
        }
        return new SelectCard_1.SelectCard('Select builder card to copy', 'Copy', availableCards, function (selectedCards) {
            var card = selectedCards[0];
            player.game.log('${0} copied ${1} production with ${2}', function (b) {
                return b.player(player).card(card).card(_this);
            });
            if (card.produce) {
                card.produce(player);
            }
            else if (card.productionBox) {
                player.adjustProduction(card.productionBox);
            }
            else {
                throw new Error("Card " + card.name + " is not a valid Robotic Workforce card.");
            }
            return undefined;
        });
    };
    return RoboticWorkforce;
}(Card_1.Card));
exports.RoboticWorkforce = RoboticWorkforce;
