"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PATHFINDERS_CARD_MANIFEST = void 0;
var CardManifest_1 = require("../CardManifest");
var CardName_1 = require("../../CardName");
var GameModule_1 = require("../../GameModule");
var AdvancedPowerGrid_1 = require("./AdvancedPowerGrid");
var AgroDrones_1 = require("./AgroDrones");
var Ambient_1 = require("./Ambient");
var Anthozoa_1 = require("./Anthozoa");
var AntidesertificationTechniques_1 = require("./AntidesertificationTechniques");
var AsteroidResources_1 = require("./AsteroidResources");
var BreedingFarms_1 = require("./BreedingFarms");
var CassiniStation_1 = require("./CassiniStation");
var CeresSpaceport_1 = require("./CeresSpaceport");
var Chimera_1 = require("./Chimera");
var CO2Reducers_1 = require("./CO2Reducers");
var CollegiumCopernicus_1 = require("./CollegiumCopernicus");
var ControlledBloom_1 = require("./ControlledBloom");
var CoordinatedRaid_1 = require("./CoordinatedRaid");
var CrewTraining_1 = require("./CrewTraining");
var Cryptocurrency_1 = require("./Cryptocurrency");
var CultivationOfVenus_1 = require("./CultivationOfVenus");
var Cyanobacteria_1 = require("./Cyanobacteria");
var DataLeak_1 = require("./DataLeak");
var DeclarationOfIndependence_1 = require("./DeclarationOfIndependence");
var DeepSpaceOperations_1 = require("./DeepSpaceOperations");
var DesignCompany_1 = require("./DesignCompany");
var DesignedOrganisms_1 = require("./DesignedOrganisms");
var DustStorm_1 = require("./DustStorm");
var DysonScreens_1 = require("./DysonScreens");
var EarlyExpedition_1 = require("./EarlyExpedition");
var EconomicEspionage_1 = require("./EconomicEspionage");
var EconomicHelp_1 = require("./EconomicHelp");
var ExpeditionToTheSurfaceVenus_1 = require("./ExpeditionToTheSurfaceVenus");
var ExperiencedMartians_1 = require("./ExperiencedMartians");
var FlatMarsTheory_1 = require("./FlatMarsTheory");
var FloaterUrbanism_1 = require("./FloaterUrbanism");
var GeologicalExpedition_1 = require("./GeologicalExpedition");
var HighTempSuperconductors_1 = require("./HighTempSuperconductors");
var HuygensObservatory_1 = require("./HuygensObservatory");
var HydrogenBombardment_1 = require("./HydrogenBombardment");
var HydrogenProcessingPlant_1 = require("./HydrogenProcessingPlant");
var InterplanetaryTransport_1 = require("./InterplanetaryTransport");
var Kickstarter_1 = require("./Kickstarter");
var LastResortIngenuity_1 = require("./LastResortIngenuity");
var LobbyHalls_1 = require("./LobbyHalls");
var LunarEmbassy_1 = require("./LunarEmbassy");
var LuxuryEstate_1 = require("./LuxuryEstate");
var MarsDirect_1 = require("./MarsDirect");
var MartianCulture_1 = require("./MartianCulture");
var MartianDustProcessingPlant_1 = require("./MartianDustProcessingPlant");
var MartianInsuranceGroup_1 = require("./MartianInsuranceGroup");
var MartianMonuments_1 = require("./MartianMonuments");
var MartianNatureWonders_1 = require("./MartianNatureWonders");
var MartianRepository_1 = require("./MartianRepository");
var MicrobiologyPatents_1 = require("./MicrobiologyPatents");
var MuseumofEarlyColonisation_1 = require("./MuseumofEarlyColonisation");
var NewVenice_1 = require("./NewVenice");
var NobelLabs_1 = require("./NobelLabs");
var OrbitalLaboratories_1 = require("./OrbitalLaboratories");
var OzoneGenerators_1 = require("./OzoneGenerators");
var PersonalAgenda_1 = require("./PersonalAgenda");
var Polaris_1 = require("./Polaris");
var Pollinators_1 = require("./Pollinators");
var PowerPlant_1 = require("./PowerPlant");
var PrefabricationofHumanHabitats_1 = require("./PrefabricationofHumanHabitats");
var PrivateSecurity_1 = require("./PrivateSecurity");
var PublicSponsoredGrant_1 = require("./PublicSponsoredGrant");
var RareEarthElements_1 = require("./RareEarthElements");
var RedCity_1 = require("./RedCity");
var Ringcom_1 = require("./Ringcom");
var ResearchGrant_1 = require("./ResearchGrant");
var ReturntoAbandonedTechnology_1 = require("./ReturntoAbandonedTechnology");
var RichDeposits_1 = require("./RichDeposits");
var RobinHaulings_1 = require("./RobinHaulings");
var SecretLabs_1 = require("./SecretLabs");
var SmallOpenPitMine_1 = require("./SmallOpenPitMine");
var SmallComet_1 = require("./SmallComet");
var SocialEvents_1 = require("./SocialEvents");
var SoilDetoxification_1 = require("./SoilDetoxification");
var Solarpedia_1 = require("./Solarpedia");
var SolarStorm_1 = require("./SolarStorm");
var SoylentSeedlingSystems_1 = require("./SoylentSeedlingSystems");
var SpaceDebrisCleaningOperation_1 = require("./SpaceDebrisCleaningOperation");
var SpaceRelay_1 = require("./SpaceRelay");
var StrategicBasePlanning_1 = require("./StrategicBasePlanning");
var Steelaris_1 = require("./Steelaris");
var TerraformingControlStation_1 = require("./TerraformingControlStation");
var TerraformingRobots_1 = require("./TerraformingRobots");
var ValuableGases_1 = require("./ValuableGases");
var VeneraBase_1 = require("./VeneraBase");
var VenusFirst_1 = require("./VenusFirst");
var VitalColony_1 = require("./VitalColony");
exports.PATHFINDERS_CARD_MANIFEST = new CardManifest_1.CardManifest({
    module: GameModule_1.GameModule.Pathfinders,
    projectCards: [
        { cardName: CardName_1.CardName.BREEDING_FARMS, Factory: BreedingFarms_1.BreedingFarms },
        { cardName: CardName_1.CardName.PREFABRICATION_OF_HUMAN_HABITATS, Factory: PrefabricationofHumanHabitats_1.PrefabricationofHumanHabitats },
        { cardName: CardName_1.CardName.NEW_VENICE, Factory: NewVenice_1.NewVenice },
        { cardName: CardName_1.CardName.AGRO_DRONES, Factory: AgroDrones_1.AgroDrones },
        { cardName: CardName_1.CardName.RARE_EARTH_ELEMENTS, Factory: RareEarthElements_1.RareEarthElements },
        { cardName: CardName_1.CardName.ORBITAL_LABORATORIES, Factory: OrbitalLaboratories_1.OrbitalLaboratories },
        { cardName: CardName_1.CardName.DUST_STORM, Factory: DustStorm_1.DustStorm },
        { cardName: CardName_1.CardName.MARTIAN_MONUMENTS, Factory: MartianMonuments_1.MartianMonuments },
        { cardName: CardName_1.CardName.MARTIAN_NATURE_WONDERS, Factory: MartianNatureWonders_1.MartianNatureWonders },
        { cardName: CardName_1.CardName.MUSEUM_OF_EARLY_COLONISATION, Factory: MuseumofEarlyColonisation_1.MuseumofEarlyColonisation },
        { cardName: CardName_1.CardName.TERRAFORMING_CONTROL_STATION, Factory: TerraformingControlStation_1.TerraformingControlStation, compatibility: GameModule_1.GameModule.Venus },
        { cardName: CardName_1.CardName.CERES_SPACEPORT, Factory: CeresSpaceport_1.CeresSpaceport },
        { cardName: CardName_1.CardName.DYSON_SCREENS, Factory: DysonScreens_1.DysonScreens },
        { cardName: CardName_1.CardName.LUNAR_EMBASSY, Factory: LunarEmbassy_1.LunarEmbassy },
        { cardName: CardName_1.CardName.GEOLOGICAL_EXPEDITION, Factory: GeologicalExpedition_1.GeologicalExpedition },
        { cardName: CardName_1.CardName.EARLY_EXPEDITION, Factory: EarlyExpedition_1.EarlyExpedition },
        { cardName: CardName_1.CardName.HYDROGEN_PROCESSING_PLANT, Factory: HydrogenProcessingPlant_1.HydrogenProcessingPlant },
        { cardName: CardName_1.CardName.POWER_PLANT_PATHFINDERS, Factory: PowerPlant_1.PowerPlant },
        { cardName: CardName_1.CardName.LUXURY_ESTATE, Factory: LuxuryEstate_1.LuxuryEstate },
        { cardName: CardName_1.CardName.RETURN_TO_ABANDONED_TECHNOLOGY, Factory: ReturntoAbandonedTechnology_1.ReturntoAbandonedTechnology },
        { cardName: CardName_1.CardName.DESIGNED_ORGANISMS, Factory: DesignedOrganisms_1.DesignedOrganisms },
        { cardName: CardName_1.CardName.SPACE_DEBRIS_CLEANING_OPERATION, Factory: SpaceDebrisCleaningOperation_1.SpaceDebrisCleaningOperation },
        { cardName: CardName_1.CardName.PRIVATE_SECURITY, Factory: PrivateSecurity_1.PrivateSecurity },
        { cardName: CardName_1.CardName.SECRET_LABS, Factory: SecretLabs_1.SecretLabs },
        { cardName: CardName_1.CardName.CYANOBACTERIA, Factory: Cyanobacteria_1.Cyanobacteria },
        { cardName: CardName_1.CardName.MARTIAN_REPOSITORY, Factory: MartianRepository_1.MartianRepository },
        { cardName: CardName_1.CardName.DATA_LEAK, Factory: DataLeak_1.DataLeak },
        { cardName: CardName_1.CardName.SMALL_OPEN_PIT_MINE, Factory: SmallOpenPitMine_1.SmallOpenPitMine },
        { cardName: CardName_1.CardName.SOLAR_STORM, Factory: SolarStorm_1.SolarStorm },
        { cardName: CardName_1.CardName.SPACE_RELAY, Factory: SpaceRelay_1.SpaceRelay },
        { cardName: CardName_1.CardName.DECLARATION_OF_INDEPENDENCE, Factory: DeclarationOfIndependence_1.DeclarationOfIndependence, compatibility: GameModule_1.GameModule.Turmoil },
        { cardName: CardName_1.CardName.MARTIAN_CULTURE, Factory: MartianCulture_1.MartianCulture },
        { cardName: CardName_1.CardName.OZONE_GENERATORS, Factory: OzoneGenerators_1.OzoneGenerators },
        { cardName: CardName_1.CardName.SMALL_COMET, Factory: SmallComet_1.SmallComet },
        { cardName: CardName_1.CardName.ECONOMIC_ESPIONAGE, Factory: EconomicEspionage_1.EconomicEspionage },
        { cardName: CardName_1.CardName.FLAT_MARS_THEORY, Factory: FlatMarsTheory_1.FlatMarsTheory },
        { cardName: CardName_1.CardName.ASTEROID_RESOURCES, Factory: AsteroidResources_1.AsteroidResources },
        { cardName: CardName_1.CardName.KICKSTARTER, Factory: Kickstarter_1.Kickstarter },
        { cardName: CardName_1.CardName.ECONOMIC_HELP, Factory: EconomicHelp_1.EconomicHelp },
        { cardName: CardName_1.CardName.INTERPLANETARY_TRANSPORT, Factory: InterplanetaryTransport_1.InterplanetaryTransport },
        { cardName: CardName_1.CardName.MARTIAN_DUST_PROCESSING_PLANT, Factory: MartianDustProcessingPlant_1.MartianDustProcessingPlant },
        { cardName: CardName_1.CardName.CULTIVATION_OF_VENUS, Factory: CultivationOfVenus_1.CultivationOfVenus, compatibility: GameModule_1.GameModule.Venus },
        { cardName: CardName_1.CardName.EXPEDITION_TO_THE_SURFACE_VENUS, Factory: ExpeditionToTheSurfaceVenus_1.ExpeditionToTheSurfaceVenus, compatibility: GameModule_1.GameModule.Venus },
        { cardName: CardName_1.CardName.LAST_RESORT_INGENUITY, Factory: LastResortIngenuity_1.LastResortIngenuity },
        { cardName: CardName_1.CardName.CRYPTOCURRENCY, Factory: Cryptocurrency_1.Cryptocurrency },
        { cardName: CardName_1.CardName.RICH_DEPOSITS, Factory: RichDeposits_1.RichDeposits },
        { cardName: CardName_1.CardName.SOLARPEDIA, Factory: Solarpedia_1.Solarpedia, compatibility: GameModule_1.GameModule.Venus },
        { cardName: CardName_1.CardName.ANTHOZOA, Factory: Anthozoa_1.Anthozoa },
        { cardName: CardName_1.CardName.ADVANCED_POWER_GRID, Factory: AdvancedPowerGrid_1.AdvancedPowerGrid },
        { cardName: CardName_1.CardName.NOBEL_LABS, Factory: NobelLabs_1.NobelLabs },
        { cardName: CardName_1.CardName.HUYGENS_OBSERVATORY, Factory: HuygensObservatory_1.HuygensObservatory, compatibility: GameModule_1.GameModule.Colonies },
        { cardName: CardName_1.CardName.CASSINI_STATION, Factory: CassiniStation_1.CassiniStation, compatibility: GameModule_1.GameModule.Colonies },
        { cardName: CardName_1.CardName.MICROBIOLOGY_PATENTS, Factory: MicrobiologyPatents_1.MicrobiologyPatents },
        { cardName: CardName_1.CardName.COORDINATED_RAID, Factory: CoordinatedRaid_1.CoordinatedRaid, compatibility: GameModule_1.GameModule.Colonies },
        { cardName: CardName_1.CardName.LOBBY_HALLS, Factory: LobbyHalls_1.LobbyHalls, compatibility: GameModule_1.GameModule.Turmoil },
        { cardName: CardName_1.CardName.RED_CITY, Factory: RedCity_1.RedCity, compatibility: GameModule_1.GameModule.Turmoil },
        { cardName: CardName_1.CardName.VENERA_BASE, Factory: VeneraBase_1.VeneraBase, compatibility: [GameModule_1.GameModule.Turmoil, GameModule_1.GameModule.Venus] },
        { cardName: CardName_1.CardName.FLOATER_URBANISM, Factory: FloaterUrbanism_1.FloaterUrbanism, compatibility: GameModule_1.GameModule.Venus },
        { cardName: CardName_1.CardName.SOIL_DETOXIFICATION, Factory: SoilDetoxification_1.SoilDetoxification, compatibility: GameModule_1.GameModule.Turmoil },
        { cardName: CardName_1.CardName.HIGH_TEMP_SUPERCONDUCTORS, Factory: HighTempSuperconductors_1.HighTempSuperconductors, compatibility: GameModule_1.GameModule.Turmoil },
        { cardName: CardName_1.CardName.PUBLIC_SPONSORED_GRANT, Factory: PublicSponsoredGrant_1.PublicSponsoredGrant, compatibility: GameModule_1.GameModule.Turmoil },
        { cardName: CardName_1.CardName.POLLINATORS, Factory: Pollinators_1.Pollinators },
        { cardName: CardName_1.CardName.SOCIAL_EVENTS, Factory: SocialEvents_1.SocialEvents },
        { cardName: CardName_1.CardName.CONTROLLED_BLOOM, Factory: ControlledBloom_1.ControlledBloom },
        { cardName: CardName_1.CardName.TERRAFORMING_ROBOTS, Factory: TerraformingRobots_1.TerraformingRobots },
    ],
    corporationCards: [
        { cardName: CardName_1.CardName.POLARIS, Factory: Polaris_1.Polaris },
        { cardName: CardName_1.CardName.AMBIENT, Factory: Ambient_1.Ambient, compatibility: GameModule_1.GameModule.Venus },
        { cardName: CardName_1.CardName.RINGCOM, Factory: Ringcom_1.Ringcom },
        { cardName: CardName_1.CardName.CHIMERA, Factory: Chimera_1.Chimera },
        { cardName: CardName_1.CardName.SOYLENT_SEEDLING_SYSTEMS, Factory: SoylentSeedlingSystems_1.SoylentSeedlingSystems },
        { cardName: CardName_1.CardName.STEELARIS, Factory: Steelaris_1.Steelaris },
        { cardName: CardName_1.CardName.MARS_DIRECT, Factory: MarsDirect_1.MarsDirect },
        { cardName: CardName_1.CardName.MARTIAN_INSURANCE_GROUP, Factory: MartianInsuranceGroup_1.MartianInsuranceGroup },
        { cardName: CardName_1.CardName.COLLEGIUM_COPERNICUS, Factory: CollegiumCopernicus_1.CollegiumCopernicus },
        { cardName: CardName_1.CardName.ROBIN_HAULINGS, Factory: RobinHaulings_1.RobinHaulings, compatibility: GameModule_1.GameModule.Venus },
    ],
    preludeCards: [
        { cardName: CardName_1.CardName.VENUS_FIRST_PATHFINDERS, Factory: VenusFirst_1.VenusFirst, compatibility: GameModule_1.GameModule.Venus },
        { cardName: CardName_1.CardName.VALUABLE_GASES_PATHFINDERS, Factory: ValuableGases_1.ValuableGases, compatibility: GameModule_1.GameModule.Venus },
        { cardName: CardName_1.CardName.CO2_REDUCERS, Factory: CO2Reducers_1.CO2Reducers, compatibility: GameModule_1.GameModule.Venus },
        { cardName: CardName_1.CardName.HYDROGEN_BOMBARDMENT, Factory: HydrogenBombardment_1.HydrogenBombardment, compatibility: GameModule_1.GameModule.Venus },
        { cardName: CardName_1.CardName.RESEARCH_GRANT_PATHFINDERS, Factory: ResearchGrant_1.ResearchGrant },
        { cardName: CardName_1.CardName.CREW_TRAINING, Factory: CrewTraining_1.CrewTraining },
        { cardName: CardName_1.CardName.DESIGN_COMPANY, Factory: DesignCompany_1.DesignCompany },
        { cardName: CardName_1.CardName.PERSONAL_AGENDA, Factory: PersonalAgenda_1.PersonalAgenda },
        { cardName: CardName_1.CardName.VITAL_COLONY, Factory: VitalColony_1.VitalColony, compatibility: GameModule_1.GameModule.Colonies },
        { cardName: CardName_1.CardName.STRATEGIC_BASE_PLANNING, Factory: StrategicBasePlanning_1.StrategicBasePlanning, compatibility: GameModule_1.GameModule.Colonies },
        { cardName: CardName_1.CardName.DEEP_SPACE_OPERATIONS, Factory: DeepSpaceOperations_1.DeepSpaceOperations },
        { cardName: CardName_1.CardName.ANTI_DESERTIFICATION_TECHNIQUES, Factory: AntidesertificationTechniques_1.AntidesertificationTechniques },
        { cardName: CardName_1.CardName.EXPERIENCED_MARTIANS, Factory: ExperiencedMartians_1.ExperiencedMartians, compatibility: GameModule_1.GameModule.Turmoil },
    ],
    cardsToRemove: [
        CardName_1.CardName.VENUS_FIRST,
        CardName_1.CardName.RESEARCH_GRANT,
    ],
});
