"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CardRequirements = void 0;
var CardRequirement_1 = require("./CardRequirement");
var RequirementType_1 = require("./RequirementType");
var constants_1 = require("../constants");
var CardRequirements = (function () {
    function CardRequirements(requirements) {
        this.requirements = requirements;
    }
    CardRequirements.builder = function (f) {
        var builder = new Builder();
        f(builder);
        return builder.build();
    };
    CardRequirements.prototype.hasMax = function () {
        return this.requirements.some(function (req) { return req.isMax; });
    };
    CardRequirements.prototype.satisfies = function (player) {
        var tags = [];
        this.requirements.forEach(function (requirement) {
            if ((requirement.type === RequirementType_1.RequirementType.TAG) &&
                requirement.isAny !== true && requirement.isMax !== true) {
                tags.push(requirement.tag);
            }
        });
        if (!player.checkMultipleTagPresence(tags)) {
            return false;
        }
        return this.requirements.every(function (requirement) { return requirement.satisfies(player); });
    };
    return CardRequirements;
}());
exports.CardRequirements = CardRequirements;
var Builder = (function () {
    function Builder() {
        this.reqs = [];
    }
    Builder.prototype.build = function () {
        return new CardRequirements(this.reqs);
    };
    Builder.prototype.oceans = function (amount, options) {
        if (amount === void 0) { amount = 1; }
        var req = new CardRequirement_1.CardRequirement(RequirementType_1.RequirementType.OCEANS, amount, options);
        if (req.amount <= 0 || req.amount > constants_1.MAX_OCEAN_TILES) {
            throw new Error('Ocean tiles must be above 0 and below ' + constants_1.MAX_OCEAN_TILES);
        }
        this.reqs.push(req);
        return this;
    };
    Builder.prototype.oxygen = function (amount, options) {
        if (amount === void 0) { amount = 1; }
        var req = new CardRequirement_1.CardRequirement(RequirementType_1.RequirementType.OXYGEN, amount, options);
        if (req.amount < constants_1.MIN_OXYGEN_LEVEL || req.amount > constants_1.MAX_OXYGEN_LEVEL) {
            throw new Error('Oxygen must be above ' + constants_1.MIN_OXYGEN_LEVEL + ' and below ' + constants_1.MAX_OXYGEN_LEVEL);
        }
        this.reqs.push(req);
        return this;
    };
    Builder.prototype.temperature = function (amount, options) {
        if (amount === void 0) { amount = 1; }
        var req = new CardRequirement_1.CardRequirement(RequirementType_1.RequirementType.TEMPERATURE, amount, options);
        if (req.amount < constants_1.MIN_TEMPERATURE || req.amount > constants_1.MAX_TEMPERATURE) {
            throw new Error('Temperature must be above ' + constants_1.MIN_TEMPERATURE + ' and below ' + constants_1.MAX_TEMPERATURE);
        }
        if (req.amount % 2 !== 0) {
            throw new Error('Temperature must be even');
        }
        this.reqs.push(req);
        return this;
    };
    Builder.prototype.venus = function (amount, options) {
        if (amount === void 0) { amount = 1; }
        var req = new CardRequirement_1.CardRequirement(RequirementType_1.RequirementType.VENUS, amount, options);
        if (req.amount < constants_1.MIN_VENUS_SCALE || req.amount > constants_1.MAX_VENUS_SCALE) {
            throw new Error('Venus must be above ' + constants_1.MIN_VENUS_SCALE + ' and below ' + constants_1.MAX_VENUS_SCALE);
        }
        this.reqs.push(req);
        return this;
    };
    Builder.prototype.tr = function (amount, options) {
        if (amount === void 0) { amount = 1; }
        this.reqs.push(new CardRequirement_1.CardRequirement(RequirementType_1.RequirementType.TR, amount, options));
        return this;
    };
    Builder.prototype.chairman = function () {
        this.reqs.push(new CardRequirement_1.CardRequirement(RequirementType_1.RequirementType.CHAIRMAN));
        return this;
    };
    Builder.prototype.resourceTypes = function (amount, options) {
        if (amount === void 0) { amount = 1; }
        this.reqs.push(new CardRequirement_1.CardRequirement(RequirementType_1.RequirementType.RESOURCE_TYPES, amount, options));
        return this;
    };
    Builder.prototype.greeneries = function (amount, options) {
        if (amount === void 0) { amount = 1; }
        this.reqs.push(new CardRequirement_1.CardRequirement(RequirementType_1.RequirementType.GREENERIES, amount, options));
        return this;
    };
    Builder.prototype.cities = function (amount, options) {
        if (amount === void 0) { amount = 1; }
        this.reqs.push(new CardRequirement_1.CardRequirement(RequirementType_1.RequirementType.CITIES, amount, options));
        return this;
    };
    Builder.prototype.colonies = function (amount, options) {
        if (amount === void 0) { amount = 1; }
        this.reqs.push(new CardRequirement_1.CardRequirement(RequirementType_1.RequirementType.COLONIES, amount, options));
        return this;
    };
    Builder.prototype.floaters = function (amount, options) {
        if (amount === void 0) { amount = 1; }
        this.reqs.push(new CardRequirement_1.CardRequirement(RequirementType_1.RequirementType.FLOATERS, amount, options));
        return this;
    };
    Builder.prototype.partyLeaders = function (amount, options) {
        if (amount === void 0) { amount = 1; }
        this.reqs.push(new CardRequirement_1.CardRequirement(RequirementType_1.RequirementType.PARTY_LEADERS, amount, options));
        return this;
    };
    Builder.prototype.tag = function (tag, amount, options) {
        if (amount === void 0) { amount = 1; }
        this.reqs.push(new CardRequirement_1.TagCardRequirement(tag, amount, options));
        return this;
    };
    Builder.prototype.production = function (resource, amount, options) {
        if (amount === void 0) { amount = 1; }
        this.reqs.push(new CardRequirement_1.ProductionCardRequirement(resource, amount, options));
        return this;
    };
    Builder.prototype.party = function (party) {
        this.reqs.push(new CardRequirement_1.PartyCardRequirement(party));
        return this;
    };
    Builder.prototype.plantsRemoved = function () {
        this.reqs.push(new CardRequirement_1.CardRequirement(RequirementType_1.RequirementType.REMOVED_PLANTS));
        return this;
    };
    Builder.prototype.colonyRate = function (amount, options) {
        if (amount === void 0) { amount = 1; }
        this.reqs.push(new CardRequirement_1.CardRequirement(RequirementType_1.RequirementType.COLONY_RATE, amount, options));
        return this;
    };
    Builder.prototype.miningRate = function (amount, options) {
        if (amount === void 0) { amount = 1; }
        this.reqs.push(new CardRequirement_1.CardRequirement(RequirementType_1.RequirementType.MINING_RATE, amount, options));
        return this;
    };
    Builder.prototype.logisticRate = function (amount, options) {
        if (amount === void 0) { amount = 1; }
        this.reqs.push(new CardRequirement_1.CardRequirement(RequirementType_1.RequirementType.LOGISTIC_RATE, amount, options));
        return this;
    };
    Builder.prototype.colonyTiles = function (amount, options) {
        if (amount === void 0) { amount = 1; }
        this.reqs.push(new CardRequirement_1.CardRequirement(RequirementType_1.RequirementType.COLONY_TILES, amount, options));
        return this;
    };
    Builder.prototype.miningTiles = function (amount, options) {
        if (amount === void 0) { amount = 1; }
        this.reqs.push(new CardRequirement_1.CardRequirement(RequirementType_1.RequirementType.MINING_TILES, amount, options));
        return this;
    };
    Builder.prototype.roadTiles = function (amount, options) {
        if (amount === void 0) { amount = 1; }
        this.reqs.push(new CardRequirement_1.CardRequirement(RequirementType_1.RequirementType.ROAD_TILES, amount, options));
        return this;
    };
    return Builder;
}());
