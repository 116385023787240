"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.LunaTrainStation = void 0;
var CardName_1 = require("../../CardName");
var CardType_1 = require("../CardType");
var Tags_1 = require("../Tags");
var MoonExpansion_1 = require("../../moon/MoonExpansion");
var TileType_1 = require("../../common/TileType");
var CardRenderDynamicVictoryPoints_1 = require("../render/CardRenderDynamicVictoryPoints");
var CardRenderer_1 = require("../render/CardRenderer");
var Units_1 = require("../../Units");
var MoonCard_1 = require("./MoonCard");
var PlaceSpecialMoonTile_1 = require("../../moon/PlaceSpecialMoonTile");
var CardRequirements_1 = require("../CardRequirements");
var Options_1 = require("../Options");
var LunaTrainStation = (function (_super) {
    __extends(LunaTrainStation, _super);
    function LunaTrainStation() {
        return _super.call(this, {
            name: CardName_1.CardName.LUNA_TRAIN_STATION,
            cardType: CardType_1.CardType.AUTOMATED,
            tags: [Tags_1.Tags.BUILDING],
            cost: 20,
            productionBox: Units_1.Units.of({ megacredits: 4 }),
            requirements: CardRequirements_1.CardRequirements.builder(function (b) { return b.logisticRate(5); }),
            reserveUnits: Units_1.Units.of({ steel: 2 }),
            tr: { moonLogistics: 1 },
            victoryPoints: 'special',
            metadata: {
                description: 'Requires a Logistic Rate of 5 or higher. Spend 2 steel. ' +
                    'Increase your M€ production 4 steps. Place this tile on the Moon and raise the Logistic Rate 1 step. ' +
                    '2 VP FOR EACH ROAD TILE ADJACENT TO THIS TILE.',
                cardNumber: 'M15',
                renderData: CardRenderer_1.CardRenderer.builder(function (b) {
                    b.minus().steel(2, { digit: Options_1.digit });
                    b.production(function (pb) { return pb.megacredits(4); });
                    b.tile(TileType_1.TileType.LUNA_TRAIN_STATION, true).moonLogisticsRate();
                }),
                victoryPoints: CardRenderDynamicVictoryPoints_1.CardRenderDynamicVictoryPoints.moonRoadTile(2, true),
            },
        }) || this;
    }
    LunaTrainStation.prototype.play = function (player) {
        _super.prototype.play.call(this, player);
        player.game.defer(new PlaceSpecialMoonTile_1.PlaceSpecialMoonTile(player, {
            tileType: TileType_1.TileType.LUNA_TRAIN_STATION,
            card: this.name,
        }, 'Select a space for Luna Train Station.'));
        MoonExpansion_1.MoonExpansion.raiseLogisticRate(player);
        return undefined;
    };
    LunaTrainStation.prototype.getVictoryPoints = function (player) {
        var moonData = MoonExpansion_1.MoonExpansion.moonData(player.game);
        var usedSpace = moonData.moon.getSpaceByTileCard(this.name);
        if (usedSpace !== undefined) {
            var adjacentSpaces = moonData.moon.getAdjacentSpaces(usedSpace);
            var adjacentMines = adjacentSpaces.filter(function (s) { return MoonExpansion_1.MoonExpansion.spaceHasType(s, TileType_1.TileType.MOON_ROAD); });
            return 2 * adjacentMines.length;
        }
        return 0;
    };
    return LunaTrainStation;
}(MoonCard_1.MoonCard));
exports.LunaTrainStation = LunaTrainStation;
