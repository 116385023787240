"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PreferencesManager = exports.preferences = void 0;
exports.preferences = [
    'hide_hand',
    'hide_awards_and_milestones',
    'hide_top_bar',
    'small_cards',
    'remove_background',
    'magnify_cards',
    'magnify_card_descriptions',
    'show_alerts',
    'hide_active_cards',
    'hide_automated_cards',
    'hide_event_cards',
    'lang',
    'enable_sounds',
    'hide_tile_confirmation',
    'show_card_number',
    'hide_discount_on_cards',
    'learner_mode',
    'hide_animated_sidebar',
    'experimental_ui',
];
var PreferencesManager = (function () {
    function PreferencesManager() {
    }
    PreferencesManager.localStorageSupported = function () {
        return typeof localStorage !== 'undefined';
    };
    PreferencesManager.save = function (name, val, updateMap) {
        if (updateMap === void 0) { updateMap = false; }
        var stringVal = typeof (val) === 'string' ? val : (val ? '1' : '0');
        if (this.localStorageSupported()) {
            localStorage.setItem(name, stringVal);
        }
        if (updateMap) {
            this.preferencesValues.set(name, stringVal);
        }
    };
    PreferencesManager.load = function (name, defaultValue) {
        if (defaultValue === void 0) { defaultValue = ''; }
        if (!this.localStorageSupported())
            return defaultValue;
        var value = localStorage.getItem(name);
        return value !== null && value !== void 0 ? value : defaultValue;
    };
    PreferencesManager.loadBoolean = function (name, defaultValue) {
        if (defaultValue === void 0) { defaultValue = false; }
        if (!this.localStorageSupported())
            return defaultValue;
        return localStorage.getItem(name) === '1';
    };
    PreferencesManager.preferencesValues = new Map();
    return PreferencesManager;
}());
exports.PreferencesManager = PreferencesManager;
