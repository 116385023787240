"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.PrideoftheEarthArkship = void 0;
var CardName_1 = require("../../CardName");
var CardType_1 = require("../CardType");
var Tags_1 = require("../Tags");
var ResourceType_1 = require("../../common/ResourceType");
var CardRenderer_1 = require("../render/CardRenderer");
var Units_1 = require("../../Units");
var MoonCard_1 = require("./MoonCard");
var CardRequirements_1 = require("../CardRequirements");
var Options_1 = require("../Options");
var ICard_1 = require("../ICard");
var PrideoftheEarthArkship = (function (_super) {
    __extends(PrideoftheEarthArkship, _super);
    function PrideoftheEarthArkship() {
        var _this = _super.call(this, {
            name: CardName_1.CardName.PRIDE_OF_THE_EARTH_ARKSHIP,
            cardType: CardType_1.CardType.ACTIVE,
            tags: [Tags_1.Tags.SCIENCE, Tags_1.Tags.SCIENCE, Tags_1.Tags.SPACE],
            cost: 22,
            resourceType: ResourceType_1.ResourceType.SCIENCE,
            victoryPoints: ICard_1.VictoryPoints.resource(1, 1),
            requirements: CardRequirements_1.CardRequirements.builder(function (b) { return b.tag(Tags_1.Tags.SCIENCE).tag(Tags_1.Tags.SPACE, 2); }),
            reserveUnits: Units_1.Units.of({ titanium: 2 }),
            metadata: {
                description: 'Requires 1 science and 2 space tags. Spend 2 titanium. 1 VP per science resource here.',
                cardNumber: 'M24',
                renderData: CardRenderer_1.CardRenderer.builder(function (b) {
                    b.action('Add 1 science resource here per every 5 science tags you have.', function (eb) {
                        eb.empty().startAction.science(1).slash().text('5').science(1, { played: Options_1.played });
                    }).br;
                    b.minus().titanium(2);
                }),
            },
        }) || this;
        _this.resourceCount = 0;
        return _this;
    }
    ;
    PrideoftheEarthArkship.prototype.play = function (player) {
        _super.prototype.play.call(this, player);
        return undefined;
    };
    PrideoftheEarthArkship.prototype.canAct = function (player) {
        return player.getTagCount(Tags_1.Tags.SCIENCE) >= 5;
    };
    PrideoftheEarthArkship.prototype.action = function (player) {
        var count = Math.floor(player.getTagCount(Tags_1.Tags.SCIENCE) / 5);
        player.addResourceTo(this, count);
        return undefined;
    };
    return PrideoftheEarthArkship;
}(MoonCard_1.MoonCard));
exports.PrideoftheEarthArkship = PrideoftheEarthArkship;
