"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.BusinessEmpire = void 0;
var Tags_1 = require("../Tags");
var PreludeCard_1 = require("./PreludeCard");
var CardName_1 = require("../../CardName");
var SelectHowToPayDeferred_1 = require("../../deferredActions/SelectHowToPayDeferred");
var CardRenderer_1 = require("../render/CardRenderer");
var Units_1 = require("../../Units");
var BusinessEmpire = (function (_super) {
    __extends(BusinessEmpire, _super);
    function BusinessEmpire() {
        return _super.call(this, {
            name: CardName_1.CardName.BUSINESS_EMPIRE,
            tags: [Tags_1.Tags.EARTH],
            productionBox: Units_1.Units.of({ megacredits: 6 }),
            startingMegacredits: -6,
            metadata: {
                cardNumber: 'P06',
                renderData: CardRenderer_1.CardRenderer.builder(function (b) {
                    b.production(function (pb) { return pb.megacredits(6); }).br;
                    b.megacredits(-6);
                }),
                description: 'Increase your M€ production 6 steps. Pay 6 M€.',
            },
        }) || this;
    }
    BusinessEmpire.prototype.canPlay = function (player) {
        if (player.isCorporation(CardName_1.CardName.MANUTECH))
            return true;
        return player.canAfford(6);
    };
    BusinessEmpire.prototype.play = function (player) {
        player.adjustProduction(this.productionBox);
        player.game.defer(new SelectHowToPayDeferred_1.SelectHowToPayDeferred(player, 6));
        return undefined;
    };
    return BusinessEmpire;
}(PreludeCard_1.PreludeCard));
exports.BusinessEmpire = BusinessEmpire;
