"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.PowerSupplyConsortium = void 0;
var Tags_1 = require("../Tags");
var Card_1 = require("../Card");
var CardType_1 = require("../CardType");
var Resources_1 = require("../../common/Resources");
var CardName_1 = require("../../CardName");
var DecreaseAnyProduction_1 = require("../../deferredActions/DecreaseAnyProduction");
var CardRequirements_1 = require("../CardRequirements");
var CardRenderer_1 = require("../render/CardRenderer");
var Options_1 = require("../Options");
var PowerSupplyConsortium = (function (_super) {
    __extends(PowerSupplyConsortium, _super);
    function PowerSupplyConsortium() {
        return _super.call(this, {
            cardType: CardType_1.CardType.AUTOMATED,
            name: CardName_1.CardName.POWER_SUPPLY_CONSORTIUM,
            tags: [Tags_1.Tags.ENERGY],
            cost: 5,
            requirements: CardRequirements_1.CardRequirements.builder(function (b) { return b.tag(Tags_1.Tags.ENERGY, 2); }),
            metadata: {
                cardNumber: '160',
                renderData: CardRenderer_1.CardRenderer.builder(function (b) {
                    b.production(function (pb) {
                        pb.minus().energy(1, { all: Options_1.all }).br;
                        pb.plus().energy(1);
                    });
                }),
                description: 'Requires 2 Power tags. Decrease any Energy production 1 step and increase your own 1 step.',
            },
        }) || this;
    }
    PowerSupplyConsortium.prototype.play = function (player) {
        player.addProduction(Resources_1.Resources.ENERGY, 1);
        player.game.defer(new DecreaseAnyProduction_1.DecreaseAnyProduction(player, Resources_1.Resources.ENERGY, { count: 1, stealing: true }));
        return undefined;
    };
    return PowerSupplyConsortium;
}(Card_1.Card));
exports.PowerSupplyConsortium = PowerSupplyConsortium;
