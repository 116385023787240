"use strict";
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ChoosePoliticalAgenda = void 0;
var OrOptions_1 = require("../inputs/OrOptions");
var SelectOption_1 = require("../inputs/SelectOption");
var DeferredAction_1 = require("./DeferredAction");
var ChoosePoliticalAgenda = (function () {
    function ChoosePoliticalAgenda(player, party, bonusCb, policyCb) {
        this.player = player;
        this.party = party;
        this.bonusCb = bonusCb;
        this.policyCb = policyCb;
        this.priority = DeferredAction_1.Priority.DEFAULT;
    }
    ChoosePoliticalAgenda.prototype.execute = function () {
        var _this = this;
        var players = this.player.game.getPlayers();
        var bonuses = this.party.bonuses.map(function (bonus) {
            var description = bonus.description + ' (' + players.map(function (player) { return player.name + ': ' + bonus.getScore(player); }).join(' / ') + ')';
            return new SelectOption_1.SelectOption(description, 'Select', function () {
                _this.bonusCb(bonus.id);
                return undefined;
            });
        });
        var orBonuses = new (OrOptions_1.OrOptions.bind.apply(OrOptions_1.OrOptions, __spreadArray([void 0], bonuses, false)))();
        orBonuses.title = 'Select a ' + this.party.name + ' bonus.';
        var policies = this.party.policies.map(function (policy) { return new SelectOption_1.SelectOption(policy.description, 'Select', function () {
            _this.policyCb(policy.id);
            return undefined;
        }); });
        var orPolicies = new (OrOptions_1.OrOptions.bind.apply(OrOptions_1.OrOptions, __spreadArray([void 0], policies, false)))();
        orPolicies.title = 'Select a ' + this.party.name + ' policy.';
        return new OrOptions_1.OrOptions(orBonuses, orPolicies);
    };
    return ChoosePoliticalAgenda;
}());
exports.ChoosePoliticalAgenda = ChoosePoliticalAgenda;
