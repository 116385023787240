"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.DarksideObservatory = void 0;
var CardName_1 = require("../../CardName");
var CardType_1 = require("../CardType");
var Tags_1 = require("../Tags");
var CardRenderer_1 = require("../render/CardRenderer");
var ResourceType_1 = require("../../common/ResourceType");
var MoonCards_1 = require("../../moon/MoonCards");
var Card_1 = require("../Card");
var SelectCard_1 = require("../../inputs/SelectCard");
var DarksideObservatory = (function (_super) {
    __extends(DarksideObservatory, _super);
    function DarksideObservatory() {
        return _super.call(this, {
            name: CardName_1.CardName.DARKSIDE_OBSERVATORY,
            cardType: CardType_1.CardType.ACTIVE,
            tags: [Tags_1.Tags.SCIENCE],
            cost: 12,
            metadata: {
                cardNumber: 'M75',
                renderData: CardRenderer_1.CardRenderer.builder(function (b) {
                    b.action('Add 1 Science to ANY card [EXCEPT those giving 2 VP or more per science resource.]', function (ab) {
                        ab.empty().startAction.science(1).asterix();
                    }).br;
                    b.or().br;
                    b.action('Add 2 Data to ANY card.', function (ab) {
                        ab.empty().startAction.data().data();
                    });
                }),
            },
        }) || this;
    }
    ;
    DarksideObservatory.prototype.include = function (card) {
        return card.resourceType === ResourceType_1.ResourceType.DATA || MoonCards_1.MoonCards.scienceCardsWithLessThan2VP.has(card.name);
    };
    DarksideObservatory.prototype.canAct = function (player) {
        var _this = this;
        return player.playedCards.some(function (c) { return _this.include(c); }) || (player.corporationCard !== undefined && this.include(player.corporationCard));
    };
    DarksideObservatory.prototype.addResource = function (card, player) {
        if (card.resourceType === ResourceType_1.ResourceType.DATA) {
            player.addResourceTo(card, { qty: 2, log: true });
        }
        if (card.resourceType === ResourceType_1.ResourceType.SCIENCE) {
            player.addResourceTo(card, { qty: 1, log: true });
        }
    };
    DarksideObservatory.prototype.action = function (player) {
        var _this = this;
        var playableCards = player.playedCards.filter(function (c) { return _this.include(c); });
        if (player.corporationCard !== undefined && this.include(player.corporationCard)) {
            playableCards.push(player.corporationCard);
        }
        return new SelectCard_1.SelectCard('Select card to add EITHER 1 Science resource OR 2 Data resources', 'Add', playableCards, function (card) {
            _this.addResource(card[0], player);
            return undefined;
        });
    };
    DarksideObservatory.prototype.play = function () {
        return undefined;
    };
    return DarksideObservatory;
}(Card_1.Card));
exports.DarksideObservatory = DarksideObservatory;
