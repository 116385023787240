"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.UndergroundDetonations = void 0;
var Tags_1 = require("../Tags");
var Card_1 = require("../Card");
var CardType_1 = require("../CardType");
var Resources_1 = require("../../common/Resources");
var CardName_1 = require("../../CardName");
var SelectHowToPayDeferred_1 = require("../../deferredActions/SelectHowToPayDeferred");
var CardRenderer_1 = require("../render/CardRenderer");
var UndergroundDetonations = (function (_super) {
    __extends(UndergroundDetonations, _super);
    function UndergroundDetonations() {
        return _super.call(this, {
            cardType: CardType_1.CardType.ACTIVE,
            name: CardName_1.CardName.UNDERGROUND_DETONATIONS,
            tags: [Tags_1.Tags.BUILDING],
            cost: 6,
            metadata: {
                cardNumber: '202',
                renderData: CardRenderer_1.CardRenderer.builder(function (b) {
                    b.action('Spend 10M€ to increase your heat production 2 steps.', function (eb) {
                        eb.megacredits(10).startAction.production(function (pb) { return pb.heat(2); });
                    });
                }),
            },
        }) || this;
    }
    UndergroundDetonations.prototype.canAct = function (player) {
        return player.canAfford(10);
    };
    UndergroundDetonations.prototype.action = function (player) {
        player.game.defer(new SelectHowToPayDeferred_1.SelectHowToPayDeferred(player, 10, { title: 'Select how to pay for action' }));
        player.addProduction(Resources_1.Resources.HEAT, 2);
        return undefined;
    };
    UndergroundDetonations.prototype.play = function () {
        return undefined;
    };
    return UndergroundDetonations;
}(Card_1.Card));
exports.UndergroundDetonations = UndergroundDetonations;
