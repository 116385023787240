"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.toName = exports.byModule = exports.byType = exports.getCards = exports.getCard = void 0;
var AllCards_1 = require("@/cards/AllCards");
var cards = new Map();
var cardArray = [];
AllCards_1.ALL_CARD_MANIFESTS.forEach(function (manifest) {
    var module = manifest.module;
    [
        manifest.projectCards,
        manifest.corporationCards,
        manifest.preludeCards,
        manifest.standardProjects
    ].forEach(function (deck) {
        deck.factories.forEach(function (cf) {
            var card = new cf.Factory();
            var cam = { card: card, module: module };
            cards.set(card.name, cam);
            cardArray.push(cam);
        });
    });
});
function getCard(cardName) {
    return cards.get(cardName);
}
exports.getCard = getCard;
function getCards(filter) {
    return cardArray.filter(filter);
}
exports.getCards = getCards;
function byType(cardType) {
    return function (cam) { return cam.card.cardType === cardType; };
}
exports.byType = byType;
function byModule(module) {
    return function (cam) { return cam.module === module; };
}
exports.byModule = byModule;
var toName = function (cam) { return cam.card.name; };
exports.toName = toName;
