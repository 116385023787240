"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.StormCraftIncorporated = void 0;
var Tags_1 = require("../Tags");
var ResourceType_1 = require("../../common/ResourceType");
var AndOptions_1 = require("../../inputs/AndOptions");
var SelectAmount_1 = require("../../inputs/SelectAmount");
var SelectCard_1 = require("../../inputs/SelectCard");
var CardName_1 = require("../../CardName");
var CardType_1 = require("../CardType");
var Card_1 = require("../Card");
var CardRenderer_1 = require("../render/CardRenderer");
var Size_1 = require("../render/Size");
var Resources_1 = require("../../common/Resources");
var StormCraftIncorporated = (function (_super) {
    __extends(StormCraftIncorporated, _super);
    function StormCraftIncorporated() {
        var _this = _super.call(this, {
            name: CardName_1.CardName.STORMCRAFT_INCORPORATED,
            tags: [Tags_1.Tags.JOVIAN],
            startingMegaCredits: 48,
            resourceType: ResourceType_1.ResourceType.FLOATER,
            cardType: CardType_1.CardType.CORPORATION,
            metadata: {
                cardNumber: 'R29',
                description: 'You start with 48 M€.',
                renderData: CardRenderer_1.CardRenderer.builder(function (b) {
                    b.br.br.br;
                    b.megacredits(48);
                    b.corpBox('action', function (ce) {
                        ce.vSpace(Size_1.Size.LARGE);
                        ce.action('Add a floater to ANY card.', function (eb) {
                            eb.empty().startAction.floaters(1).asterix();
                        });
                        ce.vSpace();
                        ce.effect('Floaters on this card may be used as 2 heat each.', function (eb) {
                            eb.startEffect.floaters(1).equals().heat(2);
                        });
                    });
                }),
            },
        }) || this;
        _this.resourceCount = 0;
        return _this;
    }
    StormCraftIncorporated.prototype.play = function () {
        return undefined;
    };
    StormCraftIncorporated.prototype.canAct = function () {
        return true;
    };
    StormCraftIncorporated.prototype.action = function (player) {
        var floaterCards = player.getResourceCards(ResourceType_1.ResourceType.FLOATER);
        if (floaterCards.length === 1) {
            player.addResourceTo(this, { log: true });
            return undefined;
        }
        return new SelectCard_1.SelectCard('Select card to add 1 floater', 'Add floater', floaterCards, function (foundCards) {
            player.addResourceTo(foundCards[0], { qty: 1, log: true });
            return undefined;
        });
    };
    StormCraftIncorporated.prototype.spendHeat = function (player, targetAmount, cb) {
        if (cb === void 0) { cb = function () { return undefined; }; }
        var heatAmount;
        var floaterAmount;
        return new AndOptions_1.AndOptions(function () {
            if (heatAmount + (floaterAmount * 2) < targetAmount) {
                throw new Error("Need to pay " + targetAmount + " heat");
            }
            if (heatAmount > 0 && heatAmount - 1 + (floaterAmount * 2) >= targetAmount) {
                throw new Error("You cannot overspend heat");
            }
            if (floaterAmount > 0 && heatAmount + ((floaterAmount - 1) * 2) >= targetAmount) {
                throw new Error("You cannot overspend floaters");
            }
            player.removeResourceFrom(player.corporationCard, floaterAmount);
            player.deductResource(Resources_1.Resources.HEAT, heatAmount);
            return cb();
        }, new SelectAmount_1.SelectAmount('Select amount of heat to spend', 'Spend heat', function (amount) {
            heatAmount = amount;
            return undefined;
        }, 0, Math.min(player.heat, targetAmount)), new SelectAmount_1.SelectAmount('Select amount of floaters on corporation to spend', 'Spend floaters', function (amount) {
            floaterAmount = amount;
            return undefined;
        }, 0, Math.min(player.getResourcesOnCorporation(), Math.ceil(targetAmount / 2))));
    };
    return StormCraftIncorporated;
}(Card_1.Card));
exports.StormCraftIncorporated = StormCraftIncorporated;
