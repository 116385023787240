"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.PhysicsComplex = void 0;
var Tags_1 = require("../Tags");
var Card_1 = require("../Card");
var ICard_1 = require("../ICard");
var CardType_1 = require("../CardType");
var ResourceType_1 = require("../../common/ResourceType");
var CardName_1 = require("../../CardName");
var CardRenderer_1 = require("../render/CardRenderer");
var Options_1 = require("../Options");
var PhysicsComplex = (function (_super) {
    __extends(PhysicsComplex, _super);
    function PhysicsComplex() {
        var _this = _super.call(this, {
            cardType: CardType_1.CardType.ACTIVE,
            name: CardName_1.CardName.PHYSICS_COMPLEX,
            tags: [Tags_1.Tags.SCIENCE, Tags_1.Tags.BUILDING],
            cost: 12,
            resourceType: ResourceType_1.ResourceType.SCIENCE,
            victoryPoints: ICard_1.VictoryPoints.resource(2, 1),
            metadata: {
                cardNumber: '095',
                renderData: CardRenderer_1.CardRenderer.builder(function (b) {
                    b.action('Spend 6 Energy to add a science resource to this card.', function (eb) {
                        eb.energy(6, { digit: Options_1.digit }).startAction.science();
                    }).br;
                    b.vpText('2 VP for each science resource on this card.');
                }),
            },
        }) || this;
        _this.resourceCount = 0;
        return _this;
    }
    PhysicsComplex.prototype.play = function () {
        return undefined;
    };
    PhysicsComplex.prototype.canAct = function (player) {
        return player.energy >= 6;
    };
    PhysicsComplex.prototype.action = function (player) {
        player.energy -= 6;
        player.addResourceTo(this, 1);
        return undefined;
    };
    return PhysicsComplex;
}(Card_1.Card));
exports.PhysicsComplex = PhysicsComplex;
