"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Revolution = void 0;
var IGlobalEvent_1 = require("./IGlobalEvent");
var GlobalEventName_1 = require("./GlobalEventName");
var PartyName_1 = require("../parties/PartyName");
var Tags_1 = require("../../cards/Tags");
var CardRenderer_1 = require("../../cards/render/CardRenderer");
var Options_1 = require("../../cards/Options");
var Size_1 = require("../../cards/render/Size");
var RENDER_DATA = CardRenderer_1.CardRenderer.builder(function (b) {
    b.earth(1, { played: Options_1.played }).plus().influence().colon().br;
    b.text('1st: ').minus().tr(2, { size: Size_1.Size.SMALL }).nbsp;
    b.text('2nd: ').minus().tr(1, { size: Size_1.Size.SMALL });
});
var Revolution = (function (_super) {
    __extends(Revolution, _super);
    function Revolution() {
        return _super.call(this, {
            name: GlobalEventName_1.GlobalEventName.REVOLUTION,
            description: 'Count Earth tags and ADD(!) influence. The player(s) with most (at least 1) loses 2 TR, and 2nd most (at least 1) loses 1 TR. SOLO: Lose 2 TR if the sum is 4 or more.',
            revealedDelegate: PartyName_1.PartyName.UNITY,
            currentDelegate: PartyName_1.PartyName.MARS,
            renderData: RENDER_DATA,
        }) || this;
    }
    Revolution.prototype.resolve = function (game, turmoil) {
        var _this = this;
        if (game.isSoloMode()) {
            if (this.getScore(game.getPlayers()[0], turmoil) >= 4) {
                game.getPlayers()[0].decreaseTerraformRatingSteps(2, { log: true });
            }
        }
        else {
            var players = __spreadArray([], game.getPlayers(), true).sort(function (p1, p2) { return _this.getScore(p2, turmoil) - _this.getScore(p1, turmoil); });
            if (this.getScore(players[0], turmoil) > this.getScore(players[1], turmoil)) {
                players[0].decreaseTerraformRatingSteps(2, { log: true });
                players.shift();
                if (players.length === 1 && this.getScore(players[0], turmoil) > 0) {
                    players[0].decreaseTerraformRating({ log: true });
                }
                else if (players.length > 1) {
                    if (this.getScore(players[0], turmoil) > this.getScore(players[1], turmoil)) {
                        players[0].decreaseTerraformRating({ log: true });
                    }
                    else {
                        var score = this.getScore(players[0], turmoil);
                        while (players.length > 0 && this.getScore(players[0], turmoil) === score) {
                            if (this.getScore(players[0], turmoil) > 0) {
                                players[0].decreaseTerraformRating({ log: true });
                            }
                            players.shift();
                        }
                    }
                }
            }
            else {
                var score = this.getScore(players[0], turmoil);
                while (players.length > 0 && this.getScore(players[0], turmoil) === score) {
                    if (this.getScore(players[0], turmoil) > 0) {
                        players[0].decreaseTerraformRatingSteps(2, { log: true });
                    }
                    players.shift();
                }
            }
        }
    };
    Revolution.prototype.getScore = function (player, turmoil) {
        return player.getTagCount(Tags_1.Tags.EARTH, 'raw') + turmoil.getPlayerInfluence(player);
    };
    return Revolution;
}(IGlobalEvent_1.GlobalEvent));
exports.Revolution = Revolution;
