"use strict";
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.AddResourcesToCards = void 0;
var DeferredAction_1 = require("./DeferredAction");
var SelectAmount_1 = require("../inputs/SelectAmount");
var AndOptions_1 = require("../inputs/AndOptions");
var AddResourcesToCards = (function () {
    function AddResourcesToCards(player, resourceType, count) {
        this.player = player;
        this.resourceType = resourceType;
        this.count = count;
        this.priority = DeferredAction_1.Priority.GAIN_RESOURCE_OR_PRODUCTION;
    }
    AddResourcesToCards.prototype.execute = function () {
        var _this = this;
        if (this.count === 0) {
            return undefined;
        }
        var cards = this.player.getResourceCards(this.resourceType);
        if (cards.length === 0) {
            return undefined;
        }
        if (cards.length === 1) {
            this.player.addResourceTo(cards[0], { qty: this.count, log: true });
            return undefined;
        }
        var map = new Map();
        var options = cards.map(function (card) {
            var cb = function (amount) {
                map.set(card.name, amount);
                return undefined;
            };
            return new SelectAmount_1.SelectAmount(card.name, '', cb, 0, _this.count);
        });
        return new (AndOptions_1.AndOptions.bind.apply(AndOptions_1.AndOptions, __spreadArray([void 0, function () {
                var sum = 0;
                cards.forEach(function (card) {
                    var _a;
                    sum += (_a = map.get(card.name)) !== null && _a !== void 0 ? _a : 0;
                });
                if (sum !== _this.count) {
                    throw new Error("Expecting " + _this.count + " resources distributed, got " + sum + ".");
                }
                cards.forEach(function (card) {
                    var _a;
                    var amount = (_a = map.get(card.name)) !== null && _a !== void 0 ? _a : 0;
                    if (amount > 0) {
                        _this.player.addResourceTo(card, { qty: amount, log: true });
                    }
                });
                return undefined;
            }], options, false)))();
    };
    return AddResourcesToCards;
}());
exports.AddResourcesToCards = AddResourcesToCards;
