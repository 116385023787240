"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.Aridor = void 0;
var Tags_1 = require("../Tags");
var Resources_1 = require("../../common/Resources");
var CardType_1 = require("../CardType");
var CardName_1 = require("../../CardName");
var SelectColony_1 = require("../../inputs/SelectColony");
var Card_1 = require("../Card");
var CardRenderer_1 = require("../render/CardRenderer");
var Aridor = (function (_super) {
    __extends(Aridor, _super);
    function Aridor() {
        var _this = _super.call(this, {
            name: CardName_1.CardName.ARIDOR,
            startingMegaCredits: 40,
            cardType: CardType_1.CardType.CORPORATION,
            initialActionText: 'Add a colony tile',
            metadata: {
                cardNumber: 'R20',
                description: 'You start with 40 M€. As your first action, put an additional Colony Tile of your choice into play',
                renderData: CardRenderer_1.CardRenderer.builder(function (b) {
                    b.br.br;
                    b.megacredits(40).nbsp.placeColony();
                    b.corpBox('effect', function (ce) {
                        ce.effect('When you get a new type of tag in play [event cards do not count], increase your M€ production 1 step.', function (eb) {
                            eb.diverseTag().startEffect.production(function (pb) { return pb.megacredits(1); });
                        });
                    });
                }),
            },
        }) || this;
        _this.allTags = new Set();
        return _this;
    }
    Aridor.prototype.initialAction = function (player) {
        var _this = this;
        var game = player.game;
        if (game.colonyDealer === undefined || !game.gameOptions.coloniesExtension)
            return undefined;
        var availableColonies = game.colonyDealer.discardedColonies;
        if (availableColonies.length === 0)
            return undefined;
        var selectColony = new SelectColony_1.SelectColony('Aridor first action - Select colony tile to add', 'Add colony tile', availableColonies, function (colony) {
            if (availableColonies.includes(colony)) {
                game.colonies.push(colony);
                game.colonies.sort(function (a, b) { return (a.name > b.name) ? 1 : -1; });
                game.log('${0} added a new Colony tile: ${1}', function (b) { return b.player(player).colony(colony); });
                _this.checkActivation(colony, game);
            }
            else {
                throw new Error("Colony " + colony.name + " is not a discarded colony");
            }
            return undefined;
        });
        return selectColony;
    };
    Aridor.prototype.checkActivation = function (colony, game) {
        if (colony.resourceType === undefined)
            return;
        game.getPlayers().forEach(function (player) {
            if (player.corporationCard !== undefined && player.corporationCard.resourceType === colony.resourceType) {
                colony.isActive = true;
                return;
            }
            var resourceCard = player.playedCards.find(function (card) { return card.resourceType === colony.resourceType; });
            if (resourceCard !== undefined) {
                colony.isActive = true;
                return;
            }
        });
    };
    Aridor.prototype.onCardPlayed = function (player, card) {
        if (card.cardType === CardType_1.CardType.EVENT ||
            card.tags.filter(function (tag) { return tag !== Tags_1.Tags.WILDCARD; }).length === 0 ||
            !player.isCorporation(this.name)) {
            return undefined;
        }
        for (var _i = 0, _a = card.tags.filter(function (tag) { return tag !== Tags_1.Tags.WILDCARD; }); _i < _a.length; _i++) {
            var tag = _a[_i];
            var currentSize = this.allTags.size;
            this.allTags.add(tag);
            if (this.allTags.size > currentSize) {
                player.addProduction(Resources_1.Resources.MEGACREDITS, 1, { log: true });
            }
        }
        return undefined;
    };
    Aridor.prototype.play = function () {
        return undefined;
    };
    return Aridor;
}(Card_1.Card));
exports.Aridor = Aridor;
