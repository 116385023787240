"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.VolcanicEruptions = void 0;
var IGlobalEvent_1 = require("./IGlobalEvent");
var GlobalEventName_1 = require("./GlobalEventName");
var PartyName_1 = require("../parties/PartyName");
var Resources_1 = require("../../common/Resources");
var CardRenderer_1 = require("../../cards/render/CardRenderer");
var RENDER_DATA = CardRenderer_1.CardRenderer.builder(function (b) {
    b.temperature(2).nbsp.production(function (pb) { return pb.heat(1); }).slash().influence();
});
var VolcanicEruptions = (function (_super) {
    __extends(VolcanicEruptions, _super);
    function VolcanicEruptions() {
        return _super.call(this, {
            name: GlobalEventName_1.GlobalEventName.VOLCANIC_ERUPTIONS,
            description: 'Increase temperature 2 steps. Increase heat production 1 step per influence.',
            revealedDelegate: PartyName_1.PartyName.SCIENTISTS,
            currentDelegate: PartyName_1.PartyName.KELVINISTS,
            renderData: RENDER_DATA,
        }) || this;
    }
    VolcanicEruptions.prototype.resolve = function (game, turmoil) {
        var _this = this;
        game.increaseTemperature(game.getPlayers()[0], 2);
        game.getPlayers().forEach(function (player) {
            var amount = turmoil.getPlayerInfluence(player);
            if (amount > 0) {
                player.addProduction(Resources_1.Resources.HEAT, amount, { log: true, from: _this.name });
            }
        });
    };
    return VolcanicEruptions;
}(IGlobalEvent_1.GlobalEvent));
exports.VolcanicEruptions = VolcanicEruptions;
