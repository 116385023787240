"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.RobinsonIndustries = void 0;
var OrOptions_1 = require("../../inputs/OrOptions");
var SelectOption_1 = require("../../inputs/SelectOption");
var Resources_1 = require("../../common/Resources");
var Card_1 = require("../Card");
var CardName_1 = require("../../CardName");
var CardType_1 = require("../CardType");
var CardRenderer_1 = require("../render/CardRenderer");
var RobinsonIndustries = (function (_super) {
    __extends(RobinsonIndustries, _super);
    function RobinsonIndustries() {
        return _super.call(this, {
            cardType: CardType_1.CardType.CORPORATION,
            name: CardName_1.CardName.ROBINSON_INDUSTRIES,
            startingMegaCredits: 47,
            metadata: {
                cardNumber: 'R27',
                description: 'You start with 47 M€.',
                renderData: CardRenderer_1.CardRenderer.builder(function (b) {
                    b.br.br.br;
                    b.megacredits(47);
                    b.corpBox('action', function (ce) {
                        ce.action('Spend 4 M€ to increase (one of) your LOWEST production 1 step.', function (eb) {
                            eb.megacredits(4).startAction.production(function (pb) { return pb.wild(1).asterix(); });
                        });
                    });
                }),
            },
        }) || this;
    }
    RobinsonIndustries.prototype.play = function () {
        return undefined;
    };
    RobinsonIndustries.prototype.canAct = function (player) {
        return player.canAfford(4);
    };
    RobinsonIndustries.prototype.action = function (player) {
        var minimum = player.getProduction(Resources_1.Resources.MEGACREDITS);
        var lowest = [];
        [Resources_1.Resources.MEGACREDITS, Resources_1.Resources.STEEL, Resources_1.Resources.TITANIUM, Resources_1.Resources.PLANTS, Resources_1.Resources.ENERGY, Resources_1.Resources.HEAT].forEach(function (resource) {
            var option = new SelectOption_1.SelectOption('Increase ' + resource + ' production 1 step', 'Select', function () {
                player.deductResource(Resources_1.Resources.MEGACREDITS, 4);
                player.addProduction(resource, 1, { log: true });
                return undefined;
            });
            if (player.getProduction(resource) < minimum) {
                lowest = [];
                minimum = player.getProduction(resource);
            }
            if (player.getProduction(resource) === minimum)
                lowest.push(option);
        });
        var result = new OrOptions_1.OrOptions();
        result.options = lowest;
        return result;
    };
    return RobinsonIndustries;
}(Card_1.Card));
exports.RobinsonIndustries = RobinsonIndustries;
