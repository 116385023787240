"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.BeamFromAThoriumAsteroid = void 0;
var Tags_1 = require("../Tags");
var Card_1 = require("../Card");
var CardType_1 = require("../CardType");
var Resources_1 = require("../../common/Resources");
var CardName_1 = require("../../CardName");
var CardRequirements_1 = require("../CardRequirements");
var CardRenderer_1 = require("../render/CardRenderer");
var BeamFromAThoriumAsteroid = (function (_super) {
    __extends(BeamFromAThoriumAsteroid, _super);
    function BeamFromAThoriumAsteroid() {
        return _super.call(this, {
            cardType: CardType_1.CardType.AUTOMATED,
            name: CardName_1.CardName.BEAM_FROM_A_THORIUM_ASTEROID,
            tags: [Tags_1.Tags.JOVIAN, Tags_1.Tags.SPACE, Tags_1.Tags.ENERGY],
            cost: 32,
            victoryPoints: 1,
            requirements: CardRequirements_1.CardRequirements.builder(function (b) { return b.tag(Tags_1.Tags.JOVIAN); }),
            metadata: {
                cardNumber: '058',
                description: 'Requires a Jovian tag. Increase your heat production and Energy production 3 steps each',
                renderData: CardRenderer_1.CardRenderer.builder(function (b) {
                    b.production(function (pb) {
                        pb.heat(3).br;
                        pb.energy(3);
                    });
                }),
            },
        }) || this;
    }
    BeamFromAThoriumAsteroid.prototype.play = function (player) {
        player.addProduction(Resources_1.Resources.HEAT, 3);
        player.addProduction(Resources_1.Resources.ENERGY, 3);
        return undefined;
    };
    return BeamFromAThoriumAsteroid;
}(Card_1.Card));
exports.BeamFromAThoriumAsteroid = BeamFromAThoriumAsteroid;
