"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.MomentumViriumHabitat = void 0;
var CardName_1 = require("../../CardName");
var CardType_1 = require("../CardType");
var Tags_1 = require("../Tags");
var MoonExpansion_1 = require("../../moon/MoonExpansion");
var MoonSpaces_1 = require("../../moon/MoonSpaces");
var CardRenderer_1 = require("../render/CardRenderer");
var Units_1 = require("../../Units");
var TileType_1 = require("../../common/TileType");
var MoonCard_1 = require("./MoonCard");
var CardRenderItem_1 = require("../render/CardRenderItem");
var MomentumViriumHabitat = (function (_super) {
    __extends(MomentumViriumHabitat, _super);
    function MomentumViriumHabitat() {
        return _super.call(this, {
            name: CardName_1.CardName.MOMENTUM_VIRUM_HABITAT,
            cardType: CardType_1.CardType.AUTOMATED,
            tags: [Tags_1.Tags.CITY, Tags_1.Tags.SPACE],
            cost: 23,
            productionBox: Units_1.Units.of({ heat: 2, megacredits: 3 }),
            reserveUnits: Units_1.Units.of({ titanium: 1 }),
            tr: { moonColony: 1 },
            metadata: {
                description: 'Spend 1 titanium. Increase your heat production 2 steps and your M€ production 3 steps. ' +
                    'Place a colony tile ON THE RESERVED AREA and raise the Colony Rate 1 step.',
                cardNumber: 'M12',
                renderData: CardRenderer_1.CardRenderer.builder(function (b) {
                    b.minus().titanium(1).br;
                    b.production(function (pb) {
                        pb.heat(2).megacredits(3);
                    }).br;
                    b.moonColony({ secondaryTag: CardRenderItem_1.AltSecondaryTag.MOON_COLONY_RATE }).asterix();
                }),
            },
        }, {
            tilesBuilt: [TileType_1.TileType.MOON_COLONY],
        }) || this;
    }
    ;
    MomentumViriumHabitat.prototype.play = function (player) {
        _super.prototype.play.call(this, player);
        MoonExpansion_1.MoonExpansion.addColonyTile(player, MoonSpaces_1.MoonSpaces.MOMENTUM_VIRIUM, this.name);
        MoonExpansion_1.MoonExpansion.raiseColonyRate(player);
        return undefined;
    };
    return MomentumViriumHabitat;
}(MoonCard_1.MoonCard));
exports.MomentumViriumHabitat = MomentumViriumHabitat;
