"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.GREENS_POLICY_4 = exports.GREENS_POLICY_3 = exports.GREENS_POLICY_2 = exports.GREENS_POLICY_1 = exports.GREENS_BONUS_2 = exports.GREENS_BONUS_1 = exports.Greens = void 0;
var Party_1 = require("./Party");
var PartyName_1 = require("./PartyName");
var Tags_1 = require("../../cards/Tags");
var Resources_1 = require("../../common/Resources");
var TileType_1 = require("../../common/TileType");
var OrOptions_1 = require("../../inputs/OrOptions");
var SelectCard_1 = require("../../inputs/SelectCard");
var SelectOption_1 = require("../../inputs/SelectOption");
var ResourceType_1 = require("../../common/ResourceType");
var Phase_1 = require("../../Phase");
var SelectHowToPayDeferred_1 = require("../../deferredActions/SelectHowToPayDeferred");
var DeferredAction_1 = require("../../deferredActions/DeferredAction");
var constants_1 = require("../../constants");
var Greens = (function (_super) {
    __extends(Greens, _super);
    function Greens() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.name = PartyName_1.PartyName.GREENS;
        _this.description = 'Want to see a new Earth as soon as possible.';
        _this.bonuses = [exports.GREENS_BONUS_1, exports.GREENS_BONUS_2];
        _this.policies = [exports.GREENS_POLICY_1, exports.GREENS_POLICY_2, exports.GREENS_POLICY_3, exports.GREENS_POLICY_4];
        return _this;
    }
    return Greens;
}(Party_1.Party));
exports.Greens = Greens;
var GreensBonus01 = (function () {
    function GreensBonus01() {
        this.isDefault = true;
        this.id = 'gb01';
        this.description = 'Gain 1 M€ for each Plant, Microbe and Animal tag you have';
    }
    GreensBonus01.prototype.getScore = function (player) {
        return player.getTagCount(Tags_1.Tags.PLANT, 'raw') +
            player.getTagCount(Tags_1.Tags.MICROBE, 'raw') +
            player.getTagCount(Tags_1.Tags.ANIMAL, 'raw');
    };
    GreensBonus01.prototype.grant = function (game) {
        var _this = this;
        game.getPlayers().forEach(function (player) {
            player.addResource(Resources_1.Resources.MEGACREDITS, _this.getScore(player));
        });
    };
    return GreensBonus01;
}());
var GreensBonus02 = (function () {
    function GreensBonus02() {
        this.id = 'gb02';
        this.description = 'Gain 2 M€ for each greenery tile you have';
        this.isDefault = false;
    }
    GreensBonus02.prototype.getScore = function (player) {
        var boardSpaces = player.game.board.spaces;
        var count = boardSpaces.filter(function (space) { return space.tile && space.tile.tileType === TileType_1.TileType.GREENERY && space.player !== undefined && space.player.id === player.id; }).length;
        return count * 2;
    };
    GreensBonus02.prototype.grant = function (game) {
        var _this = this;
        game.getPlayers().forEach(function (player) {
            player.addResource(Resources_1.Resources.MEGACREDITS, _this.getScore(player));
        });
    };
    return GreensBonus02;
}());
var GreensPolicy01 = (function () {
    function GreensPolicy01() {
        this.isDefault = true;
        this.id = 'gp01';
        this.description = 'When you place a greenery tile, gain 4 M€';
    }
    GreensPolicy01.prototype.onTilePlaced = function (player, space) {
        var _a;
        if (((_a = space.tile) === null || _a === void 0 ? void 0 : _a.tileType) === TileType_1.TileType.GREENERY && player.game.phase === Phase_1.Phase.ACTION) {
            player.addResource(Resources_1.Resources.MEGACREDITS, 4);
        }
    };
    return GreensPolicy01;
}());
var GreensPolicy02 = (function () {
    function GreensPolicy02() {
        this.id = 'gp02';
        this.description = 'When you place a tile, gain 1 plant';
        this.isDefault = false;
    }
    GreensPolicy02.prototype.onTilePlaced = function (player) {
        player.addResource(Resources_1.Resources.PLANTS, 1);
    };
    return GreensPolicy02;
}());
var GreensPolicy03 = (function () {
    function GreensPolicy03() {
        this.id = 'gp03';
        this.description = 'When you play an animal, plant or microbe tag, gain 2 M€';
        this.isDefault = false;
    }
    GreensPolicy03.prototype.onCardPlayed = function (player, card) {
        var tags = [Tags_1.Tags.ANIMAL, Tags_1.Tags.PLANT, Tags_1.Tags.MICROBE];
        var tagCount = card.tags.filter(function (tag) { return tags.includes(tag); }).length;
        player.addResource(Resources_1.Resources.MEGACREDITS, tagCount * 2);
    };
    return GreensPolicy03;
}());
var GreensPolicy04 = (function () {
    function GreensPolicy04() {
        this.id = 'gp04';
        this.description = 'Spend 5 M€ to gain 3 plants or add 2 microbes to any card (Turmoil Greens)';
        this.isDefault = false;
    }
    GreensPolicy04.prototype.canAct = function (player) {
        return player.canAfford(5) && player.politicalAgendasActionUsedCount < constants_1.POLITICAL_AGENDAS_MAX_ACTION_USES;
    };
    GreensPolicy04.prototype.action = function (player) {
        var game = player.game;
        game.log('${0} used Turmoil Greens action', function (b) { return b.player(player); });
        player.politicalAgendasActionUsedCount += 1;
        game.defer(new SelectHowToPayDeferred_1.SelectHowToPayDeferred(player, 5, {
            title: 'Select how to pay for Turmoil Greens action',
            afterPay: function () {
                var availableMicrobeCards = player.getResourceCards(ResourceType_1.ResourceType.MICROBE);
                var orOptions = new OrOptions_1.OrOptions();
                if (availableMicrobeCards.length === 1) {
                    orOptions.options.push(new SelectOption_1.SelectOption('Add 2 microbes to ' + availableMicrobeCards[0].name, 'Confirm', function () {
                        player.addResourceTo(availableMicrobeCards[0], { qty: 2, log: true });
                        return undefined;
                    }));
                }
                else if (availableMicrobeCards.length > 1) {
                    orOptions.options.push(new SelectOption_1.SelectOption('Add 2 microbes to a card', 'Confirm', function () {
                        return new SelectCard_1.SelectCard('Select card to add 2 microbes', 'Add microbes', availableMicrobeCards, function (foundCards) {
                            player.addResourceTo(foundCards[0], { qty: 2, log: true });
                            return undefined;
                        });
                    }));
                }
                orOptions.options.push(new SelectOption_1.SelectOption('Gain 3 plants', 'Confirm', function () {
                    player.addResource(Resources_1.Resources.PLANTS, 3);
                    game.log('${0} gained 3 plants', function (b) { return b.player(player); });
                    return undefined;
                }));
                if (orOptions.options.length === 1)
                    return orOptions.options[0].cb();
                game.defer(new DeferredAction_1.DeferredAction(player, function () { return orOptions; }));
                return undefined;
            },
        }));
        return undefined;
    };
    return GreensPolicy04;
}());
exports.GREENS_BONUS_1 = new GreensBonus01();
exports.GREENS_BONUS_2 = new GreensBonus02();
exports.GREENS_POLICY_1 = new GreensPolicy01();
exports.GREENS_POLICY_2 = new GreensPolicy02();
exports.GREENS_POLICY_3 = new GreensPolicy03();
exports.GREENS_POLICY_4 = new GreensPolicy04();
