"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.ValuableGases = void 0;
var Tags_1 = require("../Tags");
var PreludeCard_1 = require("./../prelude/PreludeCard");
var CardName_1 = require("../../CardName");
var ResourceType_1 = require("../../common/ResourceType");
var CardRenderer_1 = require("../render/CardRenderer");
var Size_1 = require("../render/Size");
var CardRenderItem_1 = require("../render/CardRenderItem");
var Resources_1 = require("../../common/Resources");
var Options_1 = require("../Options");
var CardType_1 = require("../CardType");
var DeferredAction_1 = require("../../deferredActions/DeferredAction");
var SelectHowToPayForProjectCard_1 = require("../../inputs/SelectHowToPayForProjectCard");
var ValuableGases = (function (_super) {
    __extends(ValuableGases, _super);
    function ValuableGases() {
        return _super.call(this, {
            name: CardName_1.CardName.VALUABLE_GASES_PATHFINDERS,
            tags: [Tags_1.Tags.JOVIAN, Tags_1.Tags.VENUS],
            metadata: {
                cardNumber: '',
                renderData: CardRenderer_1.CardRenderer.builder(function (b) {
                    b.megacredits(10).br;
                    b.text('play', Size_1.Size.MEDIUM, true).cards(1, { secondaryTag: CardRenderItem_1.AltSecondaryTag.FLOATER }).asterix().br;
                    b.projectRequirements().br;
                    b.floaters(5, { digit: Options_1.digit });
                }),
                description: 'Gain 10 M€. Play an active floater card from hand, ignoring global requirements, and add 5 floaters to it.',
            },
        }) || this;
    }
    ValuableGases.prototype.getRequirementBonus = function (player) {
        if (player.lastCardPlayed === this.name) {
            return 50;
        }
        return 0;
    };
    ValuableGases.prototype.play = function (player) {
        player.addResource(Resources_1.Resources.MEGACREDITS, 10);
        var playableCards = player.cardsInHand.filter(function (card) {
            return card.resourceType === ResourceType_1.ResourceType.FLOATER && card.cardType === CardType_1.CardType.ACTIVE;
        });
        if (playableCards.length !== 0) {
            player.game.defer(new DeferredAction_1.DeferredAction(player, function () {
                return new SelectHowToPayForProjectCard_1.SelectHowToPayForProjectCard(player, playableCards, function (selectedCard, howToPay) { return player.checkHowToPayAndPlayCard(selectedCard, howToPay); });
            }));
        }
        return undefined;
    };
    ValuableGases.prototype.onCardPlayed = function (player, card) {
        var playedCards = player.playedCards;
        if (playedCards.length > 1 && playedCards[playedCards.length - 2].name === this.name) {
            player.addResourceTo(card, 5);
        }
        return undefined;
    };
    return ValuableGases;
}(PreludeCard_1.PreludeCard));
exports.ValuableGases = ValuableGases;
