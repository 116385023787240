"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.PharmacyUnion = void 0;
var Tags_1 = require("../Tags");
var Card_1 = require("../Card");
var CardName_1 = require("../../CardName");
var ResourceType_1 = require("../../common/ResourceType");
var SelectOption_1 = require("../../inputs/SelectOption");
var OrOptions_1 = require("../../inputs/OrOptions");
var CardType_1 = require("../CardType");
var DeferredAction_1 = require("../../deferredActions/DeferredAction");
var CardRenderer_1 = require("../render/CardRenderer");
var Size_1 = require("../render/Size");
var Resources_1 = require("../../common/Resources");
var Options_1 = require("../Options");
var PharmacyUnion = (function (_super) {
    __extends(PharmacyUnion, _super);
    function PharmacyUnion() {
        var _this = _super.call(this, {
            cardType: CardType_1.CardType.CORPORATION,
            name: CardName_1.CardName.PHARMACY_UNION,
            startingMegaCredits: 46,
            resourceType: ResourceType_1.ResourceType.DISEASE,
            metadata: {
                cardNumber: 'R39',
                renderData: CardRenderer_1.CardRenderer.builder(function (b) {
                    b.megacredits(54).cards(1, { secondaryTag: Tags_1.Tags.SCIENCE });
                    b.text('(You start with 54 M€ . Draw a Science card.)', Size_1.Size.TINY, false, false);
                    b.corpBox('effect', function (ce) {
                        ce.vSpace(Size_1.Size.LARGE);
                        ce.effect(undefined, function (eb) {
                            eb.microbes(1, { all: Options_1.all, played: Options_1.played }).startEffect.disease().megacredits(-4);
                        });
                        ce.vSpace();
                        ce.effect('When ANY microbe tag is played, add a disease here and lose 4 M€ or as much as possible. When you play a science tag, remove a disease here and gain 1 TR OR if there are no diseases here, you MAY put this card face down in your EVENTS PILE to gain 3 TR.', function (eb) {
                            eb.science(1, { played: Options_1.played }).startEffect.minus().disease();
                            eb.tr(1, { size: Size_1.Size.SMALL }).slash().tr(3, { size: Size_1.Size.SMALL, digit: Options_1.digit });
                        });
                    });
                }),
            },
        }) || this;
        _this.resourceCount = 0;
        _this.isDisabled = false;
        return _this;
    }
    Object.defineProperty(PharmacyUnion.prototype, "tags", {
        get: function () {
            if (this.isDisabled) {
                return [];
            }
            return [Tags_1.Tags.MICROBE, Tags_1.Tags.MICROBE];
        },
        enumerable: false,
        configurable: true
    });
    PharmacyUnion.prototype.play = function (player) {
        this.resourceCount = 2;
        player.drawCard(1, { tag: Tags_1.Tags.SCIENCE });
        return undefined;
    };
    PharmacyUnion.prototype.onCardPlayed = function (player, card) {
        this._onCardPlayed(player, card);
    };
    PharmacyUnion.prototype.onCorpCardPlayed = function (player, card) {
        return this._onCardPlayed(player, card);
    };
    PharmacyUnion.prototype._onCardPlayed = function (player, card) {
        var _this = this;
        if (this.isDisabled)
            return undefined;
        var game = player.game;
        var hasScienceTag = card.tags.includes(Tags_1.Tags.SCIENCE);
        var hasMicrobesTag = card.tags.includes(Tags_1.Tags.MICROBE);
        var isPharmacyUnion = player.isCorporation(CardName_1.CardName.PHARMACY_UNION);
        if (isPharmacyUnion && hasScienceTag && hasMicrobesTag && this.resourceCount === 0) {
            if (player.canAfford(0, { tr: { tr: 3 } })) {
                game.defer(new DeferredAction_1.DeferredAction(player, function () {
                    var orOptions = new OrOptions_1.OrOptions(new SelectOption_1.SelectOption('Turn it face down to gain 3 TR and lose up to 4 M€', 'Confirm', function () {
                        var megaCreditsLost = Math.min(player.megaCredits, 4);
                        _this.isDisabled = true;
                        player.increaseTerraformRatingSteps(3);
                        player.deductResource(Resources_1.Resources.MEGACREDITS, megaCreditsLost);
                        game.log('${0} turned ${1} face down to gain 3 TR and lost ${2} M€', function (b) { return b.player(player).card(_this).number(megaCreditsLost); });
                        return undefined;
                    }), new SelectOption_1.SelectOption('Add a disease to it and lose up to 4 M€, then remove a disease to gain 1 TR', 'Confirm', function () {
                        var megaCreditsLost = Math.min(player.megaCredits, 4);
                        player.increaseTerraformRating();
                        player.megaCredits -= megaCreditsLost;
                        game.log('${0} added a disease to ${1} and lost ${2} M€', function (b) { return b.player(player).card(_this).number(megaCreditsLost); });
                        game.log('${0} removed a disease from ${1} to gain 1 TR', function (b) { return b.player(player).card(_this); });
                        return undefined;
                    }));
                    orOptions.title = 'Choose the order of tag resolution for Pharmacy Union';
                    return orOptions;
                }), -1);
                return undefined;
            }
        }
        if (isPharmacyUnion && hasScienceTag) {
            var scienceTags = card.tags.filter(function (tag) { return tag === Tags_1.Tags.SCIENCE; }).length;
            for (var i = 0; i < scienceTags; i++) {
                game.defer(new DeferredAction_1.DeferredAction(player, function () {
                    if (_this.isDisabled)
                        return undefined;
                    if (_this.resourceCount > 0) {
                        if (player.canAfford(0, { tr: { tr: 1 } }) === false) {
                            game.log('${0} cannot remove a disease from ${1} to gain 1 TR because of unaffordable Reds policy cost', function (b) { return b.player(player).card(_this); });
                        }
                        else {
                            _this.resourceCount--;
                            player.increaseTerraformRating();
                            game.log('${0} removed a disease from ${1} to gain 1 TR', function (b) { return b.player(player).card(_this); });
                        }
                        return undefined;
                    }
                    if (player.canAfford(0, { tr: { tr: 3 } }) === false) {
                        game.log('${0} cannot turn ${1} face down to gain 3 TR because of unaffordable Reds policy cost', function (b) { return b.player(player).card(_this); });
                        return undefined;
                    }
                    return new OrOptions_1.OrOptions(new SelectOption_1.SelectOption('Turn this card face down and gain 3 TR', 'Gain TR', function () {
                        _this.isDisabled = true;
                        player.increaseTerraformRatingSteps(3);
                        game.log('${0} turned ${1} face down to gain 3 TR', function (b) { return b.player(player).card(_this); });
                        return undefined;
                    }), new SelectOption_1.SelectOption('Do nothing', 'Confirm', function () {
                        return undefined;
                    }));
                }), -1);
            }
        }
        if (hasMicrobesTag) {
            game.defer(new DeferredAction_1.DeferredAction(player, function () {
                var microbeTagCount = card.tags.filter(function (cardTag) { return cardTag === Tags_1.Tags.MICROBE; }).length;
                var player = game.getPlayers().find(function (p) { return p.isCorporation(_this.name); });
                var megaCreditsLost = Math.min(player.megaCredits, microbeTagCount * 4);
                player.addResourceTo(_this, microbeTagCount);
                player.megaCredits -= megaCreditsLost;
                game.log('${0} added a disease to ${1} and lost ${2} M€', function (b) { return b.player(player).card(_this).number(megaCreditsLost); });
                return undefined;
            }), -1);
        }
        return undefined;
    };
    return PharmacyUnion;
}(Card_1.Card));
exports.PharmacyUnion = PharmacyUnion;
