"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SelectColony = void 0;
var PlayerInputTypes_1 = require("../PlayerInputTypes");
var SelectColony = (function () {
    function SelectColony(title, buttonLabel, colonies, cb) {
        if (buttonLabel === void 0) { buttonLabel = 'Save'; }
        this.title = title;
        this.buttonLabel = buttonLabel;
        this.colonies = colonies;
        this.cb = cb;
        this.inputType = PlayerInputTypes_1.PlayerInputTypes.SELECT_COLONY;
        this.buttonLabel = buttonLabel;
    }
    return SelectColony;
}());
exports.SelectColony = SelectColony;
