"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.PreludeCard = void 0;
var Card_1 = require("../Card");
var CardType_1 = require("../CardType");
var PreludeCard = (function (_super) {
    __extends(PreludeCard, _super);
    function PreludeCard(properties) {
        return _super.call(this, {
            cardType: CardType_1.CardType.PRELUDE,
            name: properties.name,
            tags: properties.tags,
            metadata: properties.metadata,
            productionBox: properties.productionBox,
            startingMegaCredits: properties.startingMegacredits,
        }) || this;
    }
    PreludeCard.prototype.canPlay = function (_player) {
        return true;
    };
    return PreludeCard;
}(Card_1.Card));
exports.PreludeCard = PreludeCard;
