"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.LunaArchives = void 0;
var CardName_1 = require("../../CardName");
var CardType_1 = require("../CardType");
var Tags_1 = require("../Tags");
var CardRenderer_1 = require("../render/CardRenderer");
var Card_1 = require("../Card");
var ResourceType_1 = require("../../common/ResourceType");
var Options_1 = require("../Options");
var LunaArchives = (function (_super) {
    __extends(LunaArchives, _super);
    function LunaArchives() {
        var _this = _super.call(this, {
            name: CardName_1.CardName.LUNA_ARCHIVES,
            cardType: CardType_1.CardType.ACTIVE,
            tags: [Tags_1.Tags.SCIENCE, Tags_1.Tags.MOON],
            cost: 13,
            resourceType: ResourceType_1.ResourceType.SCIENCE,
            metadata: {
                cardNumber: 'M70',
                renderData: CardRenderer_1.CardRenderer.builder(function (b) {
                    b.action('Add 1 science resource here for each Moon tag you have.', function (ab) { return ab.empty().startAction.science().slash().moon(); });
                    b.br;
                    b.effect('When playing a Moon tag, Science resources here may be used as payment, and are worth 1M€ each.', function (eb) { return eb.moon(1, { played: Options_1.played }).startEffect.science().equals().megacredits(1); });
                }),
            },
        }) || this;
        _this.resourceCount = 0;
        return _this;
    }
    ;
    LunaArchives.prototype.canAct = function () {
        return true;
    };
    LunaArchives.prototype.action = function (player) {
        var qty = player.getTagCount(Tags_1.Tags.MOON);
        player.addResourceTo(this, { qty: qty, log: true });
        return undefined;
    };
    LunaArchives.prototype.play = function () {
        return undefined;
    };
    return LunaArchives;
}(Card_1.Card));
exports.LunaArchives = LunaArchives;
