"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.SecretLabs = void 0;
var Card_1 = require("../Card");
var CardType_1 = require("../CardType");
var CardName_1 = require("../../CardName");
var CardRenderer_1 = require("../render/CardRenderer");
var Tags_1 = require("../Tags");
var CardRequirements_1 = require("../CardRequirements");
var OrOptions_1 = require("../../inputs/OrOptions");
var SelectOption_1 = require("../../inputs/SelectOption");
var PlaceOceanTile_1 = require("../../deferredActions/PlaceOceanTile");
var AddResourcesToCard_1 = require("../../deferredActions/AddResourcesToCard");
var Resources_1 = require("../../common/Resources");
var ResourceType_1 = require("../../common/ResourceType");
var SecretLabs = (function (_super) {
    __extends(SecretLabs, _super);
    function SecretLabs() {
        return _super.call(this, {
            cardType: CardType_1.CardType.AUTOMATED,
            name: CardName_1.CardName.SECRET_LABS,
            cost: 21,
            tags: [Tags_1.Tags.JOVIAN, Tags_1.Tags.BUILDING, Tags_1.Tags.SPACE],
            requirements: CardRequirements_1.CardRequirements.builder(function (b) { return b.tag(Tags_1.Tags.SCIENCE).tag(Tags_1.Tags.JOVIAN); }),
            victoryPoints: 1,
            metadata: {
                cardNumber: 'Pf26',
                renderData: CardRenderer_1.CardRenderer.builder(function (b) {
                    b.oceans(1).microbes(2).or().temperature(1).br;
                    b.plants(2).or().oxygen(1).floaters(2).br;
                }),
                description: 'Requires 1 Science tag and 1 Jovian tag. ' +
                    'Place an ocean tile. Add 2 microbes on any card. ' +
                    'OR Raise temperature 1 step. Gain 3 plants. ' +
                    'OR Raise oxygen level 1 step. Add 2 floaters on any card.',
            },
        }) || this;
    }
    SecretLabs.prototype.canAfford = function (player, tr, megacrdits) {
        if (megacrdits === void 0) { megacrdits = this.cost; }
        return player.canAfford(megacrdits, { steel: true, titanium: true, tr: tr });
    };
    ;
    SecretLabs.prototype.canPlay = function (player) {
        return this.canAfford(player, { oceans: 1 }) || this.canAfford(player, { temperature: 1 }) || this.canAfford(player, { oxygen: 1 });
    };
    SecretLabs.prototype.play = function (player) {
        var options = new OrOptions_1.OrOptions();
        if (this.canAfford(player, { oceans: 1 }, 0)) {
            options.options.push(new SelectOption_1.SelectOption('Place an ocean tile. Add 2 microbes on any card.', 'select', function () {
                player.game.defer(new PlaceOceanTile_1.PlaceOceanTile(player));
                player.game.defer(new AddResourcesToCard_1.AddResourcesToCard(player, ResourceType_1.ResourceType.MICROBE, { count: 2 }));
                return undefined;
            }));
        }
        if (this.canAfford(player, { temperature: 1 }, 0)) {
            options.options.push(new SelectOption_1.SelectOption('Raise temperature 1 step. Gain 3 plants.', 'select', function () {
                player.game.increaseTemperature(player, 1);
                player.addResource(Resources_1.Resources.PLANTS, 3, { log: true });
                return undefined;
            }));
        }
        if (this.canAfford(player, { oxygen: 1 }, 0)) {
            options.options.push(new SelectOption_1.SelectOption('Raise oxygen level 1 step. Add 2 floaters on any card.', 'select', function () {
                player.game.increaseOxygenLevel(player, 1);
                player.game.defer(new AddResourcesToCard_1.AddResourcesToCard(player, ResourceType_1.ResourceType.FLOATER, { count: 2 }));
                return undefined;
            }));
        }
        return options;
    };
    return SecretLabs;
}(Card_1.Card));
exports.SecretLabs = SecretLabs;
