"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.LunarEmbassy = void 0;
var Tags_1 = require("../Tags");
var Card_1 = require("../Card");
var CardType_1 = require("../CardType");
var SpaceName_1 = require("../../SpaceName");
var SpaceType_1 = require("../../SpaceType");
var CardName_1 = require("../../CardName");
var CardRenderer_1 = require("../render/CardRenderer");
var Resources_1 = require("../../common/Resources");
var Options_1 = require("../Options");
var LunarEmbassy = (function (_super) {
    __extends(LunarEmbassy, _super);
    function LunarEmbassy() {
        return _super.call(this, {
            cardType: CardType_1.CardType.AUTOMATED,
            name: CardName_1.CardName.LUNAR_EMBASSY,
            tags: [Tags_1.Tags.EARTH, Tags_1.Tags.MARS, Tags_1.Tags.CITY, Tags_1.Tags.SPACE],
            cost: 28,
            victoryPoints: 2,
            metadata: {
                cardNumber: 'Pf16',
                renderData: CardRenderer_1.CardRenderer.builder(function (b) {
                    b.production(function (pb) { return pb.megacredits(3); })
                        .production(function (pb) { return pb.plants(1).slash().earth(2, { played: Options_1.played }); })
                        .br
                        .cards(1).city().asterix().br;
                }),
                description: 'Increase your M€ production 2 steps, and plant production 1 step for every 2 earth tags (including this.) ' +
                    'Draw a card. Place a city tile ON THE RESERVED AREA.',
            },
        }) || this;
    }
    LunarEmbassy.prototype.play = function (player) {
        player.addProduction(Resources_1.Resources.MEGACREDITS, 3);
        player.addProduction(Resources_1.Resources.PLANTS, Math.floor((1 + player.getTagCount(Tags_1.Tags.EARTH)) / 2), { log: true });
        player.drawCard();
        player.game.addCityTile(player, SpaceName_1.SpaceName.LUNAR_EMBASSY, SpaceType_1.SpaceType.COLONY);
        return undefined;
    };
    return LunarEmbassy;
}(Card_1.Card));
exports.LunarEmbassy = LunarEmbassy;
