"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.OceanFarm = void 0;
var Card_1 = require("../Card");
var CardName_1 = require("../../CardName");
var SelectSpace_1 = require("../../inputs/SelectSpace");
var Resources_1 = require("../../common/Resources");
var SpaceBonus_1 = require("../../SpaceBonus");
var TileType_1 = require("../../common/TileType");
var CardType_1 = require("./../CardType");
var Tags_1 = require("./../Tags");
var CardRequirements_1 = require("../CardRequirements");
var CardRenderer_1 = require("../render/CardRenderer");
var Units_1 = require("../../Units");
var OceanFarm = (function (_super) {
    __extends(OceanFarm, _super);
    function OceanFarm() {
        return _super.call(this, {
            cardType: CardType_1.CardType.AUTOMATED,
            name: CardName_1.CardName.OCEAN_FARM,
            tags: [Tags_1.Tags.PLANT, Tags_1.Tags.BUILDING],
            cost: 15,
            productionBox: Units_1.Units.of({ plants: 1, heat: 1 }),
            requirements: CardRequirements_1.CardRequirements.builder(function (b) { return b.oceans(4); }),
            metadata: {
                cardNumber: 'A21',
                renderData: CardRenderer_1.CardRenderer.builder(function (b) {
                    b.production(function (pb) {
                        pb.heat(1).br;
                        pb.plants(1);
                    }).nbsp.tile(TileType_1.TileType.OCEAN_FARM, false, true);
                }),
                description: 'Requires 4 ocean tiles. Increase your heat production 1 step and increase your plant production 1 step. Place this tile on top of an existing ocean tile. The tile grants an ADJACENCY BONUS of 1 plant.',
            },
        }) || this;
    }
    OceanFarm.prototype.play = function (player) {
        var _this = this;
        player.addProduction(Resources_1.Resources.HEAT, 1);
        player.addProduction(Resources_1.Resources.PLANTS, 1);
        return new SelectSpace_1.SelectSpace('Select space for Ocean Farm', player.game.board.getOceansTiles(false), function (space) {
            var tile = {
                tileType: TileType_1.TileType.OCEAN_FARM,
                card: _this.name,
                covers: space.tile,
            };
            player.game.addTile(player, space.spaceType, space, tile);
            space.adjacency = { bonus: [SpaceBonus_1.SpaceBonus.PLANT] };
            return undefined;
        });
    };
    return OceanFarm;
}(Card_1.Card));
exports.OceanFarm = OceanFarm;
