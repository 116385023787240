"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.ExtremeColdFungus = void 0;
var Tags_1 = require("../Tags");
var Card_1 = require("../Card");
var CardType_1 = require("../CardType");
var OrOptions_1 = require("../../inputs/OrOptions");
var SelectOption_1 = require("../../inputs/SelectOption");
var SelectCard_1 = require("../../inputs/SelectCard");
var ResourceType_1 = require("../../common/ResourceType");
var CardName_1 = require("../../CardName");
var Resources_1 = require("../../common/Resources");
var CardRequirements_1 = require("../CardRequirements");
var CardRenderer_1 = require("../render/CardRenderer");
var Options_1 = require("../Options");
var ExtremeColdFungus = (function (_super) {
    __extends(ExtremeColdFungus, _super);
    function ExtremeColdFungus() {
        return _super.call(this, {
            cardType: CardType_1.CardType.ACTIVE,
            name: CardName_1.CardName.EXTREME_COLD_FUNGUS,
            tags: [Tags_1.Tags.MICROBE],
            cost: 13,
            requirements: CardRequirements_1.CardRequirements.builder(function (b) { return b.temperature(-10, { max: Options_1.max }); }),
            metadata: {
                cardNumber: '134',
                description: 'It must be -10 C or colder.',
                renderData: CardRenderer_1.CardRenderer.builder(function (b) {
                    b.action('Gain 1 plant.', function (eb) {
                        eb.empty().startAction.plants(1);
                    }).br;
                    b.or().br;
                    b.action('Add 2 microbes to ANOTHER card.', function (eb) {
                        eb.empty().startAction.microbes(2).asterix();
                    });
                }),
            },
        }) || this;
    }
    ExtremeColdFungus.prototype.play = function () {
        return undefined;
    };
    ExtremeColdFungus.prototype.canAct = function () {
        return true;
    };
    ExtremeColdFungus.prototype.action = function (player) {
        var otherMicrobeCards = player.getResourceCards(ResourceType_1.ResourceType.MICROBE);
        if (otherMicrobeCards.length === 0) {
            player.addResource(Resources_1.Resources.PLANTS, 1, { log: true });
            return undefined;
        }
        var gainPlantOption = new SelectOption_1.SelectOption('Gain 1 plant', 'Gain plant', function () {
            player.addResource(Resources_1.Resources.PLANTS, 1, { log: true });
            return undefined;
        });
        if (otherMicrobeCards.length === 1) {
            var targetCard_1 = otherMicrobeCards[0];
            return new OrOptions_1.OrOptions(new SelectOption_1.SelectOption('Add 2 microbes to ' + targetCard_1.name, 'Add microbes', function () {
                player.addResourceTo(targetCard_1, { qty: 2, log: true });
                return undefined;
            }), gainPlantOption);
        }
        return new OrOptions_1.OrOptions(new SelectCard_1.SelectCard('Select card to add 2 microbes', 'Add microbes', otherMicrobeCards, function (foundCards) {
            player.addResourceTo(foundCards[0], { qty: 2, log: true });
            return undefined;
        }), gainPlantOption);
    };
    return ExtremeColdFungus;
}(Card_1.Card));
exports.ExtremeColdFungus = ExtremeColdFungus;
