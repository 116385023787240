"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SellSteel = void 0;
var DeferredAction_1 = require("../deferredActions/DeferredAction");
var SelectAmount_1 = require("../inputs/SelectAmount");
var Resources_1 = require("../common/Resources");
var SellSteel = (function () {
    function SellSteel(player, title) {
        if (title === void 0) { title = 'Sell your steel for 3M€ each.'; }
        this.player = player;
        this.title = title;
        this.priority = DeferredAction_1.Priority.DEFAULT;
    }
    SellSteel.prototype.logSale = function (unitsSold) {
        var _this = this;
        this.player.game.log('${0} sold ${1} steel', function (b) { return b.player(_this.player).number(unitsSold); });
    };
    SellSteel.prototype.execute = function () {
        var _this = this;
        var unitsAvailable = this.player.steel;
        if (unitsAvailable <= 0) {
            this.logSale(0);
            return undefined;
        }
        return new SelectAmount_1.SelectAmount('Select a number of units of steel to sell', 'Sell steel', function (unitsSold) {
            if (unitsSold > 0) {
                var cashEarned = unitsSold * 3;
                _this.player.addResource(Resources_1.Resources.MEGACREDITS, cashEarned);
                _this.player.deductResource(Resources_1.Resources.STEEL, unitsSold);
            }
            _this.logSale(unitsSold);
            return undefined;
        }, 0, unitsAvailable);
    };
    return SellSteel;
}());
exports.SellSteel = SellSteel;
