"use strict";
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
var vue_1 = require("vue");
var CardName_1 = require("@/CardName");
var GameModule_1 = require("@/GameModule");
var ClientCardManifest_1 = require("@/client/cards/ClientCardManifest");
var CardType_1 = require("@/cards/CardType");
var mnemonist_1 = require("mnemonist");
function corpCardNames(module) {
    return (0, ClientCardManifest_1.getCards)((0, ClientCardManifest_1.byModule)(module))
        .filter((0, ClientCardManifest_1.byType)(CardType_1.CardType.CORPORATION))
        .map(ClientCardManifest_1.toName)
        .filter(function (name) { return name !== CardName_1.CardName.BEGINNER_CORPORATION; });
}
exports.default = vue_1.default.extend({
    name: 'CorporationsFilter',
    props: {
        corporateEra: {
            type: Boolean,
        },
        prelude: {
            type: Boolean,
        },
        venusNext: {
            type: Boolean,
        },
        colonies: {
            type: Boolean,
        },
        turmoil: {
            type: Boolean,
        },
        promoCardsOption: {
            type: Boolean,
        },
        communityCardsOption: {
            type: Boolean,
        },
        moonExpansion: {
            type: Boolean,
        },
        pathfindersExpansion: {
            type: Boolean,
        },
    },
    data: function () {
        var cardsByModule = new mnemonist_1.MultiMap();
        (0, ClientCardManifest_1.getCards)((0, ClientCardManifest_1.byType)(CardType_1.CardType.CORPORATION)).forEach(function (cam) {
            if (cam.card.name !== CardName_1.CardName.BEGINNER_CORPORATION) {
                cardsByModule.set(cam.module, cam.card.name);
            }
        });
        return {
            cardsByModule: cardsByModule,
            customCorporationsList: false,
            selectedCorporations: __spreadArray(__spreadArray(__spreadArray(__spreadArray(__spreadArray(__spreadArray(__spreadArray(__spreadArray(__spreadArray(__spreadArray([], corpCardNames(GameModule_1.GameModule.Base), true), this.corporateEra ? corpCardNames(GameModule_1.GameModule.CorpEra) : [], true), this.prelude ? corpCardNames(GameModule_1.GameModule.Prelude) : [], true), this.venusNext ? corpCardNames(GameModule_1.GameModule.Venus) : [], true), this.colonies ? corpCardNames(GameModule_1.GameModule.Colonies) : [], true), this.turmoil ? corpCardNames(GameModule_1.GameModule.Turmoil) : [], true), this.promoCardsOption ? corpCardNames(GameModule_1.GameModule.Promo) : [], true), this.communityCardsOption ? corpCardNames(GameModule_1.GameModule.Community) : [], true), this.moonExpansion ? corpCardNames(GameModule_1.GameModule.Moon) : [], true), this.pathfindersExpansion ? corpCardNames(GameModule_1.GameModule.Pathfinders) : [], true),
        };
    },
    methods: {
        getSelected: function () {
            if (Array.isArray(this.selectedCorporations)) {
                return this.selectedCorporations;
            }
            console.warn('unexpectedly got boolean for selectedCorporations');
            return [];
        },
        getItemsByGroup: function (group) {
            if (group === 'All')
                return Array.from(this.cardsByModule.values());
            var corps = this.cardsByModule.get(group);
            if (corps === undefined) {
                console.log('module %s not found', group);
                return [];
            }
            else {
                return corps.slice();
            }
        },
        selectAll: function (group) {
            var items = this.getItemsByGroup(group);
            for (var _i = 0, items_1 = items; _i < items_1.length; _i++) {
                var item = items_1[_i];
                if (this.getSelected().includes(item) === false) {
                    this.getSelected().push(item);
                }
            }
        },
        removeFromSelection: function (cardName) {
            var itemIdx = this.getSelected().indexOf(cardName);
            if (itemIdx !== -1) {
                this.getSelected().splice(itemIdx, 1);
            }
        },
        selectNone: function (group) {
            var items = this.getItemsByGroup(group);
            for (var _i = 0, items_2 = items; _i < items_2.length; _i++) {
                var item = items_2[_i];
                this.removeFromSelection(item);
            }
        },
        invertSelection: function (group) {
            var items = this.getItemsByGroup(group);
            for (var idx in items) {
                if (this.getSelected().includes(items[idx])) {
                    this.removeFromSelection(items[idx]);
                }
                else {
                    this.getSelected().push(items[idx]);
                }
            }
        },
    },
    watch: {
        selectedCorporations: function (value) {
            this.$emit('corporation-list-changed', value);
        },
        corporateEra: function (enabled) {
            enabled ? this.selectAll(GameModule_1.GameModule.CorpEra) : this.selectNone(GameModule_1.GameModule.CorpEra);
        },
        prelude: function (enabled) {
            enabled ? this.selectAll(GameModule_1.GameModule.Prelude) : this.selectNone(GameModule_1.GameModule.Prelude);
        },
        venusNext: function (enabled) {
            enabled ? this.selectAll(GameModule_1.GameModule.Venus) : this.selectNone(GameModule_1.GameModule.Venus);
        },
        colonies: function (enabled) {
            enabled ? this.selectAll(GameModule_1.GameModule.Colonies) : this.selectNone(GameModule_1.GameModule.Colonies);
        },
        turmoil: function (enabled) {
            enabled ? this.selectAll(GameModule_1.GameModule.Turmoil) : this.selectNone(GameModule_1.GameModule.Turmoil);
        },
        promoCardsOption: function (enabled) {
            enabled ? this.selectAll(GameModule_1.GameModule.Promo) : this.selectNone(GameModule_1.GameModule.Promo);
        },
        communityCardsOption: function (enabled) {
            enabled ? this.selectAll(GameModule_1.GameModule.Community) : this.selectNone(GameModule_1.GameModule.Community);
        },
        moonExpansion: function (enabled) {
            enabled ? this.selectAll(GameModule_1.GameModule.Moon) : this.selectNone(GameModule_1.GameModule.Moon);
        },
    },
});
