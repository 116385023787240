"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.ProcessorFactory = void 0;
var CardName_1 = require("../../CardName");
var CardType_1 = require("../CardType");
var Tags_1 = require("../Tags");
var ResourceType_1 = require("../../common/ResourceType");
var AddResourcesToCard_1 = require("../../deferredActions/AddResourcesToCard");
var CardRenderer_1 = require("../render/CardRenderer");
var Card_1 = require("../Card");
var ICard_1 = require("../ICard");
var ProcessorFactory = (function (_super) {
    __extends(ProcessorFactory, _super);
    function ProcessorFactory() {
        var _this = _super.call(this, {
            name: CardName_1.CardName.PROCESSOR_FACTORY,
            cardType: CardType_1.CardType.ACTIVE,
            tags: [Tags_1.Tags.MOON, Tags_1.Tags.BUILDING],
            cost: 8,
            resourceType: ResourceType_1.ResourceType.DATA,
            victoryPoints: ICard_1.VictoryPoints.resource(1, 3),
            metadata: {
                cardNumber: 'M86',
                renderData: CardRenderer_1.CardRenderer.builder(function (b) {
                    b.action('Spend 1 Steel to add 2 Data resources to any card.', function (eb) { return eb.startAction.steel(1).arrow().data().data(); });
                    b.br;
                    b.vpText('1 VP for every 3 data resources here.');
                }),
            },
        }) || this;
        _this.resourceCount = 0;
        return _this;
    }
    ;
    ProcessorFactory.prototype.play = function () {
        return undefined;
    };
    ProcessorFactory.prototype.canAct = function (player) {
        return player.steel > 0;
    };
    ProcessorFactory.prototype.action = function (player) {
        player.steel--;
        player.game.defer(new AddResourcesToCard_1.AddResourcesToCard(player, ResourceType_1.ResourceType.DATA, { count: 2 }));
        return undefined;
    };
    return ProcessorFactory;
}(Card_1.Card));
exports.ProcessorFactory = ProcessorFactory;
