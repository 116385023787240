"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.TheArchaicFoundationInstitute = void 0;
var CardName_1 = require("../../CardName");
var CardType_1 = require("../CardType");
var Tags_1 = require("../Tags");
var ResourceType_1 = require("../../common/ResourceType");
var CardRenderer_1 = require("../render/CardRenderer");
var Card_1 = require("../Card");
var TheArchaicFoundationInstitute = (function (_super) {
    __extends(TheArchaicFoundationInstitute, _super);
    function TheArchaicFoundationInstitute() {
        var _this = _super.call(this, {
            cardType: CardType_1.CardType.CORPORATION,
            name: CardName_1.CardName.THE_ARCHAIC_FOUNDATION_INSTITUTE,
            tags: [Tags_1.Tags.MOON, Tags_1.Tags.MOON],
            startingMegaCredits: 55,
            resourceType: ResourceType_1.ResourceType.RESOURCE_CUBE,
            metadata: {
                description: 'You start with 55 M€.',
                cardNumber: '',
                renderData: CardRenderer_1.CardRenderer.builder(function (b) {
                    b.megacredits(55).br;
                    b.effect('When you play a Moon tag, including these, add a resource cube on this card.', function (eb) {
                        eb.moon().startEffect.resourceCube();
                    }).br;
                    b.effect('Automatically remove every 3 resource cubes collected here and increase your TR 1 step.', function (eb) {
                        eb.resourceCube(3).startEffect.tr(1);
                    });
                }),
            },
        }) || this;
        _this.resourceCount = 0;
        return _this;
    }
    TheArchaicFoundationInstitute.prototype.play = function () {
        this.resourceCount += 2;
        return undefined;
    };
    TheArchaicFoundationInstitute.prototype.onCardPlayed = function (player, card) {
        var _a;
        if (((_a = player.corporationCard) === null || _a === void 0 ? void 0 : _a.name) !== this.name) {
            return undefined;
        }
        var moonTags = card.tags.filter(function (t) { return t === Tags_1.Tags.MOON; });
        var count = moonTags.length;
        if (count > 0) {
            player.addResourceTo(this, count);
            if (this.resourceCount >= 3 && player.canAfford(0, { tr: { tr: 1 } })) {
                player.removeResourceFrom(this, 3, player.game, player, true);
                player.increaseTerraformRating();
            }
        }
        ;
        return undefined;
    };
    return TheArchaicFoundationInstitute;
}(Card_1.Card));
exports.TheArchaicFoundationInstitute = TheArchaicFoundationInstitute;
