"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Card = exports.staticCardProperties = void 0;
var CardType_1 = require("./CardType");
var Tags_1 = require("./Tags");
var Units_1 = require("../Units");
var CardRenderDynamicVictoryPoints_1 = require("./render/CardRenderDynamicVictoryPoints");
var CardRenderItemType_1 = require("./render/CardRenderItemType");
exports.staticCardProperties = new Map();
var Card = (function () {
    function Card(properties) {
        this.resourceCount = 0;
        var staticInstance = exports.staticCardProperties.get(properties.name);
        if (staticInstance === undefined) {
            if (properties.cardType === CardType_1.CardType.CORPORATION && properties.startingMegaCredits === undefined) {
                throw new Error('must define startingMegaCredits for corporation cards');
            }
            if (properties.cost === undefined) {
                if ([CardType_1.CardType.CORPORATION, CardType_1.CardType.PRELUDE, CardType_1.CardType.STANDARD_ACTION].includes(properties.cardType) === false) {
                    throw new Error(properties.name + " must have a cost property");
                }
            }
            Card.autopopulateMetadataVictoryPoints(properties);
            exports.staticCardProperties.set(properties.name, properties);
            staticInstance = properties;
        }
        this.properties = staticInstance;
    }
    Object.defineProperty(Card.prototype, "adjacencyBonus", {
        get: function () {
            return this.properties.adjacencyBonus;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(Card.prototype, "cardCost", {
        get: function () {
            return this.properties.cardCost;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(Card.prototype, "cardType", {
        get: function () {
            return this.properties.cardType;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(Card.prototype, "cost", {
        get: function () {
            return this.properties.cost === undefined ? 0 : this.properties.cost;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(Card.prototype, "initialActionText", {
        get: function () {
            return this.properties.initialActionText;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(Card.prototype, "metadata", {
        get: function () {
            return this.properties.metadata;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(Card.prototype, "requirements", {
        get: function () {
            return this.properties.requirements;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(Card.prototype, "name", {
        get: function () {
            return this.properties.name;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(Card.prototype, "resourceType", {
        get: function () {
            return this.properties.resourceType;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(Card.prototype, "startingMegaCredits", {
        get: function () {
            return this.properties.startingMegaCredits === undefined ? 0 : this.properties.startingMegaCredits;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(Card.prototype, "tags", {
        get: function () {
            return this.properties.tags === undefined ? [] : this.properties.tags;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(Card.prototype, "productionBox", {
        get: function () {
            return this.properties.productionBox || Units_1.Units.EMPTY;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(Card.prototype, "cardDiscount", {
        get: function () {
            return this.properties.cardDiscount;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(Card.prototype, "reserveUnits", {
        get: function () {
            return this.properties.reserveUnits || Units_1.Units.EMPTY;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(Card.prototype, "tr", {
        get: function () {
            return this.properties.tr || {};
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(Card.prototype, "victoryPoints", {
        get: function () {
            return this.properties.victoryPoints;
        },
        enumerable: false,
        configurable: true
    });
    Card.prototype.canPlay = function (_player) {
        return true;
    };
    Card.prototype.getVictoryPoints = function (player) {
        var _a, _b, _c;
        var vp1 = this.properties.victoryPoints;
        if (vp1 === 'special') {
            throw new Error('When victoryPoints is \'special\', override getVictoryPoints');
        }
        if (vp1 !== undefined) {
            if (typeof (vp1) === 'number') {
                return vp1;
            }
            if (vp1.type === 'resource') {
                return vp1.points * Math.floor(this.resourceCount / vp1.per);
            }
            else {
                var tag = vp1.type;
                var count = (_a = player === null || player === void 0 ? void 0 : player.getTagCount(tag, 'vps')) !== null && _a !== void 0 ? _a : 0;
                return vp1.points * Math.floor(count / vp1.per);
            }
        }
        var vps = this.properties.metadata.victoryPoints;
        if (vps === undefined) {
            return 0;
        }
        if (!(vps instanceof CardRenderDynamicVictoryPoints_1.CardRenderDynamicVictoryPoints)) {
            return vps;
        }
        if (vps.targetOneOrMore === true || vps.anyPlayer === true) {
            throw new Error('Not yet handled');
        }
        var units = 0;
        switch ((_b = vps.item) === null || _b === void 0 ? void 0 : _b.type) {
            case CardRenderItemType_1.CardRenderItemType.MICROBES:
            case CardRenderItemType_1.CardRenderItemType.ANIMALS:
            case CardRenderItemType_1.CardRenderItemType.FIGHTER:
            case CardRenderItemType_1.CardRenderItemType.FLOATERS:
            case CardRenderItemType_1.CardRenderItemType.ASTEROIDS:
            case CardRenderItemType_1.CardRenderItemType.PRESERVATION:
            case CardRenderItemType_1.CardRenderItemType.DATA_RESOURCE:
            case CardRenderItemType_1.CardRenderItemType.RESOURCE_CUBE:
            case CardRenderItemType_1.CardRenderItemType.SCIENCE:
            case CardRenderItemType_1.CardRenderItemType.CAMPS:
                units = (_c = this.resourceCount) !== null && _c !== void 0 ? _c : 0;
                break;
            case CardRenderItemType_1.CardRenderItemType.JOVIAN:
                units = player === null || player === void 0 ? void 0 : player.getTagCount(Tags_1.Tags.JOVIAN, 'vps');
                break;
            case CardRenderItemType_1.CardRenderItemType.MOON:
                units = player === null || player === void 0 ? void 0 : player.getTagCount(Tags_1.Tags.MOON, 'vps');
                break;
        }
        if (units === undefined) {
            throw new Error('Not yet handled');
        }
        return vps.points * Math.floor(units / vps.target);
    };
    Card.autopopulateMetadataVictoryPoints = function (properties) {
        var vps = properties.victoryPoints;
        if (vps === undefined) {
            return;
        }
        if (vps === 'special') {
            if (properties.metadata.victoryPoints === undefined) {
                throw new Error('When card.victoryPoints is \'special\', metadata.vp and getVictoryPoints must be supplied');
            }
            return;
        }
        else {
            if (properties.metadata.victoryPoints !== undefined) {
                throw new Error('card.victoryPoints and metadata.victoryPoints cannot be on the same card');
            }
        }
        if (typeof (vps) === 'number') {
            properties.metadata.victoryPoints = vps;
            return;
        }
        if (vps.type === 'resource') {
            if (properties.resourceType === undefined) {
                throw new Error('When defining a card-resource based VP, resourceType must be defined.');
            }
            properties.metadata.victoryPoints = CardRenderDynamicVictoryPoints_1.CardRenderDynamicVictoryPoints.resource(properties.resourceType, vps.points, vps.per);
            return;
        }
        else {
            properties.metadata.victoryPoints = CardRenderDynamicVictoryPoints_1.CardRenderDynamicVictoryPoints.tag(vps.type, vps.points, vps.per);
        }
    };
    return Card;
}());
exports.Card = Card;
