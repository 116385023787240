"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.TitanAirScrapping = void 0;
var Tags_1 = require("../Tags");
var CardType_1 = require("../CardType");
var CardName_1 = require("../../CardName");
var ResourceType_1 = require("../../common/ResourceType");
var SelectOption_1 = require("../../inputs/SelectOption");
var OrOptions_1 = require("../../inputs/OrOptions");
var CardRenderer_1 = require("../render/CardRenderer");
var Card_1 = require("../Card");
var TitanAirScrapping = (function (_super) {
    __extends(TitanAirScrapping, _super);
    function TitanAirScrapping() {
        var _this = _super.call(this, {
            cost: 21,
            tags: [Tags_1.Tags.JOVIAN],
            name: CardName_1.CardName.TITAN_AIRSCRAPPING,
            cardType: CardType_1.CardType.ACTIVE,
            resourceType: ResourceType_1.ResourceType.FLOATER,
            victoryPoints: 2,
            metadata: {
                cardNumber: 'C43',
                renderData: CardRenderer_1.CardRenderer.builder(function (b) {
                    b.action('Spend 1 titanium to add 2 floaters here.', function (eb) {
                        eb.titanium(1).startAction.floaters(2);
                    }).br;
                    b.or().br;
                    b.action('Spend 2 floaters here to increase your TR 1 step.', function (eb) {
                        eb.floaters(2).startAction.tr(1);
                    });
                }),
            },
        }) || this;
        _this.resourceCount = 0;
        return _this;
    }
    TitanAirScrapping.prototype.canAct = function (player) {
        if (player.titanium > 0) {
            return true;
        }
        if (this.resourceCount >= 2) {
            return player.canAfford(0, { tr: { tr: 1 } });
        }
        return false;
    };
    TitanAirScrapping.prototype.action = function (player) {
        var _this = this;
        var opts = [];
        var addResource = new SelectOption_1.SelectOption('Spend 1 titanium to add 2 floaters on this card', 'Spend titanium', function () { return _this.addResource(player); });
        var spendResource = new SelectOption_1.SelectOption('Remove 2 floaters on this card to increase your TR 1 step', 'Remove floaters', function () { return _this.spendResource(player); });
        if (this.resourceCount >= 2 && player.canAfford(0, { tr: { tr: 1 } })) {
            opts.push(spendResource);
        }
        if (player.titanium > 0) {
            opts.push(addResource);
        }
        if (opts.length === 1) {
            return opts[0].cb();
        }
        return new (OrOptions_1.OrOptions.bind.apply(OrOptions_1.OrOptions, __spreadArray([void 0], opts, false)))();
    };
    TitanAirScrapping.prototype.addResource = function (player) {
        player.addResourceTo(this, 2);
        player.titanium--;
        return undefined;
    };
    TitanAirScrapping.prototype.spendResource = function (player) {
        player.removeResourceFrom(this, 2);
        player.increaseTerraformRating();
        return undefined;
    };
    TitanAirScrapping.prototype.play = function () {
        return undefined;
    };
    return TitanAirScrapping;
}(Card_1.Card));
exports.TitanAirScrapping = TitanAirScrapping;
